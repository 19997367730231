import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { IReleaseApplication } from '../../actions/Releases'
import { createReleaseApplication } from '../../actions/Admin'

export const useCreateReleaseApplicationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: { application: IReleaseApplication }) => createReleaseApplication(props.application),
    onSuccess: async (data, props) => {
      await queryClient.invalidateQueries(['releaseApplications'])
    },
    onError: (error: Error) => {
      createErrorToast(error)
    }
  })
}
