import { createContext, useContext, useState } from 'react'
import { RouterProps } from '../router/RouterProps'

type AllFilterValues =
  | TeamsSearchFilterValues
  | UsersSearchFilterValues
  | CustomersSearchFilterValues
  | FlagsSearchFilterValues
  | UsersReportFilterValues

interface SearchFilterStoreValues {
  customersSearchFilter: CustomersSearchFilterValues
  setCustomersSearchFilter: React.Dispatch<React.SetStateAction<CustomersSearchFilterValues>>
  teamsSearchFilter: TeamsSearchFilterValues
  setTeamsSearchFilter: React.Dispatch<React.SetStateAction<TeamsSearchFilterValues>>
  usersSearchFilter: UsersSearchFilterValues
  setUsersSearchFilter: React.Dispatch<React.SetStateAction<UsersSearchFilterValues>>
  flagsSearchFilter: FlagsSearchFilterValues
  setFlagsSearchFilter: React.Dispatch<React.SetStateAction<FlagsSearchFilterValues>>
  usersReportFilter: UsersReportFilterValues
  setUsersReportFilter: React.Dispatch<React.SetStateAction<UsersReportFilterValues>>
  updateSearchParams: (
    props: RouterProps,
    filterStore: AllFilterValues,
    filters: string[],
    checkURLState?: boolean
  ) => void
}

interface SearchFilterStoreProps {
  children: React.ReactNode
}

interface ToggleFilter {
  value: boolean
  default: boolean
}

interface CustomersSearchFilterValues {
  showInternal: ToggleFilter
  showNonInternal: ToggleFilter
  searchValue: string
  searchCategory: string
}

const defaultCustomersSearchFilters: CustomersSearchFilterValues = {
  showInternal: {
    value: false,
    default: false
  },
  showNonInternal: {
    value: true,
    default: true
  },
  searchValue: '',
  searchCategory: ''
}

interface TeamsSearchFilterValues {
  showCustomerTeamsOnly: ToggleFilter
  showProductionTeamsOnly: ToggleFilter
  searchValue: string
  searchCategory: string
}

const defaultTeamsSearchFilters: TeamsSearchFilterValues = {
  showCustomerTeamsOnly: {
    value: false,
    default: false
  },
  showProductionTeamsOnly: {
    value: false,
    default: false
  },
  searchValue: '',
  searchCategory: ''
}

export interface UsersSearchFilterValues {
  searchValue: string
  searchCategory: string
  includeNonActiveUsers: boolean
  includeUsersWithoutRoles: boolean
}

const defaultUsersSearchFilters: UsersSearchFilterValues = {
  searchValue: '',
  searchCategory: '',
  includeNonActiveUsers: true,
  includeUsersWithoutRoles: true
}

export interface FlagsSearchFilterValues {
  showCustomerTeamsOnly: ToggleFilter
  showProductionTeamsOnly: ToggleFilter
  showEnabled: ToggleFilter
}

const defaultFlagsSearchFilters: FlagsSearchFilterValues = {
  showCustomerTeamsOnly: {
    value: false,
    default: false
  },
  showProductionTeamsOnly: {
    value: false,
    default: false
  },
  showEnabled: {
    value: true,
    default: true
  }
}

export interface UsersReportFilterValues {
  showCustomerTeamsOnly: ToggleFilter
  showProductionTeamsOnly: ToggleFilter
}

const defaultUsersReportFilters: UsersReportFilterValues = {
  showCustomerTeamsOnly: {
    value: false,
    default: true
  },
  showProductionTeamsOnly: {
    value: false,
    default: true
  }
}

export const updateSearchParams = (
  props: RouterProps,
  filterStore: AllFilterValues,
  filters: string[],
  checkURLState?: boolean
) => {
  const searchParams = new URLSearchParams(props.location.search)
  filters.forEach(filter => {
    const filterStoreEntry = filterStore[filter as keyof AllFilterValues] as ToggleFilter
    if (checkURLState) {
      const URLState =
        searchParams.get(filter) === (!filterStoreEntry.default).toString()
          ? !filterStoreEntry.default
          : filterStoreEntry.default
      if (URLState !== filterStoreEntry.value) {
        updateSearchParams(props, filterStore, filters)
      }
    } else {
      searchParams.delete(filter)

      if (filterStoreEntry.value !== filterStoreEntry.default) {
        searchParams.append(filter, filterStoreEntry.value.toString())
      }
    }
  })

  if (!checkURLState) {
    props.navigate({ search: searchParams.toString() })
  }
}

const SearchFilterStoreContext = createContext<SearchFilterStoreValues | undefined>(undefined)

/**
 * Hook to provide access to context within functional components that have been provided with the SearchFilterStore.
 */
export const useSearchFilterStore = () => {
  const context = useContext(SearchFilterStoreContext)
  if (context === undefined) {
    throw new Error('useSearchFilterStore must be used within SearchFilterStoreProvider')
  }
  return context
}

/**
 * Provider for search and filter store.
 *
 * Provides components with access to:
 * - Customers search and filter store (new model)
 * - Teams search and filter store (new model)
 */
export const SearchFilterStoreProvider = ({ children }: SearchFilterStoreProps) => {
  const [customersSearchFilter, setCustomersSearchFilter] =
    useState<CustomersSearchFilterValues>(defaultCustomersSearchFilters)
  const [teamsSearchFilter, setTeamsSearchFilter] = useState<TeamsSearchFilterValues>(defaultTeamsSearchFilters)
  const [usersSearchFilter, setUsersSearchFilter] = useState<UsersSearchFilterValues>(defaultUsersSearchFilters)
  const [flagsSearchFilter, setFlagsSearchFilter] = useState<FlagsSearchFilterValues>(defaultFlagsSearchFilters)
  const [usersReportFilter, setUsersReportFilter] = useState<UsersReportFilterValues>(defaultUsersReportFilters)

  const values: SearchFilterStoreValues = {
    customersSearchFilter,
    setCustomersSearchFilter,
    teamsSearchFilter,
    setTeamsSearchFilter,
    usersSearchFilter,
    setUsersSearchFilter,
    flagsSearchFilter,
    setFlagsSearchFilter,
    usersReportFilter,
    setUsersReportFilter,
    updateSearchParams
  }
  return <SearchFilterStoreContext.Provider value={values}>{children}</SearchFilterStoreContext.Provider>
}
