import { Icon, Input, Message } from 'semantic-ui-react'
import { ReactElement } from 'react'
import { EditableDisplayBaseField } from './EditableDisplayBaseField'

export interface EditableDisplayStringFieldProps {
  fieldName: string
  editFieldFn: (value: string) => void
  editedValue: string
  value: string
  error: boolean
  editable: boolean
  DisplayComponent?: ReactElement
  showField: (editable: boolean) => boolean
  isEditableTable?: boolean
}

export const EditableDisplayStringField = ({
  fieldName,
  editable,
  DisplayComponent,
  editFieldFn,
  value,
  editedValue,
  error,
  showField,
  isEditableTable = false
}: EditableDisplayStringFieldProps) => (
  <EditableDisplayBaseField
    isEditableTable={isEditableTable}
    fieldName={fieldName}
    editable={editable}
    DisplayComponent={DisplayComponent ? DisplayComponent : <p>{value}</p>}
    EditComponent={
      <>
        <Input
          fluid
          value={editedValue}
          onChange={(_, data) => {
            editFieldFn(data.value)
          }}
          error={error}
          name="editedSalesforceId"
        />
        {fieldName === 'Name' && (
          <Message color="blue">
            <Icon name="warning" size="large" />
            Editing the team name changes the team URL. The old URL will redirect to the new one.
          </Message>
        )}
      </>
    }
    showField={showField}
  />
)
