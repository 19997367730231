import { Checkbox, DropdownItemProps, Label, Select, Table } from 'semantic-ui-react'
import { useEffect } from 'react'
import { ownerOptions } from '../../newTeams/services/TeamInformationService'
import { INewTeamsRequest } from '../../../services/MigrateCustomerService'
import { InputWithError } from '../../formComponents/Inputs'
import { useValidateTeamService } from '../../../services/ValidateTeamService'
import { validateTeamName } from '../../../views/newTeams/services/CreateTeamService'

export interface IMigrateTeamRowProps {
  tenantId: string
  environmentOptions: DropdownItemProps[]
  newTeamsRequest: INewTeamsRequest[]
  migratedTenantIds: string[]
  updateTeamRequest: (tenantId: string, teamRequest: INewTeamsRequest) => void
  addMigratedTenantId: (tenantId: string) => void
  removeMigratedTenantId: (tenantId: string) => void
  setIsInvalidTeamsRequest: (isInvalid: boolean) => void
}

export const errorTeamNames = {
  errorNotUniqueTeamNameInForm: 'Team names must be unique.',
  errorNotUniqueTeamNameInDB: 'This team name already exists in a new model team. Team names must be unique.',
  errorNotValidTeamName:
    'Team name must be lowercase, start with a letter, and contain letters, numbers, or "-" character.'
}

export const MigrateTeamRow = ({
  tenantId,
  environmentOptions,
  newTeamsRequest,
  migratedTenantIds,
  updateTeamRequest,
  addMigratedTenantId,
  removeMigratedTenantId,
  setIsInvalidTeamsRequest
}: IMigrateTeamRowProps) => {
  const request = newTeamsRequest.find(req => req.team.tenantId === tenantId)
  const newTeam = request?.team
  const environment = request?.environment
  const isMigratedChecked = migratedTenantIds.includes(tenantId)
  const isTeamNameEmpty = isMigratedChecked && !newTeam?.primaryName
  const isTeamDescriptionEmpty = isMigratedChecked && !newTeam?.description
  const isOwnerEmpty = isMigratedChecked && !newTeam?.owner
  const isEnvironmentEmpty = isMigratedChecked && !environment
  const { teamNameIsValid, checkingTeamName, debouncedTeamName, editedTeamName, setEditedTeamName } =
    useValidateTeamService()
  const migratedTeamsRequest = newTeamsRequest.filter(req => migratedTenantIds.includes(req.team.tenantId))

  const errorNotUniqueTeamNameInForm =
    migratedTeamsRequest.filter(req => req.team.primaryName === newTeam?.primaryName).length > 1

  const errorNotUniqueTeamNameInDB = !(
    !editedTeamName.trim() ||
    debouncedTeamName !== editedTeamName ||
    teamNameIsValid ||
    checkingTeamName
  )

  const errorNotValidTeamName = !validateTeamName(newTeam?.primaryName ?? '')

  useEffect(() => {
    setIsInvalidTeamsRequest(errorNotUniqueTeamNameInDB || errorNotUniqueTeamNameInForm || errorNotValidTeamName)
  }, [errorNotUniqueTeamNameInDB, errorNotUniqueTeamNameInForm, errorNotValidTeamName])

  return (
    <Table.Row key={tenantId} className="team">
      <Table.Cell>
        <Checkbox
          toggle
          checked={isMigratedChecked}
          onChange={(_, data) => {
            if (data.checked) {
              addMigratedTenantId(tenantId)
            } else {
              removeMigratedTenantId(tenantId)
            }
          }}
          name={newTeam?.primaryName}
          disabled={environment === 'Production'}
          readOnly={environment === 'Production'}
        />
      </Table.Cell>
      <Table.Cell>
        <InputWithError
          required={isMigratedChecked}
          key={'teamName-' + tenantId}
          placeholder="Team name"
          name={'teamName-' + tenantId}
          error={isTeamNameEmpty}
          labelSelector={isTeamNameEmpty}
          labelMessage={'Team name is required'}
          value={newTeam?.primaryName ?? ''}
          onChange={(_, data) => {
            newTeam &&
              updateTeamRequest(tenantId, {
                environment: environment ?? '',
                team: { ...newTeam, primaryName: data.value }
              })
            setEditedTeamName(data.value)
          }}
        />
        {isMigratedChecked && errorNotUniqueTeamNameInDB && (
          <Label pointing="above" color="red">
            {errorTeamNames.errorNotUniqueTeamNameInDB}
          </Label>
        )}

        {isMigratedChecked && errorNotUniqueTeamNameInForm && (
          <Label pointing="above" color="red">
            {errorTeamNames.errorNotUniqueTeamNameInForm}
          </Label>
        )}

        {isMigratedChecked && errorNotValidTeamName && (
          <Label pointing="above" color="red">
            {errorTeamNames.errorNotValidTeamName}
          </Label>
        )}
      </Table.Cell>
      <Table.Cell>
        <InputWithError
          key={'teamDescription-' + tenantId}
          required={isMigratedChecked}
          placeholder="Team description"
          name={'teamDescription-' + tenantId}
          error={isTeamDescriptionEmpty}
          labelSelector={isTeamDescriptionEmpty}
          labelMessage="Team description is required"
          value={newTeam?.description ?? ''}
          onChange={(_, data) =>
            newTeam &&
            updateTeamRequest(tenantId, {
              environment: environment ?? '',
              team: { ...newTeam, description: data.value }
            })
          }
        />
      </Table.Cell>
      <Table.Cell>{newTeam?.region}</Table.Cell>
      <Table.Cell>{newTeam?.tenantId}</Table.Cell>
      <Table.Cell>{newTeam?.isSalesforce ? 'Yes' : 'No'}</Table.Cell>
      <Table.Cell>
        <Select
          required={isMigratedChecked}
          value={newTeam?.owner}
          error={isOwnerEmpty}
          options={ownerOptions}
          onChange={(_, data) =>
            newTeam &&
            updateTeamRequest(tenantId, {
              environment: environment ?? '',
              team: { ...newTeam, owner: data.value as string }
            })
          }
          fluid
        />
        {isMigratedChecked && !newTeam?.owner && (
          <Label pointing="above" color="red">
            Team owner is required
          </Label>
        )}
      </Table.Cell>
      <Table.Cell>
        <Select
          id="environment"
          required={isMigratedChecked}
          value={environment}
          error={isEnvironmentEmpty}
          options={environmentOptions}
          onChange={(_, data) => {
            newTeam &&
              updateTeamRequest(tenantId, {
                environment: (data.value as string) ?? '',
                team: newTeam
              })
            data.value === 'Production' && addMigratedTenantId(tenantId)
          }}
          fluid
        />
        {isEnvironmentEmpty && (
          <Label pointing="above" color="red">
            Team environment is required
          </Label>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
