import React from 'react'
import { Button, Form, Grid, InputProps, Modal } from 'semantic-ui-react'

interface IProps {
  createComponentHook: (componentName: string) => Promise<boolean>
}

export const CreateComponentsModal = (props: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [newComponentName, setNewComponentName] = React.useState<string>('')

  const submit = async () => {
    setSubmitting(true)
    if (await props.createComponentHook(newComponentName)) {
      setOpen(false)
      setNewComponentName('')
    }
    setSubmitting(false)
  }

  const changeComponentName = (event: React.SyntheticEvent, data: InputProps) => {
    setNewComponentName(data.value)
  }

  const clearNewComponent = () => {
    setNewComponentName('')
  }

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      size="mini"
      trigger={
        <Button className="form-button-sked-blue" primary content="Create Component" onClick={() => setOpen(true)} />
      }
    >
      <Modal.Header>Create Component</Modal.Header>
      <Modal.Content>
        <Form onSubmit={submit} loading={submitting}>
          <Form.Input
            label="Component Name"
            placeholder="Component Name"
            required
            name="componentName"
            onChange={changeComponentName}
            value={newComponentName}
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column floated="left">
                <Form.Button
                  id="modal-submit-no-padding"
                  type="submit"
                  className="modal-submit-no-padding"
                  content="Submit"
                  loading={submitting}
                  fluid
                />
              </Grid.Column>
              <Grid.Column floated="right" verticalAlign="bottom">
                <Form.Button type="reset" content="Reset" onClick={clearNewComponent} fluid />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
