import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { createSuccessToast } from '../../views/alertComponents/Alert'
import { deleteReleaseApplication } from '../../actions/Admin'

export const useDeleteReleaseApplicationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation((applicationName: string) => deleteReleaseApplication(applicationName), {
    onSuccess: (_data, applicationName) => {
      queryClient.invalidateQueries(['releaseApplication', applicationName])
      queryClient.invalidateQueries(['releaseApplications'])
      createSuccessToast(`Successfully deleted release application ${applicationName}`)
    },
    onError: error => {
      if (isAxiosError(error) && error.response?.data) {
        console.error(error.response.data)
      } else {
        console.error(error)
      }
    }
  })
}
