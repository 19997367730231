import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'
import { createDelivery, NewDelivery } from '../../actions/Deliveries'

export const useCreateDeliveryMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (delivery: NewDelivery) => createDelivery(delivery),
    onSuccess: async (_, delivery) => {
      createSuccessToast(`Created new delivery for ${delivery.applicationName} ${delivery.version}.`)
      await queryClient.invalidateQueries(['deliveries'])
    },
    onError: (error: Error) => {
      createErrorToast(error, { title: 'Error creating delivery' })
    }
  })
}
