import * as _ from 'lodash'
import { Button, Grid, Icon, Checkbox, Header } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import {
  getMultipleTenantFeatures,
  IFeatureFlagWithDescription,
  IMultiTenantFeatureFlags,
  TenantWithNameAndRegion
} from '../../actions/Tenants'
import { useGetFeatureFlagReportQuery } from '../../queries/GetFeatureFlagReportQuery'
import { useGetTenantsInfoQuery } from '../../queries/GetTenantsInfoQuery'
import { FeatureFlagList } from './components/FeatureFlagList'
import { FeatureEnabledForTenantList } from './components/FeatureEnabledForTenantList'

export interface TenantWithFeaturesNameAndRegion extends TenantWithNameAndRegion {
  featureFlags: IFeatureFlagWithDescription[]
}

export interface IFeatureFlagAggregate {
  [x: string]: { count: number; description: string }
}

export const aggregateTenantFeatures = (customTenants: TenantWithFeaturesNameAndRegion[]): IFeatureFlagAggregate => {
  const aggr: IFeatureFlagAggregate = {}
  customTenants.forEach(tenant => {
    tenant.featureFlags
      .filter(feature => feature.type === 'Feature')
      .forEach(feature => {
        aggr[feature.name] = {
          count: (aggr[feature.name]?.count || 0) + (feature.value ? 1 : 0),
          description: feature.description
        }
      })
  })
  return aggr
}

export const combineTenantsWithFeatures =
  (tenantFeatures: IMultiTenantFeatureFlags) =>
  (tenant: TenantWithNameAndRegion): TenantWithFeaturesNameAndRegion | undefined => {
    const features = tenantFeatures.successes[tenant.tenantId]
    if (!features) {
      return undefined
    }
    return {
      ...tenant,
      featureFlags: features
    }
  }

export const getFeaturesForTenants = async (
  tenants: TenantWithNameAndRegion[]
): Promise<TenantWithFeaturesNameAndRegion[]> => {
  const tenantsByRegion = _.groupBy<TenantWithNameAndRegion>(tenants, 'apiUrl')
  const featureRequests = Object.keys(tenantsByRegion).map(
    async (apiUrl): Promise<TenantWithFeaturesNameAndRegion[]> => {
      const regionTenants = tenantsByRegion[apiUrl]
      const tenantIds = regionTenants.map(tenant => tenant.tenantId)
      const tenantFeatures = await getMultipleTenantFeatures(tenantIds, apiUrl)
      const tenantsWithFeatures = regionTenants.map(combineTenantsWithFeatures(tenantFeatures))
      return _.compact(tenantsWithFeatures)
    }
  )
  const tenantsAndFeaturesByRegion = await Promise.all(featureRequests)
  return _.flatten(tenantsAndFeaturesByRegion)
}

const title = 'Feature Flag Report'

export const FeatureFlagReport = () => {
  const [featureFlag, setFeatureFlag] = useState<string | undefined>()
  const [showEnabled, setShowEnabled] = useState<boolean>(true)
  const [showCustomerOnly, setShowCustomerOnly] = useState<boolean>(false)
  const [showProductionOnly, setShowProductionOnly] = useState<boolean>(false)
  const { data: tenantsData, refetch } = useGetTenantsInfoQuery()
  const { data, isFetching } = useGetFeatureFlagReportQuery(tenantsData)
  const aggr = aggregateTenantFeatures(data || [])
  const { setDocumentTitle } = useDocumentTitle(title)

  useEffect(() => {
    setDocumentTitle(title)
  }, [])

  return (
    <div className="route-component legacy-page">
      <Grid columns={3} stackable verticalAlign="middle">
        <Grid.Column width={4}>
          <Grid verticalAlign="middle">
            <Grid.Column>
              {featureFlag && <Icon name="backward" onClick={() => setFeatureFlag(undefined)} className="clickable" />}
            </Grid.Column>
            <Grid.Column width={featureFlag ? 1 : 16}>
              <Header as="h3">{featureFlag || 'Feature flag report'}</Header>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column width={9} textAlign="right">
          {featureFlag && (
            <Checkbox
              toggle
              label="Show Customer Tenants Only"
              checked={showCustomerOnly}
              onClick={() => setShowCustomerOnly(prevState => !prevState)}
              style={{ padding: '5px' }}
            />
          )}
          {featureFlag && (
            <Checkbox
              toggle
              label="Show Production Tenants Only"
              checked={showProductionOnly}
              onClick={() => setShowProductionOnly(prevState => !prevState)}
              style={{ padding: '5px' }}
            />
          )}
          {featureFlag && (
            <Checkbox
              toggle
              label="Show Enabled"
              checked={showEnabled}
              onClick={() => setShowEnabled(prevState => !prevState)}
              style={{ padding: '5px' }}
            />
          )}
        </Grid.Column>
        <Grid.Column textAlign="right" width={3}>
          <Button size="large" color="blue" content="Fetch data" onClick={() => refetch()} loading={isFetching} />
        </Grid.Column>
      </Grid>
      {featureFlag ? (
        <FeatureEnabledForTenantList
          tenants={data || []}
          featureFlag={featureFlag}
          checkEnabled={showEnabled}
          showCustomerOnly={showCustomerOnly}
          showProductionOnly={showProductionOnly}
        />
      ) : (
        <FeatureFlagList
          tenants={data || []}
          featureFlagAggregate={aggr}
          loading={isFetching}
          setFeatureFlag={setFeatureFlag}
        />
      )}
    </div>
  )
}
