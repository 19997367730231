import { Button } from 'semantic-ui-react'
import { ReorderDirection } from '../../../views/newTeams/services/LoginConnectionsService'

interface ReorderLoginControlsProps {
  handleReorder: (index: number, direction: ReorderDirection) => void
  index: number
  disabled?: boolean
}

const ReorderLoginControls = ({ handleReorder, index, disabled = false }: ReorderLoginControlsProps) => (
  <>
    <Button
      data-testid="reorder-up-button"
      icon="angle up"
      className="form-button-sked-blue"
      onClick={() => handleReorder(index, ReorderDirection.Up)}
      disabled={disabled}
    />
    <Button
      data-testid="reorder-down-button"
      icon="angle down"
      className="form-button-sked-blue"
      onClick={() => handleReorder(index, ReorderDirection.Down)}
      disabled={disabled}
    />
  </>
)

export default ReorderLoginControls
