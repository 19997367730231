import 'prismjs/themes/prism-solarizedlight.css'
import * as ReactDOM from 'react-dom'
import './styles/utils.scss'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { AuthStoreProvider } from './context/AuthContext'
import { initDataDogRum } from './analytics/instrumentation'

initDataDogRum()

ReactDOM.render(
  <BrowserRouter>
    <AuthStoreProvider>
      <App />
    </AuthStoreProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
