import { NewDeleteModal } from '../../modalComponents/NewDeleteModal'
import { useDeleteTeamService } from '../services/DeleteTeamService'

export interface DeleteTeamModalProps {
  teamId: string
  redirectFn: () => void
  menuItem?: boolean
}

const DeleteTeamModal = ({ teamId, redirectFn, menuItem }: DeleteTeamModalProps) => {
  const type = 'Team'
  const buttonText = 'Delete Team'
  const deleteCustomerService = useDeleteTeamService({ teamId, type, buttonText, redirectFn })

  return <NewDeleteModal {...deleteCustomerService} menuItem={menuItem} />
}

export default DeleteTeamModal
