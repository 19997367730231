import { Grid, Input, Label, Pagination } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { Link } from 'react-router-dom'
import { Table } from '../../../components/TableComponents/ReactTable'
import { IPackageTenant } from '../../../actions/Package'
import { handlePaginationChange } from '../../tableComponents/TableFunctions'
import { formatDate } from '../../../utils/dateUtils'
import { PackageTenantQueryResponse, useGetPackageTenantsQuery } from '../../../queries/packages/GetPackageTenantsQuery'
import { useInfiniteTableService } from '../services/InfiniteTableService'
import { environmentColours } from '../../../views/newTeams/component/NewTeamsList'
import { PackageInstallationStatus } from './PackageInstallationStatus'

export interface IPackageTenantsList {
  region: string
  packageName: string
  packageVersion: string
  title: string
}

export const PackageTenantsList = (props: IPackageTenantsList) => {
  const { title, packageName, packageVersion, region } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [activePage, setActivePage] = useState(1)
  const packageTenantInfiniteQueryResult = useGetPackageTenantsQuery(
    packageName,
    packageVersion,
    region,
    debouncedSearchValue
  )

  const { pageData, isLoading, maxPages, handleSearchChange } = useInfiniteTableService<PackageTenantQueryResponse>({
    infiniteQueryResult: packageTenantInfiniteQueryResult,
    setSearchValue,
    activePage,
    setActivePage
  })

  const packageTenants = pageData?.result

  const packageTenantColumns = [
    {
      Header: 'Name',
      accessor: (packageTenant: IPackageTenant) => packageTenant.team?.name,
      Cell: ({ row }: { row: Row<IPackageTenant> }) =>
        row.original.team ? <Link to={`/teams/${row.original.team?.id}`}>{row.original.team?.name}</Link> : <></>
    },
    {
      Header: 'Tenant ID',
      accessor: (packageTenant: IPackageTenant) => packageTenant.tenantId
    },
    {
      Header: 'Status',
      accessor: (packageTenant: IPackageTenant) => packageTenant.status,
      Cell: ({ row }: { row: Row<IPackageTenant> }) => <PackageInstallationStatus status={row.original.status} />
    },
    {
      Header: 'Installed',
      accessor: (packageTenant: IPackageTenant) => packageTenant.completedAt,
      Cell: ({ row }: { row: Row<IPackageTenant> }) => (
        <>{formatDate(row.original.completedAt, 'MMM DD, YYYY - hh:mma')}</>
      )
    },
    {
      Header: 'Installed by',
      accessor: (packageTenant: IPackageTenant) => packageTenant.createdBy
    },
    {
      Header: 'Customer',
      accessor: (packageTenant: IPackageTenant) => packageTenant.team?.customerName,
      Cell: ({ row }: { row: Row<IPackageTenant> }) =>
        row.original.team ? (
          <Link to={`/customers/${row.original.team.customerId}`}>{row.original.team.customerName}</Link>
        ) : (
          <></>
        )
    },
    {
      Header: 'Environment',
      accessor: (packageTenant: IPackageTenant) => packageTenant.team?.environment,
      Cell: ({ row }: { row: Row<IPackageTenant> }) =>
        row.original.team ? (
          <Label color={environmentColours[row.original.team?.environment] ?? 'grey'} basic>
            {row.original.team?.environment}
          </Label>
        ) : (
          <></>
        )
    },
    {
      Header: 'Owner',
      accessor: (packageTenant: IPackageTenant) => packageTenant.team?.owner
    }
  ]

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9} verticalAlign="middle">
            <h3>{title}</h3>
          </Grid.Column>
          <Grid.Column width={7} textAlign="right">
            <Input
              placeholder="Search by Tenant ID"
              onChange={handleSearchChange}
              icon="search"
              iconPosition="left"
              value={searchValue}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table
        columns={packageTenantColumns}
        data={packageTenants ?? []}
        emptyMessage="No tenants found"
        textAlign="left"
        loading={isLoading}
      />

      {maxPages > 1 && (
        <Pagination
          activePage={activePage}
          boundaryRange={0}
          siblingRange={4}
          onPageChange={handlePaginationChange(setActivePage)}
          totalPages={maxPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      )}
    </>
  )
}
