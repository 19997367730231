import { IFeatureFlags, IFeatureFlagWithDescription } from '../../../actions/Tenants'
import { VendorClaim } from '../../../actions/Utils'
import { TenantApiService } from '../../newTeams/services/TenantApiService'

import { useFeaturesQuery } from '../../../queries/features/GetFeaturesQuery'
import { useFeatureMutation } from '../../../mutations/features/UpdateFeatureMutation'
import { TenantFeaturesTable } from './TenantFeaturesTable'

interface IProps {
  tenantApi: TenantApiService
}

export const toNameAndValueMap = (features: IFeatureFlagWithDescription[]): IFeatureFlags =>
  new Map(features.map(feature => [feature.name, feature.value]))

export const TenantFeatures = (props: IProps) => {
  const { data: featuresWithDescriptions, isFetching } = useFeaturesQuery(
    props.tenantApi.tenantId,
    props.tenantApi.teamAPI,
    props.tenantApi.parentLoading
  )
  const { mutate: pushFeatures } = useFeatureMutation()

  const features = featuresWithDescriptions ? featuresWithDescriptions.filter(f => f.type === 'Feature') : []
  const featuresGa = features.filter(f => f.status === 'GeneralAvailability')
  const featuresBeta = features.filter(f => f.status === 'Beta')
  const featuresAlpha = features.filter(f => f.status === 'Alpha')
  const featuresOther = features.filter(
    f => f.status === 'Development' || f.status === 'Internal' || f.status === 'Deprecated'
  )
  const packageCaps = featuresWithDescriptions?.filter(f => f.type === 'Package') ?? []

  const handleSubmit = (patch: IFeatureFlags, successCallback: () => void) => {
    pushFeatures({ tenantId: props.tenantApi.tenantId, teamAPI: props.tenantApi.teamAPI, patch, successCallback })
  }

  const loading = props.tenantApi.parentLoading || isFetching

  return (
    <div>
      <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <TenantFeaturesTable
          tenantId={props.tenantApi.tenantId}
          editable={true}
          featuresWithDescriptions={featuresGa || []}
          handleSubmit={handleSubmit}
          loading={loading}
          title="General availability features"
          subtitle="Features that are fully implemented and stable"
          hasStatusColumn={false}
          hasDefaultColumn={true}
        />
      </div>
      {featuresBeta.length > 0 && (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <TenantFeaturesTable
            tenantId={props.tenantApi.tenantId}
            editable={true}
            featuresWithDescriptions={featuresBeta || []}
            handleSubmit={handleSubmit}
            loading={loading}
            title="Beta features"
            subtitle="Features that are available for customers to use but may be unstable"
            hasStatusColumn={false}
            hasDefaultColumn={true}
          />
        </div>
      )}
      {featuresAlpha.length > 0 && (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <TenantFeaturesTable
            tenantId={props.tenantApi.tenantId}
            editable={true}
            featuresWithDescriptions={featuresAlpha || []}
            handleSubmit={handleSubmit}
            loading={loading}
            title="Alpha features"
            subtitle="Features that are available for customers to use but may be unstablFeatures that are available for preview by selected customers and are unstable"
            hasStatusColumn={false}
            hasDefaultColumn={true}
          />
        </div>
      )}
      {featuresOther.length > 0 && (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <TenantFeaturesTable
            tenantId={props.tenantApi.tenantId}
            editable={true}
            featuresWithDescriptions={featuresOther || []}
            handleSubmit={handleSubmit}
            loading={loading}
            title="Other features"
            subtitle="Features that are under development, for internal use or deprecated"
            hasStatusColumn={true}
            hasDefaultColumn={true}
          />
        </div>
      )}
      {props.tenantApi.vendor === VendorClaim.Salesforce && packageCaps.length > 0 && (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <TenantFeaturesTable
            tenantId={props.tenantApi.tenantId}
            editable={false}
            featuresWithDescriptions={packageCaps || []}
            handleSubmit={handleSubmit}
            loading={loading}
            title="Package capabilities"
            subtitle="Read-only flags that describe the data model and capabilities of the customer's installed Salesforce package"
            hasStatusColumn={false}
            hasDefaultColumn={false}
          />
        </div>
      )}
    </div>
  )
}
