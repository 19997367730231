import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { ReactElement, useEffect, useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { AxiosError } from 'axios'
import { isUndefined, startCase } from 'lodash'

export type EditableInformationFieldData<T> =
  | EditableInformationOtherEditableFieldData<T>
  | EditableInformationOtherFieldData<T>
  | EditableInformationStringFieldData<T>

export interface EditableInformationOtherEditableFieldData<T> extends EditableInformationBaseFieldData<T> {
  isString: false
  DisplayComponent: (props: { value?: T[keyof T] }) => ReactElement
  EditComponent: (props: { editedValue?: T[keyof T]; editFn: (editedValue?: T[keyof T]) => void }) => ReactElement
  editable: true
  error: boolean
  editFieldFn: (value?: T[keyof T]) => void
  editedValue?: T[keyof T]
}

export interface EditableInformationOtherFieldData<T> extends EditableInformationBaseFieldData<T> {
  isString: false
  DisplayComponent: (props: { value?: T[keyof T] }) => ReactElement
  editable: false
}

export interface EditableInformationStringFieldData<T> extends EditableInformationBaseFieldData<T> {
  isString: true
  error: boolean
  DisplayComponent?: (props: { value?: string }) => ReactElement
  editFieldFn: (value?: string) => void
  editedValue?: string
}

export interface EditableInformationBaseFieldData<T> {
  isString: boolean
  fieldName: keyof T
  headerTitle?: string
  value?: T[keyof T]
  editable: boolean
  showField: (editing: boolean) => boolean
}

export interface EditableRecordInformationService<T> {
  title: string
  recordName: string
  data?: T
  loading: boolean
  fields: EditableInformationFieldData<T>[]
  editing: boolean
  submitting: boolean
  toggleEditing: () => void
  disableEditing: () => void
  saveEdits: (editedData: Partial<T>) => void
  editedData: Partial<T>
  editIsValid: boolean
}

export type EditableInformationFieldConfig<T> =
  | EditableInformationOtherEditableFieldConfig<T>
  | EditableInformationOtherFieldConfig<T>
  | EditableInformationStringFieldConfig<T>

export interface EditableInformationOtherEditableFieldConfig<T> extends EditableInformationBaseFieldConfig<T> {
  isString: false
  editable: true
  DisplayComponent: (props: { value?: any }) => ReactElement
  EditComponent: (props: { editedValue?: T[keyof T]; editFn: (editedValue?: T[keyof T]) => void }) => ReactElement
}

export interface EditableInformationOtherFieldConfig<T> extends EditableInformationBaseFieldConfig<T> {
  isString: false
  editable: false
  DisplayComponent: (props: { value?: any }) => ReactElement
}

export interface EditableInformationStringFieldConfig<T> extends EditableInformationBaseFieldConfig<T> {
  fieldName: keyof T
  isString: true
  DisplayComponent?: (props: { value?: string }) => ReactElement
}

export interface EditableInformationBaseFieldConfig<T> {
  isString: boolean
  headerTitle?: string
  fieldName: keyof T
  validateField?: (editedData?: T) => boolean
  editable: boolean
  showField?: (values?: T, editedValues?: T) => (editing: boolean) => boolean
}

const createFieldData =
  <T extends object>(
    setEditedData: (setFn: (prevState: T | undefined) => T | undefined) => void,
    data?: T,
    editedData?: T
  ) =>
  (field: EditableInformationFieldConfig<T>): EditableInformationFieldData<T> => {
    const showField = field.showField ? field.showField(data, editedData) : () => true
    if (field.isString) {
      const editedValue = (editedData?.[field.fieldName] || '') as string
      const editFieldFn = (value?: string) =>
        setEditedData(prevState => ({ ...(prevState || ({} as T)), [field.fieldName]: value || '' }))
      return {
        headerTitle: field.headerTitle,
        fieldName: field.fieldName,
        isString: true,
        editable: field.editable,
        value: data?.[field.fieldName],
        editedValue,
        editFieldFn,
        error: field.validateField ? !field.validateField(editedData) : false,
        DisplayComponent: field.DisplayComponent,
        showField
      }
    } else if (field.editable) {
      const editedValue = editedData?.[field.fieldName] as T[keyof T]
      const editFieldFn = (value?: T[keyof T]) =>
        setEditedData(prevState => ({ ...(prevState || ({} as T)), [field.fieldName]: value }))
      return {
        headerTitle: field.headerTitle,
        fieldName: field.fieldName,
        isString: false,
        editable: true,
        value: data?.[field.fieldName],
        editedValue,
        editFieldFn,
        error: field.validateField ? !field.validateField(editedData) : false,
        DisplayComponent: field.DisplayComponent,
        EditComponent: field.EditComponent,
        showField
      }
    } else {
      return {
        headerTitle: field.headerTitle,
        fieldName: field.fieldName,
        isString: false,
        editable: false,
        value: data?.[field.fieldName],
        DisplayComponent: field.DisplayComponent,
        showField
      }
    }
  }

const createEditedData = <T extends object>(
  fields: EditableInformationFieldData<T>[],
  data?: T,
  editedData?: T
): Partial<T> => {
  const editedDataPartial: Partial<T> = {}
  fields.forEach(field => {
    if (field.editable && data?.[field.fieldName] !== editedData?.[field.fieldName]) {
      editedDataPartial[field.fieldName] = editedData?.[field.fieldName] as T[keyof T]
    }
  })
  return editedDataPartial
}

const validateEditedData = <T extends object>(
  fieldInformation: EditableInformationFieldConfig<T>[],
  editedDiff: Partial<T>,
  editedData?: T
): boolean =>
  fieldInformation
    .map(field => {
      if (field.editable && !isUndefined(editedDiff[field.fieldName]) && field.validateField) {
        return field.validateField(editedData)
      }
      return true
    })
    .reduce((prev, curr) => prev && curr)

export const useEditableRecordInformationService = <T extends object>(
  recordName: string,
  recordQuery: UseQueryResult<T>,
  saveMutation: (onSaveFn: () => void) => UseMutationResult<T, AxiosError, Partial<T>>,
  fieldInformation: EditableInformationFieldConfig<T>[]
) => {
  const { value: editing, toggle: toggleEditing, setFalse: disableEditing } = useBoolean(false)
  const { isLoading: loading, data } = recordQuery
  const { mutate: saveEdits, isLoading: submitting } = saveMutation(() => disableEditing())
  const [editedData, setEditedData] = useState<T | undefined>()

  useEffect(() => {
    if (!editing) {
      setEditedData(data)
    }
  }, [data])

  const fields = fieldInformation.map(createFieldData(setEditedData, data, editedData))

  const editedDataPartial = createEditedData(fields, data, editedData)

  const editIsValid =
    validateEditedData(fieldInformation, editedDataPartial) && Object.keys(editedDataPartial).length > 0

  return {
    title: `${startCase(recordName)} Information`,
    recordName,
    data,
    loading,
    fields,
    editing,
    toggleEditing,
    disableEditing,
    saveEdits,
    editedData: editedDataPartial,
    editIsValid,
    submitting
  }
}
