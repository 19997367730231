import { createSuccessToast } from '../../alertComponents/Alert'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useDeleteTeamMutation } from '../../../mutations/DeleteTeamMutation'

export interface DeleteTeamServiceConfig {
  teamId: string
  type: string
  buttonText: string
  redirectFn: () => void
}

export interface DeleteTeamService {
  deleteMethod: () => void
  content: string
  type: string
  buttonText: string
  isDeleting: boolean
}

export const useDeleteTeamService = ({
  teamId,
  type,
  buttonText,
  redirectFn
}: DeleteTeamServiceConfig): DeleteTeamService => {
  const { data: team } = useGetTeamQuery(teamId)
  const deleteTeamMutation = useDeleteTeamMutation(teamId)(() => {
    createSuccessToast(`Team with name ${team?.primaryName} deleted`)
    redirectFn()
  })

  return {
    deleteMethod: () => deleteTeamMutation.mutate(),
    content: `Are you sure you want to delete ${team?.primaryName}?`,
    type,
    buttonText,
    isDeleting: deleteTeamMutation.isLoading
  }
}
