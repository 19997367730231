import { compact } from 'lodash'
import { IRegionInfo, getRegions } from '../actions/Regions'
import { ITeam, getTeams } from '../actions/Teams'
import { ITenant, TenantWithNameAndRegion, getTenants } from '../actions/Tenants'

export const CombineTeamTenantInfoService = (
  teams: ITeam[],
  tenants: ITenant[],
  regions: IRegionInfo[]
): TenantWithNameAndRegion[] => {
  const tenantsWithRegion = tenants.map(tenant => {
    const team = teams.find(findTeam => findTeam.id === tenant.teamId)
    if (!team) {
      return undefined
    }
    const region = regions.find(findRegion => findRegion.region === team.region)
    if (!region) {
      return undefined
    }
    return {
      ...tenant,
      teamName: team.name,
      region: region.name,
      apiUrl: region.server.api
    }
  })
  return compact(tenantsWithRegion)
}

export const getTenantsInfo = async () => {
  const requests: [Promise<ITeam[]>, Promise<ITenant[]>, Promise<IRegionInfo[]>] = [
    getTeams(),
    getTenants(),
    getRegions()
  ]
  const requestsData = await Promise.all(requests)
  const [teams, tenants, regions] = requestsData
  return CombineTeamTenantInfoService(teams, tenants, regions)
}
