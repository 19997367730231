import { useGetConnectedFunctionQuery } from '../../../queries/GetConnectedFunctionQuery'
import { IConnectedFunction } from '../../../actions/ConnectedFunction'

export interface PkgrServiceConfig {
  tenantId: string
  region: string
}

export interface PkgrService {
  connectedFunctions: IConnectedFunction[]
  isLoading: boolean
}

export const usePkgrService = ({ tenantId, region }: PkgrServiceConfig): PkgrService => {
  const { data: connectedFunctions, isLoading } = useGetConnectedFunctionQuery(tenantId, region)

  return {
    connectedFunctions: connectedFunctions ?? [],
    isLoading
  }
}
