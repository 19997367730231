import Editor from 'react-simple-code-editor'
import { highlight, languages } from 'prismjs'

interface JsonViewerEditableProps extends JsonViewerBaseProps {
  changeValue?: (value: string) => void
}

interface JsonViewerBaseProps {
  editable: boolean
  value: string
}

const codeHighlight = (code: string) => highlight(code, languages.json, 'json')

const validateJson = (value: string) => {
  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}

export const JsonEditableViewer = (props: JsonViewerEditableProps) => {
  const { value, editable, changeValue } = props
  return (
    <Editor
      value={value}
      onValueChange={editedValue => (editable && changeValue ? changeValue(editedValue) : null)}
      highlight={codeHighlight}
      padding={editable ? 10 : 0}
      disabled={!editable}
      style={
        editable
          ? {
              borderRadius: '.28571429rem',
              borderColor: validateJson(value as string) ? 'rgba(34,36,38,.15)' : '#e0b4b4',
              borderStyle: 'solid',
              borderWidth: '1px'
            }
          : undefined
      }
    />
  )
}
