import * as React from 'react'
import { Loader } from 'semantic-ui-react'

interface ILoaderProps {
  loading: boolean
  children: JSX.Element
}

export const LoaderWrap: React.FC<ILoaderProps> = props =>
  props.loading ? (
    <div style={{ textAlign: 'center' }}>
      <Loader inline active />
    </div>
  ) : (
    props.children
  )
