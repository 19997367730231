import * as React from 'react'
import { Dropdown, DropdownItemProps, DropdownProps, Input, InputOnChangeData } from 'semantic-ui-react'

import { ISearch } from '../Utils'

export interface IProps {
  searchValue: ISearch
  searchOptions: DropdownItemProps[]
  handleSearchChange: (event: React.ChangeEvent, data: InputOnChangeData) => void
  handleSearchCategoryChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
}

export class SearchInput extends React.PureComponent<IProps> {
  render() {
    const { searchValue, searchOptions } = this.props

    return (
      <Input
        label={
          <Dropdown
            options={searchOptions}
            onChange={this.props.handleSearchCategoryChange}
            value={searchValue.searchCategory}
          />
        }
        labelPosition="right"
        value={searchValue.searchValue}
        placeholder="Search"
        onChange={this.props.handleSearchChange}
        icon="search"
        iconPosition="left"
      />
    )
  }
}
