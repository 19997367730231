import { authService, authServiceConfig } from './Endpoints'
import { IResult } from './Utils'

export interface TeamConnection {
  enabled: boolean
  connectionId: string
  type: string
  displayName: string
  action?: string
}

export interface ConnectionOnTeamRequest {
  teamId: string
  connectionId: string
}

export interface ConnectionOnTeamConfigRequest {
  api: string
  customerId: string
  description: string
  request: ConnectionOnTeamRequest
}

export interface TeamConnectionsConfigRequest {
  api: string
  teamId: string
}

export const getTeamConnectionsByTeamId = (teamConnectionsConfig: TeamConnectionsConfigRequest) =>
  authService
    .get<IResult<TeamConnection[]>>(`/admin/team/${teamConnectionsConfig.teamId}/applicable-connections`, {
      ...authServiceConfig,
      baseURL: `${teamConnectionsConfig.api}/authorization`
    })
    .then(response => response.data.result)

export const enableConnectionOnTeam = (connectionOnTeamConfig: ConnectionOnTeamConfigRequest) =>
  authService
    .post(
      `/admin/customer/${connectionOnTeamConfig.customerId}/connection/enable-on-team`,
      connectionOnTeamConfig.request,
      {
        ...authServiceConfig,
        baseURL: `${connectionOnTeamConfig.api}/authorization`
      }
    )
    .then(response => response.data.result)

export const disableConnectionOnTeam = (connectionOnTeamConfig: ConnectionOnTeamConfigRequest) =>
  authService
    .post(
      `/admin/customer/${connectionOnTeamConfig.customerId}/connection/disable-on-team`,
      connectionOnTeamConfig.request,
      {
        ...authServiceConfig,
        baseURL: `${connectionOnTeamConfig.api}/authorization`
      }
    )
    .then(response => response.data.result)
