import { useInfiniteQuery } from '@tanstack/react-query'
import { getPackageTenants, IPackageTenant } from '../../actions/Package'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { DEFAULT_PAGE_SIZE, DEFAULT_REFETCH_INTERVAL } from './PackageQueryConfig'

export interface PackageTenantQueryResponse {
  currentPage: number
  totalResults: number
  result: IPackageTenant[]
}

export const useGetPackageTenantsQuery = (packageName: string, packageVersion: string, region: string, query: string) =>
  useInfiniteQuery(
    ['packageTenants', packageName, packageVersion, region, query],
    ({ pageParam = {} }) =>
      getPackageTenants(
        {
          currentPage: pageParam.currentPage ?? 1,
          pageSize: DEFAULT_PAGE_SIZE,
          tenantId: query,
          packageVersion,
          packageName
        },
        region
      ),
    {
      getNextPageParam: lastPage => {
        const currentPage = lastPage?.currentPage ?? 1
        const totalResults = lastPage?.totalResults ?? 0
        return currentPage * DEFAULT_PAGE_SIZE < totalResults ? { currentPage: currentPage + 1 } : undefined
      },
      retry: false,
      onError: (error: Error) => createErrorToast(error),
      refetchInterval: DEFAULT_REFETCH_INTERVAL
    }
  )
