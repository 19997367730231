import { useMemo } from 'react'
import { getTeamsColumns } from '../../../newTeams/component/NewTeamsList'
import { useGetTeamsByConnectionIdsQuery } from '../../../../queries/GetTeamsByConnectionIdsQuery'
import { Table } from '../../../../components/TableComponents/ReactTable'

export const TeamsLoginOptionList = ({ customerId, loginOptionId }: { customerId: string; loginOptionId: string }) => {
  const { data: teams, isFetching } = useGetTeamsByConnectionIdsQuery(customerId, [loginOptionId])
  const displayTeams = useMemo(
    () => teams?.find(result => result.connectionId === loginOptionId)?.result ?? [],
    [teams]
  )

  return (
    <>
      <p style={{ color: 'gray' }}>A list of teams that enabled this login option</p>
      <small>
        {displayTeams?.length || 0} team{displayTeams?.length === 1 ? '' : 's'}
      </small>
      <div className="scrollable-with-pagination">
        <Table
          loading={isFetching}
          columns={getTeamsColumns(false, true)}
          data={displayTeams}
          emptyMessage="No teams have enabled this login option yet"
          color="blue"
          textAlign="left"
        />
      </div>
    </>
  )
}
