import { useMemo } from 'react'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import Breadcrumbs from './Breadcrumbs'
import { BreadcrumbItem } from './DynamicBreadcrumbs'

interface CustomerBreadcrumbWrapperProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  initialBreadcrumbs: BreadcrumbItem[]
  customerId: string
}

/**
 * Customer Details breadcrumb for new customer model.
 *
 * Retrieves customer information and builds final step of breadcrumb.
 */
const CustomerBreadcrumbWrapper = ({ initialBreadcrumbs, customerId }: CustomerBreadcrumbWrapperProps) => {
  const { data: customer } = useGetCustomerQuery(customerId)

  const breadcrumbItems = useMemo(() => {
    if (!customer?.name) {
      return initialBreadcrumbs
    }
    return [
      ...initialBreadcrumbs,
      {
        path: `/customers/${customerId}`,
        name: customer.name
      } as BreadcrumbItem
    ]
  }, [customer, customerId, initialBreadcrumbs])

  return <Breadcrumbs items={breadcrumbItems} />
}

export default CustomerBreadcrumbWrapper
