import { Button, Segment, Table } from 'semantic-ui-react'
import * as React from 'react'
import { DynamicMetadataService } from '../../services/DynamicMetadataService'
import { TableLoaderWrap } from '../../views/tableComponents/TableLoader'
import { EditableTableHeader } from './EditableTableComponents/EditableTableHeader'
import { DynamicEditRow } from './DynamicMetadataTableComponents/DynamicEditRow'
import { DynamicDisplayRow } from './DynamicMetadataTableComponents/DynamicDisplayRow'

export const DynamicMetadataTable = ({
  tableTitle,
  addRow,
  metadata,
  metadataTypes,
  editedMetadata,
  editedKeys,
  editedValues,
  editedTypes,
  reset,
  loading,
  setKey,
  setType,
  deleteRow,
  makeSetValue,
  updateMetadata,
  isUpdating,
  editing,
  isEditable,
  toggleEditing,
  metadataIsValid
}: DynamicMetadataService) => (
  <div data-testid="metadata">
    <EditableTableHeader
      editing={editing}
      isEditable={isEditable}
      loading={loading}
      reset={reset}
      submit={() => updateMetadata(editedMetadata)}
      submitting={isUpdating}
      tableTitle={tableTitle}
      toggleEditing={toggleEditing}
      validateSubmit={metadataIsValid}
    />
    <Segment color="blue" style={{ flexGrow: 0 }}>
      <Table celled basic="very" stackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Key</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            {editing && <Table.HeaderCell>Remove</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={loading} array={editing ? [1] : editedKeys} emptyMessage="No Metadata">
          <React.Fragment>
            <Table.Body>
              {editing
                ? editedKeys.map((_key, index) => (
                    <DynamicEditRow
                      key={index}
                      editedKey={editedKeys[index]}
                      editedValue={editedValues[index] ?? ''}
                      editedType={editedTypes[index]}
                      makeSetValue={makeSetValue(index)}
                      setKey={setKey(index)}
                      setType={setType(index)}
                      deleteRow={deleteRow(index)}
                    />
                  ))
                : Object.keys(metadata || {}).map(key => (
                    <DynamicDisplayRow key={key} metadataKey={key} type={metadataTypes[key]} value={metadata?.[key]} />
                  ))}
              {editing && (
                <Table.Row key="new-field">
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell />
                  <Table.Cell>
                    <Button icon="plus" onClick={addRow} data-testid="plus-button" />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </React.Fragment>
        </TableLoaderWrap>
      </Table>
    </Segment>
  </div>
)
