import { Grid, Icon, Segment } from 'semantic-ui-react'
import { useState } from 'react'
import { useDeliveryChecksTemplatesQuery } from '../../queries/deliveries/GetDeliveryCheckTemplatesQuery'
import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { useReleaseApplicationDetailsQuery } from '../../queries/settings/GetReleaseApplicationDetailsQuery'
import { ReleaseApplicationsList } from './components/ReleaseApplicationsList'
import { CreateReleaseApplicationModal } from './components/CreateReleaseApplicationModal'

export const ReleaseApplications = () => {
  const {
    data: releaseApplications,
    isLoading: releaseApplicationsLoading,
    refetch
  } = useReleaseApplicationDetailsQuery()
  const { data: deliveryCheckTemplates, isLoading: templatesLoading } = useDeliveryChecksTemplatesQuery()
  const searchOptions = [
    { text: 'All', value: 'all' },
    { text: 'Name', value: 'applicationName' },
    { text: 'Component', value: 'component' },
    { text: 'Repository Owner', value: 'repositoryOwner' },
    { text: 'Repository Name', value: 'repositoryName' }
  ]
  const [createModalOpen, setCreateModalOpen] = useState(false)

  const simpleSearch = useSimpleSearch(searchOptions)

  return (
    <Segment color="blue" className="scrollable">
      <Grid stackable verticalAlign="middle">
        <Grid.Column width={6}>
          <h3>Releasable Applications</h3>
        </Grid.Column>
        <Grid.Column textAlign="right" width={10}>
          <Icon
            size="large"
            name="refresh"
            onClick={refetch}
            loading={templatesLoading || releaseApplicationsLoading}
            className="clickable"
          />
          <SimpleSearch simpleSearch={simpleSearch} />
          <CreateReleaseApplicationModal
            open={createModalOpen}
            setOpen={setCreateModalOpen}
            deliveryCheckTemplates={deliveryCheckTemplates || []}
          />
        </Grid.Column>
      </Grid>

      <ReleaseApplicationsList
        applications={releaseApplications || []}
        searchValue={simpleSearch}
        deliveryCheckTemplates={deliveryCheckTemplates || []}
        loading={templatesLoading || releaseApplicationsLoading}
      />
    </Segment>
  )
}
