import { Grid, Icon, Segment } from 'semantic-ui-react'

import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useDeploymentTemplatesQuery } from '../../queries/deployments/GetDeploymentTemplateQuery'
import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { CreateDeploymentTemplateModal } from './components/CreateDeploymentTemplateModal'
import { DeploymentTemplatesDetailsModal } from './components/DeploymentTemplatesDetailsModal'
import { DeploymentTemplatesList } from './components/DeploymentTemplatesList'

export const DeploymentTemplates = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalTemplateName, setModalTemplateName] = useState('')
  const { data: deploymentTemplates, status: deploymentTemplatesStatus } = useDeploymentTemplatesQuery()
  const searchOptions = [
    { text: 'All', value: 'all' },
    { text: 'Name', value: 'name' },
    { text: 'Application Name', value: 'applicationName' }
  ]
  const simpleSearch = useSimpleSearch(searchOptions)

  const queryClient = useQueryClient()
  const refresh = () => queryClient.invalidateQueries(['deploymentTemplates'])

  const closeModal = () => {
    setModalOpen(false)
    setModalTemplateName('')
  }

  const createTemplateDetailsLink = (name: string) => () => {
    setModalOpen(true)
    setModalTemplateName(name)
  }

  return (
    <Segment color="blue" className="scrollable">
      <Grid stackable verticalAlign="middle">
        <Grid.Column width={6}>
          <h3>Deployment Templates</h3>
        </Grid.Column>
        <Grid.Column textAlign="right" width={10}>
          <Icon
            size="large"
            name="refresh"
            onClick={refresh}
            loading={deploymentTemplatesStatus === 'loading'}
            className="clickable"
          />
          <SimpleSearch simpleSearch={simpleSearch} />
          <CreateDeploymentTemplateModal updateTemplates={refresh} />
        </Grid.Column>
      </Grid>
      <DeploymentTemplatesDetailsModal modalOpen={modalOpen} closeModal={closeModal} templateName={modalTemplateName} />
      <DeploymentTemplatesList
        templates={deploymentTemplates ?? []}
        loading={deploymentTemplatesStatus === 'loading'}
        makeDetailsLink={createTemplateDetailsLink}
        searchValue={simpleSearch}
      />
    </Segment>
  )
}
