import { Icon, Table } from 'semantic-ui-react'

import { Delivery } from '../../../actions/Deliveries'

interface IProps {
  deliveries?: Delivery[]
  approvedOnly: boolean
  primeDeliveryIndex: number
  version: string
  tag: string
  handleRowSelection: (index: number) => () => void
}

export const DeliveryCompareSelectorTable = (props: IProps) => {
  const deliveryStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return <Icon name="clock" style={{ color: '#008cff' }} />
      case 'approved':
        return <Icon name="check" color="green" />
      case 'rejected':
        return <Icon name="x" color="red" />
      default:
        return <Icon name="question" />
    }
  }

  const deliveryIndxDiffIcon = (indxDiff: number) => {
    // negative means the selected delivery is AHEAD of the main delivery
    if (indxDiff < 0) {
      return <Icon name="forward" />
    } else if (indxDiff > 0) {
      return <Icon name="backward" />
    } else {
      return <Icon name="minus" />
    }
  }

  return (
    <Table striped color="blue" size="small" stackable sortable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Delivery </Table.HeaderCell>
          <Table.HeaderCell>Tag </Table.HeaderCell>
          <Table.HeaderCell textAlign="center"> Version Difference </Table.HeaderCell>
          <Table.HeaderCell textAlign="center"> Status </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.deliveries?.map((delivery, idx) => {
          if (
            (props.approvedOnly && delivery.status !== 'approved') ||
            !delivery.version.includes(props.version) ||
            !delivery.tag.includes(props.tag)
          ) {
            return
          } else {
            return (
              <Table.Row key={delivery.id} onClick={props.handleRowSelection(idx)}>
                <Table.Cell>{delivery.version}</Table.Cell>
                <Table.Cell>{delivery.tag}</Table.Cell>
                <Table.Cell textAlign="center">
                  {deliveryIndxDiffIcon(idx - props.primeDeliveryIndex)}
                  {Math.abs(idx - props.primeDeliveryIndex)}
                </Table.Cell>
                <Table.Cell textAlign="center">{deliveryStatusIcon(delivery.status)}</Table.Cell>
              </Table.Row>
            )
          }
        })}
      </Table.Body>
    </Table>
  )
}
