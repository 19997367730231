import { Table, TableHeaderCellProps } from 'semantic-ui-react'

interface IProps {
  title: string
}

type SortedHeaderCellProps = IProps & TableHeaderCellProps

export const SortedHeaderCell =
  (sortColumn: string, sortDirection: 'ascending' | 'descending', onClick: (clickedColumn: string) => () => void) =>
  (props: SortedHeaderCellProps) => {
    const name = props.name || props.title.toLowerCase()
    return (
      <Table.HeaderCell
        {...props}
        title={undefined}
        name={name}
        sorted={sortColumn === name ? sortDirection : undefined}
        onClick={onClick(name)}
      >
        {props.title}
      </Table.HeaderCell>
    )
  }
