import { Form } from 'semantic-ui-react'
import { CreateCustomerConnectionService } from '../../../services/CreateCustomerConnectionService'
import { InputWithError } from '../../../../../views/formComponents/Inputs'
interface EmailPasswordLoginOptionProps {
  createCustomerConnectionService: CreateCustomerConnectionService
}

const EmailPasswordLoginOption = ({ createCustomerConnectionService }: EmailPasswordLoginOptionProps) => {
  const { setDescription, description, isErrorFormMessageVisible } = createCustomerConnectionService
  return (
    <Form.Field width={16} error={false}>
      <label htmlFor="description">Description *</label>

      <InputWithError
        placeholder="Enter description"
        name="description"
        onChange={(_, data) => setDescription(data.value)}
        width={12}
        value={description}
        labelMessage={'Description is required'}
        labelSelector={isErrorFormMessageVisible && description === ''}
        required
        error={isErrorFormMessageVisible && description === ''}
      />
    </Form.Field>
  )
}

export default EmailPasswordLoginOption
