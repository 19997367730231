import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { logError } from '../../analytics/error-log'
import { createErrorToast, createSuccessToast } from '../alertComponents/Alert'

interface IProps {
  submitFn: () => Promise<any>
  header: string
  disabled?: boolean
  content: string
  successMessage: string
  buttonClassName?: string
  globalLoading?: boolean
}

interface IState {
  open: boolean
  loading: boolean
}

export class SimpleModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  toggleOpen = () => {
    this.setState(prevProps => ({ open: !prevProps.open }))
  }

  handleSubmitFn = async () => {
    if (!this.props.disabled) {
      this.setState({ loading: true })
      try {
        await this.props.submitFn()
        this.toggleOpen()
        if (this.props.successMessage) {
          createSuccessToast(this.props.successMessage)
        }
      } catch (error) {
        logError(error)
        createErrorToast(error)
        this.toggleOpen()
      } finally {
        this.setState({ loading: false })
      }
    }
  }

  render() {
    return (
      <Modal
        open={this.state.open}
        onClose={this.toggleOpen}
        size="mini"
        trigger={
          <Button
            content={this.props.header}
            onClick={this.toggleOpen}
            className={this.props.buttonClassName}
            disabled={this.props.disabled || this.props.globalLoading}
            loading={this.props.globalLoading}
          />
        }
      >
        <Modal.Header>{this.props.header}</Modal.Header>
        <Modal.Content>
          <p>{this.props.content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button name="no" negative content="No" onClick={this.toggleOpen} />
          <Button name="yes" positive content="Yes" onClick={this.handleSubmitFn} loading={this.state.loading} />
        </Modal.Actions>
      </Modal>
    )
  }
}
