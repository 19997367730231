import { Button, Modal, Message } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'

export interface ConnectionOnTeamModalProps {
  connectionId: string
  enableConnection: (connectionId: string) => void
  displayName: string
}

const INFO_ENABLE_CONNECTION_MODAL =
  'When enabled, a button for this login option displays on the login page and you can invite users to log into the team using this option.'

const EnableConnectionOnTeamModal = ({ connectionId, enableConnection, displayName }: ConnectionOnTeamModalProps) => {
  const { value: modalEnabledIsOpen, toggle: toggleModalEnabledIsOpen } = useBoolean(false)

  const enableTeamConnection = () => {
    enableConnection(connectionId)
    toggleModalEnabledIsOpen()
  }

  return (
    <Modal
      id={connectionId}
      trigger={
        <Button onClick={toggleModalEnabledIsOpen} basic fluid size="medium">
          Enable
        </Button>
      }
      size="small"
      open={modalEnabledIsOpen}
      closeOnDimmerClick
      onClose={toggleModalEnabledIsOpen}
      closeIcon
    >
      <Modal.Content>
        <Message
          content={
            <div>
              <p>{INFO_ENABLE_CONNECTION_MODAL}</p>
            </div>
          }
          icon="info"
          info
        />
        <p>Are you sure you want to enable &quot;{displayName}&quot;?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel-add-login-option-button"
          content="No, Cancel"
          onClick={toggleModalEnabledIsOpen}
          style={{
            border: 'none',
            backgroundColor: 'transparent'
          }}
        />

        <Button
          id="create-team-button"
          data-testid="create-team-form-button"
          className="form-button-sked-blue"
          content="Yes, Enable"
          onClick={() => enableTeamConnection()}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default EnableConnectionOnTeamModal
