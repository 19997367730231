import LoginConnectionsTable from '../../../components/TableComponents/LoginConnectionsTable'
import { useLoginConnectionsService } from '../services/LoginConnectionsService'

const TeamLoginConnectionsTable = ({ teamId, customerId }: { teamId: string; customerId: string }) => {
  const loginConnectionsService = useLoginConnectionsService({
    tableTitle: 'Login Connections',
    teamId
  })

  return <LoginConnectionsTable {...loginConnectionsService} />
}

export default TeamLoginConnectionsTable
