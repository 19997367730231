import { adminApi } from './Endpoints'
import { IRelease } from './Releases'

export interface IBuild {
  releaseId: number
  buildNumber: number
  production: boolean
  createdAt: string
}

interface INewVersionBody {
  version: string
  hotfixBaseVersion?: string
}

export const getMobileReleases = async (): Promise<IRelease[]> =>
  adminApi.get<IRelease[]>('/mobile/release').then(response => response.data)

export const startMobileBuild = async (type: string): Promise<object> =>
  adminApi.post<object>(`/mobile/build/start/${type}`).then(response => response.data)

export const createMobileVersion = async (version: string, hotfixVersion?: string) => {
  const body = {
    version
  } as INewVersionBody
  if (hotfixVersion) {
    body.hotfixBaseVersion = hotfixVersion
  }
  return adminApi.post('/mobile/release/create/Sked-X', body)
}

export const reviewMobileRelease = async (version: string) =>
  adminApi.post('/mobile/release/review/Sked-X', { version })

export const completeMobileRelease = async (version: string) =>
  adminApi.post('/mobile/release/complete/Sked-X', { version })

export const getMobileBuilds = async (application: string, version: string): Promise<IBuild[]> =>
  adminApi.get<IBuild[]>(`/mobile/release/${application}/${version}/builds`).then(response => response.data)
