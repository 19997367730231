import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { createErrorToast } from '../alertComponents/Alert'

interface IProps {
  deleteMethod(): Promise<any>
  updateData(): void
  content: string
  type: string
  buttonText?: string
  disabled?: boolean
}

interface IState {
  open: boolean
  loading: boolean
}

export class DeleteModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  handleDelete = () => {
    this.setState({ loading: true })
    this.props
      .deleteMethod()
      .then(() => {
        this.setState({ open: false, loading: false })
        this.props.updateData()
      })
      .catch(error => {
        this.setState({ open: false, loading: false })
        createErrorToast(error)
      })
  }

  render() {
    const { open, loading } = this.state
    const { buttonText, disabled } = this.props

    return (
      <Modal
        trigger={
          <Button
            icon={buttonText ? undefined : 'delete'}
            content={buttonText}
            onClick={this.toggleOpen}
            color="red"
            disabled={disabled}
          />
        }
        size="mini"
        closeIcon
        open={open}
        onClose={this.toggleOpen}
      >
        <Modal.Header>{'Delete ' + this.props.type}</Modal.Header>
        <Modal.Content>
          <p>{this.props.content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggleOpen} content="Cancel" />
          <Button negative id="confirm-modal-delete" content="Delete" onClick={this.handleDelete} loading={loading} />
        </Modal.Actions>
      </Modal>
    )
  }
}
