import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query'
import { Team, getTeamById } from '../actions/NewTeams'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useGetTeamQuery = (teamId: string): UseQueryResult<Team> =>
  useQuery(['team', teamId], () => getTeamById(teamId), {
    onError: (error: Error) => createErrorToast(error),
    keepPreviousData: true
  })
export const prefetchGetTeamQuery = async (queryClient: QueryClient, teamId?: string) => {
  if (teamId) {
    await queryClient.prefetchQuery({
      queryKey: ['team', teamId],
      queryFn: () => getTeamById(teamId)
    })
  }
}
