import { Icon, Table } from 'semantic-ui-react'
import { IBuild } from '../../../actions/Mobile'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'

interface IProps {
  builds: IBuild[]
  loading: boolean
}

const sortFn = (build1: IBuild, build2: IBuild) =>
  build1.buildNumber > build2.buildNumber ? -1 : build1.buildNumber < build2.buildNumber ? 1 : 0

export const BuildsList = (props: IProps) => (
  <Table striped color="blue" stackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Number</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Production</Table.HeaderCell>
        <Table.HeaderCell>Built At</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <TableLoaderWrap loading={props.loading} array={props.builds} emptyMessage="No Jira Tickets Found for this Release">
      <Table.Body>
        {props.builds.sort(sortFn).map(build => (
          <Table.Row key={build.buildNumber}>
            <Table.Cell>{build.buildNumber}</Table.Cell>
            <Table.Cell textAlign="center">
              <Icon name={build.production ? 'check' : 'close'} />
            </Table.Cell>
            <Table.Cell>{build.createdAt}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </TableLoaderWrap>
  </Table>
)
