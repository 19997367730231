import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'

interface IProps {
  deleteMethod(): void
  content: string
  type: string
  isDeleting: boolean
  buttonText?: string
  disabled?: boolean
  menuItem?: boolean
}

export const NewDeleteModal: React.FC<IProps> = ({
  deleteMethod,
  content,
  type,
  buttonText,
  disabled,
  isDeleting,
  menuItem
}) => {
  const { value: modalIsOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false)
  const [deletionStarted, setDeletionStarted] = useState(false)

  // useEffect might not fire in the short time between deletion starting and finishing
  // so make sure we capture the change in another way
  React.useEffect(() => {
    if (deletionStarted && !isDeleting) {
      closeModal()
    }
  }, [deletionStarted, isDeleting, closeModal])

  const deleteAndClose = () => {
    deleteMethod()
    setDeletionStarted(true)
  }

  return (
    <Modal
      trigger={
        <Button
          data-testid="delete-action-button"
          icon={buttonText ? undefined : 'trash alternate'}
          content={buttonText}
          onClick={openModal}
          disabled={disabled}
          color={menuItem ? undefined : 'red'}
          basic={menuItem}
          className={menuItem ? 'modal-delete-menu' : undefined}
        />
      }
      size="mini"
      closeIcon
      open={modalIsOpen}
      onClose={closeModal}
      aria-label={`Confirm Delete ${type}`}
    >
      <Modal.Header>{'Delete ' + type}</Modal.Header>
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal} content="No" />
        <Button
          negative
          id="confirm-modal-delete"
          content="Yes"
          onClick={deleteAndClose}
          loading={isDeleting}
          disabled={isDeleting}
        />
      </Modal.Actions>
    </Modal>
  )
}
