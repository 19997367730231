import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getInstalledPackage, InstalledPackage, InstalledPackageParams } from '../../actions/Package'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { DEFAULT_REFETCH_INTERVAL } from './PackageQueryConfig'

export const useGetInstalledPackageQuery = (
  params: InstalledPackageParams,
  region: string
): UseQueryResult<InstalledPackage[]> =>
  useQuery(['InstalledPackage', params], () => getInstalledPackage(params, region), {
    onError: error => createErrorToast(error),
    refetchInterval: DEFAULT_REFETCH_INTERVAL,
    enabled: !!params.tenantId
  })
