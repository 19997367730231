import * as _ from 'lodash'
import * as React from 'react'
import { Button, Grid, Popup, Segment } from 'semantic-ui-react'
import { getOnlineEnvironments, IOnlineEnvironment, updateOnlineEnvironments } from '../../actions/Environments'
import { createErrorToast } from '../alertComponents/Alert'
import { LoaderWrap } from '../loadingComponents/LoaderWrap'
import { EnvironmentStatus } from './components/EnvironmentStatus'

type ColumnNum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16

export interface IState {
  environments: IOnlineEnvironment[]
  loading: boolean
  updating: boolean
  lastUpdate: string
}

export class Environments extends React.PureComponent<object, IState> {
  constructor(props: object) {
    super(props)
    this.state = {
      environments: [],
      lastUpdate: '',
      loading: false,
      updating: false
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      const environments = await getOnlineEnvironments()
      this.setState({ environments: environments.data, lastUpdate: environments.lastUpdate, loading: false })
    } catch (error) {
      this.setState({ loading: false })
      createErrorToast(error)
    }
  }

  renderEnvironments = () => {
    const renderEnvironments = _.chunk(this.state.environments, 6) as IOnlineEnvironment[][]
    return renderEnvironments.map((environments, index) => (
      <Grid.Row key={index}>
        {environments.map(environment => (
          <Grid.Column key={environment.environment.id} textAlign="center">
            <EnvironmentStatus environment={environment} />
          </Grid.Column>
        ))}
      </Grid.Row>
    ))
  }

  reloadEnvironments = async () => {
    try {
      this.setState({ updating: true })
      const environments = await updateOnlineEnvironments()
      this.setState({ updating: false, environments: environments.data, lastUpdate: environments.lastUpdate })
    } catch (error) {
      this.setState({ updating: false })
    }
  }

  render() {
    const { environments, loading, updating, lastUpdate } = this.state
    return (
      <Segment color={'blue'} className="scrollable">
        <Grid verticalAlign="middle" columns={2}>
          <Grid.Column textAlign="left">
            <h3>Online Environments</h3>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Popup
              position="top right"
              trigger={
                <Button
                  style={{ float: 'right' }}
                  icon="redo"
                  color="blue"
                  loading={updating}
                  onClick={this.reloadEnvironments}
                />
              }
              content="Reload the status of all environments."
            />
          </Grid.Column>
        </Grid>
        <LoaderWrap loading={loading}>
          {environments.length ? (
            <div>
              <Grid
                stackable
                verticalAlign="middle"
                columns={(environments.length > 6 ? 6 : environments.length) as ColumnNum}
                className="scrollable"
              >
                {this.renderEnvironments()}
              </Grid>
              <p style={{ marginTop: '20px' }}>Last Updated: {lastUpdate}</p>
            </div>
          ) : (
            <p>No Environments Available from this Instance</p>
          )}
        </LoaderWrap>
      </Segment>
    )
  }
}
