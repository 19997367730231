import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useGetTeamLoginConnectionsQuery } from '../../../queries/GetTeamLoginConnectionsQuery'
import Env from '../../../env/Env'
import {
  ConnectionOnTeamConfigRequest,
  TeamConnection,
  TeamConnectionsConfigRequest
} from '../../../actions/NewTeamConnections'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useEnableTeamLoginOptionMutation } from '../../../mutations/EnableTeamLoginOptionMutation'
import { useDisableTeamLoginOptionMutation } from '../../../mutations/DisableTeamLoginOptionMutation'
import { createErrorToast } from '../../alertComponents/Alert'

export type login_option_action = 'enable' | 'disable'
export interface TeamLoginTableUtil {
  name: string
  enable: {
    sendRequest: (connectionId: string) => void
  }
  disable: {
    sendRequest: (connectionId: string) => void
  }
}

export interface LoginOptionsServiceConfig {
  teamId: string
}
export interface LoginOptionsService {
  skeduloLoginOptionsData: TeamConnection[]
  customerLoginOptionsData: TeamConnection[]
  isLoading: boolean
  isUpdating: boolean
  skeduloTeamLoginTableUtil: TeamLoginTableUtil
  customerTeamLoginTableUtil: TeamLoginTableUtil
  customerId: string
}

export const useLoginOptionsService = ({ teamId }: LoginOptionsServiceConfig): LoginOptionsService => {
  // team data
  const { data: team, isLoading: isLoadingTeam } = useGetTeamQuery(teamId)

  // Handle team connection details querying
  const { data: regionInfo, isLoading: isLoadingRegions } = useGetRegionQuery()
  const teamApi = regionInfo?.find(region => region.region.toLowerCase() === team?.region.toLowerCase())?.server.api
  const teamConnectionsConfig: TeamConnectionsConfigRequest = { api: teamApi ?? Env.API, teamId }

  // Get login options data
  const { data: loginOptions, isLoading: isLoadingGetConnections } =
    useGetTeamLoginConnectionsQuery(teamConnectionsConfig)
  const optionsGrouping = (option: TeamConnection) => option.type === 'skedulo' || option.type === 'general-database'
  const customerLoginOptionsData = loginOptions?.filter(option => !optionsGrouping(option)) ?? []
  const skeduloLoginOptionsData = loginOptions?.filter(optionsGrouping) ?? []

  // mutation to enable / disable login options
  const enableTeamLoginConMutation = useEnableTeamLoginOptionMutation(teamId, team?.customerId)
  const disableTeamLoginConMutation = useDisableTeamLoginOptionMutation(teamId, team?.customerId)

  const validRequest = (req: ConnectionOnTeamConfigRequest) =>
    req.api && req.customerId && req.description && req.request.teamId && req.request.connectionId

  const processTeamLoginOption = (type: login_option_action, connectionId: string) => {
    const loginOption = loginOptions?.find(option => option.connectionId === connectionId)

    const loginConRequest: ConnectionOnTeamConfigRequest = {
      api: teamApi ?? Env.API,
      customerId: team?.customerId ?? '',
      description: loginOption?.displayName ?? '',
      request: {
        teamId,
        connectionId: connectionId ?? ''
      }
    }

    if (validRequest(loginConRequest) && type === 'enable') {
      enableTeamLoginConMutation.mutate(loginConRequest)
    } else if (validRequest(loginConRequest) && type === 'disable') {
      disableTeamLoginConMutation.mutate(loginConRequest)
    } else {
      createErrorToast('Problem enabling/disabling login connection, please ensure all row details are valid')
    }
  }

  const enableTeamLoginOption = (connectionId: string) => processTeamLoginOption('enable', connectionId)
  const disableTeamLoginOption = (connectionId: string) => processTeamLoginOption('disable', connectionId)

  const skeduloTeamLoginTableUtil: TeamLoginTableUtil = {
    name: 'Skedulo',
    enable: {
      sendRequest: enableTeamLoginOption
    },
    disable: {
      sendRequest: disableTeamLoginOption
    }
  }

  const customerTeamLoginTableUtil: TeamLoginTableUtil = {
    name: 'Customer',
    enable: {
      sendRequest: enableTeamLoginOption
    },
    disable: {
      sendRequest: disableTeamLoginOption
    }
  }

  return {
    skeduloLoginOptionsData,
    customerLoginOptionsData,
    isLoading: isLoadingTeam || isLoadingRegions || isLoadingGetConnections,
    isUpdating: enableTeamLoginConMutation.isLoading || disableTeamLoginConMutation.isLoading,
    skeduloTeamLoginTableUtil,
    customerTeamLoginTableUtil,
    customerId: team?.customerId ?? ''
  }
}
