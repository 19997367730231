import * as React from 'react'

import { Breadcrumb, Button, DropdownProps, Grid, Icon, InputOnChangeData } from 'semantic-ui-react'
import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IEnvironmentDetails } from '../../actions/Environments'
import { createErrorToast } from '../alertComponents/Alert'
import { DeployApplicationModal } from '../deployments/components/DeployApplicationModal'
import { SearchableSelector } from '../formComponents/Selectors'
import { useApplicationsQuery } from '../../queries/applications/GetApplicationsQuery'
import { useEnvironmentsQuery } from '../../queries/environments/GetEnvironmentsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useRouterProps } from '../../router/RouterProps'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { SimpleSearch, useSimpleSearch } from '../searchComponents/SimpleSearch'
import { ApplicationCompareList } from './components/ApplicationCompareList'

const searchOptions = [
  { text: 'All', value: 'all' },
  { text: 'Application', value: 'name' },
  { text: 'Tag', value: 'tag' }
]

export interface IApplicationComparison {
  name: string
  environment1Tag: string
  environment2Tag: string
}

const title = 'Compare Applications'

export const ApplicationCompare = () => {
  const [showAll, setShowAll] = useState(true)
  const { data: applications, status: applicationsStatus } = useApplicationsQuery()
  const { data: environments, status: environmentsStatus } = useEnvironmentsQuery()
  useDocumentTitle(title)
  const routerProps = useRouterProps()
  const queryClient = useQueryClient()
  const modal = useDeploymentModal()
  const simpleSearch = useSimpleSearch(searchOptions)

  const getEnvironmentFromId = (id: string | null) => {
    const idNum = parseInt(id || '', 10)
    if (Number.isInteger(idNum)) {
      return environments?.find(environment => environment.id === idNum)
    }
    return undefined
  }

  const search = new URLSearchParams(routerProps.location.search)
  const environment1 = getEnvironmentFromId(search.get('env1'))
  const environment2 = getEnvironmentFromId(search.get('env2'))

  const getEnvironmentOptions = () =>
    environments?.map(environment => ({
      text: `${environment.name} - ${environment.instance}`,
      value: environment.id.toString()
    })) || []

  const handleEnvironmentChange =
    (environmentNum: string) =>
    (_event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => {
      const env1 = environmentNum === '1' ? data.value : search.get('env1')
      const env2 = environmentNum === '2' ? data.value : search.get('env2')
      routerProps.navigate({
        search: `?env1=${env1}&env2=${env2}`
      })
    }

  const getEnvironmentTag = (applicationName: string, environment: IEnvironmentDetails) =>
    applications?.find(
      application =>
        (application.name === applicationName || application.meta.name === applicationName) &&
        application.instance === environment.instance &&
        application.environment === environment.name
    )?.meta.tag || ''

  const getTableData = () => {
    if (!environment1 || !environment2) {
      return []
    }
    const applicationNames = new Set(applications?.map(application => application.name || application.meta.name))
    return Array.from(applicationNames)
      .map(applicationName => ({
        name: applicationName,
        environment1Tag: getEnvironmentTag(applicationName, environment1),
        environment2Tag: getEnvironmentTag(applicationName, environment2)
      }))
      .filter(application => application.environment1Tag || application.environment2Tag)
  }

  const toggleShowAll = () => setShowAll(prevState => !prevState)

  const handleRedeploy =
    (envIndx: number, application: IApplicationComparison, environment: IEnvironmentDetails | undefined) => () => {
      if (environment === undefined) {
        createErrorToast('Unable to deploy to undefined environment.')
      } else {
        modal.populateDeployment({
          env: environment.name,
          instance: environment.instance,
          application: application.name,
          tag: application[`environment${(envIndx % 2) + 1}Tag` as keyof IApplicationComparison]
        })
        modal.setModalOpen(true)
      }
    }

  return (
    <div className="route-component">
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <Grid verticalAlign="middle">
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column>
              <Icon
                size="large"
                name="refresh"
                onClick={async () => {
                  await queryClient.invalidateQueries(['applications'])
                }}
                loading={applicationsStatus === 'loading'}
                className="clickable"
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleSearch simpleSearch={simpleSearch} />
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Grid>
            <Grid.Column width={6}>
              <SearchableSelector
                placeholder="Environment 1"
                options={getEnvironmentOptions()}
                onChange={handleEnvironmentChange('1')}
                value={environment1?.id.toString() || ''}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <SearchableSelector
                placeholder="Environment 2"
                options={getEnvironmentOptions()}
                onChange={handleEnvironmentChange('2')}
                value={environment2?.id.toString() || ''}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                content={showAll ? 'Diff' : 'All'}
                className="form-button-sked-blue"
                primary
                fluid
                onClick={toggleShowAll}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
      <ApplicationCompareList
        applications={getTableData()}
        searchValue={simpleSearch}
        handleRedeploy={handleRedeploy}
        loading={applicationsStatus === 'loading' || environmentsStatus === 'loading'}
        showAll={showAll}
        environment1={environment1}
        environment2={environment2}
      />
      <DeployApplicationModal modal={modal} />
    </div>
  )
}
