import { adminApi } from './Endpoints'

export const DEFAULT_DEPLOYMENT = {
  id: 0,
  status: '',
  timestamp: '',
  instance: '',
  application: '',
  success: false,
  output: ' ',
  env: '',
  tag: '',
  duration: 0,
  config: '',
  explanation: '',
  check_mode: false
}

export interface IDeploymentEnvironment {
  id: number
  name: string
  instance: string
  deploymentTemplates: IDeploymentTemplate[]
  environment: string
}

export interface IDeploymentTemplate {
  name: string
  applicationName: string | null
}

export interface IEnvironment {
  name: string
  instance: string
}

export type TDeploymentDetails = IDeployment

export interface IDeployment {
  id: number
  success: boolean
  status: string
  timestamp: string
  env: string
  instance: string
  application: string
  tag?: string
  duration: number
  config: string
  check_mode: boolean
  explanation?: string
  output: string
  user: string
  repositoryName?: string
}

export interface IOldDeployment {
  env: string
  instance: string
  application: string
  tag?: string
  config?: string
  check_mode?: boolean
}

export interface INewDeployment {
  application: string
  docker_tag?: string
  user: string
  environments: IEnvironment[]
  branch?: string
  check_mode?: boolean
}

export const getDeployments = async (pageSize: number, environment?: IEnvironment): Promise<IDeployment[]> => {
  const response = await adminApi.get<IDeployment[]>('/deploy/deployment', {
    params: {
      page_size: pageSize,
      environment: environment && environment.name,
      instance: environment && environment.instance
    }
  })
  return response.data
}

export const getLatestDeploymentsByApplication = async (
  environment: string,
  application: string
): Promise<IDeployment[]> => {
  const response = await adminApi.get<IDeployment[]>(`/deploy/deployment/latest/${environment}/${application}`, {
    params: {
      environment,
      application
    }
  })
  return response.data
}

export const getDeployment = async (env: string, instance: string, id: string): Promise<IDeployment> => {
  const response = await adminApi.get<IDeployment>(`/deploy/deployment/${env}/${instance}/${id}`)
  return response.data
}

export const createDeployment = async (deployment: INewDeployment): Promise<IDeployment[]> => {
  const response = await adminApi.post<IDeployment[]>('/deploy', { ...deployment })
  return response.data
}

export const getDeploymentEnvironments = async (): Promise<IDeploymentEnvironment[]> => {
  const response = await adminApi.get<IDeploymentEnvironment[]>('/deploy/environments')
  return response.data
}

export const cancelDeployment = async (env: string, instance: string, id: number): Promise<boolean> => {
  const response = await adminApi.delete<boolean>(`/deploy/deployment/${env}/${instance}/${id}`)
  return response.data
}
