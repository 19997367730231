import { useQuery } from '@tanstack/react-query'
import { expensiveQueryConfig } from '../views/Utils'
import { TenantWithNameAndRegion } from '../actions/Tenants'
import { getUsersForTenants, TenantWithSummary } from '../views/tenants/UsersReportForTenants'

export const useGetUsersReportForTenantsQuery = (tenantsData: TenantWithNameAndRegion[]) =>
  useQuery<TenantWithSummary[]>(
    ['tenants', 'users', tenantsData],
    () => (tenantsData ? getUsersForTenants(tenantsData) : []),
    {
      enabled: !!tenantsData,
      ...expensiveQueryConfig
    }
  )
