import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { CustomerConnection, deleteConnection } from '../actions/CustomerConnections'

export const useDeleteCustomerLoginOptionMutation = (loginOption: CustomerConnection) => (successFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<CustomerConnection, AxiosError>(() => deleteConnection(loginOption.customerId, loginOption.id), {
    onSuccess: async () => {
      successFn()
      createSuccessToast(`Login option with name ${loginOption.displayName} deleted`)
      await queryClient.invalidateQueries(['customerConnection', loginOption.customerId])
    },
    onError: (error: AxiosError) => {
      createErrorToast(error)
    }
  })
}
