import { Button, Table } from 'semantic-ui-react'
import { useState } from 'react'
import { IEnvironmentDetails } from '../../../actions/Environments'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { IApplicationComparison } from '../ApplicationCompare'
import { ISimpleSearch } from '../../searchComponents/SimpleSearch'

interface IProps {
  applications: IApplicationComparison[]
  searchValue: ISimpleSearch
  handleRedeploy(
    envIndx: number,
    application: IApplicationComparison,
    environment: IEnvironmentDetails | undefined
  ): () => void
  loading: boolean
  showAll: boolean
  environment1?: IEnvironmentDetails
  environment2?: IEnvironmentDetails
}

export const ApplicationCompareList = (props: IProps) => {
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')
  const { searchValue, applications, showAll, loading } = props

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('ascending')
    } else {
      setSortDirection(prevState => (prevState === 'ascending' ? 'descending' : 'ascending'))
    }
  }

  const filterApplications = (): IApplicationComparison[] => {
    const lowerSearch = searchValue.searchText.toLowerCase()
    switch (searchValue.searchCategory) {
      case 'name': {
        return applications?.filter(application => application.name.includes(lowerSearch)) || []
      }
      case 'tag': {
        return (
          applications?.filter(
            application =>
              application.environment1Tag?.includes(lowerSearch) || application.environment2Tag?.includes(lowerSearch)
          ) || []
        )
      }
      default: {
        return filterData(
          { searchValue: searchValue.searchText, searchCategory: searchValue.searchCategory },
          applications
        )
      }
    }
  }

  const getEnvironmentTitle = (environmentNumber: string) => {
    const environment = props[`environment${environmentNumber}` as keyof IProps] as IEnvironmentDetails
    return environment ? `${environment?.name}-${environment?.instance}` : `Environment ${environmentNumber}`
  }

  const isDifferent = (application: IApplicationComparison) =>
    application.environment1Tag !== application.environment2Tag

  const SortedApplicationHeaderCell = SortedHeaderCell(sortColumn, sortDirection, handleSort)

  const filteredApplications: IApplicationComparison[] = filterApplications()
  const sortedApplications = sortData(filteredApplications, sortColumn, sortDirection)
  const displayApplications = sortedApplications.filter(
    application => showAll || application.environment1Tag !== application.environment2Tag
  )
  return (
    <div className="scrollable">
      <Table striped color="blue" stackable style={{ width: '100%' }} sortable className="sticky-table">
        <Table.Header>
          <Table.Row>
            <SortedApplicationHeaderCell title="Application" name="name" />
            <SortedApplicationHeaderCell colSpan="2" title={`${getEnvironmentTitle('1')} Tag`} name="environment1Tag" />
            <SortedApplicationHeaderCell colSpan="2" title={`${getEnvironmentTitle('2')} Tag`} name="environment2Tag" />
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={loading} array={displayApplications} emptyMessage="No Applications">
          <Table.Body>
            {displayApplications.map(row => (
              <Table.Row key={row.name} error={isDifferent(row)}>
                <Table.Cell>{row.name}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{row.environment1Tag}</Table.Cell>
                <Table.Cell>
                  {row.environment1Tag && isDifferent(row) && (
                    <Button
                      circular
                      icon="arrow right"
                      onClick={props.handleRedeploy(2, row, props.environment2)}
                      className="circle-icon-button"
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {row.environment2Tag && isDifferent(row) && (
                    <Button
                      circular
                      icon="arrow left"
                      onClick={props.handleRedeploy(1, row, props.environment1)}
                      className="circle-icon-button"
                    />
                  )}
                </Table.Cell>
                <Table.Cell className="table-cell-overflow">{row.environment2Tag}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </div>
  )
}
