import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Customer, PatchCustomer, updateCustomer } from '../actions/NewCustomers'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export const useUpdateCustomerMutation = (customerId: string) => (onSaveFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Customer, AxiosError, PatchCustomer>(
    (patchCustomer: PatchCustomer) => updateCustomer(customerId)(patchCustomer),
    {
      onSuccess: (updatedCustomer: Customer) => {
        queryClient.invalidateQueries(['customer', customerId])
        queryClient.invalidateQueries(['customers'])
        queryClient.invalidateQueries(['customerTeams'])
        createSuccessToast(`Customer ${updatedCustomer.name} is updated.`)
        onSaveFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
