import { Button, Modal, Message } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'

export interface ConnectionOnTeamModalProps {
  connectionId: string
  disableConnection: (connectionId: string) => void
  displayName: string
}

export const WARNING_DISABLE_LAST_OPTION =
  'Disabling all customer login options will prevent all customer’ users from logging into this team.'

export const WARNING_DISABLE_OPTION_WITH_USER =
  'Disabling this login option will prevent its current users from logging into this team.'

const DisableConnectionOnTeamModal = ({ connectionId, disableConnection, displayName }: ConnectionOnTeamModalProps) => {
  const { value: modalDisabledIsOpen, toggle: toggleModalDisabledIsOpen } = useBoolean(false)

  const disableTeamConnection = () => {
    disableConnection(connectionId)
    toggleModalDisabledIsOpen()
  }

  return (
    <Modal
      trigger={
        <Button onClick={toggleModalDisabledIsOpen} basic fluid size="medium">
          Disable
        </Button>
      }
      size="small"
      open={modalDisabledIsOpen}
      closeOnDimmerClick
      onClose={toggleModalDisabledIsOpen}
      closeIcon
    >
      <Modal.Content>
        <Message
          content={
            <div>
              <p>{WARNING_DISABLE_OPTION_WITH_USER}</p>
            </div>
          }
          icon="warning"
          warning
        />
        <p>Are you sure you want to disable &quot;{displayName}&quot;?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel-option-button"
          content="No, Cancel"
          onClick={toggleModalDisabledIsOpen}
          style={{
            border: 'none',
            backgroundColor: 'transparent'
          }}
        />

        <Button
          id="disable-connection-team-button"
          data-testid="create-team-form-button"
          className="form-button-sked-red"
          content="Yes, Disable"
          onClick={() => disableTeamConnection()}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default DisableConnectionOnTeamModal
