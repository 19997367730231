import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createDeployment, IEnvironment } from '../../actions/Deployments'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'
import { decodeErrors } from '../../actions/Utils'

export const useDeploymentCreationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: {
      application: string
      environments: IEnvironment[]
      docker_tag: string
      user: string
      branch: string
      check_mode: boolean
    }) => createDeployment(props),
    onSuccess: async (data, props) => {
      await queryClient.invalidateQueries(['deployments'])
      createSuccessToast(
        `Deployments started successfully with the following IDs: ${data.map(deployment => deployment.id)}`
      )
    },
    onError: (error: Error) => {
      decodeErrors(error, data => null, createErrorToast)
    }
  })
}
