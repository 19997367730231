import { isEqual } from 'lodash'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, DropdownProps, Header, InputOnChangeData, Menu, Segment, Select } from 'semantic-ui-react'
import { ITeam } from '../../../actions/Teams'
import { categoryOptions, environmentOptions, ITenant, updateTenant } from '../../../actions/Tenants'
import { createErrorToast } from '../../alertComponents/Alert'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { mapStringsToOptions } from '../../Utils'

interface IProps {
  tenant?: ITenant
  team?: ITeam
  loadTenant(): Promise<void>
  loading: boolean
}

interface IState {
  editable: boolean
  editing: boolean
  editedCategory: string
  editedEnvironment: string
}

export class TenantInformation extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      editable: false,
      editing: false,
      editedCategory: props.tenant?.category || '',
      editedEnvironment: props.tenant?.environment || ''
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(prevProps.tenant || {}, this.props.tenant || {})) {
      const { tenant } = this.props
      this.setState({
        editedCategory: tenant?.category || '',
        editedEnvironment: tenant?.environment || ''
      })
    }
  }

  submitEditedTenant = async () => {
    const tenant = this.props.tenant
    const { editedCategory, editedEnvironment } = this.state
    if (this.validateEdited() && tenant) {
      this.setState({ editing: true })
      try {
        await updateTenant(tenant.tenantId, {
          environment: editedEnvironment,
          category: editedCategory
        })
        this.setState({
          editable: false,
          editedEnvironment: tenant.environment || '',
          editedCategory: tenant.category || ''
        })
        await this.props.loadTenant()
      } catch (error) {
        createErrorToast(error)
      }
      this.setState({ editing: false })
    }
  }

  validateEdited = () => {
    const tenant = this.props.tenant
    const { editedCategory, editedEnvironment } = this.state
    return (
      editedEnvironment &&
      editedCategory &&
      (editedCategory !== tenant?.category || editedEnvironment !== tenant?.environment)
    )
  }

  toggleEditable = () => {
    this.setState(prevState => ({ editable: !prevState.editable }))
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps): void => {
    const name = data.name
    const value = data.value
    // The ...this.state is required due to a @types/react bug should be removed when this is fixed.
    this.setState({ ...this.state, [name]: value })
  }

  render() {
    const { editable, editedCategory, editedEnvironment } = this.state
    const { team, tenant, loading } = this.props
    return (
      <div>
        <Header as="h3">Tenant Info</Header>
        <Segment color="blue">
          <LoaderWrap loading={loading}>
            <React.Fragment>
              <Header size="tiny">Tenant ID / Org ID</Header>
              <p className="table-cell-overflow">{tenant?.tenantId}</p>
              {tenant?.parentTenantId && (
                <React.Fragment>
                  <Header size="tiny">Cloned from Tenant ID</Header>
                  <Link to={`/old-model/tenants/${tenant?.parentTenantId}`} className="table-cell-overflow">
                    {tenant?.parentTenantId}
                  </Link>
                </React.Fragment>
              )}
              <Header size="tiny">Vendor</Header>
              <p>{tenant?.vendor}</p>
              {team?.customerId && (
                <React.Fragment>
                  <Header size="tiny">Customer</Header>
                  <Link to={`/old-model/customers/${team?.customerId}`}>{team?.customerName}</Link>
                </React.Fragment>
              )}
              <Header size="tiny">Team</Header>
              <Link className="table-cell-overflow" to={`/old-model/teams/${tenant?.teamId}`}>
                {team?.name}
              </Link>
              <Header size="tiny">Category</Header>
              {editable ? (
                <Select
                  required
                  value={editedCategory}
                  label="Category"
                  name="editedCategory"
                  placeholder="Category"
                  options={mapStringsToOptions(categoryOptions)}
                  onChange={this.onChange}
                  search
                />
              ) : (
                <p>{tenant?.category || 'Not Set'}</p>
              )}
              <Header size="tiny">Environment</Header>
              {editable ? (
                <Select
                  required
                  value={editedEnvironment}
                  label="Environment"
                  name="editedEnvironment"
                  placeholder="Environment"
                  options={mapStringsToOptions(environmentOptions)}
                  onChange={this.onChange}
                  search
                />
              ) : (
                <p>{tenant?.environment || 'Not Set'}</p>
              )}
              <Header size="tiny">Created By</Header>
              <p>{tenant?.createdBy || 'Unknown'}</p>
              <Header size="tiny">Updated By</Header>
              <p>{tenant?.updatedBy || 'Unknown'}</p>
              <Header size="tiny">Created Date</Header>
              <p>{tenant?.createdDate}</p>
              <Header size="tiny">Updated Date</Header>
              <p>{tenant?.updatedDate}</p>
              <Menu text stackable>
                <Menu.Item position="left">
                  <Button onClick={this.toggleEditable} content={editable ? 'Cancel' : 'Edit'} />
                </Menu.Item>
                {editable && (
                  <Menu.Item position="right">
                    <Button
                      primary
                      onClick={this.submitEditedTenant}
                      content="Save"
                      disabled={!this.validateEdited()}
                    />
                  </Menu.Item>
                )}
              </Menu>
            </React.Fragment>
          </LoaderWrap>
        </Segment>
      </div>
    )
  }
}
