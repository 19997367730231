import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { logError } from '../../../analytics/error-log'
import { deployRelease, IRelease } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

interface IProps {
  release?: IRelease
  deployable: boolean
}

interface IState {
  open: boolean
  loading: boolean
}

export class DeployReleaseModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  toggleOpen = () => {
    this.setState(prevProps => ({ open: !prevProps.open }))
  }

  handleDeployRelease = async () => {
    if (this.props.release) {
      this.setState({ loading: true })
      try {
        await deployRelease(this.props.release.applicationName, this.props.release.version)
        this.toggleOpen()
        createSuccessToast('Created deployment for release successfully. Monitor deployment on deployments tab.')
      } catch (error) {
        logError(error)
        createErrorToast(error)
        this.toggleOpen()
      }
      this.setState({ loading: false })
    }
  }

  render() {
    const release = this.props.release || ({} as IRelease)
    return (
      <Modal
        open={this.state.open}
        onClose={this.toggleOpen}
        size="mini"
        trigger={
          <Button
            content="Deploy Release"
            onClick={this.toggleOpen}
            className="form-button-sked-blue"
            disabled={!this.props.release || !this.props.deployable}
          />
        }
      >
        <Modal.Header>Deploy Release</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to deploy release for {release.applicationName} version {release.version}.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button name="no" negative content="No" onClick={this.toggleOpen} />
          <Button name="yes" positive content="Yes" onClick={this.handleDeployRelease} loading={this.state.loading} />
        </Modal.Actions>
      </Modal>
    )
  }
}
