import * as React from 'react'
import { Button, Icon, Table, TableHeaderCell } from 'semantic-ui-react'
import { IRelease } from '../../../actions/Releases'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISimpleSearchValue } from '../../searchComponents/SimpleSearch'

const filter = /([0-9]*)\.([0-9]*)\.([0-9])*/

interface IProps {
  releases?: IRelease[]
  loading: boolean
  searchValue: ISimpleSearchValue
  createDetailsLink(applicationName: string, version: string): () => void
  simple?: boolean
}

interface IState {
  sortColumn: keyof IRelease
  sortDirection: 'ascending' | 'descending'
}

export class ReleasesList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      sortColumn: 'version',
      sortDirection: 'descending'
    }
  }

  handleSort = (clickedColumn: string) => () => {
    const { sortColumn, sortDirection } = this.state
    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn as keyof IRelease,
        sortDirection: 'ascending'
      })
    } else {
      this.setState({
        sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending'
      })
    }
  }

  compareRelease = (a: IRelease, b: IRelease) => {
    const matchA = filter.exec(a.version)
    const matchB = filter.exec(b.version)
    if (matchA === null || matchB === null) {
      return 0
    }
    const matchesA = matchA.slice(1).map(x => parseInt(x, 10))
    const matchesB = matchB.slice(1).map(x => parseInt(x, 10))
    return matchesA[0] - matchesB[0] || matchesA[1] - matchesB[1] || matchesA[2] - matchesB[2]
  }

  sortReleases = (data: IRelease[], sortColumn: keyof IRelease, sortDirection: 'ascending' | 'descending') => {
    const result = [...data]
    if (result.length > 0 && sortColumn === 'version') {
      result.sort(this.compareRelease)
      if (sortDirection === 'descending') {
        result.reverse()
      }
      return result
    }
    return sortData(result, sortColumn, sortDirection)
  }

  render() {
    const { loading, releases, searchValue } = this.props
    const { sortColumn, sortDirection } = this.state

    const SortedReleaseHeaderCell = SortedHeaderCell(sortColumn, sortDirection, this.handleSort)

    const filteredReleases: IRelease[] = filterData(
      { searchValue: searchValue.searchText, searchCategory: searchValue.searchCategory },
      releases
    )
    const sortedReleases = this.sortReleases(filteredReleases, sortColumn, sortDirection)
    return (
      <Table
        striped
        color="blue"
        size="small"
        stackable
        sortable
        className={!this.props.simple ? 'sticky-table' : undefined}
        aria-label="Other Releases"
      >
        <Table.Header>
          <Table.Row>
            <SortedReleaseHeaderCell title="Version" />
            <SortedReleaseHeaderCell title="Application" name="applicationName" />
            <TableHeaderCell textAlign="center">CD Release</TableHeaderCell>
            <TableHeaderCell textAlign="center">Released</TableHeaderCell>
            <SortedReleaseHeaderCell title="Released Date" name="releasedDate" />
            <TableHeaderCell textAlign="center">Details</TableHeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap loading={loading} array={releases || []} emptyMessage="No Releases Found">
          <Table.Body>
            {sortedReleases.map(release => (
              <Table.Row key={release.id}>
                <Table.Cell>{release.version}</Table.Cell>
                <Table.Cell>{release.applicationName}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Icon name={release.isCD ? 'check' : 'close'} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Icon name={release.released ? 'check' : 'close'} />
                </Table.Cell>
                <Table.Cell>{release.releasedDate}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    icon="file alternate outline"
                    className="square-icon-button"
                    onClick={this.props.createDetailsLink(release.applicationName, release.version)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    )
  }
}
