import { useQuery } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { getReleaseNotesPage } from '../../actions/Confluence'

export const useGetReleaseNotesPageQuery = (component?: string, version?: string) =>
  useQuery(['releaseNotesPage', component, version], () => getReleaseNotesPage(component!, version!), {
    onError: error => {
      if (isAxiosError(error) && error.response && error.response.status !== 404) {
        throw error
      }
    },
    enabled: !!component && !!version
  })
