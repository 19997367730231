import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { useDeleteCustomerMutation } from '../../../mutations/DeleteCustomerMutation'
import { createSuccessToast } from '../../alertComponents/Alert'

export const useDeleteCustomerService = (customerId: string, onSuccessDelete: () => void) => {
  const { data: customer } = useGetCustomerQuery(customerId)
  const deleteCustomerMutation = useDeleteCustomerMutation(customerId)(() => {
    createSuccessToast(`Customer with name ${customer?.name} deleted`)
    onSuccessDelete()
  })

  return {
    deleteMethod: () => deleteCustomerMutation.mutate(),
    content: `Are you sure you want to delete ${customer?.name}?`,
    type: 'Customer',
    buttonText: 'Remove Customer',
    isDeleting: deleteCustomerMutation.isLoading
  }
}
