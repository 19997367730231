import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonProps,
  DropdownItemProps,
  DropdownProps,
  Form,
  InputOnChangeData,
  Modal
} from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useNavigate } from 'react-router'
import { useFilterService } from '../../../../services/DynamoQueryFilterService'
import { useCreateCustomerService } from '../../../../services/CreateCustomerService'
import { useCreateNelxTeamService } from '../../services/CreateNelxTeamService'
import CustomerFormSection from './FormSections/CustomerFormSection'
import TeamFormSection from './FormSections/TeamFormSection'

interface CreateNelxTeamFormProps {
  buttonText: string
  title: string
  suppliedCustomerId?: string
  suppliedCustomerName?: string
  suppliedCustomerRegion?: string
}

const CreateNelxTeamForm = ({
  buttonText,
  title,
  suppliedCustomerId,
  suppliedCustomerName,
  suppliedCustomerRegion
}: CreateNelxTeamFormProps) => {
  const { handleSearchChange: handleCustomerSearch } = useFilterService(['name'], 'name', {})
  const createCustomerService = useCreateCustomerService()
  const createNelxTeamService = useCreateNelxTeamService(suppliedCustomerRegion)

  const { value: modalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)
  const [isResetForm, setResetForm] = useState(false)
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  const navigate = useNavigate()

  // only show US region
  const regionOptions = useMemo(
    () => createNelxTeamService.regions.filter(region => region.name === 'US'),
    [createNelxTeamService.regions]
  )

  const environmentOptions: DropdownItemProps[] = useMemo(
    () =>
      createNelxTeamService.environmentOptions?.map(env => ({
        key: env,
        text: env,
        value: env
      })),
    [createNelxTeamService.environmentOptions]
  )

  /**
   * Check if we should disable or allow submision of form.
   */
  useEffect(() => {
    if (createNelxTeamService.addCustomer) {
      const canSubmit =
        createNelxTeamService.teamIsValid &&
        createCustomerService.customerName !== '' &&
        (!createCustomerService.customerIsInternal ? createCustomerService.customerSalesforceId !== '' : true) &&
        createCustomerService.customerIsValid
      setCanSubmitForm(canSubmit)
    } else {
      setCanSubmitForm(createNelxTeamService.teamIsValid)
    }
  }, [
    createNelxTeamService.addCustomer,
    createNelxTeamService.teamIsValid,
    createCustomerService.customerName,
    createCustomerService.customerSalesforceId,
    createCustomerService.customerIsValid
  ])

  /**
   * Check if we should pre-fill team service and customer service
   */
  useEffect(() => {
    if (suppliedCustomerId || suppliedCustomerName) {
      createNelxTeamService.handleNelxTeamInput('customerId')(suppliedCustomerId as string)
      createCustomerService.setCustomerName(suppliedCustomerName as string)
    }
    if (isResetForm) {
      setResetForm(false)
    }
  }, [suppliedCustomerId, suppliedCustomerName, isResetForm])

  const handleClose = () => {
    setResetForm(true)
    createNelxTeamService.reset()
    if (createCustomerService.customerIsInternal) {
      createCustomerService.toggleCustomerIsInternal()
    }
    closeModal()
  }

  const handleTeamInformationInput = (
    _event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => {
    createNelxTeamService.handleNelxTeamInput(data.name)(data.value as string)
  }

  /**
   * Create team on submit of form.
   */
  const handleTeamCreation = async () => {
    if (!canSubmitForm) {
      return
    }
    createNelxTeamService.runCreateNelxTeamProcess(
      createCustomerService.customer,
      createCustomerService.createCustomerAsync,
      finaliseTeamCreation
    )
  }

  const finaliseTeamCreation = (teamId: string) => {
    handleClose()
    if (teamId) {
      navigate(`/new-console/teams/${teamId}`)
    }
  }

  return (
    <Modal
      data-testid="create-team-modal"
      closeIcon
      open={modalOpen}
      trigger={
        <Button
          content={buttonText}
          onClick={openModal}
          disabled={createNelxTeamService.isLoading || createCustomerService.creatingCustomer}
          loading={createNelxTeamService.isLoading || createCustomerService.creatingCustomer}
        />
      }
      onClose={handleClose}
      onOpen={openModal}
    >
      <Modal.Header id="modal-header-add-team">{title}</Modal.Header>
      <Modal.Content>
        <Form loading={createNelxTeamService.isLoading || createCustomerService.creatingCustomer}>
          {/* Customer */}
          {!suppliedCustomerId && !suppliedCustomerName && (
            <CustomerFormSection
              title="Customer information"
              customerOptions={[]} // implement this when we decide to add customer options
              addCustomer={createNelxTeamService.addCustomer}
              internalCustomer={createCustomerService.customerIsInternal}
              customerName={suppliedCustomerName ? suppliedCustomerName : createCustomerService.customerName}
              customerId={suppliedCustomerId ? suppliedCustomerId : createNelxTeamService.newTeam.customerId || ''}
              customerSalesforceId={createCustomerService.customerSalesforceId}
              handleCustomerInput={() => null} // implement this when we decide to add new customer form
              toggleInternalCustomer={createCustomerService.toggleCustomerIsInternal}
              toggleAddCustomer={createNelxTeamService.toggleAddCustomer}
              customerNameIsValid={createCustomerService.customerNameIsValid}
              customerIdIsValid={createCustomerService.customerSalesforceIdIsValid}
              checkingCustomer={createCustomerService.checkingCustomerName}
              handleCustomerSearch={handleCustomerSearch}
              regions={createCustomerService.regionsInfo}
              selectedRegion={createCustomerService.selectedRegion}
            />
          )}
          {/* Team Information */}
          <TeamFormSection
            title="Team information"
            regionOptions={regionOptions}
            environmentOptions={environmentOptions}
            salesforceTeam={createNelxTeamService.isSalesforceTeam}
            newTeam={createNelxTeamService.newTeam}
            handleTeamInformationInput={handleTeamInformationInput}
            errors={createNelxTeamService.formErrors}
            isCreateNelxTeam={true}
          />
          * Initial user setup is no longer needed. Simply click the link in the team details screen to go through the
          web app onboarding.
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel-create-team-button"
          content="Cancel"
          onClick={handleClose}
          disabled={createNelxTeamService.isLoading || createCustomerService.creatingCustomer}
        />
        <Button
          id="create-team-button"
          data-testid="create-team-form-button"
          className="form-button-sked-blue"
          content="Create Team"
          onClick={handleTeamCreation}
          disabled={!canSubmitForm || createCustomerService.creatingCustomer || createNelxTeamService.isLoading}
          loading={createNelxTeamService.isLoading || createCustomerService.creatingCustomer}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default CreateNelxTeamForm
