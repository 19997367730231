import _ from 'lodash'
import * as React from 'react'
import { Button, Form, InputProps, Grid, Modal } from 'semantic-ui-react'
import { Delivery, ICompleteDeliveryRequest } from '../../../actions/Deliveries'
import { useRejectDeliveryMutation } from '../../../mutations/deliveries/RejectDeliveryMutation'

interface IProps {
  delivery?: Delivery
  disabled: boolean
}

export const RejectDeliveryModal = (props: IProps) => {
  const { mutate: rejectDelivery } = useRejectDeliveryMutation()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [explanation, setExplanation] = React.useState<string>('')

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleRejectDelivery = async () => {
    setLoading(true)
    if (!_.isUndefined(delivery)) {
      const completeDeliveryRequest: ICompleteDeliveryRequest = {
        completedExplanation: explanation
      }
      rejectDelivery({
        delivery: { applicationName: delivery.applicationName, version: delivery.version },
        explanation: completeDeliveryRequest
      })
    }
    setLoading(false)
    setOpen(false)
  }

  const changeExplanation = (event: React.SyntheticEvent, data: InputProps) => {
    setExplanation(data.value)
  }

  const { delivery } = props
  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      size="mini"
      trigger={
        <Button negative fluid onClick={toggleOpen} disabled={!delivery || props.disabled}>
          Reject
        </Button>
      }
    >
      <Modal.Header>Reject Delivery</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to reject delivery for <b>{delivery?.applicationName}</b> version{' '}
          <b>{delivery?.version}</b>?
        </p>
        <Form>
          <Form.Input
            label="Explanation"
            placeholder="Explanation to approve this delivery"
            name="explanation"
            onChange={changeExplanation}
            value={explanation}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={2}>
          <Grid.Column>
            <Button name="no" negative fluid content="No" onClick={toggleOpen} />
          </Grid.Column>
          <Grid.Column>
            <Button name="yes" positive fluid content="Yes" onClick={handleRejectDelivery} loading={loading} />
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
