import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast } from '../views/alertComponents/Alert'
import { resetTeam, ResetTeamConfig, Team } from '../actions/NewTeams'

export const useResetTeamMutation = (teamId: string) => (onSuccessResetFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Team, AxiosError, ResetTeamConfig>(
    (resetTeamConfig: ResetTeamConfig) => resetTeam(teamId)(resetTeamConfig),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['team', teamId])
        queryClient.invalidateQueries(['teams'])
        onSuccessResetFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
