import { Button, Form, Radio } from 'semantic-ui-react'
import { IRegionInfo } from '../actions/Regions'
import { RegionFlag } from './regionFlags'

interface IRegionSelectProps {
  regionsInfo: IRegionInfo[]
  selectedRegion: string
  setSelectedRegion: (region: string) => void
}

export const RegionSelect = ({ regionsInfo, selectedRegion, setSelectedRegion }: IRegionSelectProps) => (
  <>
    <Form.Group inline widths="equal" style={{ marginLeft: '1em' }}>
      {regionsInfo.map(regionInfo => (
        <Form.Field key={regionInfo.region}>
          <Button
            type="button"
            className={selectedRegion === regionInfo.region.toUpperCase() ? 'radio selected' : 'radio'}
            data-testid={`radio-${regionInfo.region}`}
            onClick={() => {
              setSelectedRegion(regionInfo.region.toUpperCase())
            }}
            basic
            fluid
          >
            <Radio
              label={
                <label>
                  {regionInfo.name}&nbsp;&nbsp;
                  <RegionFlag region={regionInfo.region} />
                </label>
              }
              name="regionCheckboxGroup"
              value={regionInfo.region}
              checked={selectedRegion === regionInfo.region.toUpperCase()}
            />
          </Button>
        </Form.Field>
      ))}
    </Form.Group>
  </>
)
