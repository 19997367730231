import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { deletePod } from '../../actions/Applications'

export const useDeletePodMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: { name: string; application: string; environment: string; instance: string }) =>
      deletePod(props.name, props.environment, props.instance),
    onSuccess: async (data, props) => {
      await Promise.all([
        queryClient.invalidateQueries(['pods', props.application, props.environment, props.instance]),
        queryClient.invalidateQueries(['pods', props.application])
      ])
    },
    onError: (error: Error, props) => {
      createErrorToast(`Deleting pod ${props.name} failed. ${error}`)
    }
  })
}
