import { useGetCustomerConnectionsQuery } from '../../../../queries/GetCustomerConnectionsQuery'
import { renderLoginOptionDetails } from './EditLoginOptionsForm/Utils'

interface LoginOptionsDetailFormProps {
  customerId: string
  loginOptionId: string
}

const LoginOptionsDetailForm = ({ customerId, loginOptionId }: LoginOptionsDetailFormProps) => {
  const { data: customerConnections } = useGetCustomerConnectionsQuery(customerId)
  const loginOption = customerConnections?.find(connection => connection.id === loginOptionId)
  return loginOption ? renderLoginOptionDetails(loginOption) : null
}

export default LoginOptionsDetailForm
