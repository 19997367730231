import LoginOptionsTable from '../../../components/TableComponents/LoginOptionsTable'
import { useLoginOptionsService } from '../services/LoginOptionsService'

const TeamLoginOptionsTable = ({ teamId }: { teamId: string }) => {
  const loginOptionsService = useLoginOptionsService({ teamId })

  return <LoginOptionsTable {...loginOptionsService} />
}

export default TeamLoginOptionsTable
