import { JSXElementConstructor, useEffect, useState } from 'react'
import { Grid, Tab, Header, Dropdown, Menu, Popup, Icon } from 'semantic-ui-react'
import { startCase, toLower, find } from 'lodash'
import { useGetCustomerConnectionsQuery } from '../../queries/GetCustomerConnectionsQuery'
import DynamicBreadcrumbs from '../../components/Breadcrumbs/DynamicBreadcrumbs'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useTabLinkingService } from '../../services/TabLinkingService'
import { useRouterProps } from '../../router/RouterProps'
import { getTeamsList } from './services/CustomerTeamsService'
import LoginOptionsDetailForm from './component/LoginOptions/LoginOptionsDetailForm'
import { TeamsLoginOptionList } from './component/LoginOptions/TeamsLoginOptionList'
import { NewDeleteLoginOptionModal } from './component/LoginOptions/NewDeleteLoginOptionModal'

const panes: { [x: string]: JSXElementConstructor<{ customerId: string; loginOptionId: string }> } = {
  Details: LoginOptionsDetailForm,
  Teams: TeamsLoginOptionList
}

const makePane =
  (customerId: string, loginOptionId: string) =>
  (name: string, Component: JSXElementConstructor<{ customerId: string; loginOptionId: string }>) => {
    const startCaseName = startCase(name)
    const sentenceCaseName = startCaseName.charAt(0) + toLower(startCaseName.slice(1))

    return {
      menuItem: sentenceCaseName,
      render: () => (
        <Tab.Pane>
          <Component customerId={customerId} loginOptionId={loginOptionId} />
        </Tab.Pane>
      )
    }
  }

const getPanes = (customerId: string, loginOptionId: string) =>
  Object.keys(panes).map(paneName => makePane(customerId, loginOptionId)(paneName, panes[paneName]))

export const CustomerLoginOptionsDetails = () => {
  const props = useRouterProps()
  const [redirect, setRedirect] = useState(false)

  const loginOptionId = props.params.id!
  const customerId = props.params.customerId!

  const { data: customerConnections } = useGetCustomerConnectionsQuery(customerId)
  const teams = getTeamsList(customerId)
  const loginOption = customerConnections?.find(connection => connection.id === loginOptionId)
  const connectionWithTeamData = teams?.filter(team => find(team.authConnections, { connectionId: loginOption?.id }))

  const isLoginOptionUsed = connectionWithTeamData.length === 0

  const { setDocumentTitle } = useDocumentTitle(loginOption?.displayName)

  useEffect(() => {
    if (loginOption?.displayName) {
      setDocumentTitle(loginOption?.displayName)
    }
  }, [loginOption])

  const { onTabChange, activeIndex } = useTabLinkingService(
    Object.keys(panes).map(pane => pane),
    'Details'
  )

  useEffect(() => {
    if (redirect) {
      props.navigate(`/customers/${loginOption?.customerId}?tab=login-options`)
    }
  }, [redirect])

  return (
    <div className="route-component">
      <div style={{ marginBottom: '2.5rem' }}>
        <DynamicBreadcrumbs
          initialBreadcrumbs={[{ path: '/customers', name: 'Customers' }]}
          customerId={customerId}
          loginOptionId={loginOptionId}
        />
      </div>

      {loginOption && (
        <Grid stackable>
          <Grid.Column floated="left" width={5} textAlign="left">
            {loginOption?.displayName && (
              <Header style={{ display: 'inline' }} as="h2">
                {loginOption.displayName}
              </Header>
            )}
          </Grid.Column>
          <Grid.Column floated="right" width={5} textAlign="right">
            <Menu secondary stackable>
              <Menu.Menu position="right">
                <Dropdown text="Actions" button basic className="action-menu">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NewDeleteLoginOptionModal
                        loginOption={loginOption}
                        onSuccessDelete={() => setRedirect(true)}
                        menuItem={true}
                        disabled={!isLoginOptionUsed}
                      />
                      {!isLoginOptionUsed && (
                        <Popup
                          content="Login options can not be deleted because there are teams using it."
                          trigger={
                            <Icon name="info circle" size="small" style={{ paddingLeft: '5px', fontSize: '17px' }} />
                          }
                          basic
                        />
                      )}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid>
      )}

      <Grid stackable>
        <Grid.Column stretched style={{ height: '100%' }} className="scrollable">
          <Tab panes={getPanes(customerId, loginOptionId)} onTabChange={onTabChange} activeIndex={activeIndex} />
        </Grid.Column>
      </Grid>
    </div>
  )
}
