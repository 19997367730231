import * as React from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'

export interface EditableTableProps {
  editing: boolean
  isEditable: boolean
  loading: boolean
  reset: () => any
  submit: () => any
  submitting: boolean
  tableTitle: string
  toggleEditing: () => any
  validateSubmit: boolean
}

const EditableTableControls = ({
  toggleEditing,
  validateSubmit,
  submit,
  reset,
  loading,
  editing,
  submitting
}: EditableTableProps) =>
  editing ? (
    <React.Fragment>
      <Button
        disabled={!validateSubmit || submitting}
        loading={submitting}
        onClick={submit}
        color="blue"
        content="Save"
      />
      <Button content="Cancel" onClick={reset} disabled={submitting} />
    </React.Fragment>
  ) : (
    <Button content="Edit" onClick={toggleEditing} className="sked-blue-button" disabled={loading} />
  )

export const EditableTableHeader = (props: EditableTableProps) => (
  <div>
    <Grid>
      <Grid.Column width={8} textAlign="left" verticalAlign="middle">
        <Header as="h3">{props.tableTitle}</Header>
      </Grid.Column>
      <Grid.Column width={8} textAlign="right">
        {props.isEditable ? <EditableTableControls {...props} /> : ''}
      </Grid.Column>
    </Grid>
  </div>
)
