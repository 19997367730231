import React from 'react'
import { Modal, Checkbox, Divider } from 'semantic-ui-react'
import { DeliveryCheckTemplate, IDeliveryCheckDefinition, INewDeliveryCheckTemplate } from 'src/actions/DeliveryCheck'
import { EditDeliveryCheckTemplateForm } from './EditDeliveryCheckTemplateForm'

interface IProps {
  selectedCheckTemplate?: DeliveryCheckTemplate
  open: boolean
  deliveryCheckDefinitions: IDeliveryCheckDefinition[]
  closeEditModalHook: () => void
  editDeliveryCheckTemplateHook: (id: string, template: INewDeliveryCheckTemplate) => Promise<boolean>
}

export const EditDeliveryCheckTemplateModal = (props: IProps) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [enableEdit, setEnableEdit] = React.useState<boolean>(false)

  const onSubmitHook = async (template: INewDeliveryCheckTemplate) => {
    if (props.selectedCheckTemplate) {
      // should always be true to submit
      setLoading(true)
      const res = await props.editDeliveryCheckTemplateHook(props.selectedCheckTemplate.id, template)
      setLoading(false)
      return res
    }
    return false
  }

  React.useEffect(() => {
    setEnableEdit(false)
  }, [props.selectedCheckTemplate])

  return (
    <Modal closeIcon open={props.open} onClose={props.closeEditModalHook} size="large">
      <Modal.Header>{props.selectedCheckTemplate?.name ?? ''}</Modal.Header>
      <Modal.Content>
        <Checkbox
          name="toggleEdit"
          toggle
          onChange={() => setEnableEdit(!enableEdit)}
          label="Enable Editing"
          checked={enableEdit}
        />
        <Divider horizontal />
        <EditDeliveryCheckTemplateForm
          loading={loading}
          deliveryCheckDefinitions={props.deliveryCheckDefinitions}
          enableEdit={enableEdit}
          onSubmitHook={onSubmitHook}
          initialState={props.selectedCheckTemplate}
        />
      </Modal.Content>
    </Modal>
  )
}
