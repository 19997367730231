import { Card, Icon } from 'semantic-ui-react'
import { IOnlineEnvironment } from '../../../actions/Environments'

export interface IProps {
  environment: IOnlineEnvironment
}

const renderStatusIcon = (online: boolean) => (
  <p>
    <Icon {...(online ? { name: 'check circle', color: 'green' } : { name: 'remove circle', color: 'red' })} />
    {online ? 'Online' : 'Offline'}
  </p>
)

export const EnvironmentStatus = (props: IProps) => (
  <Card centered className="table-cell-overflow">
    <Card.Content>
      <Card.Header>
        {props.environment.environment.environment} - {props.environment.environment.instance}
      </Card.Header>
      <Card.Description>Namespace: {props.environment.environment.name}</Card.Description>
    </Card.Content>
    <Card.Content extra>{renderStatusIcon(props.environment.online)}</Card.Content>
  </Card>
)
