import * as React from 'react'
import { DropdownProps, Grid, Icon, InputOnChangeData, SearchProps } from 'semantic-ui-react'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getCustomers, ICustomer } from '../../actions/Customers'
import { createErrorToast } from '../alertComponents/Alert'
import { SearchInput } from '../searchComponents/SearchInput'
import { IViewSearchProps } from '../Utils'
import { AddCustomerModal } from './components/AddCustomerModal'
import { CustomersList } from './components/CustomersList'

interface IState {
  customers: ICustomer[]
  loading: boolean
}

const title = 'Customers'

export default class Customers extends React.PureComponent<IViewSearchProps, IState> {
  constructor(props: IViewSearchProps) {
    super(props)
    this.state = {
      customers: [],
      loading: true
    }
  }

  async componentDidMount() {
    await this.fetchCustomers()
    document.title = formatDocumentTitle(title)
  }

  fetchCustomers = async (): Promise<void> => {
    this.setState({ loading: true })
    try {
      const customers = await getCustomers()
      this.setState({ customers, loading: false })
    } catch (error) {
      createErrorToast(error)
      this.setState({ loading: false })
    }
  }

  handleSearchChange: SearchProps['onChange'] = (event: React.ChangeEvent, data: InputOnChangeData) => {
    const newSearch = { searchValue: data.value, searchCategory: this.props.searchValue.searchCategory }
    this.props.handleSearchChange(newSearch)
  }

  handleSearchCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    const newSearch = { searchValue: this.props.searchValue.searchValue, searchCategory: data.value as string }
    this.props.handleSearchChange(newSearch)
  }

  handleRowClick = (id: string) => () => this.props.navigate(`/old-model/customers/${id}`)

  render() {
    const { searchValue } = this.props
    const { customers, loading } = this.state

    const searchOptions = [
      { text: 'All', value: 'all' },
      { text: 'Name', value: 'name' },
      { text: 'Id', value: 'id' },
      { text: 'Salesforce Id', value: 'salesforceId' }
    ]

    return (
      <div className="route-component legacy-page">
        <Grid stackable={true} columns={2} verticalAlign="middle">
          <Grid.Column>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Icon size="large" name="refresh" onClick={this.fetchCustomers} loading={loading} />
              </Grid.Column>
              <Grid.Column>
                <SearchInput
                  searchValue={searchValue}
                  searchOptions={searchOptions}
                  handleSearchChange={this.handleSearchChange}
                  handleSearchCategoryChange={this.handleSearchCategoryChange}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <AddCustomerModal customers={customers} fetchCustomers={this.fetchCustomers} />
          </Grid.Column>
        </Grid>
        <CustomersList
          customers={customers}
          handleRowClick={this.handleRowClick}
          searchValue={searchValue}
          loading={loading}
        />
      </div>
    )
  }
}
