import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { deleteDeploymentTemplate } from '../../actions/Admin'

export const useDeleteDeploymentTemplateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation((templateName: string) => deleteDeploymentTemplate(templateName), {
    onSuccess: (_data, templateName) => {
      queryClient.invalidateQueries(['deploymentTemplate', templateName])
      queryClient.invalidateQueries(['deploymentTemplates'])
    },
    onError: error => {
      createErrorToast(error)
    }
  })
}
