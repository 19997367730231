import * as React from 'react'
import { Button, DropdownProps, Form, Icon, InputOnChangeData, InputProps, Message, Table } from 'semantic-ui-react'
import { createCustomer } from '../../../actions/Customers'
import { IRegionInfo } from '../../../actions/Regions'
import {
  addMultipleConections,
  IAuthConnection,
  IFormTeam,
  INewLoginOption,
  ITeam,
  provisionTeam
} from '../../../actions/Teams'
import { createSuccessToast, getErrorContent } from '../../alertComponents/Alert'
import { BasicInput, RegionInput, TeamNameInput } from '../../formComponents/Inputs'
import { CustomerSelector } from '../../formComponents/Selectors'
import { LoginOptionRow } from '../../formComponents/Tables'

interface IState {
  addCustomer: boolean
  newCustomerName: string
  loading: boolean
  submitted: boolean
  error?: Error
  connectionError?: Error
  customerID: string
  teamName: string
  teamNameUsed: boolean
  teamDescription: string
  region: string
  createdTeam?: ITeam
  defaultLoginOption: INewLoginOption
  loginOptions: INewLoginOption[]
  numLoginOptions: number
}

interface IProps {
  handleAddTeamModal(): void
  fetchTeams(): Promise<void>
  regions: IRegionInfo[]
}

export class NewTeamForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      addCustomer: false,
      newCustomerName: '',
      loading: true,
      submitted: false,
      teamNameUsed: false,
      customerID: ' ',
      teamName: '',
      teamDescription: '',
      region: '',
      numLoginOptions: 0,
      defaultLoginOption: { loginOption: 'skedulo', loginButtonText: '', endpoint: '' },
      loginOptions: []
    }
  }

  componentDidMount() {
    this.setState({ loading: false })
  }

  validateTeamName() {
    if (this.state.teamName === '') {
      return true
    }
    if (/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/.test(this.state.teamName)) {
      return true
    }
    return false
  }

  validateDefaultEndpoint() {
    const { endpoint, loginOption } = this.state.defaultLoginOption
    return this.validateEndpoint(endpoint, loginOption)
  }

  validateLoginOptionEndpoint(loginOptionNum: number) {
    const { endpoint, loginOption } = this.state.loginOptions[loginOptionNum]
    return this.validateEndpoint(endpoint, loginOption)
  }

  validateEndpoint(endpoint: string, loginOption: string) {
    if (endpoint === '') {
      return true
    }
    if (loginOption === 'salesforce-custom-endpoint') {
      return /^[a-z][a-z.0-9-]{0,61}$/.test(endpoint)
    }
    return true
  }

  validateEndpoints() {
    if (!this.validateDefaultEndpoint()) {
      return false
    }
    for (let i = 0; i < this.state.loginOptions.length; i++) {
      if (!this.validateLoginOptionEndpoint(i)) {
        return false
      }
    }
    return true
  }

  validateCustomer() {
    return !this.state.addCustomer || (this.state.addCustomer && this.state.newCustomerName.trim())
  }

  validateTeam() {
    return (
      this.validateCustomer() &&
      this.validateTeamName() &&
      this.validateEndpoints() &&
      this.state.teamDescription &&
      this.state.region
    )
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: InputProps | DropdownProps) => {
    const name = data.name
    const value = data.value
    this.setState({ [name as keyof IState]: value } as Pick<IState, keyof IState>)
  }

  onChangeLowerCase = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
    this.onChange(event, {
      ...data,
      value: data.value.toLowerCase()
    })
  }

  addLoginOption = () => {
    const { loginOptions } = this.state
    const newLoginOptions: INewLoginOption[] = [
      ...loginOptions,
      { loginOption: 'salesforce-custom-endpoint', loginButtonText: '', endpoint: '' }
    ]
    this.setState({ loginOptions: newLoginOptions })
  }

  removeLoginOption = (loginOptionNum: number) => () => {
    this.setState(prevState => {
      const loginOptions = prevState.loginOptions
      loginOptions.splice(loginOptionNum, 1)
      return { loginOptions }
    })
  }

  onChangeLoginOption =
    (loginOptionNum: number) => (event: React.SyntheticEvent<HTMLElement>, data: InputProps | DropdownProps) => {
      const name = data.name
      const value = data.value

      this.setState(prevState => {
        const loginOptions = [...prevState.loginOptions]
        loginOptions[loginOptionNum][name as keyof INewLoginOption] = value
        return { loginOptions }
      })
    }

  onChangeDefaultLoginOption = (event: React.SyntheticEvent<HTMLElement>, data: InputProps | DropdownProps) => {
    const name = data.name
    const value = data.value
    this.setState(prevState => {
      const defaultLoginOption = { ...prevState.defaultLoginOption }
      defaultLoginOption[name as keyof INewLoginOption] = value
      return { defaultLoginOption }
    })
  }

  getVendor = (loginOption: INewLoginOption): string =>
    loginOption.loginOption === 'salesforce-custom-endpoint' ? 'salesforce' : loginOption.loginOption

  getCreatedMessage = (): string => {
    const team = this.state.createdTeam
    let message = `Successfully Created Team with ID: ${(this.state.createdTeam || { id: '' }).id}`
    const connections = (team || { auth0Connections: [] as IAuthConnection[] }).auth0Connections
    if (connections) {
      message += '. With the following login options: '
      message += connections.map(x => x.name).join(', ')
    }
    return message
  }

  addTeam = (): void => {
    this.setState({
      loading: true,
      error: undefined,
      submitted: false,
      teamNameUsed: false
    })
    if (this.state.addCustomer) {
      createCustomer(this.state.newCustomerName)
        .then(
          response => this.setState({ customerID: response.id, addCustomer: false }, this.submitTeam),
          error => this.setState({ error, loading: false, submitted: false })
        )
        .catch(error => this.setState({ error, loading: false }))
    } else {
      this.submitTeam()
    }
  }

  submitTeam = (): void => {
    const { teamName, teamDescription, customerID, defaultLoginOption, loginOptions, region } = this.state
    const vendor = this.getVendor(defaultLoginOption)

    const team: IFormTeam = {
      name: teamName,
      description: teamDescription,
      customerId: customerID !== ' ' ? customerID : undefined,
      region,
      vendor,
      displayName: defaultLoginOption.loginButtonText === '' ? undefined : defaultLoginOption.loginButtonText,
      instance: defaultLoginOption.endpoint === '' ? undefined : defaultLoginOption.endpoint
    }

    if (this.validateTeam()) {
      provisionTeam(team).then(
        createdTeam => {
          this.setState({
            createdTeam
          })
          const connections = loginOptions.map((x, i) => ({
            vendor: this.getVendor(x),
            teamId: createdTeam.id,
            name: `${createdTeam.name}-${i + 1}`,
            instance: x.endpoint,
            displayName: x.loginButtonText
          }))
          if (connections.length === 0) {
            this.setState({ loading: false, submitted: true })
            this.props.fetchTeams()
            this.props.handleAddTeamModal()
            createSuccessToast(this.getCreatedMessage())
          } else {
            addMultipleConections(createdTeam.id, connections).then(
              createdTeamWithConnections => {
                this.setState({ loading: false, submitted: true, createdTeam: createdTeamWithConnections })
                this.props.fetchTeams()
                this.props.handleAddTeamModal()
                createSuccessToast(this.getCreatedMessage())
              },
              error => {
                this.setState({ loading: false, connectionError: error })
              }
            )
          }
        },
        error => {
          if (error.response.status === 409) {
            this.setState({ loading: false, teamNameUsed: true })
          } else {
            this.setState({ loading: false, error })
          }
        }
      )
    } else {
      this.setState({
        loading: false,
        error: new Error('There are errors in highlighted fields.')
      })
    }
  }

  getErrorMessage() {
    const error = this.state.error
    const connectionError = this.state.connectionError
    let errorMessage = getErrorContent(error)
    errorMessage += '\n'
    if (!error && connectionError) {
      errorMessage += 'Team was created successfully but there was an error adding additional connections: '
    } else if (connectionError) {
      errorMessage += 'There was also an error creating connections: '
    }
    errorMessage += getErrorContent(connectionError)
    return errorMessage
  }

  renderLoginOptionsTableRows = () => {
    const { loginOptions } = this.state
    return loginOptions.map((x, i) => (
      <LoginOptionRow
        loginOptionData={x}
        onChange={this.onChangeLoginOption(i)}
        iconAction={this.removeLoginOption(i)}
        endpointError={this.validateLoginOptionEndpoint(i)}
        key={i}
        id={`${i + 1}`}
      />
    ))
  }

  toggleAddCustomer = () => {
    this.setState(prevState => ({ addCustomer: !prevState.addCustomer }))
  }

  clearForm = (): void => {
    this.setState({
      addCustomer: false,
      newCustomerName: '',
      loading: false,
      submitted: false,
      error: undefined,
      teamNameUsed: false,
      customerID: ' ',
      teamName: '',
      teamDescription: '',
      createdTeam: undefined,
      loginOptions: []
    })
  }

  render() {
    const {
      loading,
      submitted,
      createdTeam,
      error,
      teamName,
      teamNameUsed,
      teamDescription,
      region,
      customerID,
      connectionError,
      addCustomer,
      newCustomerName
    } = this.state
    const { regions } = this.props

    return (
      <div>
        <Form
          loading={loading}
          success={submitted && !!createdTeam}
          error={!!error || !!connectionError}
          onSubmit={this.addTeam}
        >
          <Form.Group inline widths="equal">
            {!addCustomer ? (
              <CustomerSelector value={customerID} onChange={this.onChange} label="Customer" />
            ) : (
              <Form.Input
                label="Customer"
                value={newCustomerName}
                onChange={this.onChange}
                placeholder="New customer name"
                name="newCustomerName"
                fluid
                required
              />
            )}
            <Icon name={addCustomer ? 'close' : 'add'} onClick={this.toggleAddCustomer} />
          </Form.Group>
          <Form.Group>
            <TeamNameInput
              value={teamName}
              onChange={this.onChangeLowerCase}
              error={!this.validateTeamName()}
              labelSelector={teamNameUsed}
              labelMessage="Team Name in Use."
            />
            <RegionInput
              regions={regions}
              placeholder="Choose a region..."
              label="Region"
              onChange={this.onChange}
              name="region"
              value={region}
              required={true}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <BasicInput value={teamDescription} onChange={this.onChange} label="Team Description" required={true} />
          </Form.Group>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Login Option</Table.HeaderCell>
                <Table.HeaderCell>Login Button Text</Table.HeaderCell>
                <Table.HeaderCell>Endpoint</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <LoginOptionRow
                loginOptionData={this.state.defaultLoginOption}
                onChange={this.onChangeDefaultLoginOption}
                iconAction={this.addLoginOption}
                endpointError={this.validateDefaultEndpoint()}
                default
                id="0"
              />
              {this.renderLoginOptionsTableRows()}
            </Table.Body>
          </Table>
          <Message error={true} header="Error" content={this.getErrorMessage()} />
          <Button
            id="modal-button-add-team"
            type="submit"
            fluid={true}
            className="form-button-sked-blue"
            disabled={!this.validateTeam()}
          >
            Submit
          </Button>
        </Form>
      </div>
    )
  }
}
