import { adminApi } from './Endpoints'

export interface ISalesforceAccountWithContractsResult extends ISalesforceAccountResult {
  CurrentContractStartDate: string | null
  CurrentContractEndDate: string | null
}

export interface ISalesforceAccountResult {
  Id: string
  Name: string
  CurrencyIsoCode: string | null
  CustomerStage: string | null
  Owner: { Name: string | null } | null
  CSM: { Name: string | null } | null
  ActiveACV: number | null
  ActiveACVUSD: number | null
  TotalLicensedSeats: number | null
  TotalUsedLicences: number | null
  ACVSummary: number | null
  CustomerStartDate: string | null
  PrimaryUseCase: string | null
  Industry: string | null
  SupportTier: string | null
  CustomerHappiness: string | null
  CustomerHappinessNotes: string | null
  CustomerHappinessPriorValue: string | null
  CustomerGoLiveDate: string | null
  PlatformType: string | null
  EnriteProduct: string | null
  ExternalProducts: string | null
  Orders: Order[]
}

export interface Order {
  Id: string
  Name: string
  TotalReadOnlyLicences: number
  TotalResourceLicences: number
  TotalSchedulerLicences: number
  CurrentReadOnlyLicences: number
  CurrentResourceLicences: number
  CurrentSchedulerLicences: number
  OrderStatus: string
}

export interface ISalesforceAccountWithContracts {
  result: ISalesforceAccountWithContractsResult
  instanceUrl: string
}

export interface ISalesforceAccount {
  result: ISalesforceAccountResult
  instanceUrl: string
}

export const getSalesforceAccountWithContracts = async (
  salesforceId: string
): Promise<ISalesforceAccountWithContracts> =>
  adminApi
    .get<ISalesforceAccountWithContracts>(`/salesforce/account/${salesforceId}`, { params: { include: 'contracts' } })
    .then(response => response.data)

export const getSalesforceAccount = async (salesforceId: string): Promise<ISalesforceAccount> =>
  adminApi.get<ISalesforceAccount>(`/salesforce/account/${salesforceId}`).then(response => response.data)
