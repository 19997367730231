import * as React from 'react'
import { Button, DropdownProps, Form, Icon, Input, InputOnChangeData, Label, Modal, Popup } from 'semantic-ui-react'
import {
  addExistingTenant,
  categoryOptions,
  environmentOptions,
  INewTenant,
  validateTenantId
} from '../../../actions/Tenants'
import { Vendor } from '../../../actions/Utils'
import { createErrorToast } from '../../alertComponents/Alert'
import { SearchableSelector } from '../../formComponents/Selectors'
import { mapStringsToOptions } from '../../Utils'

export interface IProps {
  teamId: string
  updateTeam(): void
}

export interface IState {
  vendor: Vendor
  tenantId: string
  orgId: string
  submitting: boolean
  open: boolean
  category: string
  environment: string
}

const vendorOptions = [
  {
    text: 'Skedulo',
    value: 'skedulo'
  },
  {
    text: 'Salesforce',
    value: 'salesforce'
  }
]

export class AddTenantModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      vendor: 'skedulo',
      tenantId: '',
      orgId: '',
      submitting: false,
      open: false,
      environment: '',
      category: ''
    }
  }

  onChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps): void => {
    const name = data.name
    const value = data.value
    // The ...this.state is required due to a @types/react bug should be removed when this is fixed.
    this.setState({ ...this.state, [name]: value })
  }

  resetForm = () => {
    this.setState({
      vendor: 'skedulo',
      tenantId: '',
      orgId: '',
      submitting: false,
      environment: '',
      category: ''
    })
  }

  getTenantPrefix(): '' | 'sk_' {
    if (this.state.vendor === 'skedulo') {
      return 'sk_'
    }
    return ''
  }

  addTenant(): Promise<string> {
    this.setState({ submitting: true })
    const { tenantId, vendor, environment, category } = this.state
    const newTenant: INewTenant = {
      vendor,
      tenantId: this.getTenantPrefix() + tenantId,
      orgId: this.getTenantPrefix() + tenantId,
      teamId: this.props.teamId,
      environment,
      category
    }
    return addExistingTenant(newTenant)
  }

  handleAddTenant = () => {
    this.addTenant()
      .then(response => {
        this.props.updateTeam()
        this.resetForm()
        this.toggleForm()
      })
      .catch(error => {
        createErrorToast(error)
        this.setState({ submitting: false })
      })
  }

  toggleForm = () => this.setState(prev => ({ open: !prev.open }))

  getTenantLabel = () => {
    const vendor = this.state.vendor
    if (vendor === 'skedulo') {
      return 'sk_'
    }
    return null
  }

  render() {
    const { tenantId, vendor, submitting, open, category, environment } = this.state
    const tenantIdIsValid = validateTenantId(vendor, tenantId)
    const tenantIdError = tenantId !== '' && vendor !== '' && !tenantIdIsValid
    const tenantIdErrorMessage =
      vendor === 'skedulo'
        ? 'Tenant ID should be 32 character UUID'
        : vendor === 'salesforce'
          ? 'Tenant ID should be 18 character Org ID'
          : ''
    return (
      <Modal
        open={open}
        closeIcon
        onClose={this.toggleForm}
        trigger={
          <Button className={'form-button-sked-blue'} content={'Add an Existing Tenant'} onClick={this.toggleForm} />
        }
      >
        <Modal.Header>Add an Existing Tenant to Team</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleAddTenant} loading={submitting}>
            <Form.Field required>
              <label>Vendor</label>
              <Form.Select
                name="vendor"
                placeholder="Select vendor"
                options={vendorOptions}
                onChange={this.onChange}
                value={vendor}
              />
            </Form.Field>
            <Form.Field required>
              <label>
                Tenant ID &nbsp;
                <Popup
                  trigger={<Icon name="question circle" />}
                  content="Tenant ID is the same as the 18 character Org ID for SalesForce and 32 character UUID for Skedulo vendors."
                />
              </label>
              <Input
                name="tenantId"
                placeholder="Enter Tenant ID"
                value={tenantId}
                error={tenantIdError}
                onChange={this.onChange}
                fluid
                label={this.getTenantLabel()}
              />
              {tenantIdError && (
                <Label name="errorMessage" pointing={true} color="red">
                  {tenantIdErrorMessage}
                </Label>
              )}
            </Form.Field>
            <SearchableSelector
              required
              value={category}
              label="Category"
              options={mapStringsToOptions(categoryOptions)}
              onChange={this.onChange}
            />
            <SearchableSelector
              required
              value={environment}
              label="Environment"
              options={mapStringsToOptions(environmentOptions)}
              onChange={this.onChange}
            />
            <Form.Button
              type="submit"
              disabled={vendor === '' || !tenantIdIsValid || environment === '' || category === ''}
              className="sked-blue-button"
              content="Submit"
              fluid
            />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
