import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getDeployment, getDeploymentEnvironments, getDeployments, IDeployment } from '../../actions/Deployments'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { decodeErrors } from '../../actions/Utils'

export const useDeploymentQuery = (env: string, instance: string, id: string) =>
  useQuery({
    queryKey: ['deployment', env, instance, id],
    queryFn: () => getDeployment(env, instance, id),
    onError: error => {
      createErrorToast(`Couldn't get deployment with id ${id} in ${env} ${instance}: ${error}`)
    },
    refetchInterval: data => {
      if (!data || data.status === 'running' || data.status === 'pending') {
        return 3000
      }
      return false
    }
  })

export const useDeploymentsQuery = () => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['deployments'],
    queryFn: () => getDeployments(100),
    onError: error => {
      const setData = (data: IDeployment[]) => queryClient.setQueryData(['deployments'], data)
      decodeErrors<IDeployment[]>(error, setData, createErrorToast)
    },
    refetchInterval: 30000
  })
}

export const useDeploymentsQueryByEnvironmentAndInstance = (env: string, instance: string) =>
  useQuery({
    queryKey: ['deployments', env, instance],
    queryFn: () => getDeployments(100, { name: env, instance }),
    onError: error => {
      createErrorToast(`Couldn't get deployments for environment ${env} ${instance}: ${error}`)
    }
  })

export const useDeploymentEnvironmentsQuery = () =>
  useQuery({
    queryKey: ['deploymentEnvironments'],
    queryFn: () => getDeploymentEnvironments(),
    onError: error => createErrorToast(error)
  })
