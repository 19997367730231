import { StatusCount, StatusIcon, StatusIconProps } from '../../components/StatusCount'

const podStatusIconLookup: { [key: string]: StatusIconProps } = {
  error: {
    statusName: 'Error',
    color: 'red',
    iconName: 'times circle'
  },
  pending: {
    statusName: 'Pending',
    color: 'yellow',
    iconName: 'clock'
  },
  running: {
    statusName: 'Running',
    color: 'green',
    iconName: 'check circle'
  },
  imagepull: {
    statusName: 'Image Pull Fail',
    color: 'blue',
    iconName: 'exclamation circle'
  }
}

export const PodStatusIcon = (props: { status: string }) => (
  <StatusIcon status={props.status} statusIconPropsByStatus={podStatusIconLookup} />
)

export const PodStatusCount = (props: { status: string; count: number }) => (
  <StatusCount status={props.status} count={props.count} statusIconPropsByStatus={podStatusIconLookup} />
)
