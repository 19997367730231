import { useQuery } from '@tanstack/react-query'
import { IRegionInfo } from '../actions/Regions'
import { TeamWithApi } from '../views/newCustomers/component/CustomerUsers'
import { Team } from '../actions/NewTeams'
import { expensiveQueryConfig } from '../views/Utils'

export const combineTeamsWithApis = (teams: Team[], regionsInfo: IRegionInfo[]): TeamWithApi[] =>
  teams.map(team => {
    const api =
      regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === team.region.toLowerCase())?.server.api || ''
    return {
      team,
      api
    }
  })

export const useGetTeamRegionInfoQuery = (
  teamsData?: Team[],
  regionsInfo?: IRegionInfo[],
  doneFetchingTeams?: boolean
) =>
  useQuery<TeamWithApi[]>(['teams', 'regionInfo'], () => combineTeamsWithApis(teamsData!, regionsInfo!), {
    enabled: !!doneFetchingTeams && !!regionsInfo && !!teamsData,
    ...expensiveQueryConfig
  })
