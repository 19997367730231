import * as React from 'react'
import { Button, DropdownProps, Modal, Select } from 'semantic-ui-react'
import { startMobileBuild } from '../../../actions/Mobile'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

interface IState {
  open: boolean
  loading: boolean
  buildType: (typeof buildOptions)[number]
}

const buildOptions = ['', 'nightly', 'production'] as const
type BuildType = (typeof buildOptions)[number]

const buildSelectorOptions = [
  {
    value: 'nightly',
    text: 'Nightly'
  },
  {
    value: 'production',
    text: 'Production'
  }
]

export class StartBuildModal extends React.PureComponent<object, IState> {
  constructor(props: object) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      buildType: ''
    }
  }

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  onChange = (event: React.SyntheticEvent, data: DropdownProps) => {
    if (buildOptions.includes(data.value as BuildType)) {
      this.setState({ buildType: data.value as BuildType })
    }
  }

  startBuild = async () => {
    this.setState({ loading: true })
    try {
      await startMobileBuild(this.state.buildType)
      createSuccessToast(`Mobile ${this.state.buildType} queued successfully.`)
    } catch (error) {
      createErrorToast(error)
    } finally {
      this.setState({ loading: false, open: false })
    }
  }

  render() {
    const { open, loading, buildType } = this.state

    return (
      <Modal
        trigger={<Button onClick={this.toggleOpen} content="Start Build" />}
        size="mini"
        closeIcon
        open={open}
        onClose={this.toggleOpen}
      >
        <Modal.Header>Start Mobile Build</Modal.Header>
        <Modal.Content>
          <Select
            value={buildType}
            onChange={this.onChange}
            options={buildSelectorOptions}
            search
            fluid
            placeholder="Select Type"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggleOpen} content="Cancel" />
          <Button positive content="Start" onClick={this.startBuild} loading={loading} disabled={!buildType} />
        </Modal.Actions>
      </Modal>
    )
  }
}
