import { adminApi } from './Endpoints'

export interface IEnvironmentDetails {
  id: number
  name: string
  instance: string
  environment: string
}

export interface IOnlineEnvironment {
  environment: IEnvironmentDetails
  online: boolean
}

export interface IOnlineEnvironments {
  data: IOnlineEnvironment[]
  lastUpdate: string
}

export const getEnvironments = async (): Promise<IEnvironmentDetails[]> =>
  adminApi.get<IEnvironmentDetails[]>('/environments').then(result => result.data)

export const getOnlineEnvironments = async (): Promise<IOnlineEnvironments> =>
  adminApi.get<IOnlineEnvironments>('/environments/status').then(result => result.data)

export const updateOnlineEnvironments = () =>
  adminApi.post<IOnlineEnvironments>('/environments/status/update').then(result => result.data)
