import * as React from 'react'
import classnames from 'classnames'
import { Table } from 'semantic-ui-react'

import { IObjectPermissions } from '../../../../actions/Permissions'
import { PermissionIcon } from './PermissionIcon'
import { permissionMatches } from './permission-utils'
import { PermissionField } from './PermissionField'

/**
 * Renders a row with the Object name and permissions along with any fields that belong to this object
 */
export const PermissionObject: React.FC<{
  /**
   * Name to display
   */
  objectName: string
  /**
   * Base package object permission used to compare
   */
  baseObject: IObjectPermissions
  /**
   * Selected permission set to check
   */
  userObject?: IObjectPermissions
  /**
   * Show all fields with and without errors, only error are displayed when false
   */
  showAll: boolean
}> = ({ objectName, baseObject, userObject, showAll }) => {
  const readMatches = userObject ? permissionMatches(baseObject.read, userObject.read) : false
  const updateMatches = userObject ? permissionMatches(baseObject.update, userObject.update) : false
  const rowClasses = classnames({ 'tw-bg-red-100': !readMatches || !updateMatches })

  return (
    <>
      <Table.Row className={rowClasses}>
        <Table.Cell>{objectName}</Table.Cell>
        <Table.Cell>
          <PermissionIcon hasPermission={baseObject.read} matches={readMatches} />
        </Table.Cell>
        <Table.Cell>
          <PermissionIcon hasPermission={baseObject.update} matches={updateMatches} />
        </Table.Cell>
      </Table.Row>

      {Object.keys(baseObject.fields)
        .sort()
        .map(fieldName => {
          const baseFields = baseObject.fields[fieldName]
          const userFields = userObject?.fields[fieldName]
          return (
            <PermissionField
              key={`field:${fieldName}`}
              fieldName={fieldName}
              baseFields={baseFields}
              userFields={userFields}
              showAll={showAll}
            />
          )
        })}
    </>
  )
}
