import * as _ from 'lodash'
import { elasticserver } from './Endpoints'
import { IResult } from './Utils'

export const DEFAULT_REGION = 'us'

export interface IRegionInfo {
  region: string
  name: string
  server: {
    api: string
    uiPlatform: string
  }
}

export interface IRegions {
  [key: string]: string
}

export interface IRegionUrls {
  [key: string]: string
}

export const toRegions = (regionInfos: IRegionInfo[]): IRegions =>
  _.chain(regionInfos).keyBy('region').mapValues('name').value()

export const toRegionUrls = (regionInfos: IRegionInfo[]): IRegionUrls =>
  _.chain(regionInfos).keyBy('region').mapValues('server.api').value()

export const getRegions = (): Promise<IRegionInfo[]> =>
  elasticserver.get<IResult<IRegionInfo[]>>('/auth/config/regions').then(regions => regions.data.result)
