import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery
} from '@tanstack/react-query'
import { createErrorToast } from '../views/alertComponents/Alert'
import { getPackages, IPackage } from '../actions/Package'

export interface GetPackageInfiniteQuery {
  data?: InfiniteData<PackagesQueryResponse>
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<PackagesQueryResponse>, Error>>
  isLoading: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<PackagesQueryResponse, Error>>
  hasNextPage?: boolean
}

export interface PackageQueryFilter {
  packageName?: string
  tenantId?: string
  packageVersion?: string
}

export type PackageQuery = PackageQueryFilter & {
  pageSize?: number
  currentPage?: number
}

export interface PackagesQueryResponse {
  next?: string
  result: IPackage[]
}

export const useGetPackagesInfiniteQuery = (query: string) =>
  useInfiniteQuery(
    ['Packages', query],
    ({ pageParam = {} }) =>
      getPackages({
        next: pageParam.next ?? '',
        query
      }),
    {
      getNextPageParam: lastPage => (lastPage.next ? { next: lastPage.next } : undefined),
      retry: false,
      onError: (error: Error) => createErrorToast(error)
    }
  )
