import { Image } from 'semantic-ui-react'
import {
  GOOGLE_WORKSPACE,
  MICROSOFT_AZURE_AD,
  OKTA_WORKFORCE,
  OPEN_ID_CONNECT,
  SAML_AUTH,
  SsoType
} from '../views/newCustomers/services/Constants'

export const ssoLogo = (name: SsoType) => {
  const getLogoBasedOnName = () => {
    switch (name) {
      case GOOGLE_WORKSPACE:
        return require('../images/sso/google-avatar.svg')
      case OKTA_WORKFORCE:
        return require('../images/sso/okta-avatar.svg')
      case OPEN_ID_CONNECT:
        return require('../images/sso/openID-avatar.svg')
      case SAML_AUTH:
        return require('../images/sso/SAML-avatar.svg')
      case MICROSOFT_AZURE_AD:
        return require('../images/sso/azure-avatar.svg')
      default:
        return null
    }
  }

  return <Image src={getLogoBasedOnName()} style={{ width: '42px', height: 'auto' }} id={`sso-logo-${name}`} />
}
