import _ from 'lodash'
import { Button, Form, Icon, Modal, Popup } from 'semantic-ui-react'
import { useEffect, useMemo, useState } from 'react'
import { IDeploymentEnvironment } from '../../../actions/Deployments'
import { SearchableSelector } from '../../formComponents/Selectors'
import { mapStringsToOptions } from '../../Utils'
import { useUserQuery } from '../../../queries/auth/GetUserQuery'
import { useOnlineEnvironmentsQuery } from '../../../queries/environments/GetEnvironmentsQuery'
import { useDeploymentCreationMutation } from '../../../mutations/deployments/CreateDeploymentMutation'
import { DeploymentModal } from '../../../hooks/useDeploymentModal'
import { useDeploymentEnvironmentsQuery } from '../../../queries/deployments/GetDeploymentsQuery'

interface IProps {
  modal: DeploymentModal
  withButton?: boolean
}

interface IEnvironmentInstanceProps {
  environments?: IDeploymentEnvironment[]
  environmentName: string
  isInstanceSelected(instance: string): boolean
  toggleInstance(instance: string): () => void
  onlineEnvironments: Map<number, boolean>
}

const EnvironmentInstances = (props: IEnvironmentInstanceProps) => (
  <Form.Group inline aria-label="Environment Instances">
    <label>Instances:</label>
    {props.environments
      ?.filter(environment => environment.name === props.environmentName)
      .map(environment => (
        <Form.Checkbox
          key={environment.id}
          id={environment.instance}
          label={<label htmlFor={environment.instance}>{environment.instance}</label>}
          toggle
          checked={props.isInstanceSelected(environment.instance)}
          onClick={props.toggleInstance(environment.instance)}
          disabled={!props.onlineEnvironments.get(environment.id)}
        />
      )) || []}
    <Popup content="Greyed out instances are currently offline." trigger={<Icon name="question circle" />} />
  </Form.Group>
)
export const DeployApplicationModal = (props: IProps) => {
  const [submitting, setSubmitting] = useState(false)
  const { data: onlineEnvironmentsData } = useOnlineEnvironmentsQuery()
  const { data: environments } = useDeploymentEnvironmentsQuery()
  const { data: user } = useUserQuery()
  const { mutate: createDeployment } = useDeploymentCreationMutation()

  const {
    modalOpen,
    setModalOpen,
    environmentName,
    setEnvironmentName,
    applicationName,
    setApplicationName,
    deploymentTag,
    setDeploymentTag,
    deploymentBranch,
    setDeploymentBranch,
    deploymentCheckMode,
    setDeploymentCheckMode,
    selectedEnvironments,
    setSelectedEnvironments,
    selectedEnvironmentsHaveChanged,
    setSelectedEnvironmentsHaveChanged,
    configVersionVisible,
    setConfigVersionVisible,
    clearDeployment,
    toggleOpen
  } = props.modal

  const getAppOptions = () => {
    if (environmentName && environments) {
      const filtered = environments.filter(environment => environment.name === environmentName)
      return _.uniqWith(
        _.flatten(
          filtered.map(environment =>
            environment.deploymentTemplates.map(deploymentTemplate => deploymentTemplate.name)
          )
        ),
        _.isEqual
      ).sort((a, b) => a.localeCompare(b))
    }
    return []
  }

  const getEnvironmentOptions = (deploymentEnvironments?: IDeploymentEnvironment[]) =>
    _.uniqWith(
      deploymentEnvironments?.map(environment => ({ value: environment.name, text: environment.name })),
      _.isEqual
    )

  const envOptions = getEnvironmentOptions(environments)
  const onlineEnvironments = useMemo(() => {
    const result = new Map<number, boolean>()
    onlineEnvironmentsData?.data.forEach(onlineEnvironment =>
      result.set(onlineEnvironment.environment.id, onlineEnvironment.online)
    )
    return result
  }, [onlineEnvironmentsData])

  const getInstanceOptions = useMemo(
    () =>
      _.uniqWith(
        _.flatten(
          environments
            ?.filter(
              environment =>
                environment.name === environmentName &&
                environment.deploymentTemplates.map(template => template.name).includes(applicationName) &&
                onlineEnvironments.get(environment.id)
            )
            .map(environment => environment.instance)
        ),
        _.isEqual
      ),
    [environments, onlineEnvironments, applicationName, environmentName]
  )

  const submitDeploy = async () => {
    if (selectedEnvironments.length >= 1) {
      setSubmitting(true)
      createDeployment({
        application: applicationName,
        environments: selectedEnvironments,
        docker_tag: deploymentTag,
        user: user?.name || '',
        branch: deploymentBranch,
        check_mode: deploymentCheckMode
      })
      setSubmitting(false)
      setModalOpen(false)
      clearDeployment()
    }
  }

  useEffect(() => {
    const potentialEnvironments = getInstanceOptions.map(instance => ({ name: environmentName, instance }))
    // only update selectedEnvironments if the selected environments haven't already been changed for this environment
    if (potentialEnvironments.length > 0 && !selectedEnvironmentsHaveChanged) {
      setSelectedEnvironments(potentialEnvironments)
      setSelectedEnvironmentsHaveChanged(true)
    }
  }, [
    environmentName,
    selectedEnvironmentsHaveChanged,
    getInstanceOptions,
    setSelectedEnvironments,
    setSelectedEnvironmentsHaveChanged
  ])

  const isInstanceSelected = (instance: string) => selectedEnvironments.some(env => env.instance === instance)

  const toggleInstance = (instance: string) => () => {
    if (isInstanceSelected(instance)) {
      setSelectedEnvironments(prevState => prevState.filter(env => env.instance !== instance))
    } else {
      setSelectedEnvironments(prevState => [...prevState, { name: environmentName, instance }])
    }
  }

  const handleOverrideClick = (checked: boolean) => {
    setConfigVersionVisible(checked)
    if (!checked) {
      setDeploymentBranch('')
    }
  }

  const CONFIG_VERSION_HELP =
    'This can be used for branch-based configuration or to ensure that the configuration used for production deploys matches lower environments'

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        clearDeployment()
        toggleOpen()
      }}
      closeOnDimmerClick={false}
      closeIcon
      size="tiny"
      aria-label="New Deployment"
      trigger={
        props.withButton && (
          <Button onClick={toggleOpen} className="form-button-sked-blue" primary content="Deploy Application" />
        )
      }
    >
      <Modal.Header id="modal-header-add-team">New Deployment</Modal.Header>
      <Modal.Content id="modal-content-add-team">
        <Form onSubmit={submitDeploy}>
          <SearchableSelector
            label="Environment"
            options={envOptions}
            onChange={(_e, data) => {
              setEnvironmentName(data.value as string)
              setSelectedEnvironmentsHaveChanged(false)
            }}
            value={environmentName}
          />
          <Form.Group inline widths="equal" label="Application">
            <SearchableSelector
              label="Application"
              options={mapStringsToOptions(getAppOptions())}
              onChange={(_e, data) => {
                setApplicationName(data.value as string)
              }}
              value={applicationName}
              disabled={!environmentName}
            />
          </Form.Group>

          <EnvironmentInstances
            environments={environments}
            isInstanceSelected={isInstanceSelected}
            environmentName={environmentName}
            onlineEnvironments={onlineEnvironments}
            toggleInstance={toggleInstance}
          />

          <Form.Input
            label={<label htmlFor="tag">Tag</label>}
            name="docker_tag"
            id="tag"
            placeholder="Git Tag"
            onChange={(_e, data) => {
              setDeploymentTag(data.value)
            }}
            value={deploymentTag}
          />
          <Form.Group inline>
            <Form.Checkbox
              key="override_config"
              label={<label htmlFor="override_config">Override config</label>}
              id="override_config"
              toggle
              checked={configVersionVisible}
              onClick={(_event, data) => handleOverrideClick(data.checked || false)}
            />
            <Popup content={CONFIG_VERSION_HELP} trigger={<Icon name="question circle" />} />
          </Form.Group>

          {configVersionVisible && (
            <Form.Input
              label={<label htmlFor="config_version">Configuration version</label>}
              id="config_version"
              name="branch"
              placeholder="Branch/commit hash of ansible-skedulo-kube to use (defaults to latest)"
              onChange={(_e, data) => {
                setDeploymentBranch(data.value)
              }}
              value={deploymentBranch}
            />
          )}
          <Form.Checkbox
            key="check_mode"
            label={<label htmlFor="check_mode">Check mode</label>}
            id="check_mode"
            toggle
            checked={deploymentCheckMode}
            onClick={(_e, data) => setDeploymentCheckMode(data.checked || false)}
          />
          <Button
            id="modal-button-create-deployment"
            type="submit"
            fluid={true}
            disabled={submitting || selectedEnvironments.length < 1}
            className="form-button-sked-blue"
            loading={submitting}
            content="Submit"
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
