import React, { useContext } from 'react'
import hasAuthTokenInLocalStorage from '../auth/AuthChecker'

export const AuthContext = React.createContext<AuthStoreValues | undefined>(undefined)

interface AuthStoreProps {
  children: React.ReactNode
}

export interface AuthStoreValues {
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
}

export const useAuthStore = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuthStore must be used within AuthContextProvider')
  }
  return context
}

export const AuthStoreProvider = ({ children }: AuthStoreProps) => {
  const [auth, setAuth] = React.useState(hasAuthTokenInLocalStorage())
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value: AuthStoreValues = { isAuthenticated: auth, setIsAuthenticated: setAuth }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
