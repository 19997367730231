import { Form, Message } from 'semantic-ui-react'
import { InputWithError } from '../../../formComponents/Inputs'
import { ResetTeamService } from '../../services/ResetTeamService'

export interface SalesforceResetTeamFormProps {
  resetTeamService: ResetTeamService
}

const SalesforceResetTeamForm = ({ resetTeamService }: SalesforceResetTeamFormProps) => {
  const {
    resetSalesforceTeam,
    isResetting,
    salesforceId,
    setSalesforceId,
    showSalesforceIdError,
    isReadySalesforceReset
  } = resetTeamService

  return (
    <>
      <Message
        content={
          <div>
            <p>
              Resetting this Team will remove system configuration including packages, custom pages, settings, custom
              field and object mappings.
            </p>
          </div>
        }
        icon="warning"
        error
      />
      <p>Enter the details of the new Salesforce Org that you would like to reset this Team to.</p>
      <Form onSubmit={resetSalesforceTeam}>
        <InputWithError
          required
          label="Salesforce ID"
          placeholder="Salesforce ID"
          labelMessage="Must be a valid Salesforce Org ID."
          error={showSalesforceIdError}
          labelSelector={showSalesforceIdError}
          name="salesforceId"
          value={salesforceId ?? ''}
          onChange={(_, data) => setSalesforceId(data.value)}
        />
        <Form.Button
          type="submit"
          id="modal-button-add-customer"
          fluid={true}
          loading={isResetting}
          disabled={!isReadySalesforceReset}
        >
          Reset Team
        </Form.Button>
      </Form>
    </>
  )
}

export default SalesforceResetTeamForm
