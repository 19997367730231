import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast } from '../views/alertComponents/Alert'
import { deleteTeam } from '../actions/NewTeams'

export const useDeleteTeamMutation = (teamId: string) => (onSuccessDeleteFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<string, AxiosError>(() => deleteTeam(teamId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['team', teamId])
      queryClient.invalidateQueries(['teams'])
      queryClient.invalidateQueries(['customer'])
      queryClient.invalidateQueries(['customerTeams'])
      onSuccessDeleteFn()
    },
    onError: (error: AxiosError) => {
      createErrorToast(error)
    }
  })
}
