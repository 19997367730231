import { useMemo } from 'react'
import { useGetTeamQuery } from '../../queries/GetTeamQuery'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import Breadcrumbs from './Breadcrumbs'
import { BreadcrumbItem } from './DynamicBreadcrumbs'

interface TeamBreadcrumbWrapperProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  initialBreadcrumbs: BreadcrumbItem[]
  teamId: string
}

/**
 * Team Details breadcrumb for new customer model.
 *
 * Retrieves customer and team information and builds final step of breadcrumb.
 */
const TeamBreadcrumbWrapper = ({ initialBreadcrumbs, teamId }: TeamBreadcrumbWrapperProps) => {
  const { data: team } = useGetTeamQuery(teamId)
  const { data: customer } = useGetCustomerQuery(team?.customerId ?? '')

  const breadcrumbItems = useMemo(() => {
    if (!team?.primaryName || !customer?.name) {
      return initialBreadcrumbs
    }
    return [
      ...initialBreadcrumbs,
      {
        path: `/customers/${customer.id}`,
        name: customer.name
      } as BreadcrumbItem,
      {
        path: `/teams/${teamId}`,
        name: team.primaryName
      } as BreadcrumbItem
    ]
  }, [team, customer])

  return <Breadcrumbs items={breadcrumbItems} />
}

export default TeamBreadcrumbWrapper
