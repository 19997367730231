import { Icon, Label, Popup, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react'
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'

export interface StatusIconProps {
  statusName: string
  color?: SemanticCOLORS
  iconName: SemanticICONS
  loading?: boolean
}

const unknownStatusIcon = (unknownStatus: string): StatusIconProps => ({
  statusName: unknownStatus,
  color: 'orange',
  iconName: 'question circle'
})

export const StatusIcon = (props: {
  status: string
  statusIconPropsByStatus: { [key: string]: StatusIconProps }
  popupContent?: string
  size?: IconSizeProp | 'medium'
}) => {
  const iconProps = props.statusIconPropsByStatus[props.status] ?? unknownStatusIcon(props.status)
  return (
    <Popup
      trigger={
        <Icon
          name={iconProps.iconName}
          color={iconProps.color}
          aria-label={iconProps.statusName}
          size={props.size === 'medium' ? undefined : props.size ?? 'large'}
          loading={iconProps.loading}
        />
      }
    >
      {props.popupContent ?? iconProps.statusName}
    </Popup>
  )
}
export const StatusCount = (props: {
  status: string
  count: number
  statusIconPropsByStatus: { [key: string]: StatusIconProps }
}) => {
  const iconProps = props.statusIconPropsByStatus[props.status] ?? unknownStatusIcon(props.status)

  return props.count > 0 ? (
    <Label size="large" color={iconProps.color}>
      <Icon name={iconProps.iconName} />
      {`${props.count.toString()} ${iconProps.statusName}`}
    </Label>
  ) : null
}
