import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon'
import { StatusIcon, StatusIconProps } from '../../../components/StatusCount'

const deliveryStatusIconLookup: { [key: string]: StatusIconProps } = {
  pending: {
    iconName: 'clock',
    color: 'blue',
    statusName: 'Pending'
  },
  approved: {
    iconName: 'check circle',
    color: 'green',
    statusName: 'Deployed'
  },
  rejected: {
    iconName: 'times circle',
    color: 'red',
    statusName: 'Rejected'
  },
  superseded: {
    iconName: 'minus circle',
    color: 'yellow',
    statusName: 'Superseded'
  }
}

export const DeliveryStatusIcon = (props: { status: string; size?: IconSizeProp | 'medium' }) => (
  <StatusIcon status={props.status} statusIconPropsByStatus={deliveryStatusIconLookup} size={props.size} />
)

export const DeliveryStatusText = (props: { status: string }) => (
  <>{deliveryStatusIconLookup[props.status].statusName}</>
)
