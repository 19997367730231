import { useDynamicMetadataService } from '../../../services/DynamicMetadataService'
import { useUpdateCustomerMutation } from '../../../mutations/UpdateCustomerMutation'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { DynamicMetadataTable } from '../../../components/TableComponents/DynamicMetadataTable'
import { Customer } from '../../../actions/NewCustomers'

export const CustomerMetadataTable = ({ customerId }: { customerId: string }) => {
  const dynamicMetadataService = useDynamicMetadataService<Customer>({
    updateMutation: useUpdateCustomerMutation(customerId),
    getQuery: useGetCustomerQuery(customerId),
    getMetadataFromObject: customer => customer?.metadata,
    makeObjectFromMetadata: metadata => ({ metadata }),
    tableTitle: 'Metadata',
    isEditable: true
  })
  return <DynamicMetadataTable {...dynamicMetadataService} />
}
