import { Grid, Input } from 'semantic-ui-react'
import { CustomerConnection } from '../../../../../actions/CustomerConnections'
import { useUpdateCustomerConnectionService } from '../../../services/UpdateCustomerConnectionService'
import { formatDate } from '../../../../../utils/dateUtils'
import { getDisplayedLoginOptionType } from '../../../../../actions/Utils'
import { renderEditSaveCancelButton, renderRow } from './Utils'

interface OpenIDConnectLoginOptionDetailsProps {
  loginOption: CustomerConnection
}

const OpenIDConnectLoginOptionDetails = ({ loginOption }: OpenIDConnectLoginOptionDetailsProps) => {
  const updateCustomerConnectionService = useUpdateCustomerConnectionService(loginOption)
  const { description, isEditable, setDescription, metadataFormValues, setMetadataFormValues } =
    updateCustomerConnectionService
  const { metadata, createdAt, updatedAt, id } = loginOption

  return (
    <>
      {renderEditSaveCancelButton(updateCustomerConnectionService)}
      <Grid columns="6" style={{ margin: '5px' }}>
        {renderRow('Login option type', <>{getDisplayedLoginOptionType(loginOption?.type ?? '')}</>)}
        {renderRow(
          'Description',
          isEditable ? (
            <Input
              width={12}
              placeholder="Description"
              value={description}
              onChange={(_, data) => setDescription(data.value)}
              style={{ width: '600px' }}
            />
          ) : (
            <>{description}</>
          )
        )}
        {renderRow(
          'Discovery URL',
          isEditable ? (
            <Input
              placeholder="Discovery URL"
              value={metadataFormValues.discoveryUrl}
              onChange={(_, data) => setMetadataFormValues({ ...metadataFormValues, discoveryUrl: data.value })}
              style={{ width: '600px' }}
            />
          ) : (
            <>{metadata.discoveryUrl}</>
          )
        )}
        {renderRow(
          'Client ID',
          isEditable ? (
            <Input
              placeholder="Client ID"
              value={metadataFormValues?.clientId}
              onChange={(_, data) => setMetadataFormValues({ ...metadataFormValues, clientId: data.value })}
              style={{ width: '600px' }}
            />
          ) : (
            <>{metadata.clientId}</>
          )
        )}
        {renderRow(
          'Client secret',
          isEditable ? (
            <Input
              placeholder="Client secret"
              value={metadataFormValues?.clientSecret}
              onChange={(_, data) => setMetadataFormValues({ ...metadataFormValues, clientSecret: data.value })}
              style={{ width: '600px' }}
            />
          ) : (
            <>{metadata.clientSecret}</>
          )
        )}

        {renderRow(
          'Scopes',
          isEditable ? (
            <Input
              placeholder="Scopes"
              value={metadataFormValues?.scopes?.join(' ')}
              onChange={(_, data) => setMetadataFormValues({ ...metadataFormValues, scopes: data.value.split(' ') })}
              style={{ width: '600px' }}
            />
          ) : (
            <>{metadataFormValues?.scopes?.join(' ')}</>
          )
        )}

        {renderRow('Created', <>{formatDate(createdAt, 'h:mma ddd D MMM YYYY')}</>)}
        {renderRow('Updated', <>{formatDate(updatedAt, 'h:mma ddd D MMM YYYY')}</>)}
        {renderRow('Connection ID', <>{id}</>)}
      </Grid>
    </>
  )
}

export default OpenIDConnectLoginOptionDetails
