import { kebabCase, lowerCase, upperFirst } from 'lodash'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'react-table'
import { createMemoizedColumns, Table } from '../../components/TableComponents/ReactTable'
import { IFeatureFlagAggregate } from './NewFeatureFlagReport'

interface DisplayData {
  name: string
  count: number
  description: string
}

export const NewFeatureFlagList = ({
  featureFlagAggregate,
  loading
}: {
  featureFlagAggregate: IFeatureFlagAggregate
  loading: boolean
}) => {
  const displayData: DisplayData[] = useMemo(
    () =>
      Object.keys(featureFlagAggregate).map(key => ({
        name: key,
        count: featureFlagAggregate[key].count,
        description: featureFlagAggregate[key].description
      })),
    [featureFlagAggregate]
  )

  const flagColumns = createMemoizedColumns<DisplayData>([
    {
      Header: 'Feature flag',
      accessor: 'name',
      Cell: ({ row }: { row: Row<DisplayData> }) => (
        <Link to={`/feature-flag-report/${kebabCase(row.original.name)}`} style={{ padding: '10px' }}>
          {upperFirst(lowerCase(row.original.name))}
        </Link>
      )
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Teams enabled',
      accessor: 'count'
    }
  ])

  return (
    <div className="scrollable">
      <Table
        loading={loading}
        columns={flagColumns}
        data={loading ? [] : displayData}
        emptyMessage={'Click the Fetch data button to load the latest feature flag data'}
        celled
        color="blue"
        textAlign="left"
        className="sticky-table"
      />
    </div>
  )
}
