import { useQuery } from '@tanstack/react-query'
import { getEnvironments, getOnlineEnvironments } from '../../actions/Environments'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useEnvironmentsQuery = () =>
  useQuery({
    queryKey: ['environments'],
    queryFn: () => getEnvironments(),
    onError: error => createErrorToast(error)
  })
export const useOnlineEnvironmentsQuery = () =>
  useQuery({
    queryKey: ['onlineEnvironments'],
    queryFn: () => getOnlineEnvironments(),
    onError: error => createErrorToast(error)
  })
