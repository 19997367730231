import { Grid, Button, Header, Segment, Loader } from 'semantic-ui-react'
import { IField } from '../services/FieldUtils'
import { useFormService } from '../services/FormService'

export interface PackageInformationPanelProps {
  title: string
  fields: IField[]
  showEditButton: boolean
  isLoading: boolean
}

export const renderField = ({ title, viewComponent, editComponent }: IField, isEditMode: boolean): JSX.Element => (
  <Grid.Row key={title}>
    <Grid.Column width={7} verticalAlign="middle">
      <Header as="h5">{title}</Header>
    </Grid.Column>
    <Grid.Column width={9} verticalAlign="middle">
      {isEditMode ? editComponent : viewComponent}
    </Grid.Column>
  </Grid.Row>
)

export const PackageInformationPanel = (props: PackageInformationPanelProps) => {
  const { showEditButton, title, fields, isLoading } = props

  const { handleEditClicked, handleSaveClicked, handleCancelClicked, isEditable, isUpdating, isSaveButtonEnabled } =
    useFormService()

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9} verticalAlign="middle">
            <Header as="h3">{title}</Header>
          </Grid.Column>
          {showEditButton && (
            <Grid.Column width={7} textAlign="right">
              {isEditable ? (
                <>
                  <Button
                    size="small"
                    color="blue"
                    content="Save"
                    loading={isUpdating}
                    disabled={!isSaveButtonEnabled}
                    onClick={handleSaveClicked}
                  />
                  <Button size="small" content="Cancel" onClick={handleCancelClicked} disabled={isUpdating} />
                </>
              ) : (
                <Button color="blue" size="small" content="Edit" disabled={isEditable} onClick={handleEditClicked} />
              )}
            </Grid.Column>
          )}
        </Grid.Row>
        {isLoading ? (
          <Grid.Row style={{ margin: '10px' }}>
            <Loader active={true} />
          </Grid.Row>
        ) : (
          fields.map(field => renderField(field, isEditable))
        )}
      </Grid>
    </Segment>
  )
}
