import * as React from 'react'
import { Button, Checkbox, Modal } from 'semantic-ui-react'
import { deleteTeam } from '../../../actions/Teams'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

export interface IProps {
  id: string
  name: string
  setRedirect(): void
}

export interface IState {
  open: boolean
  permanentDelete: boolean
  loading: boolean
}

export class DeleteTeamModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      permanentDelete: false,
      loading: false
    }
  }

  handleDeleteTeam = () => {
    this.setState({ loading: true })
    deleteTeam(this.props.id, this.state.permanentDelete).then(
      connectionsNotDeleted => {
        const extraContent =
          connectionsNotDeleted.length !== 0
            ? ` Some team connections were not able to be removed: ${connectionsNotDeleted.join(', ')}.`
            : ''
        createSuccessToast(`Team successfully deleted.${extraContent}`)
        this.setState({ loading: false })
        this.toggleOpen()
        this.props.setRedirect()
      },
      error => {
        this.setState({ loading: false })
        this.toggleOpen()
        createErrorToast(error)
        this.props.setRedirect()
      }
    )
  }

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  togglePermanentDelete = () => this.setState(prevState => ({ permanentDelete: !prevState.permanentDelete }))

  render() {
    const { open, permanentDelete } = this.state
    const { name } = this.props

    return (
      <Modal
        size="large"
        trigger={<Button negative onClick={this.toggleOpen} content="Delete" />}
        open={open}
        onClose={this.toggleOpen}
      >
        <Modal.Header>Delete Team: {name}</Modal.Header>
        <Modal.Content>
          <p>
            Would you like to also <b>permanently remove</b> this team from the database?{' '}
          </p>
          <Checkbox
            onChange={this.togglePermanentDelete}
            checked={permanentDelete}
            label="Delete from database (WARNING: This is irreversible!)"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggleOpen} content="Cancel" />
          <Button negative content="Delete" onClick={this.handleDeleteTeam} loading={this.state.loading} />
        </Modal.Actions>
      </Modal>
    )
  }
}
