import React from 'react'
import Env from '../../../env/Env'

export const getJiraIssueIdsFromCommitMessage = (message: string) => message.match(/[A-Z0-9]{2,}-[0-9]+/g) || [] // return empty array if none found

export const getJiraURLFromIssueId = (jiraId: string) => `${Env.JIRA_URL}/browse/${jiraId}`

export const listJiraURLFromCommitMessage = (message: string, listStyleOptions?: React.CSSProperties) => {
  // extract Jira ids from message
  const issueIds = getJiraIssueIdsFromCommitMessage(message)
  // create list of url elements from issue IDs
  const uniqueIssues = new Set(issueIds)
  return (
    // render compact list unless otherwise specified
    <ul style={{ listStyleType: 'none', padding: 0, ...listStyleOptions }}>
      {[...uniqueIssues].map(id => (
        <li key={id}>
          <a href={getJiraURLFromIssueId(id)}>{id}</a>
        </li>
      ))}
    </ul>
  )
}
