import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import { BreadcrumbItem } from './DynamicBreadcrumbs'

interface BreadcrumbsProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  items: BreadcrumbItem[]
}

/**
 * Renders Breadcrumb navigation with defined BreadcrumbItems.
 */
const Breadcrumbs = ({ items }: BreadcrumbsProps) => (
  <Breadcrumb>
    {items.map((breadcrumb, index) => (
      <Fragment key={`breadcrumb-${breadcrumb.name.toLowerCase()}-${index}`}>
        <Breadcrumb.Section>
          <Link to={breadcrumb.path}>{breadcrumb.name}</Link>
        </Breadcrumb.Section>
        {index < items.length - 1 && <Breadcrumb.Divider />}
      </Fragment>
    ))}
  </Breadcrumb>
)

export default Breadcrumbs
