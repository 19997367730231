import { DropdownItemProps, Label, Icon, Popup } from 'semantic-ui-react'
import { startCase } from 'lodash'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useUpdateTeamNameMutation } from '../../../mutations/UpdateTeamNameMutation'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { useUpdateTeamMutation } from '../../../mutations/UpdateTeamMutation'
import {
  EditableInformationFieldConfig,
  useEditableRecordInformationService
} from '../../../services/EditableRecordInformationService'
import { Team } from '../../../actions/NewTeams'
import { CustomerSelectorNew, SearchableSelector } from '../../formComponents/Selectors'
import { useGetEnvironmentsQuery } from '../../../queries/GetEnvironmentsQuery'
import { environmentColours } from '../component/NewTeamsList'
import { RegionFlag } from '../../../utils/regionFlags'

export const ownerOptions: DropdownItemProps[] = [
  { key: 'Customer', text: 'Customer', value: 'Customer' },
  { key: 'Partner', text: 'Partner', value: 'Partner' },
  { key: 'Sales', text: 'Sales', value: 'Sales' },
  { key: 'Engineering', text: 'Engineering', value: 'Engineering' },
  { key: 'CX', text: 'CX', value: 'CX' },
  { key: 'Other', text: 'Other', value: 'Other' }
]

/**
 * Returns WebApp Prod URL if the hostname is Admin Console Prod URL.
 * Or returns WebApp Staging URL if the hostname is Admin Console Staging URL.
 * Otherwise return WebApp Dev URL.
 */
export const getURLBasedOnHostname = (hostname: string, teamName: string) => {
  if (hostname === 'admin-console.skedulo.com') {
    return `https://${teamName}.my.skedulo.com`
  } else if (hostname === 'staging-admin-console.test.skl.io') {
    return `https://${teamName}.my-staging.test.skl.io`
  } else {
    return `https://${teamName}.my-dev.test.skl.io`
  }
}

export const useTeamInformationService = (teamId: string, customerId: string) => {
  const { data: environments } = useGetEnvironmentsQuery()

  const environmentOptions = useMemo(
    () =>
      environments?.map(environment => ({
        key: environment,
        text: startCase(environment),
        value: environment
      })) ?? [],
    [environments]
  )
  const teamInformationFields: EditableInformationFieldConfig<Team>[] = [
    {
      fieldName: 'id',
      headerTitle: 'Team ID',
      isString: true,
      editable: false
    },
    {
      fieldName: 'primaryName',
      headerTitle: 'Name',
      isString: true,
      editable: true
    },
    {
      fieldName: 'authConnections',
      headerTitle: 'URL',
      isString: true,
      editable: false,
      DisplayComponent: () => {
        const url = team?.primaryName ? getURLBasedOnHostname(window.location.hostname, team.primaryName) : null

        return url ? (
          <a href={url} target="_blank" rel="noreferrer">
            {url} &nbsp;
            <Icon name="external alternate" size="small" />
          </a>
        ) : (
          <></>
        )
      }
    },
    {
      fieldName: 'description',
      headerTitle: 'Description',
      isString: true,
      editable: true
    },
    {
      fieldName: 'tenantId',
      headerTitle: 'Tenant ID',
      isString: true,
      editable: false
    },
    {
      fieldName: 'environment',
      headerTitle: 'Environment',
      isString: false,
      editable: true,
      EditComponent: ({ editedValue, editFn }) => (
        <SearchableSelector
          options={environmentOptions}
          value={editedValue as string}
          onChange={(event, { value }) => editFn(value as string)}
          required
        />
      ),
      DisplayComponent: ({ value }) => (
        <Label color={environmentColours[value] ?? 'grey'} basic>
          {value}
        </Label>
      )
    },
    {
      fieldName: 'region',
      headerTitle: 'Region',
      isString: false,
      editable: false,
      DisplayComponent: ({ value }) => (
        <Popup
          content={value}
          position="top left"
          trigger={
            <div>
              <RegionFlag region={value} />
            </div>
          }
          style={{
            opacity: 0.9,
            textAlign: 'center'
          }}
          inverted
        />
      )
    },
    {
      fieldName: 'owner',
      headerTitle: 'Owner',
      isString: false,
      editable: true,
      EditComponent: ({ editedValue, editFn }) => (
        <SearchableSelector
          options={ownerOptions}
          value={editedValue as string}
          onChange={(event, { value }) => editFn(value as string)}
          required
        />
      ),
      DisplayComponent: ({ value }) => <p>{value}</p>
    },
    {
      fieldName: 'customerId',
      headerTitle: 'Customer',
      isString: false,
      editable: true,
      EditComponent: ({ editedValue, editFn }) => <CustomerSelectorNew value={editedValue as string} editFn={editFn} />,
      DisplayComponent: ({ value }) => (
        <Link to={`/customers/${value}`}>
          <p> {customer?.name} </p>
        </Link>
      )
    },
    {
      fieldName: 'createdBy',
      headerTitle: 'Created',
      isString: true,
      editable: false,
      DisplayComponent: ({ value }) => (
        <p>
          {dayjs(team?.createdAt).format('MMMM D, YYYY')} by {team?.createdBy}
        </p>
      )
    },
    {
      fieldName: 'updatedBy',
      headerTitle: 'Updated',
      isString: true,
      editable: false,
      DisplayComponent: ({ value }) => (
        <p>
          {dayjs(team?.updatedAt).format('MMMM D, YYYY')} by {team?.updatedBy}
        </p>
      )
    }
  ]

  const getTeamQuery = useGetTeamQuery(teamId)
  const { data: team } = getTeamQuery
  const { data: customer } = useGetCustomerQuery(customerId)
  const updateTeamMutation = useUpdateTeamMutation(teamId)
  const editableRecordService = useEditableRecordInformationService<Team>(
    'team',
    getTeamQuery,
    updateTeamMutation,
    teamInformationFields
  )
  const updateTeamNameMutation = useUpdateTeamNameMutation(() => editableRecordService.disableEditing())

  /**
   * Update team data and update team name if applicable.
   * Updating a team name requires a different operation to patching team data.
   *
   * @param editedData - edited team data for patch.
   */
  const updateTeam = (editedData: Partial<Team>) => {
    // Only updating name
    if (editedData.primaryName && Object.keys(editedData).length === 1) {
      updateTeamNameMutation.mutate({ teamId, name: editedData.primaryName })
      return
    }
    // Patching team information, maybe updating name
    if (editedData) {
      editableRecordService.saveEdits(editedData)
      if (editedData.primaryName) {
        updateTeamNameMutation.mutate({ teamId, name: editedData.primaryName })
      }
    }
  }

  return {
    ...editableRecordService,
    title: `${startCase(editableRecordService.recordName)} Details`,
    saveEdits: updateTeam,
    submitting: editableRecordService.submitting || updateTeamNameMutation.isLoading
  }
}
