import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createTeam, CreateTeamConfig, Team } from '../actions/NewTeams'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export const useCreateTeamMutation = (successFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Team, AxiosError, CreateTeamConfig>(
    (createTeamConfig: CreateTeamConfig) => createTeam(createTeamConfig),
    {
      onSuccess: (teamResult: Team) => {
        queryClient.invalidateQueries(['teams'])
        createSuccessToast(`Created ${teamResult.environment} environment team successfully: ${teamResult.primaryName}`)
        successFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
