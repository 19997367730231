import { kebabCase, lowerCase, upperFirst } from 'lodash'
import { useMemo } from 'react'
import Breadcrumbs from './Breadcrumbs'
import { BreadcrumbItem } from './DynamicBreadcrumbs'

interface FeatureFlagBreadcrumbWrapperProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  initialBreadcrumbs: BreadcrumbItem[]
  featureFlag: string
}

/**
 * FeatureFlag Details breadcrumb for new FeatureFlag model.
 *
 * Retrieves FeatureFlag information and builds final step of breadcrumb.
 */
const FeatureFlagBreadcrumbWrapper = ({ initialBreadcrumbs, featureFlag }: FeatureFlagBreadcrumbWrapperProps) => {
  const breadcrumbItems = useMemo(
    () => [
      ...initialBreadcrumbs,
      {
        path: `/feature-flag-report/${kebabCase(featureFlag)}`,
        name: upperFirst(lowerCase(featureFlag))
      } as BreadcrumbItem
    ],
    [featureFlag, initialBreadcrumbs]
  )

  return <Breadcrumbs items={breadcrumbItems} />
}

export default FeatureFlagBreadcrumbWrapper
