import { Button, Checkbox, Table } from 'semantic-ui-react'
import { useState } from 'react'
import _ from 'lodash'
import { IReleaseApplication } from '../../../actions/Releases'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { DeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { ISimpleSearchValue } from '../../searchComponents/SimpleSearch'
import { ReleaseApplicationsDetailsModal } from './ReleaseApplicationsDetailsModal'

interface IProps {
  applications: IReleaseApplication[]
  searchValue: ISimpleSearchValue
  loading: boolean
  deliveryCheckTemplates: DeliveryCheckTemplate[]
}

export const ReleaseApplicationsList = (props: IProps) => {
  const [sortColumn, setSortColumn] = useState('applicationName')
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editModalApplication, setEditModalApplication] = useState<IReleaseApplication | undefined>(undefined)

  const openApplicationDetails = (application: IReleaseApplication) => () => {
    setEditModalOpen(true)
    setEditModalApplication(application)
  }

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('ascending')
    } else {
      setSortDirection(prevState => (prevState === 'ascending' ? 'descending' : 'ascending'))
    }
  }
  const SortedTemplateHeaderCell = SortedHeaderCell(sortColumn, sortDirection, handleSort)

  const filteredReleaseApplications: IReleaseApplication[] = filterData(
    { searchValue: props.searchValue.searchText, searchCategory: props.searchValue.searchCategory },
    props.applications
  )
  const sortedReleaseApplications = sortData(filteredReleaseApplications, sortColumn, sortDirection)

  return (
    <>
      {editModalApplication && (
        <ReleaseApplicationsDetailsModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          application={editModalApplication}
          deliveryCheckTemplates={props.deliveryCheckTemplates}
        />
      )}
      <Table striped color="blue" sortable selectable stackable size="small" celled>
        <Table.Header>
          <Table.Row>
            <SortedTemplateHeaderCell title="Name" />
            <SortedTemplateHeaderCell title="Component" />
            <SortedTemplateHeaderCell title="Repository Name" />
            <SortedTemplateHeaderCell title="Repository Owner" />
            <Table.HeaderCell textAlign="center" className="table-cell-overflow">
              Release Method
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" className="table-cell-overflow">
              Hidden
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <TableLoaderWrap
          loading={props.loading}
          array={sortedReleaseApplications}
          emptyMessage="No Deployment Templates"
        >
          <Table.Body>
            {sortedReleaseApplications.map(releaseApplication => (
              <Table.Row key={releaseApplication.applicationName}>
                <Table.Cell className="table-cell-overflow">{releaseApplication.applicationName}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.component}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.repositoryName}</Table.Cell>
                <Table.Cell className="table-cell-overflow">{releaseApplication.repositoryOwner}</Table.Cell>
                <Table.Cell className="table-cell-overflow">
                  {_.startCase(releaseApplication.releaseMethod.replace('-', ' '))}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Checkbox checked={releaseApplication.isHidden} disabled />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    icon="file alternate outline"
                    className="square-icon-button"
                    onClick={openApplicationDetails(releaseApplication)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </TableLoaderWrap>
      </Table>
    </>
  )
}
