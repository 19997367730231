import { RouterProps } from '../router/RouterProps'

export interface ISearch {
  searchValue: string
  searchCategory: string
}

export interface IViewSearchProps extends RouterProps {
  searchValue: ISearch
  handleSearchChange: (data?: ISearch) => void
}

export const mapStringsToOptions = (options: string[]) => options.map(option => ({ text: option, value: option }))

export const expensiveQueryConfig = {
  cacheTime: 10 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false
}
