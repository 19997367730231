import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getTeamsByConnectionIds, TeamsWithConnId } from '../actions/NewTeams'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useGetTeamsByConnectionIdsQuery = (
  customerId: string,
  connectionIds: string[]
): UseQueryResult<TeamsWithConnId[]> =>
  useQuery(
    ['teams', 'customerConnection', connectionIds],
    () => getTeamsByConnectionIds(customerId, connectionIds.join(',')),
    {
      onError: error => createErrorToast(error)
    }
  )
