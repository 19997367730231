import { Breadcrumb, Divider, Grid, Header, Icon, Menu } from 'semantic-ui-react'
import { Navigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useRouterProps } from '../../router/RouterProps'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useDeploymentsQueryByEnvironmentAndInstance } from '../../queries/deployments/GetDeploymentsQuery'
import { useApplicationPodsQuery, useApplicationQuery } from '../../queries/applications/GetApplicationsQuery'
import { useReleaseApplicationQuery } from '../../queries/releases/GetReleaseApplicationsQuery'
import { PodsTable } from '../podComponents/PodsTable'
import { ApplicationDeployments } from './components/ApplicationDeployments'
import { ApplicationInformation } from './components/ApplicationInformation'
import { ApplicationRevisionsList } from './components/ApplicationRevisionsList'

export const ApplicationDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('')

  const appName = routerProps.params.name!
  const environment = routerProps.params.environment!
  const instance = routerProps.params.instance!

  const { data: application, error } = useApplicationQuery(appName, instance, environment)
  const { data: pods, status: podsStatus } = useApplicationPodsQuery(application)
  const { data: releaseApplication } = useReleaseApplicationQuery(application?.name)
  const queryClient = useQueryClient()

  const invalidateApplication = useCallback(
    () => async () => {
      await queryClient.invalidateQueries(['application', appName, instance, environment])
    },
    [appName, instance, environment, queryClient]
  )

  if (application !== undefined && documentTitle !== application.name) {
    setDocumentTitle(application.name)
  }

  const { data: deployments, status: deploymentsStatus } = useDeploymentsQueryByEnvironmentAndInstance(
    environment,
    instance
  )

  const filteredDeployments = deployments
    ? deployments.filter(deployment => application?.name && deployment.application?.includes(application.name))
    : []

  return (
    <div className="route-component">
      {error && <Navigate to="/applications/applications" />}
      <span>
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={3} style={{ height: '100%' }}>
            <ApplicationInformation
              application={application}
              releaseApplication={releaseApplication}
              name={appName}
              instance={instance}
              environment={environment}
            />
          </Grid.Column>

          <Grid.Column width={11} stretched>
            <Header as="h3">Pods</Header>
            <PodsTable pods={pods || []} loading={podsStatus === 'loading'} />
            <Divider />
            <ApplicationRevisionsList application={application} reloadApplication={invalidateApplication} />
            <Divider />
            <ApplicationDeployments deployments={filteredDeployments} loading={deploymentsStatus === 'loading'} />
          </Grid.Column>
        </Grid>
      </span>
    </div>
  )
}
