import { WrappedCacheEntry } from '@auth0/auth0-spa-js'
import { cacheKey } from './AuthCacheKey'

// Check if there is a valid entry in localstorage
// Access tokens can be expired but still refreshed for a longer period using refresh tokens
export const hasAuthTokenInLocalStorage = (): boolean => {
  try {
    const tokenString = localStorage.getItem(cacheKey())
    if (!tokenString) {
      return false
    }

    const entry: WrappedCacheEntry = JSON.parse(tokenString)

    if (!entry) {
      return false
    }

    return true
  } catch {
    return false
  }
}

export default hasAuthTokenInLocalStorage
