import { useDeleteCustomerLoginOptionMutation } from '../../../mutations/DeleteCustomerLoginOptionMutation'
import { CustomerConnection } from '../../../actions/CustomerConnections'

export interface DeleteCustomerConnectionService {
  runDeleteCustomerLoginOption: () => void
  isDeleting: boolean
}

export const useDeleteCustomerConnectionService = (
  loginOption: CustomerConnection,
  onSuccessDelete: () => void
): DeleteCustomerConnectionService => {
  const { mutate: deleteCustomerLoginOptionMutation, isLoading: deleteCustomerLoginOptionLoading } =
    useDeleteCustomerLoginOptionMutation(loginOption)(() => {
      onSuccessDelete()
    })

  const runDeleteCustomerLoginOption = () => {
    deleteCustomerLoginOptionMutation()
  }

  return {
    runDeleteCustomerLoginOption,
    isDeleting: deleteCustomerLoginOptionLoading
  }
}
