import * as React from 'react'

import { DropdownProps, Grid, Icon, InputOnChangeData, SearchProps } from 'semantic-ui-react'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getMobileReleases } from '../../actions/Mobile'
import { IRelease } from '../../actions/Releases'
import { createErrorToast } from '../alertComponents/Alert'
import { ReleasesList } from '../releases/Components/ReleasesList'
import { SearchInput } from '../searchComponents/SearchInput'
import { IViewSearchProps } from '../Utils'
import { logError } from '../../analytics/error-log'
import { CutMobileReleaseModal } from './components/CutMobileReleaseModal'
import { StartBuildModal } from './components/StartBuildModal'

interface IState {
  releases: IRelease[]
  loading: boolean
}

const title = 'Mobile Releases'

export class MobileReleases extends React.PureComponent<IViewSearchProps, IState> {
  constructor(props: IViewSearchProps) {
    super(props)
    this.state = {
      releases: [],
      loading: true
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.fetchReleases()
    document.title = formatDocumentTitle(title)
    this.setState({ loading: false })
  }

  fetchReleases = async () => {
    try {
      const releases = await getMobileReleases()
      this.setState({ releases })
    } catch (error) {
      createErrorToast(error)
      logError(error)
    }
  }

  handleSearchChange: SearchProps['onChange'] = (event: React.ChangeEvent, data: InputOnChangeData) => {
    const newSearch = { searchValue: data.value, searchCategory: this.props.searchValue.searchCategory }
    this.props.handleSearchChange(newSearch)
  }

  handleSearchCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    const newSearch = { searchValue: this.props.searchValue.searchValue, searchCategory: data.value as string }
    this.props.handleSearchChange(newSearch)
  }

  createDetailsLink = (applicationName: string, version: string) => () => {
    this.props.navigate(`/applications/mobile-releases/${applicationName}/${version}`)
  }

  render() {
    const { loading, releases } = this.state
    const { searchValue } = this.props
    const searchOptions = [
      { text: 'All', value: 'all' },
      { text: 'Version', value: 'version' },
      { text: 'Application', value: 'application' }
    ]
    return (
      <div className="route-component">
        <Grid columns={2} verticalAlign="middle" stackable>
          <Grid.Column>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Icon
                  size="large"
                  name="refresh"
                  onClick={this.fetchReleases}
                  loading={loading}
                  className="clickable"
                />
              </Grid.Column>
              <Grid.Column>
                <SearchInput
                  searchValue={searchValue}
                  searchOptions={searchOptions}
                  handleSearchChange={this.handleSearchChange}
                  handleSearchCategoryChange={this.handleSearchCategoryChange}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <StartBuildModal />
            <CutMobileReleaseModal />
          </Grid.Column>
        </Grid>
        <ReleasesList
          releases={releases}
          loading={loading}
          searchValue={{ searchText: searchValue.searchValue, searchCategory: searchValue.searchCategory }}
          createDetailsLink={this.createDetailsLink}
        />
      </div>
    )
  }
}
