import { adminApi } from './Endpoints'

export interface IJiraTicketDetails {
  expand: string
  id: string
  self: string
  key: string
  fields: ITicketFields
}

export interface IReleaseNotesRequiredField {
  self: string
  value: string
  id: string
}

export interface ITicketFields {
  summary: string
  description: IDescriptionField
  status: IStatusField
  issuetype: IIssueTypeField
  customfield_12712: IReleaseNotesRequiredField // release notes required?
  customfield_12713: string // release notes
}

export interface IStatusField {
  description: string
  iconUrl: string
  id: string
  name: string
  self: string
  statusCategory: {
    colorName: string
    id: number
    key: string
    name: string
  }
}

export interface IDescriptionField {
  content: IDescriptionContent[]
  type: string
  version: number
}

export interface IDescriptionContent {
  type: string
  content?: IDescriptionContent[]
  text?: string
}

export interface IIssueTypeField {
  avatarId: number
  description: string
  iconUrl: string
  id: string
  name: string
  self: string
  subtask: boolean
}

export const getReleaseTickets = (
  version: string,
  component: string,
  service?: string
): Promise<IJiraTicketDetails[]> =>
  adminApi
    .get<IJiraTicketDetails[]>(`/jira/release/${component}/${version}/tickets`, {
      params: {
        service
      }
    })
    .then(result => result.data)
