import { useParams } from 'react-router'
import { Grid, Header, Icon } from 'semantic-ui-react'
import { useGetPackageQuery } from '../../queries/packages/GetPackageQuery'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetPackageTenantCountQuery } from '../../queries/packages/GetPackageTenantCountQuery'
import { PackageInformationPanel } from './component/PackageInformationPanel'
import { PackageVersionsList } from './component/PackageVersionsList'
import { getPackageDetailsFields } from './services/FieldUtils'
import { combinePackageWithTenantCount, generatePackageTenantQuery } from './services/PackageTenantCountUtils'

export const PackageDetails = () => {
  const params = useParams()
  const packageName = params.id!

  const { data: regionsInfo } = useGetRegionQuery()
  const { data: packageData, isLoading: isLoadingPackageQuery } = useGetPackageQuery(packageName)
  const { data: packageTenantCount, isLoading: isLoadingPackageTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo || [],
    generatePackageTenantQuery(packageData)
  )

  const packageWithTenantCount = combinePackageWithTenantCount(packageData, packageTenantCount || [])

  return (
    <div className="package-component">
      <Grid stackable>
        <Grid.Column floated="left" width={5} textAlign="left">
          <a href="/packages">
            <Icon name="chevron left" size="small" /> Packages
          </a>
          <Header as="h2">{packageName}</Header>
        </Grid.Column>
      </Grid>
      <Grid stackable style={{ height: 'calc(100% - 50px - 3rem)' }}>
        <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
          <PackageInformationPanel
            title="Package details"
            fields={getPackageDetailsFields(packageWithTenantCount)}
            showEditButton={false}
            isLoading={isLoadingPackageTenantCount || isLoadingPackageQuery}
          />
        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }} className="scrollable">
          <PackageVersionsList
            title="Package versions"
            packageName={packageName}
            regionsInfo={regionsInfo || []}
            latestVersion={packageWithTenantCount?.latestVersion}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}
