import { Grid, Menu } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { Components } from './Components'
import { DeliveryCheckTemplates } from './DeliveryCheckTemplates'
import { DeploymentTemplates } from './DeploymentTemplates'
import { Environments } from './Environments'
import { ReleaseApplications } from './ReleaseApplications'

const title = 'Settings'

const SettingsContent = (props: { settingsPage: string }) => {
  switch (props.settingsPage) {
    case 'environments':
      return <Environments />
    case 'deploymentTemplates':
      return <DeploymentTemplates />
    case 'releaseApplications':
      return <ReleaseApplications />
    case 'components':
      return <Components />
    case 'deliveryCheckTemplates':
      return <DeliveryCheckTemplates />
    default:
      return <div />
  }
}

export const Settings = () => {
  const params = useParams()
  const activeItem = params.setting ?? 'environments'
  useDocumentTitle(title)

  return (
    <div className="route-component">
      <Menu fluid pointing secondary>
        <Menu.Item active={activeItem === 'environments'} as={Link} to="/applications/settings/environments">
          Environments
        </Menu.Item>
        <Menu.Item
          active={activeItem === 'deploymentTemplates'}
          as={Link}
          to="/applications/settings/deploymentTemplates"
        >
          Deployment Templates
        </Menu.Item>
        <Menu.Item
          active={activeItem === 'releaseApplications'}
          as={Link}
          to="/applications/settings/releaseApplications"
        >
          Release Applications
        </Menu.Item>

        <Menu.Item active={activeItem === 'components'} as={Link} to="/applications/settings/components">
          Components
        </Menu.Item>

        <Menu.Item
          active={activeItem === 'deliveryCheckTemplates'}
          as={Link}
          className="item"
          to="/applications/settings/deliveryCheckTemplates"
        >
          Delivery Check Templates
        </Menu.Item>
      </Menu>
      <Grid columns={1} stackable style={{ height: 'calc(100% - 54px)' }}>
        <Grid.Column style={{ height: '100%' }}>
          <SettingsContent settingsPage={activeItem} />
        </Grid.Column>
      </Grid>
    </div>
  )
}
