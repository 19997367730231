import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { IDeliveryCheckDefinition, INewDeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { EditDeliveryCheckTemplateForm } from './EditDeliveryCheckTemplateForm'

interface IProps {
  deliveryCheckDefinitions: IDeliveryCheckDefinition[]
  createDeliveryCheckTemplateHook: (template: INewDeliveryCheckTemplate) => Promise<boolean>
}

export const CreateDeliveryCheckTemplateModal = (props: IProps) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmitHook = async (template: INewDeliveryCheckTemplate) => {
    setLoading(true)
    const res = await props.createDeliveryCheckTemplateHook(template)
    if (res) {
      setOpen(false)
    }
    setLoading(false)
    return res
  }

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      size="large"
      aria-label="Create Delivery Check"
      trigger={
        <Button className="form-button-sked-blue" primary content="Create Template" onClick={() => setOpen(true)} />
      }
    >
      <Modal.Header>Create Delivery Check Template</Modal.Header>
      <Modal.Content>
        <EditDeliveryCheckTemplateForm
          loading={loading}
          deliveryCheckDefinitions={props.deliveryCheckDefinitions}
          enableEdit={true}
          onSubmitHook={onSubmitHook}
        />
      </Modal.Content>
    </Modal>
  )
}
