import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { deleteCustomer } from '../../../actions/Customers'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

interface IProps {
  customerName?: string
  customerId?: string
  setRedirect(): void
}

interface IState {
  open: boolean
  loading: boolean
}

export class DeleteCustomerModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  handleConfirmDeleteCustomer = async () => {
    this.setState({ loading: true })
    if (this.props.customerId) {
      try {
        await deleteCustomer(this.props.customerId)
        createSuccessToast(`Customer ${this.props.customerName} successfully deleted.`)
        this.toggleOpen()
        this.props.setRedirect()
      } catch (error) {
        createErrorToast(error)
      } finally {
        this.setState({ loading: false })
      }
    }
  }

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  render() {
    const { open } = this.state
    const { customerName } = this.props

    return (
      <Modal
        trigger={
          <Button color="red" id={`delete-${this.props.customerId}`} onClick={this.toggleOpen}>
            Delete
          </Button>
        }
        size="mini"
        open={open}
        onClose={this.toggleOpen}
      >
        <Modal.Header>Delete Customer</Modal.Header>
        <Modal.Content>
          <p>{`Are you sure you want to delete: ${customerName}`}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggleOpen} name="cancel">
            Cancel
          </Button>
          <Button
            negative
            id="confirm-modal-delete"
            content="Delete"
            loading={this.state.loading}
            onClick={this.handleConfirmDeleteCustomer}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
