import { Team } from '../../../actions/NewTeams'
import { useTeamInformationService } from '../services/TeamInformationService'
import { EditableTable } from './EditableTable'

const TeamDetailsTable = ({ teamId, customerId }: { teamId: string; customerId: string }) => {
  const teamInformationService = useTeamInformationService(teamId, customerId)
  return <EditableTable<Team> {...teamInformationService} />
}

export default TeamDetailsTable
