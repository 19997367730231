import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { IApplication, IApplicationRevision, rollbackApplication } from '../../actions/Applications'

export const useApplicationRollbackMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: { application: IApplication; revision: IApplicationRevision }) =>
      rollbackApplication(
        props.application.meta.name,
        props.application.environment,
        props.application.instance,
        props.revision.revisionId
      ),
    onSuccess: async (data, props) => {
      await queryClient.invalidateQueries([
        'applicationRevisions',
        props.application.meta.name,
        props.application?.environment,
        props.application?.instance
      ])
    },
    onError: (error: Error) => {
      createErrorToast(`Rollback application failed. ${error}`)
    }
  })
}
