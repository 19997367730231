import { Row } from 'react-table'
import { Button } from 'semantic-ui-react'
import { DeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'

interface IProps {
  templates: DeliveryCheckTemplate[]
  isLoading: boolean
  openDeliveryCheckTemplateModalHook: (template: DeliveryCheckTemplate) => () => void
}

const renderModalButton = (
  deliveryCheckTemplate: DeliveryCheckTemplate,
  openModalHook: (template: DeliveryCheckTemplate) => () => void
) => (
  <Button onClick={openModalHook(deliveryCheckTemplate)} icon="file alternate outline" className="square-icon-button" />
)

export const DeliveryCheckTemplatesList = (props: IProps) => {
  const columns = createMemoizedColumns<DeliveryCheckTemplate>([
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Details',
      Cell: ({ row }: { row: Row<DeliveryCheckTemplate> }) =>
        renderModalButton(row.original, props.openDeliveryCheckTemplateModalHook)
    }
  ])

  return (
    <Table<DeliveryCheckTemplate>
      columns={columns}
      data={props.templates}
      loading={props.isLoading}
      emptyMessage={'No check templates found.'}
      color="blue"
    />
  )
}
