import _ from 'lodash'
import * as React from 'react'
import { Button, Form, Header, Modal } from 'semantic-ui-react'
import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { DeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { SearchableSelector } from '../../formComponents/Selectors'
import { IReleaseApplication, ReleaseMethod } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'
import { DetailsCheckbox, DetailsInput } from '../../formComponents/Inputs'
import { useDeleteReleaseApplicationMutation } from '../../../mutations/settings/DeleteReleaseApplication'
import { NewDeleteModal } from '../../modalComponents/NewDeleteModal'
import { usePatchReleaseApplicationMutation } from '../../../mutations/settings/PatchReleaseApplication'

export interface IProps {
  application: IReleaseApplication
  deliveryCheckTemplates: DeliveryCheckTemplate[]
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReleaseApplicationsDetailsModal = (props: IProps) => {
  const application = props.application

  const { mutateAsync: deleteReleaseApplication, isLoading: isDeleting } = useDeleteReleaseApplicationMutation()
  const { mutate: patchReleaseApplication } = usePatchReleaseApplicationMutation()
  const [component, setComponent] = useState(application.component)
  const [repositoryName, setRepositoryName] = useState(application.repositoryName)
  const [repositoryOwner, setRepositoryOwner] = useState(application.repositoryOwner)
  const [releaseMethod, setReleaseMethod] = useState<ReleaseMethod>(application.releaseMethod)
  const [isHidden, setIsHidden] = useState(application.isHidden)
  const [editing, setEditing] = useState(false)
  const [deliveryCheckTemplateId, setDeliveryCheckTemplateId] = useState(application.deliveryCheckTemplateId)

  const applicationFromState: IReleaseApplication = {
    applicationName: application.applicationName,
    component,
    repositoryOwner,
    repositoryName,
    releaseMethod,
    isHidden,
    deliveryCheckTemplateId
  }

  const resetState = useCallback(() => {
    setComponent(application.component)
    setRepositoryName(application.repositoryName)
    setRepositoryOwner(application.repositoryOwner)
    setReleaseMethod(application.releaseMethod)
    setIsHidden(application.isHidden)
    setDeliveryCheckTemplateId(application.deliveryCheckTemplateId)
    setEditing(false)
  }, [
    setComponent,
    setRepositoryOwner,
    setRepositoryName,
    setReleaseMethod,
    setIsHidden,
    setDeliveryCheckTemplateId,
    setEditing,
    application
  ])

  useEffect(() => {
    resetState()
  }, [props.application, resetState])

  const patchApplication = {} as Partial<IReleaseApplication>
  const editedApplication = applicationFromState
  for (const [key, value] of Object.entries(editedApplication)) {
    const releaseApplicationKey = key as keyof IReleaseApplication
    if (editedApplication[releaseApplicationKey] !== application[releaseApplicationKey]) {
      patchApplication[releaseApplicationKey] = value
    }
  }

  const isDifferent = !_.isEmpty(patchApplication)

  const submitEdit = () => {
    patchReleaseApplication({ applicationName: application.applicationName, patchApplication })
    createSuccessToast(`Application ${application?.applicationName} updated successfully.`)
    setEditing(false)
    // props.setOpen(false)
  }

  const deleteAndCloseModal = async (applicationName: string) => {
    try {
      await deleteReleaseApplication(applicationName)
      props.setOpen(false)
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        createErrorToast(`Deletion failed: ${error.response.data} Editing it and hiding it might work instead`)
      } else {
        createErrorToast(error)
      }
    }
  }

  const deliveryCheckTemplateOptions = [
    { text: 'None', value: '' },
    ...props.deliveryCheckTemplates.map(template => ({
      text: template.name,
      value: template.id
    }))
  ]

  return (
    <Modal
      open={props.open}
      closeIcon
      onClose={() => {
        resetState()
        props.setOpen(false)
      }}
      size="tiny"
      aria-label="Release Application Details"
    >
      <Modal.Header>Release Application</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <Header size="tiny">Name</Header>
          <p>{application.applicationName}</p>

          <DetailsInput
            editable={editing}
            value={application.component}
            name="component"
            onChange={(_ev, data) => setComponent(data.value)}
            editedValue={component}
          />
          <DetailsInput
            editable={editing}
            value={application.repositoryName}
            name="repositoryName"
            onChange={(_ev, data) => setRepositoryName(data.value)}
            editedValue={repositoryName}
          />
          <SearchableSelector
            label="Release Method"
            value={editing ? releaseMethod : application.releaseMethod}
            name="releaseMethod"
            id="releaseMethod"
            options={[
              { text: 'Normal', value: 'normal' },
              { text: 'Mobile', value: 'mobile' },
              { text: 'Fast Track', value: 'fast-track' }
            ]}
            onChange={(_ev, data) => setReleaseMethod(data.value as ReleaseMethod)}
          />
          <DetailsCheckbox
            editable={editing}
            checked={editing ? isHidden : application.isHidden}
            title="Hidden"
            name="isHidden"
            onClick={(_ev, data) => setIsHidden(data.checked ?? false)}
          />
          <DetailsInput
            editable={editing}
            value={application?.repositoryOwner}
            name="repositoryOwner"
            onChange={(_ev, data) => setRepositoryOwner(data.value)}
            editedValue={repositoryOwner}
          />
          <SearchableSelector
            name="deliveryCheckTemplateId"
            options={deliveryCheckTemplateOptions}
            value={deliveryCheckTemplateId ?? ''}
            onChange={(_ev, data) => setDeliveryCheckTemplateId(data.value as string)}
            label="Delivery Check Template"
            disabled={!editing}
          />
          {editing ? (
            <>
              <Button color="grey" content="Cancel" onClick={() => setEditing(false)} />
              <Button color="blue" content="Save" disabled={!isDifferent} onClick={submitEdit} />
            </>
          ) : (
            <>
              <NewDeleteModal
                deleteMethod={() => deleteAndCloseModal(application.applicationName)}
                content={`Are you sure you want to delete release application ${application?.applicationName}`}
                type="Release Application"
                buttonText="Delete"
                disabled={editing}
                isDeleting={isDeleting}
              />
              <Button primary content="Edit" onClick={() => setEditing(true)} />
            </>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  )
}
