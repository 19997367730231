import { Row } from 'react-table'
import { Button } from 'semantic-ui-react'
import { IComponent } from '../../../actions/Components'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'

interface IProps {
  components: IComponent[]
  isLoading: boolean
  openDeleteModalHook: (component: IComponent) => () => void
}

export const ComponentsList = (props: IProps) => {
  const columns = createMemoizedColumns<IComponent>([
    {
      Header: 'Component Name',
      accessor: 'componentName'
    },
    {
      Header: 'Delete Component',
      Cell: ({ row }: { row: Row<IComponent> }) => (
        <Button onClick={props.openDeleteModalHook(row.original)} color={'red'} icon={'x'} />
      )
    }
  ])

  return (
    <Table<IComponent>
      columns={columns}
      data={props.components}
      loading={props.isLoading}
      emptyMessage={'No components found.'}
      color="blue"
    />
  )
}
