import { AxiosResponse } from 'axios'
import { elasticserver } from './Endpoints'
import { IMetadata } from './Tenants'
import { ICreated, IResult } from './Utils'

export interface ICustomer {
  id: string
  name: string
  metadata: IMetadata
  salesforceId?: string
  region?: string
  createdBy?: string
  updatedBy?: string
  createdDate: string
  updatedDate: string
}

export interface IPatchCustomer {
  name?: string
  salesforceId?: string | null
  metadata?: IMetadata
}

export interface IReturnedCreatedCustomer {
  created: ICustomer
}

export const getCustomers = async (): Promise<ICustomer[]> =>
  elasticserver.get<IResult<ICustomer[]>>('/admin/provision/customer').then(customers => customers.data.result)

export const createCustomer = async (name: string, salesforceId?: string, metadata?: IMetadata): Promise<ICustomer> =>
  elasticserver
    .post<ICreated<ICustomer>>('/admin/provision/customer', { name, salesforceId, metadata })
    .then(response => response.data.created)

export const updateCustomer = async (customerId: string, patchCustomer: IPatchCustomer) =>
  elasticserver.put(`/admin/provision/customer/${customerId}`, patchCustomer).then(response => response)

export const deleteCustomer = async (customerId: string): Promise<AxiosResponse> =>
  elasticserver
    .delete(`/admin/provision/customer/${customerId}`, { params: { customerId, delete: true } })
    .then(response => response)
