import { Message } from 'semantic-ui-react'

const MigrationWarning = ({ isCustomer }: { isCustomer?: boolean }) => (
  <Message
    header="Migration Required"
    content={
      <div>
        <p>
          This Customer/Team/Tenant is currently on the old version of the Skedulo environment data model. You need to
          migrate this Customer and all of its Teams/Tenants to the new version as the old model will soon be
          deprecated.{' '}
          {isCustomer ?? 'To migrate, navigate to the Customer details screen and then click "Migrate Customer".'}{' '}
          <a
            href="https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2395799569/New+Team+Model+-+Migrations+overview"
            target="_blank"
            rel="noreferrer"
          >
            <b style={{ fontWeight: 800 }}>Learn more</b>
          </a>
        </p>
      </div>
    }
    icon="warning"
    error
  />
)

export default MigrationWarning
