import * as React from 'react'
import { Link } from 'react-router-dom'
import { Pagination, Table } from 'semantic-ui-react'
import { DEFAULT_REGION } from '../../../actions/Regions'
import { ITeam } from '../../../actions/Teams'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, getDisplayedData, handlePaginationChange, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISearch } from '../../Utils'

interface IProps {
  teams: ITeam[]
  regions: { [key: string]: string }
  handleRowClick?: (id: string) => () => void
  searchValue: ISearch
  loading: boolean
  simple?: boolean
}

interface IState {
  sortColumn: string
  sortDirection: 'ascending' | 'descending'
  activePage: number
}

export class TeamsList extends React.PureComponent<IProps, IState> {
  TEAMS_PER_PAGE = 17

  constructor(props: IProps) {
    super(props)
    this.state = {
      sortColumn: 'name',
      sortDirection: 'ascending',
      activePage: 1
    }
  }

  handleSort = (clickedColumn: string) => () => {
    const { sortColumn, sortDirection } = this.state
    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        sortDirection: 'ascending'
      })
    } else {
      this.setState({
        sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending'
      })
    }
  }

  handlePaginationChangeFn = handlePaginationChange(activePage => this.setState({ activePage }))

  regionInfo = (team: ITeam): { region: string; regionName: string } => {
    const region = team.region || DEFAULT_REGION
    const regionName = this.props.regions[region] || region
    return { region, regionName }
  }

  render() {
    const { handleRowClick, searchValue, teams, simple } = this.props
    const { sortColumn, sortDirection, activePage } = this.state

    const SortedTeamHeaderCell = SortedHeaderCell(sortColumn, sortDirection, this.handleSort)

    const filteredTeams: ITeam[] = filterData(searchValue, teams)
    const sortedTeams = sortData(filteredTeams, sortColumn, sortDirection)
    const displayedTeams: ITeam[] = getDisplayedData(sortedTeams, activePage, this.TEAMS_PER_PAGE)
    const totalPages: number = Math.ceil(filteredTeams.length / this.TEAMS_PER_PAGE)

    return (
      <React.Fragment>
        <div className="scrollable-with-pagination">
          <Table
            striped={!simple}
            color="blue"
            sortable={!simple}
            selectable={!simple}
            stackable
            size="small"
            celled={simple}
            basic={!simple ? false : 'very'}
            className={!simple ? 'sticky-table' : undefined}
          >
            <Table.Header>
              <Table.Row>
                <SortedTeamHeaderCell title="Name" />
                <SortedTeamHeaderCell title="Description" />
                {!simple && <SortedTeamHeaderCell title="Customer Name" name="customerName" />}
                <SortedTeamHeaderCell title="Region" width="1" />
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap loading={this.props.loading} array={displayedTeams} emptyMessage="No Teams">
              <Table.Body>
                {(simple ? filteredTeams : displayedTeams).map(team => (
                  <Table.Row
                    key={team.id}
                    onClick={handleRowClick ? handleRowClick(team.id) : undefined}
                    className={simple ? '' : 'clickable'}
                  >
                    <Table.Cell className="table-cell-overflow">
                      {simple ? <Link to={`/old-model/teams/${team.id}`}>{team.name}</Link> : team.name}
                    </Table.Cell>
                    <Table.Cell className="table-cell-overflow">{team.description}</Table.Cell>
                    {!simple && <Table.Cell className="table-cell-overflow">{team.customerName}</Table.Cell>}
                    <Table.Cell>{this.regionInfo(team).regionName}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </div>
        {!simple && (
          <Pagination
            activePage={activePage}
            boundaryRange={1}
            onPageChange={this.handlePaginationChangeFn}
            totalPages={totalPages}
          />
        )}
      </React.Fragment>
    )
  }
}
