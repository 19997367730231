import * as React from 'react'
import { Icon } from 'semantic-ui-react'

/**
 * Renders icon in color
 *
 * ✅ icon: renders when the base permission is set to true
 *
 * ❌ icon: renders when the base permission is set to false
 *
 * blue color: when the base permission matches
 *
 * red color: when the base permission does not match
 */
export const PermissionIcon: React.FC<{ hasPermission: boolean; matches: boolean }> = ({ hasPermission, matches }) => {
  const color = matches ? 'blue' : 'red'

  if (hasPermission) {
    return <Icon name="checkmark" color={color} />
  }
  return <Icon name="remove" color={color} />
}
