import { Breadcrumb, Grid, Icon, Loader, Menu } from 'semantic-ui-react'
import { TDeploymentDetails } from 'src/actions/Deployments'
import { useRouterProps } from '../../router/RouterProps'
import { useDeploymentQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { DeploymentInformation } from './components/DeploymentInformation'

export const DeploymentDetails = () => {
  const routerProps = useRouterProps()
  const { documentTitle, setDocumentTitle } = useDocumentTitle('Deployment Details')

  const id = routerProps.params.id || ''
  const env = routerProps.params.env || ''
  const instance = routerProps.params.instance || ''
  const { data: deployment, status: deploymentStatus } = useDeploymentQuery(env, instance, id)

  if (deployment?.application && documentTitle !== deployment?.application) {
    setDocumentTitle(deployment?.application)
  }

  return (
    <div className="route-component">
      <Menu secondary fluid>
        <Menu.Menu position="left">
          <Menu.Item>
            <Breadcrumb>
              <Breadcrumb.Section className="back-button" onClick={() => routerProps.navigate(-1)}>
                <Icon name="chevron left" size="big" />
                Back
              </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Grid columns={2} stackable style={{ height: 'calc(100% - 50px)' }}>
        <Grid.Column width={3} style={{ height: '100%' }}>
          <DeploymentInformation deployment={(deployment || {}) as TDeploymentDetails} />
        </Grid.Column>
        <Grid.Column width={13} style={{ height: '100%' }} className="scrollable-no-margin">
          {deployment?.explanation && <div>{deployment.explanation}</div>}
          <div className="console">
            <div dangerouslySetInnerHTML={{ __html: deployment?.output || '' }} />
            {(!deployment || deployment.status === 'running' || deployment.status === 'pending') && (
              <Loader size="tiny" active inline />
            )}
            <Loader active={deploymentStatus === 'loading'} size="large" />
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}
