import { InfiniteData } from '@tanstack/react-query'
import { Pagination } from 'semantic-ui-react'
import { useState } from 'react'
import { handlePaginationChange } from '../../views/tableComponents/TableFunctions'
import { DynamoQueryResponse } from '../../actions/Utils'
import { MemoizedColumns, Table } from './ReactTable'

export interface InfiniteTableProps<T extends object, D extends object> {
  loading: boolean
  columns: MemoizedColumns<D>
  data?: InfiniteData<T>
  emptyMessage: string
  fetchNextPage: () => void
  fetchingNextPage: boolean
  hasNextPage?: boolean
  getValuesFromData: (base?: T) => D[]
  scrollable?: boolean
}

export const getValuesFromDynamoQuery = <T,>(base?: DynamoQueryResponse<T>) => base?.results || []

export const InfinitePaginatedTable = <T extends object, D extends object>({
  loading,
  columns,
  data,
  fetchNextPage,
  fetchingNextPage,
  hasNextPage,
  emptyMessage,
  getValuesFromData,
  scrollable
}: InfiniteTableProps<T, D>) => {
  const [activePage, setActivePage] = useState(1)
  const maxPages = hasNextPage ? (data?.pages.length || 0) + 1 : data?.pages.length || 0
  if (activePage > (data?.pages?.length || 1)) {
    if (hasNextPage) {
      fetchNextPage()
    } else {
      setActivePage(data?.pages.length || 1)
    }
  }
  return (
    <>
      <div className={scrollable ? (maxPages > 1 ? 'scrollable-with-pagination' : 'scrollable') : undefined}>
        <Table<D>
          loading={loading || fetchingNextPage}
          columns={columns}
          data={getValuesFromData(data?.pages[activePage - 1])}
          emptyMessage={emptyMessage}
          striped
          color="blue"
          className={scrollable ? 'sticky-table' : undefined}
          size="small"
          textAlign="left"
        />
      </div>
      {maxPages > 1 && (
        <Pagination
          activePage={activePage}
          boundaryRange={0}
          siblingRange={4}
          onPageChange={handlePaginationChange(setActivePage)}
          totalPages={maxPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      )}
    </>
  )
}
