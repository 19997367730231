import { Row } from 'react-table'
import { Label, Popup, SemanticCOLORS } from 'semantic-ui-react'
import { createMemoizedColumns, Table } from '../../../../components/TableComponents/ReactTable'
import { IUserListFormatted } from './Users'

interface StatusColours {
  [key: string]: SemanticCOLORS
}

export const statusColours: StatusColours = {
  Deactivated: 'red',
  Created: 'grey',
  Active: 'green',
  Invited: 'orange',
  Inactive: 'red'
}

const dateTimePopup = (date: string | null) => (
  <Popup trigger={<span>{date?.split(',')[0]}</span>} content={date} basic />
)

const makeStatusLabel = (value: string) => (
  <Label color={statusColours[value]} basic>
    {value}
  </Label>
)

export const UserList = ({
  data,
  loading,
  isSalesforce
}: {
  data?: IUserListFormatted[]
  loading?: boolean
  isSalesforce?: boolean
}) => {
  const userColumns = createMemoizedColumns<IUserListFormatted>([
    {
      Header: 'User ID',
      accessor: 'UserID'
    },
    {
      Header: 'Email',
      accessor: 'Email'
    },
    {
      Header: 'Roles',
      accessor: 'Roles'
    },
    {
      Header: 'Created date',
      accessor: 'CreatedDate',
      Cell: ({ row }: { row: Row<IUserListFormatted> }) => dateTimePopup(row.original.CreatedDate)
    },
    {
      Header: 'Last login date',
      accessor: 'LastLoginDate',
      Cell: ({ row }: { row: Row<IUserListFormatted> }) => dateTimePopup(row.original.LastLoginDate ?? '')
    },
    {
      Header: 'Licence status',
      accessor: 'UserActive',
      Cell: ({ row }: { row: Row<IUserListFormatted> }) =>
        makeStatusLabel(row.original.UserActive === 'True' ? 'Active' : 'Inactive')
    },
    {
      Header: 'User status',
      accessor: 'Status',
      Cell: ({ row }: { row: Row<IUserListFormatted> }) => makeStatusLabel(row.original.Status ?? 'Created')
    }
  ])

  const excludeColumns = isSalesforce ? ['LastLoginDate', 'Status'] : []
  const filteredColumns = userColumns.filter(column => !excludeColumns.some(accessor => accessor === column.accessor))

  return (
    <div>
      <p style={{ paddingTop: '10px' }}>
        <em>
          <small>Note: Only the first 2000 users are returned below.</small>
        </em>
      </p>
      <Table
        loading={loading}
        columns={filteredColumns}
        data={data || []}
        emptyMessage="No users found"
        celled
        color="blue"
      />
    </div>
  )
}
