import { StatusCount, StatusIcon, StatusIconProps } from '../../../components/StatusCount'

const deliveryChecksStatusIconLookup: { [key: string]: StatusIconProps } = {
  expired: {
    statusName: 'Expired',
    color: 'grey',
    iconName: 'clock'
  },
  failed: {
    statusName: 'Failed',
    color: 'red',
    iconName: 'times circle'
  },
  created: {
    statusName: 'Pending',
    color: 'yellow',
    iconName: 'clock'
  },
  warning: {
    statusName: 'Warning',
    color: 'orange',
    iconName: 'exclamation circle'
  },
  passed: {
    statusName: 'Passed',
    color: 'green',
    iconName: 'check circle'
  }
}

export const DeliveryCheckStatusIcon = (props: { status: string; expired: boolean; id: string }) => (
  <StatusIcon
    statusIconPropsByStatus={deliveryChecksStatusIconLookup}
    status={props.expired ? 'expired' : props.status}
  />
)

export const DeliveryCheckStatusCount = (props: { status: string; count: number }) => (
  <StatusCount status={props.status} count={props.count} statusIconPropsByStatus={deliveryChecksStatusIconLookup} />
)
