import { Table } from 'semantic-ui-react'
import { MetadataTypes, MetadataValue } from '../../../services/DynamicMetadataService'
import { DynamicValueDisplay, DynamicValueDisplayProps } from './DynamicValueDisplay'

export interface DynamicDisplayRowProps {
  metadataKey: string
  type: MetadataTypes
  value: MetadataValue
}

const makeDisplayProps = (type: MetadataTypes, value: MetadataValue): DynamicValueDisplayProps => {
  switch (type) {
    case 'string':
    case 'number':
      return {
        type,
        value: value as number | string
      }
    case 'boolean':
      return {
        type,
        value: value as boolean
      }
    case 'json':
      return {
        type,
        value: value as Record<string, MetadataValue>
      }
    case 'undefined':
      return {
        type,
        value: undefined
      }
  }
}

export const DynamicDisplayRow = ({ type, metadataKey, value }: DynamicDisplayRowProps) => (
  <Table.Row>
    <Table.Cell>{metadataKey}</Table.Cell>
    <Table.Cell width={10} textAlign={type === 'boolean' || type === 'undefined' ? 'center' : undefined}>
      <DynamicValueDisplay {...makeDisplayProps(type, value)} />
    </Table.Cell>
    <Table.Cell>{type}</Table.Cell>
  </Table.Row>
)
