import { Button, Modal } from 'semantic-ui-react'
import { useResetTeamService } from '../services/ResetTeamService'
import SalesforceResetTeamForm from './ResetTeam/SalesforceResetTeamForm'
import StandaloneResetTeamForm from './ResetTeam/StandaloneResetTeamForm'

export interface ResetTeamModalProps {
  teamId: string
  redirectFn: () => void
  isSalesforceForm: boolean
  menuItem?: boolean
}

const ResetTeamModal = ({ teamId, redirectFn, menuItem, isSalesforceForm }: ResetTeamModalProps) => {
  const resetTeamService = useResetTeamService({
    teamId,
    redirectFn
  })
  const { toggleModalIsOpen, modalIsOpen } = resetTeamService

  return (
    <Modal
      trigger={
        <Button
          onClick={toggleModalIsOpen}
          color={menuItem ? undefined : 'red'}
          basic={menuItem}
          className={menuItem ? 'modal-reset-menu' : undefined}
        >
          Reset Team
        </Button>
      }
      size="small"
      open={modalIsOpen}
      closeOnDimmerClick
      onClose={toggleModalIsOpen}
      closeIcon
    >
      <Modal.Header id="modal-header-add-customer">Reset Team</Modal.Header>
      <Modal.Content>
        {isSalesforceForm ? (
          <SalesforceResetTeamForm resetTeamService={resetTeamService} />
        ) : (
          <StandaloneResetTeamForm resetTeamService={resetTeamService} />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default ResetTeamModal
