import { Auth0Client } from '@auth0/auth0-spa-js'
import Env from '../env/Env'

export const AuthClient = new Auth0Client({
  clientId: Env.AUTH0_CLIENT_ID,
  domain: Env.AUTH0_CLIENT_DOMAIN,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  authorizationParams: {
    audience: Env.AUTH0_AUDIENCE,
    scope: 'openid groups permissions roles profile',
    redirect_uri: Env.AUTH0_CALLBACK
  }
})

export default AuthClient
