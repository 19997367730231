import { useQuery } from '@tanstack/react-query'
import { compareTwoDeliveries, getDeliveryCommitDiff } from '../../actions/Deliveries'

export const useDeliveryCommitDiffQuery = (applicationName: string, version: string, enabled: boolean) =>
  useQuery({
    queryKey: ['deliveryCommits', applicationName, version],
    queryFn: () => getDeliveryCommitDiff(applicationName, version),
    enabled
  })

export const useDeliveryCompareQuery = (applicationName: string, baseVersion: string, headVersion?: string) =>
  useQuery({
    queryKey: ['deliveryCommitsCompare', applicationName, baseVersion, headVersion],
    queryFn: () => compareTwoDeliveries(applicationName, baseVersion, headVersion!),
    enabled: !!headVersion
  })
