import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError, isAxiosError } from 'axios'
import { createErrorToast } from '../../views/alertComponents/Alert'
import {
  getApplication,
  getApplications,
  getGlobalPods,
  getPods,
  getRevisions,
  IApplication
} from '../../actions/Applications'
import { decodeErrors } from '../../actions/Utils'

export const useApplicationsQuery = () => {
  const queryClient = useQueryClient()
  const setData = (data: IApplication[]) => queryClient.setQueryData(['applications'], data)

  return useQuery({
    queryKey: ['applications'],
    queryFn: () => getApplications(),
    onError: error => {
      decodeErrors<IApplication[]>(error, setData, createErrorToast)
    }
  })
}

export const useApplicationQuery = (appName: string, instance: string, env: string) =>
  useQuery({
    queryKey: ['application', appName, env, instance],
    queryFn: () => getApplication(appName, env, instance),
    onError: error => {
      if (isAxiosError(error) && error.response && error.response.status === 404) {
        createErrorToast(`No application found with the name: ${appName}. For ${env} ${instance}.`)
      } else {
        createErrorToast(error)
      }
    }
  })

export const useApplicationRevisionsQuery = (application?: IApplication) =>
  useQuery({
    queryKey: ['applicationRevisions', application?.meta.name, application?.environment, application?.instance],
    queryFn: () => getRevisions(application!.meta.name, application!.environment, application!.instance),
    onError: (error: any) => {
      if (!error.isAxiosError || (error as AxiosError).response?.status !== 404) {
        createErrorToast(error)
      }
    },
    enabled: !!application
  })

export const useApplicationPodsQuery = (application?: IApplication) =>
  useQuery({
    queryKey: ['pods', application?.name, application?.environment, application?.instance],
    queryFn: () => getPods(application!.name, application!.environment, application!.instance),
    refetchInterval: 15000,
    onError: (error: any) => {
      if (!error.isAxiosError || (error as AxiosError).response?.status !== 404) {
        createErrorToast(error)
      }
    },
    enabled: !!application
  })

export const useGlobalApplicationPodsQuery = (applicationName?: string) => {
  const queryClient = useQueryClient()
  return useQuery({
    queryKey: ['pods', applicationName],
    queryFn: () => getGlobalPods(applicationName!),
    refetchInterval: 15000,
    onError: error => {
      const setData = (data: IApplication[]) => queryClient.setQueryData(['pods', applicationName], data)
      decodeErrors<IApplication[]>(error, setData, createErrorToast)
    },
    enabled: !!applicationName
  })
}
