import { Icon, Popup } from 'semantic-ui-react'

interface IProps {
  status: string
}

export const PackageInstallationStatus = (props: IProps) => {
  const { status } = props

  switch (status) {
    case 'Completed':
      return (
        <Popup
          content={status}
          position="right center"
          trigger={<Icon name="check circle" color="green" size="large" aria-label={status} />}
        />
      )
    case 'Failed':
      return (
        <Popup
          content={status}
          position="right center"
          trigger={<Icon name="times circle" color="red" size="large" aria-label={status} />}
        />
      )
    case 'In Progress':
      return (
        <Popup
          content={<div>Package is currently installing, this can take up to 5 minutes.</div>}
          position="right center"
          trigger={<Icon name="refresh" loading size="large" aria-label={status} />}
        />
      )
    default:
      return (
        <Popup
          content={status}
          trigger={<Icon name="question circle" size="large" color="orange" aria-label={status} />}
        />
      )
  }
}
