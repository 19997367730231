import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { UseQueryResult } from '@tanstack/react-query'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { IAuthConnectionDetails } from '../../../actions/Teams'
import { useDeleteTeamLoginMutation } from '../../../mutations/DeleteTeamLoginMutation'
import { useCreateTeamLoginMutation } from '../../../mutations/CreateTeamLoginMutation'
import {
  AuthConnections,
  DetailedAuthConnections,
  LoginConnectionsConfig,
  Vendor,
  VendorPrefix
} from '../../../actions/NewTeams'
import { createErrorToast, createInfoToast, createSuccessToast } from '../../../views/alertComponents/Alert'
import { useUpdateTeamMutation } from '../../../mutations/UpdateTeamMutation'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useGetConnectionsDetailsQuery } from '../../../queries/GetConnectionsDetailsQuery'
import Env from '../../../env/Env'

export interface LoginConnectionsServiceConfig {
  tableTitle: string
  teamId: string
}

export interface LoginConnectionsService {
  tableTitle: string
  loginConnections: AuthConnections
  isLoading: boolean
  isUpdating: boolean
  isCreating: boolean
  isDeleting: boolean
  newLoginConnection: LoginConnectionsConfig
  vendorOptions: VendorOptions[]
  validEndpoint: boolean
  validConnectionName: boolean
  validLoginConnection: boolean
  createLoginConnection: () => void
  handleConnectionInput: (inputField: string) => (value: string) => void
  reset: () => void
  reorderConnections: (index: number, direction: ReorderDirection) => void
  deleteLoginConnection: (connectionName: string) => void
}

export enum ReorderDirection {
  Up = -1,
  Down = 1
}

export interface VendorOptions {
  key: string
  text: string
  value: Vendor
  prefix: VendorPrefix
}

const vendorOptions: VendorOptions[] = [
  { key: 'sf', text: 'Salesforce', value: 'salesforce', prefix: 'sf-' },
  { key: 'sfc', text: 'Salesforce Community', value: 'salesforce-community', prefix: 'sfc-' }
]

const defaultLoginConnectionConfig: LoginConnectionsConfig = {
  vendor: 'salesforce',
  teamId: '',
  name: '',
  instance: '',
  displayName: ''
}

export const validateEndpoint = (connection: LoginConnectionsConfig) => {
  const { instance, vendor } = connection

  // Must contain at least one '.' surrounded by text, 1st character must be a letter, can include '\'
  if (
    vendor === 'salesforce-community' &&
    instance.length &&
    /^[A-Za-z][A-Za-z0-9./-]*\.+[A-Za-z0-9./-]+$/.test(instance)
  ) {
    return true
  }

  // Must contain at least one '.' surrounded by text, 1st character must be a letter, cannot include '/'
  if (vendor === 'salesforce' && instance.length && /^[A-Za-z][A-Za-z0-9.-]+\.+[A-Za-z0-9.-]+$/.test(instance)) {
    return true
  }

  return false
}

export const validateConnectionName = (connection: LoginConnectionsConfig, authConnections: AuthConnections) => {
  const { name, vendor } = connection
  const prefix = vendorOptions.find(option => option.value === vendor)?.prefix

  if (
    /^\S*$/.test(name) &&
    !authConnections?.some(authConnection => authConnection.name === `${prefix ?? ''}${name}`)
  ) {
    return true
  }
  return false
}

export const useLoginConnectionsService = ({
  tableTitle,
  teamId
}: LoginConnectionsServiceConfig): LoginConnectionsService => {
  const { data, isLoading } = useGetTeamQuery(teamId)

  // Handle connection details querying
  const { data: regionInfo, isLoading: isLoadingRegions } = useGetRegionQuery()
  const teamApi = regionInfo?.find(region => region.region.toLowerCase() === data?.region.toLowerCase())?.server.api

  // Connections details query per connection in team
  const connectionsDetailsResult: UseQueryResult<IAuthConnectionDetails>[] = useGetConnectionsDetailsQuery(teamId)(
    data?.authConnections ?? [],
    teamApi ?? Env.API
  )
  const connectionsDetailsLoading = connectionsDetailsResult.some(res => res.isLoading)

  const [detailedAuthConnections, setDetailedAuthConnections] = useState<DetailedAuthConnections>([])

  useEffect(() => {
    const connectionsDetailsData = connectionsDetailsResult?.map(res => res.data)
    if (data?.authConnections && connectionsDetailsData) {
      const detailedConnections = data.authConnections.map((conn, index) => {
        const connDetails = connectionsDetailsData[index]
        return {
          ...conn,
          ...connDetails
        }
      })
      setDetailedAuthConnections(detailedConnections)
    }
  }, [data, connectionsDetailsLoading])

  const updateLoginConnectionsMutation = useUpdateTeamMutation(teamId)(() => reset())
  const updateLoginConnections = (newLoginConnections: AuthConnections) =>
    updateLoginConnectionsMutation.mutate(createMutationObject(newLoginConnections))
  const createMutationObject = (connections: AuthConnections) => ({ existingAuthConnections: connections })

  const createLoginConnectionsMutation = useCreateTeamLoginMutation(teamId)(() => {
    reset()
  })
  const createLoginConnection = () => {
    if (validLoginConnection) {
      createInfoToast(`Creating login connection: ${newLoginConnection.name}.`)
      createLoginConnectionsMutation.mutate(newLoginConnection)
    } else {
      createErrorToast('Problem creating login connection, please ensure all fields are valid or try again.')
    }
  }

  const deleteLoginConnectionsMutation = useDeleteTeamLoginMutation(teamId)(() =>
    createSuccessToast(`Deleted login connection from team: ${data?.primaryName}`)
  )
  const deleteLoginConnection = (connectionName: string) => {
    createInfoToast(`Deleting login connection: ${connectionName}.`)
    deleteLoginConnectionsMutation.mutate(connectionName)
  }

  const [newLoginConnection, setNewLoginConnection] = useState<LoginConnectionsConfig>({
    ...defaultLoginConnectionConfig,
    teamId
  })
  const debouncedLoginConnection = useDebounce(newLoginConnection, 500)

  const validEndpoint = useMemo(() => validateEndpoint(debouncedLoginConnection), [debouncedLoginConnection])
  const validConnectionName = useMemo(
    () => validateConnectionName(debouncedLoginConnection, data?.authConnections ?? []),
    [debouncedLoginConnection]
  )
  const validLoginConnection = useMemo(
    () => Object.values(debouncedLoginConnection).every(value => value !== '') && validEndpoint && validConnectionName,
    [debouncedLoginConnection]
  )

  const reset = () => {
    setNewLoginConnection({ ...defaultLoginConnectionConfig, teamId })
  }

  const handleConnectionInput = (inputField: string) => (value: string) => {
    setNewLoginConnection(prevState => ({
      ...prevState,
      [inputField]: formatInputValue(inputField, value.replace('https://', '').replace('http://', ''))
    }))
  }

  const formatInputValue = (inputField: string, value: string) =>
    inputField === 'instance'
      ? value.replace(/^([^/]*)(\/.*)?/, (all, hostname, after) => (hostname?.toLowerCase() ?? '') + (after ?? ''))
      : value

  const reorderConnections = (index: number, direction: ReorderDirection) => {
    const reorderedConns = data?.authConnections ? [...data.authConnections] : []
    if (!(index + direction < 0 || index + direction >= reorderedConns.length)) {
      const conn = reorderedConns.splice(index, 1).shift()
      conn && reorderedConns.splice(index + direction, 0, conn)
      updateLoginConnections(reorderedConns)
    }
  }

  return {
    tableTitle,
    loginConnections: detailedAuthConnections,
    isLoading: isLoading || isLoadingRegions || connectionsDetailsLoading,
    isUpdating: updateLoginConnectionsMutation.isLoading,
    isCreating: createLoginConnectionsMutation.isLoading,
    isDeleting: deleteLoginConnectionsMutation.isLoading,
    newLoginConnection,
    vendorOptions,
    validEndpoint,
    validConnectionName,
    validLoginConnection,
    createLoginConnection,
    handleConnectionInput,
    reset,
    reorderConnections,
    deleteLoginConnection
  }
}
