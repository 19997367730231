import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonProps,
  DropdownItemProps,
  DropdownProps,
  Form,
  Icon,
  InputOnChangeData,
  Modal,
  Popup
} from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useNavigate } from 'react-router'
import { useCustomersService } from '../../../newCustomers/services/CustomersService'
import { useFilterService } from '../../../../services/DynamoQueryFilterService'
import { useCreateTeamService } from '../../services/CreateTeamService'
import { useCreateCustomerService } from '../../../../services/CreateCustomerService'
import CustomerFormSection from './FormSections/CustomerFormSection'
import TeamFormSection from './FormSections/TeamFormSection'
import InitialUserFormSection from './FormSections/InitialUserFormSection'
import LoginOptionsFormSection from './FormSections/LoginOptionsFormSection'

interface CreateTeamFormProps {
  buttonText: string
  title: string
  suppliedCustomerId?: string
  suppliedCustomerName?: string
  suppliedCustomerRegion?: string
}

export const tooltip = (message: string) => (
  <Popup trigger={<Icon name="question circle" style={{ margin: '0' }} />} content={message} basic />
)

const CreateTeamForm = ({
  buttonText,
  title,
  suppliedCustomerId,
  suppliedCustomerName,
  suppliedCustomerRegion
}: CreateTeamFormProps) => {
  const { handleSearchChange: handleCustomerSearch, dynamoQueryFilter } = useFilterService(['name'], 'name', {})
  // Query up to 50 customers for now, user to search in selector.
  const { data: customers } = useCustomersService(dynamoQueryFilter, 50)
  const createCustomerService = useCreateCustomerService()
  const createTeamService = useCreateTeamService(suppliedCustomerRegion)

  const { value: modalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)
  const [canSubmitForm, setCanSubmitForm] = useState(false)
  const [isResetForm, setResetForm] = useState(false)

  const navigate = useNavigate()

  // Right now there'll only ever be one page, but in future this will handle multiple pages.
  const customerOptions: DropdownItemProps[] = useMemo(
    () =>
      customers?.pages.flatMap(page =>
        page.results.map(customer => ({
          key: customer.id,
          text: customer.name,
          value: customer.id
        }))
      ) ?? [],
    [customers]
  )

  const regionOptions = useMemo(() => createTeamService.regions, [createTeamService.regions])

  const environmentOptions: DropdownItemProps[] = useMemo(
    () =>
      createTeamService.environmentOptions?.map(env => ({
        key: env,
        text: env,
        value: env
      })),
    [createTeamService.environmentOptions]
  )

  /**
   * Check if we should disable or allow submision of form.
   */
  useEffect(() => {
    if (createTeamService.addCustomer) {
      const canSubmit =
        createTeamService.teamIsValid &&
        createCustomerService.customerName !== '' &&
        (!createCustomerService.customerIsInternal ? createCustomerService.customerSalesforceId !== '' : true) &&
        createCustomerService.customerIsValid
      setCanSubmitForm(canSubmit)
    } else {
      setCanSubmitForm(createTeamService.teamIsValid)
    }
  }, [
    createTeamService.addCustomer,
    createTeamService.teamIsValid,
    createCustomerService.customerName,
    createCustomerService.customerSalesforceId,
    createCustomerService.customerIsValid
  ])

  /**
   * Check if we should pre-fill team service and customer service
   */
  useEffect(() => {
    if (suppliedCustomerId || suppliedCustomerName) {
      createTeamService.handleTeamInput('customerId')(suppliedCustomerId as string)
      createCustomerService.setCustomerName(suppliedCustomerName as string)
    }
    if (isResetForm) {
      setResetForm(false)
    }
  }, [suppliedCustomerId, suppliedCustomerName, isResetForm])

  const handleClose = () => {
    setResetForm(true)
    createTeamService.reset()
    if (createCustomerService.customerIsInternal) {
      createCustomerService.toggleCustomerIsInternal()
    }
    closeModal()
  }

  const handleCustomerInput = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => {
    if (data.name === 'customer') {
      createTeamService.handleTeamInput('customerId')(data.value as string)
      handleCustomerSearch({}, { value: '' })
    }

    if (data.name === 'customerSalesforceId') {
      createCustomerService.setCustomerSalesforceId(data.value as string)
      createTeamService.handleTeamInput('customerId')(data.value as string)
    }

    if (data.name === 'customerName') {
      createCustomerService.setCustomerName(data.value as string)
    }

    if (data.name === 'region') {
      createCustomerService.setSelectedRegion(data.value as string)
    }
  }

  const handleTeamInformationInput = (
    _event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => {
    createTeamService.handleTeamInput(data.name)(data.value as string)
  }

  const handleInitialUserInput = (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    createTeamService.handleUserInput(data.name)(data.value)
  }

  const handleLoginOptionInput =
    (index: number) => (_event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      createTeamService.handleLoginInput(index)(data.name)(data.value)
    }

  /**
   * Create team on submit of form.
   */
  const handleTeamCreation = async () => {
    if (!canSubmitForm) {
      return
    }
    createTeamService.runCreateTeamProcess(
      createCustomerService.customer,
      createCustomerService.createCustomerAsync,
      finaliseTeamCreation
    )
  }

  const finaliseTeamCreation = (teamId: string) => {
    handleClose()
    if (teamId) {
      navigate(`/teams/${teamId}`)
    }
  }

  return (
    <Modal
      data-testid="create-team-modal"
      closeIcon
      open={modalOpen}
      trigger={
        <Button
          className={'form-button-sked-blue'}
          content={buttonText}
          onClick={openModal}
          disabled={createTeamService.isLoading || createCustomerService.creatingCustomer}
          loading={createTeamService.isLoading || createCustomerService.creatingCustomer}
        />
      }
      onClose={handleClose}
      onOpen={openModal}
    >
      <Modal.Header id="modal-header-add-team">{title}</Modal.Header>
      <Modal.Content>
        <Form loading={createTeamService.isLoading || createCustomerService.creatingCustomer}>
          {/* Customer */}
          {!suppliedCustomerId && !suppliedCustomerName && (
            <CustomerFormSection
              title="Customer information"
              customerOptions={customerOptions}
              addCustomer={createTeamService.addCustomer}
              internalCustomer={createCustomerService.customerIsInternal}
              customerName={suppliedCustomerName ? suppliedCustomerName : createCustomerService.customerName}
              customerId={suppliedCustomerId ? suppliedCustomerId : createTeamService.newTeam.customerId || ''}
              customerSalesforceId={createCustomerService.customerSalesforceId}
              handleCustomerInput={handleCustomerInput}
              toggleInternalCustomer={createCustomerService.toggleCustomerIsInternal}
              toggleAddCustomer={createTeamService.toggleAddCustomer}
              customerNameIsValid={createCustomerService.customerNameIsValid}
              customerIdIsValid={createCustomerService.customerSalesforceIdIsValid}
              checkingCustomer={createCustomerService.checkingCustomerName}
              handleCustomerSearch={handleCustomerSearch}
              regions={createCustomerService.regionsInfo}
              selectedRegion={createCustomerService.selectedRegion}
            />
          )}
          {/* Team Information */}
          <TeamFormSection
            title="Team information"
            regionOptions={regionOptions}
            environmentOptions={environmentOptions}
            salesforceTeam={createTeamService.isSalesforceTeam}
            newTeam={createTeamService.newTeam}
            handleTeamInformationInput={handleTeamInformationInput}
            errors={createTeamService.formErrors}
            isCreateNelxTeam={false}
          />
          <div
            className={
              createTeamService.newTeam.isSalesforce
                ? 'show login'
                : createTeamService.newTeam.isSalesforce === false
                  ? 'show user'
                  : 'hide'
            }
          >
            {createTeamService.isSalesforceTeam ? (
              /* Login options setup for sf tenants */
              <LoginOptionsFormSection
                title="Login options"
                loginOptions={createTeamService.loginOptions}
                handleLoginOptionInput={handleLoginOptionInput}
                addLoginOption={createTeamService.addLoginOption}
                deleteLoginOption={createTeamService.removeLoginOption}
                errors={createTeamService.formErrors}
              />
            ) : (
              /* Initial user for standalone tenants */
              <InitialUserFormSection
                title="Initial user setup"
                initialUser={createTeamService.initialUser}
                handleInitialUserInput={handleInitialUserInput}
                errors={createTeamService.formErrors}
              />
            )}
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel-create-team-button"
          content="Cancel"
          onClick={handleClose}
          disabled={createTeamService.isLoading || createCustomerService.creatingCustomer}
        />
        <Button
          id="create-team-button"
          data-testid="create-team-form-button"
          className="form-button-sked-blue"
          content="Create Team"
          onClick={handleTeamCreation}
          disabled={!canSubmitForm || createCustomerService.creatingCustomer || createTeamService.isLoading}
          loading={createTeamService.isLoading || createCustomerService.creatingCustomer}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default CreateTeamForm
