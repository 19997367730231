import { Divider, Icon, Popup, Statistic } from 'semantic-ui-react'
import { IUserSummary } from 'src/actions/Tenants'
import { LoaderWrap } from '../../../loadingComponents/LoaderWrap'

export const UserSummary = ({
  data,
  loading,
  showProductionUsersOnly
}: {
  data: IUserSummary[]
  loading: boolean
  showProductionUsersOnly?: boolean
}) => (
  <LoaderWrap loading={loading}>
    <div data-testid="user-summary">
      <Statistic.Group size="small">
        <Statistic horizontal>
          <Statistic.Value>{data[0].totalActiveResources}</Statistic.Value>
          <Statistic.Label>
            {showProductionUsersOnly ? 'Active Production Resources ' : 'Active Resources '}
            <Popup
              trigger={<Icon name="question circle" />}
              content={
                <div>
                  A user is counted as a Resource if they are active, have the &apos;Resource&apos; role assigned, and{' '}
                  <b>don&apos;t have access to ANY</b> of the following permissions via their allocated user roles:
                  <ul>
                    <li>Manage resource allocations</li>
                    <li>Dispatch allocated work to resources</li>
                    <li>Create and send job and shift offers</li>
                  </ul>
                </div>
              }
              basic
              wide
            />
          </Statistic.Label>
        </Statistic>
        <Statistic horizontal>
          <Statistic.Value>{data[0].totalActiveSchedulers}</Statistic.Value>
          <Statistic.Label>
            {showProductionUsersOnly ? 'Active Production Schedulers ' : 'Active Schedulers '}
            <Popup
              trigger={<Icon name="question circle" />}
              content={
                <div>
                  A user is counted as a Scheduler if they are active and <b>have access to ANY</b> of the following
                  permissions via their allocated user roles:
                  <ul>
                    <li>Manage resource allocations</li>
                    <li>Dispatch allocated work to resources</li>
                    <li>Create and send job and shift offers</li>
                  </ul>
                  Users with the Admin role applied are always counted as a Scheduler.
                </div>
              }
              basic
              wide
            />
          </Statistic.Label>
        </Statistic>
      </Statistic.Group>
      <Divider />
    </div>
  </LoaderWrap>
)
