import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast } from '../views/alertComponents/Alert'
import { deleteTeamName } from '../actions/NewTeams'

export const useDeleteTeamNameMutation = (teamId: string) => (onSuccessDeleteFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<string, AxiosError, string>((teamName: string) => deleteTeamName(teamId)(teamName), {
    onSuccess: () => {
      queryClient.invalidateQueries(['team', teamId, 'teamNames'])
      queryClient.invalidateQueries(['customerTeams'])
      onSuccessDeleteFn()
    },
    onError: (error: AxiosError) => {
      createErrorToast(error)
    }
  })
}
