import { useEffect, useState } from 'react'

interface UseDocumentTitle {
  documentTitle: string
  setDocumentTitle: React.Dispatch<React.SetStateAction<string>>
}

export const DEFAULT_ADMIN_CONSOLE_TITLE = 'Skedulo Admin Console'

export const useDocumentTitle = (title: string = ''): UseDocumentTitle => {
  const [documentTitle, setDocumentTitle] = useState(title)

  useEffect(() => {
    if (documentTitle) {
      document.title = `${documentTitle} - Skedulo Admin Console`
    } else {
      document.title = DEFAULT_ADMIN_CONSOLE_TITLE
    }
    return () => {
      document.title = DEFAULT_ADMIN_CONSOLE_TITLE
    }
  }, [documentTitle])

  return {
    documentTitle,
    setDocumentTitle
  }
}
