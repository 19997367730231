import * as React from 'react'
import { Button, Header, Input, InputOnChangeData, Segment } from 'semantic-ui-react'
import { ICustomer, updateCustomer } from '../../../actions/Customers'
import { createErrorToast } from '../../alertComponents/Alert'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'

interface IProps {
  customer?: ICustomer
  fetchCustomer(): void
  loading: boolean
}

interface IState {
  editable: boolean
  editedName: string
  editedSalesforceId: string
  editing: boolean
}

export class CustomerInformation extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      editable: false,
      editedName: props.customer?.name || '',
      editedSalesforceId: props.customer?.salesforceId || '',
      editing: false
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.customer?.name !== this.props.customer?.name) {
      this.setState({ editedName: this.props.customer?.name || '' })
    }
    if (prevProps.customer?.salesforceId !== this.props.customer?.salesforceId) {
      this.setState({ editedSalesforceId: this.props.customer?.salesforceId || '' })
    }
  }

  toggleEditable = (): void => {
    this.setState(prevState => ({ editable: !prevState.editable }))
  }

  handleEdit = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void => {
    const name = data.name
    const value = data.value
    this.setState(prevState => ({ ...prevState, [name]: value }))
  }

  validateEdit = () => {
    const { editedName, editedSalesforceId } = this.state
    const name = this.props.customer?.name || ''
    const salesforceId = this.props.customer?.salesforceId

    // Check that the Id is either empty or containing '001' followed by 15 alpha-numeric characters (case-insensitive)
    const sfIdRegex = /^(001[a-z0-9]{15})?$/i

    return (
      !!editedName &&
      (name !== editedName || (salesforceId !== editedSalesforceId && sfIdRegex.test(editedSalesforceId)))
    )
  }

  editCustomer = async () => {
    const { customer, fetchCustomer } = this.props
    const { editedName, editedSalesforceId } = this.state
    if (customer && this.validateEdit()) {
      try {
        this.setState({ editing: true })
        await updateCustomer(customer.id, { name: editedName, salesforceId: editedSalesforceId })
        this.setState({ editable: false })
        fetchCustomer()
      } catch (error) {
        createErrorToast(error)
      } finally {
        this.setState({ editing: false })
      }
    }
  }

  render() {
    const { editedName, editedSalesforceId, editable, editing } = this.state
    const customer = this.props.customer || ({} as ICustomer)
    return (
      <div>
        <Header as="h3">Customer Information</Header>
        <Segment color="blue">
          <LoaderWrap loading={this.props.loading}>
            <React.Fragment>
              <Header size="tiny">Name:</Header>
              {editable ? (
                <span>
                  <Input fluid value={editedName} onChange={this.handleEdit} name="editedName" />
                </span>
              ) : (
                <p>{customer.name}</p>
              )}
              <Header size="tiny">Salesforce ID:</Header>
              {editable ? (
                <span>
                  <Input fluid value={editedSalesforceId} onChange={this.handleEdit} name="editedSalesforceId" />
                </span>
              ) : (
                <p>{customer.salesforceId || 'No Salesforce ID'}</p>
              )}
              <Header size="tiny">Created By</Header>
              <p>{customer.createdBy || 'Unknown'}</p>
              <Header size="tiny">Updated By</Header>
              <p>{customer.updatedBy || 'Unknown'}</p>
              <Header size="tiny">Created Date</Header>
              <p>{customer.createdDate}</p>
              <Header size="tiny">Updated Date</Header>
              <p>{customer.updatedDate}</p>
              {editable && (
                <Button
                  primary
                  onClick={this.editCustomer}
                  floated="right"
                  disabled={!this.validateEdit()}
                  loading={editing}
                  content="Save"
                />
              )}
              <Button onClick={this.toggleEditable} content={editable ? 'Cancel' : 'Edit'} />
            </React.Fragment>
          </LoaderWrap>
        </Segment>
      </div>
    )
  }
}
