import * as React from 'react'
import { Pagination, Table } from 'semantic-ui-react'
import { ICustomer } from '../../../actions/Customers'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, getDisplayedData, handlePaginationChange, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISearch } from '../../Utils'

interface IProps {
  customers: ICustomer[]
  handleRowClick(id: string): () => void
  searchValue: ISearch
  loading: boolean
}

interface IState {
  sortColumn: string
  sortDirection: 'ascending' | 'descending'
  activePage: number
}

export class CustomersList extends React.PureComponent<IProps, IState> {
  CUSTOMERS_PER_PAGE = 17

  constructor(props: IProps) {
    super(props)
    this.state = {
      sortColumn: 'name',
      sortDirection: 'ascending',
      activePage: 1
    }
  }

  handleSort = (clickedColumn: string) => () => {
    const { sortColumn, sortDirection } = this.state
    if (sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        sortDirection: 'ascending'
      })
    } else {
      this.setState({
        sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending'
      })
    }
  }

  handlePaginationChangeFn = handlePaginationChange((activePage: number) => this.setState({ activePage }))

  render() {
    const { sortColumn, sortDirection, activePage } = this.state
    const { searchValue, handleRowClick } = this.props

    const SortedCustomerHeaderCell = SortedHeaderCell(sortColumn, sortDirection, this.handleSort)

    const filteredCustomers: ICustomer[] = filterData<ICustomer>(searchValue, this.props.customers)
    const sortedCustomers = sortData(filteredCustomers, sortColumn, sortDirection)
    const displayedCustomers = getDisplayedData(sortedCustomers, activePage, this.CUSTOMERS_PER_PAGE)
    const totalPages: number = Math.ceil(filteredCustomers.length / this.CUSTOMERS_PER_PAGE)

    return (
      <React.Fragment>
        <div className="scrollable-with-pagination">
          <Table striped color="blue" sortable size="small" selectable singleLine className="sticky-table">
            <Table.Header>
              <Table.Row>
                <SortedCustomerHeaderCell title="Name" />
                <SortedCustomerHeaderCell title="Salesforce Account ID" name="salesforceId" />
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap loading={this.props.loading} array={displayedCustomers} emptyMessage="No Customers">
              <Table.Body>
                {displayedCustomers.map(customer => (
                  <Table.Row key={customer.id} onClick={handleRowClick(customer.id)} className="clickable">
                    <Table.Cell>{customer.name}</Table.Cell>
                    <Table.Cell>{customer.salesforceId}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
        </div>
        <Pagination
          activePage={activePage}
          boundaryRange={1}
          onPageChange={this.handlePaginationChangeFn}
          totalPages={totalPages}
        />
      </React.Fragment>
    )
  }
}
