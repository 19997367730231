import * as _ from 'lodash'
import { Button, Grid, Checkbox, Header } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { camelCase, lowerCase, upperFirst } from 'lodash'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { CSVLink } from 'react-csv'
import { Team } from '../../actions/NewTeams'
import { useFilterService } from '../../services/DynamoQueryFilterService'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { getMultipleTenantFeatures, IFeatureFlagWithDescription, IMultiTenantFeatureFlags } from '../../actions/Tenants'
import { useGetFeatureFlagReportQuery } from '../../queries/GetNewFeatureFlagReportQuery'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetTeamRegionInfoQuery } from '../../queries/GetTeamRegionInfoQuery'
import { TeamWithApi } from '../newCustomers/component/CustomerUsers'
import DynamicBreadcrumbs from '../../components/Breadcrumbs/DynamicBreadcrumbs'
import { useSearchFilterStore } from '../../context/SearchFilterStoreContext'
import { createSuccessToast } from '../alertComponents/Alert'
import { useRouterProps } from '../../router/RouterProps'
import { displayTeams, FeatureEnabledForTeamList } from './FeatureEnabledForTeamList'
import { NewFeatureFlagList } from './NewFeatureFlagList'
import { useTeamsServiceFeatureFlags } from './services/TeamsService'
import { getTeamsColumns } from './component/NewTeamsList'
export interface TeamWithFeatures extends Team {
  featureFlags: IFeatureFlagWithDescription[]
}

export interface IFeatureFlagAggregate {
  [x: string]: { count: number; description: string }
}

export const aggregateTeamFeatures = (customTeams: TeamWithFeatures[]): IFeatureFlagAggregate => {
  const aggr: IFeatureFlagAggregate = {}
  customTeams.forEach(team => {
    team.featureFlags
      .filter(feature => feature.type === 'Feature')
      .forEach(feature => {
        aggr[feature.name] = {
          count: (aggr[feature.name]?.count || 0) + (feature.value ? 1 : 0),
          description: feature.description
        }
      })
  })
  return aggr
}

export const combineTeamsWithFeatures =
  (teamFeatures: IMultiTenantFeatureFlags) =>
  (team: TeamWithApi): TeamWithFeatures | undefined => {
    const features = teamFeatures.successes[team.team.tenantId]
    if (!features) {
      return undefined
    }
    return {
      ...team.team,
      featureFlags: features
    }
  }

export const getFeaturesForTeams = async (teams: TeamWithApi[]): Promise<TeamWithFeatures[]> => {
  const teamsByRegion = _.groupBy<TeamWithApi>(teams, 'api')
  const featureRequests = Object.keys(teamsByRegion).map(async (apiUrl): Promise<TeamWithFeatures[]> => {
    const regionTeams = teamsByRegion[apiUrl]
    const tenantIds = regionTeams.map(team => team.team.tenantId)
    const teamFeatures = await getMultipleTenantFeatures(tenantIds, apiUrl)
    const teamsWithFeatures = regionTeams.map(combineTeamsWithFeatures(teamFeatures))
    return _.compact(teamsWithFeatures)
  })
  const teamsAndFeaturesByRegion = await Promise.all(featureRequests)
  return _.flatten(teamsAndFeaturesByRegion)
}

const title = 'Feature Flag Report'

export const NewFeatureFlagReport = () => {
  const { flagsSearchFilter, setFlagsSearchFilter, updateSearchParams } = useSearchFilterStore()
  const routerProps = useRouterProps()
  const initFlagSearchFilter = () => {
    const searchParams = new URLSearchParams(routerProps.location.search)

    return {
      showCustomerTeamsOnly: {
        ...flagsSearchFilter.showCustomerTeamsOnly,
        value: searchParams.get('showCustomerTeamsOnly')
          ? searchParams.get('showCustomerTeamsOnly') === 'true'
          : flagsSearchFilter.showCustomerTeamsOnly.default
      },
      showEnabled: {
        ...flagsSearchFilter.showEnabled,
        value: searchParams.get('showEnabled')
          ? searchParams.get('showEnabled') === 'true'
          : flagsSearchFilter.showEnabled.default
      },
      showProductionTeamsOnly: {
        ...flagsSearchFilter.showProductionTeamsOnly,
        value: searchParams.get('showProductionTeamsOnly')
          ? searchParams.get('showProductionTeamsOnly') === 'true'
          : flagsSearchFilter.showProductionTeamsOnly.default
      }
    }
  }
  let featureFlag = camelCase(routerProps.params.id) || undefined

  // Handle special cases where feature flag name is not in normal camel case
  featureFlag = featureFlag?.replace('Ui', 'UI')
  featureFlag = featureFlag?.replace('OptionalLookups', '_optionalLookups')

  const { dynamoQueryFilter } = useFilterService([], 'primaryName')
  const {
    data: teamsService,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingTeams
  } = useTeamsServiceFeatureFlags(dynamoQueryFilter)
  const teamsList = teamsService?.pages.flatMap(page => page.results.map(team => team))
  const { data: regionsInfo } = useGetRegionQuery()
  const { data: teamsWithApis } = useGetTeamRegionInfoQuery(teamsList, regionsInfo, !hasNextPage)
  const { data, isFetching: isFetchingFlags } = useGetFeatureFlagReportQuery(teamsWithApis)
  const aggr = aggregateTeamFeatures(data || [])
  const { setDocumentTitle } = useDocumentTitle(title)
  const [toggleValues, setToggleValues] = useState(initFlagSearchFilter())
  const { value: showToast, setValue: setShowToast } = useBoolean(false)
  const isEitherFetchingDebounced = useDebounce(isFetchingFlags || isFetchingTeams, 100)

  useEffect(() => {
    setDocumentTitle(title)
  }, [])

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }, [teamsService])

  useEffect(() => {
    if (!isEitherFetchingDebounced && data && showToast) {
      createSuccessToast('Latest feature flag report data successfully fetched.')
      setShowToast(false)
    }
  }, [isEitherFetchingDebounced])

  const onToggleFilter = (filter: 'showCustomerTeamsOnly' | 'showProductionTeamsOnly' | 'showEnabled') => () => {
    setToggleValues({
      ...flagsSearchFilter,
      [filter]: {
        ...flagsSearchFilter[filter],
        value: !flagsSearchFilter[filter].value
      }
    })
  }

  const refetchButton = () => {
    refetch()
    setShowToast(true)
  }

  useEffect(() => {
    setFlagsSearchFilter({ ...flagsSearchFilter, ...toggleValues })
    updateSearchParams(routerProps, toggleValues, ['showCustomerTeamsOnly', 'showProductionTeamsOnly', 'showEnabled'])
  }, [toggleValues])

  useEffect(() => {
    updateSearchParams(
      routerProps,
      toggleValues,
      ['showCustomerTeamsOnly', 'showProductionTeamsOnly', 'showEnabled'],
      true
    )
  }, [routerProps.location.search])

  const filteredTeams =
    featureFlag && data
      ? displayTeams({
          teams: data,
          featureFlag,
          checkEnabled: flagsSearchFilter.showEnabled.value,
          showCustomerOnly: flagsSearchFilter.showCustomerTeamsOnly.value,
          showProductionOnly: flagsSearchFilter.showProductionTeamsOnly.value
        })
      : []

  const teamColumns = getTeamsColumns(true, true)

  return (
    <div className="route-component">
      {featureFlag && (
        <div style={{ marginBottom: '2.5rem' }}>
          <DynamicBreadcrumbs
            initialBreadcrumbs={[{ path: '/feature-flag-report', name: 'Feature flag report' }]}
            featureFlag={featureFlag}
          />
        </div>
      )}
      <Grid columns={3} stackable verticalAlign="middle">
        <Grid.Column width={4}>
          <Header as="h3">{upperFirst(lowerCase(featureFlag)) || 'Feature flag report'}</Header>
        </Grid.Column>
        <Grid.Column width={9} textAlign="right">
          {featureFlag && (
            <Checkbox
              toggle
              label="Show customer teams only"
              checked={flagsSearchFilter.showCustomerTeamsOnly.value}
              onClick={onToggleFilter('showCustomerTeamsOnly')}
              style={{ paddingLeft: '24px' }}
              aria-label="Show customer teams only"
            />
          )}
          {featureFlag && (
            <Checkbox
              toggle
              label="Show production teams only"
              checked={flagsSearchFilter.showProductionTeamsOnly.value}
              onClick={onToggleFilter('showProductionTeamsOnly')}
              style={{ paddingLeft: '24px' }}
              aria-label="Show production teams only"
            />
          )}
          {featureFlag && (
            <Checkbox
              toggle
              label="Show enabled"
              checked={flagsSearchFilter.showEnabled.value}
              onClick={onToggleFilter('showEnabled')}
              style={{ paddingLeft: '24px' }}
              aria-label="Show enabled"
            />
          )}
        </Grid.Column>
        <Grid.Column width={3} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {featureFlag &&
            (filteredTeams.length > 0 ? (
              <CSVLink
                data={filteredTeams}
                headers={teamColumns.map(column => column.accessor as string)}
                filename={`${featureFlag}.csv`}
              >
                {' '}
                <Button className="form-button-sked-blue" content="Export to CSV" />
              </CSVLink>
            ) : (
              <Button className="form-button-sked-blue" content="Export to CSV" disabled />
            ))}
          <Button
            className="form-button-sked-blue"
            content="Fetch data"
            onClick={() => refetchButton()}
            loading={isEitherFetchingDebounced}
            disabled={isEitherFetchingDebounced}
          />
        </Grid.Column>
      </Grid>
      {featureFlag ? (
        <FeatureEnabledForTeamList data={filteredTeams} loading={isEitherFetchingDebounced} />
      ) : (
        <NewFeatureFlagList featureFlagAggregate={aggr} loading={isFetchingTeams || isFetchingFlags} />
      )}
    </div>
  )
}
