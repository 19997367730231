import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'
import { createDeploymentTemplate } from '../../actions/Admin'
import { IEnvironmentDetails } from '../../actions/Environments'

export const useDeploymentTemplateCreationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: {
      templateName: string
      templateEnvironments: IEnvironmentDetails[]
      applicationName: string
      pinConfiguration: boolean
    }) =>
      createDeploymentTemplate(
        props.templateName,
        props.templateEnvironments,
        props.applicationName,
        props.pinConfiguration
      ),
    onSuccess: async (data, props) => {
      await queryClient.invalidateQueries(['deploymentTemplates'])
      await queryClient.setQueryData(['deploymentTemplate', props.templateName], data)
      createSuccessToast(`Deployment template ${props.templateName} created.`)
    },
    onError: error => {
      createErrorToast(error)
    }
  })
}
