import { Form, Icon, Popup } from 'semantic-ui-react'
import { CreateCustomerConnectionService } from '../../../services/CreateCustomerConnectionService'
import { InputWithError } from '../../../../../views/formComponents/Inputs'
import { SCOPES_INSTRUCTION } from '../../../../../views/newCustomers/services/Constants'

interface OpenIDConnectLoginOptionProps {
  createCustomerConnectionService: CreateCustomerConnectionService
}

const OpenIDConnectLoginOption = ({ createCustomerConnectionService }: OpenIDConnectLoginOptionProps) => {
  const { setDescription, ssoExtraFieldsMethods, description, isErrorFormMessageVisible } =
    createCustomerConnectionService
  const { setDiscoveryUrl, setClientId, setClientSecret, setScopes } = ssoExtraFieldsMethods.openIDSpecificMethods
  const { discoveryUrl, clientId, clientSecret, scopes } = ssoExtraFieldsMethods.openIDExtraFields

  return (
    <Form.Field width={16} error={false}>
      <label htmlFor="description">Description *</label>

      <InputWithError
        id="description"
        placeholder="Enter description"
        name="description"
        onChange={(_, data) => setDescription(data.value)}
        width={12}
        value={description}
        labelMessage={'Description is required'}
        labelSelector={isErrorFormMessageVisible && description === ''}
        error={isErrorFormMessageVisible && description === ''}
        required
      />

      <label htmlFor="discovery">Discovery URL *</label>

      <InputWithError
        id="discovery"
        placeholder="Enter discovery URL"
        name="discovery"
        onChange={(_, data) => setDiscoveryUrl(data.value)}
        width={12}
        value={discoveryUrl}
        labelMessage={'Discovery URL is required'}
        labelSelector={isErrorFormMessageVisible && discoveryUrl === ''}
        error={isErrorFormMessageVisible && discoveryUrl === ''}
        required
      />

      <label htmlFor="client_id">Client ID *</label>

      <InputWithError
        id="client_id"
        name="client_id"
        placeholder="Enter client id"
        onChange={(_, data) => setClientId(data.value)}
        width={12}
        value={clientId}
        labelMessage={'Client ID is required'}
        labelSelector={isErrorFormMessageVisible && clientId === ''}
        error={isErrorFormMessageVisible && clientId === ''}
        required
      />

      <label htmlFor="clientsecret">Client secret</label>

      <Form.Input
        id="clientsecret"
        name="clientsecret"
        placeholder="Enter client secret"
        onChange={(_, data) => setClientSecret(data.value)}
        width={12}
        value={clientSecret}
      />

      <label htmlFor="scopes">
        Scopes{' '}
        <Popup trigger={<Icon name="question circle" style={{ margin: '0' }} />} content={SCOPES_INSTRUCTION} basic />
      </label>

      <Form.Input
        id="scopes"
        name="scopes"
        placeholder="Enter scopes (separated by space)"
        onChange={(_, data) => setScopes(data.value.split(' '))}
        width={12}
        value={scopes.join(' ')}
      />
    </Form.Field>
  )
}

export default OpenIDConnectLoginOption
