import * as React from 'react'
import { useState } from 'react'
import { IDeployment, IEnvironment, IOldDeployment } from '../actions/Deployments'

export interface DeploymentModal {
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  environmentName: string
  setEnvironmentName: React.Dispatch<React.SetStateAction<string>>
  applicationName: string
  setApplicationName: React.Dispatch<React.SetStateAction<string>>
  deploymentTag: string
  setDeploymentTag: React.Dispatch<React.SetStateAction<string>>
  deploymentBranch: string
  setDeploymentBranch: React.Dispatch<React.SetStateAction<string>>
  deploymentCheckMode: boolean
  setDeploymentCheckMode: React.Dispatch<React.SetStateAction<boolean>>
  selectedEnvironments: IEnvironment[]
  setSelectedEnvironments: React.Dispatch<React.SetStateAction<IEnvironment[]>>
  selectedEnvironmentsHaveChanged: boolean
  setSelectedEnvironmentsHaveChanged: React.Dispatch<React.SetStateAction<boolean>>
  configVersionVisible: boolean
  setConfigVersionVisible: React.Dispatch<React.SetStateAction<boolean>>
  clearDeployment(): void
  populateDeployment(deployment: IOldDeployment): void
  toggleOpen(): void
}

export const useDeploymentModal = (): DeploymentModal => {
  const [modalOpen, setModalOpen] = useState(false)
  const [environmentName, setEnvironmentName] = useState('')
  const [applicationName, setApplicationName] = useState('')
  const [deploymentTag, setDeploymentTag] = useState('')
  const [deploymentBranch, setDeploymentBranch] = useState('')
  const [deploymentCheckMode, setDeploymentCheckMode] = useState(false)
  const [selectedEnvironments, setSelectedEnvironments] = useState<IEnvironment[]>([])
  const [selectedEnvironmentsHaveChanged, setSelectedEnvironmentsHaveChanged] = useState(false)
  const [configVersionVisible, setConfigVersionVisible] = useState(false)

  const clearDeployment = () => {
    setEnvironmentName('')
    setApplicationName('')
    setDeploymentTag('')
    setDeploymentBranch('')
    setDeploymentCheckMode(false)
    setSelectedEnvironments([])
    setConfigVersionVisible(false)
    setSelectedEnvironmentsHaveChanged(false)
  }

  const populateDeployment = (deployment: IDeployment) => {
    setEnvironmentName(deployment.env)
    setApplicationName(deployment.application)
    setDeploymentTag(deployment.tag || '')
    setDeploymentBranch(deployment.config)
    setDeploymentCheckMode(deployment.check_mode)
    setSelectedEnvironments([{ name: deployment.env, instance: deployment.instance }])
    setConfigVersionVisible(!!deployment.config)
    setSelectedEnvironmentsHaveChanged(true)
  }

  const toggleOpen = () => {
    setModalOpen(prevState => !prevState)
  }

  return {
    modalOpen,
    setModalOpen,
    environmentName,
    setEnvironmentName,
    applicationName,
    setApplicationName,
    deploymentTag,
    setDeploymentTag,
    deploymentBranch,
    setDeploymentBranch,
    deploymentCheckMode,
    setDeploymentCheckMode,
    selectedEnvironments,
    setSelectedEnvironments,
    selectedEnvironmentsHaveChanged,
    setSelectedEnvironmentsHaveChanged,
    configVersionVisible,
    setConfigVersionVisible,
    clearDeployment,
    populateDeployment,
    toggleOpen
  }
}
