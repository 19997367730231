import _ from 'lodash'
import { IDeliveryCheck } from './DeliveryCheck'

import { adminApi } from './Endpoints'
import { IRelease, IReleaseApplication } from './Releases'
import { ICommitInformation, ICommitsCompareResult } from './Commits'

export type DeliveryStatus = 'pending' | 'approved' | 'rejected' | 'superseded'

export interface IDeliveryName {
  applicationName: string
  version: string
}

export interface IDeliveryInfo {
  id: string
  tag: string
  status: DeliveryStatus
  releaseId?: number
  testsPass: boolean
  testsPassedAt?: string
  createdBy?: string
  testsLoggedBy?: string
  completedBy?: string
  completedExplanation?: string
  testsLoggedAt?: string
  createdAt: string
  updatedAt: string
  configVersion?: string
  pinConfiguration?: boolean
  supersededBy?: string
}

export type Delivery = IDeliveryName & IDeliveryInfo

export type NewDelivery = IDeliveryName & Partial<IDeliveryInfo>

export type TDeliveryDetails = Delivery & { application: IReleaseApplication; release: IRelease }

interface IDeliveriesResultBase {
  message: string
  version: string
  pinConfiguration: boolean
}

export interface IProspectiveDeliveryResult extends IDeliveriesResultBase {
  delivery?: NewDelivery
}

export interface ICreateDeliveryResult extends IDeliveriesResultBase {
  delivery: Delivery
  deployment: any[]
}

export interface ICompleteDeliveryRequest {
  completedExplanation: string
}

export const getDeliveries = async (): Promise<Delivery[]> =>
  adminApi.get<Delivery[]>('/delivery').then(response => response.data)

export const getDeliveriesForApplication = async (application: string, pending?: boolean): Promise<Delivery[]> =>
  adminApi
    .get<
      Delivery[]
    >(`/delivery/application/${application}` + (!_.isUndefined(pending) ? `?pending=${pending.toString()}` : ''))
    .then(response => response.data)

export const getDelivery = async (name: IDeliveryName): Promise<TDeliveryDetails> =>
  adminApi.get<TDeliveryDetails>(`/delivery/${name.applicationName}/${name.version}`).then(response => response.data)

export const createDelivery = async (delivery: NewDelivery): Promise<ICreateDeliveryResult> =>
  adminApi.post<ICreateDeliveryResult>('/delivery', delivery).then(response => response.data)

export const updateDelivery = async (name: IDeliveryName, patch: Partial<IDeliveryInfo>) =>
  adminApi.patch(`/delivery/${name.applicationName}/${name.version}`, patch).then(response => response.data)

export const deleteDelivery = async (name: IDeliveryName) =>
  adminApi.delete(`/delivery/${name.applicationName}/${name.version}`).then(response => response.data)

export const getNextDeliveryForApplication = async (applicationName: string) =>
  adminApi
    .get<IProspectiveDeliveryResult>(`/delivery/application/${applicationName}/next`)
    .then(response => response.data)

interface IApproveDeliveryResult {
  approved: Delivery[]
  rejected: Delivery[]
  deployment: any
}

export const deployDelivery = async (name: IDeliveryName, completeDeliveryRequest: ICompleteDeliveryRequest) =>
  adminApi
    .post<IApproveDeliveryResult>(`/delivery/approve/${name.applicationName}/${name.version}`, completeDeliveryRequest)
    .then(response => response.data)

interface IRejectDeliveryResult {
  rejected: Delivery[]
}

export const rejectDelivery = async (name: IDeliveryName, completeDeliveryRequest?: ICompleteDeliveryRequest) =>
  adminApi
    .post<IRejectDeliveryResult>(`/delivery/reject/${name.applicationName}/${name.version}`, completeDeliveryRequest)
    .then(response => response.data)

export interface IDeliveryCommitDiffResult {
  environments: { environment: string; version: string }[]
  versions: {
    version: string
    commitDiff: ICommitInformation[]
  }[]
}

export const getDeliveryCommitDiff = async (name: string, version: string) =>
  adminApi.get<IDeliveryCommitDiffResult>(`/delivery/commits/${name}/${version}`).then(response => response.data)

export const compareTwoDeliveries = async (applicationName: string, baseVersion: string, headVersion: string) =>
  adminApi
    .get<ICommitsCompareResult>(`/delivery/compare/commits/${applicationName}/${baseVersion}/${headVersion}`)
    .then(response => response.data)

export const getChecksForDelivery = async (applicationName: string, version: string) =>
  adminApi.get<IDeliveryCheck[]>(`/delivery/${applicationName}/${version}/checks`).then(response => response.data)

export const runChecksForDelivery = async (applicationName: string, version: string) =>
  adminApi.post<IDeliveryCheck[]>(`/delivery/${applicationName}/${version}/checks/run`).then(response => response.data)
