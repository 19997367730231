import * as _ from 'lodash'
import * as React from 'react'
import { Button, Menu, Segment, Table } from 'semantic-ui-react'
import {
  deleteConnection,
  editTeamDetails,
  IAuthConnection,
  IDetailedAuthConection,
  IEditedTeam
} from '../../../actions/Teams'
import { DeleteModal } from '../../modalComponents/DeleteModal'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'

interface IProps {
  auth0Connections: IDetailedAuthConection[]
  teamId: string
  updateTeam(): void
  loading: boolean
}

interface IState {
  orderedAuth0Connections: IDetailedAuthConection[]
  loading: boolean
}

export class ConnectionsList extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      orderedAuth0Connections: props.auth0Connections,
      loading: false
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.auth0Connections !== this.props.auth0Connections) {
      this.setState({ orderedAuth0Connections: this.props.auth0Connections })
    }
  }

  moveConnection = (connection: IDetailedAuthConection, movement: number) => () => {
    const newOrder = [...this.state.orderedAuth0Connections]
    const index = newOrder.indexOf(connection)
    if (!(index - movement < 0 || index - movement >= newOrder.length)) {
      const conn = newOrder.splice(index, 1).shift()
      conn && newOrder.splice(index - movement, 0, conn)
      this.setState({ orderedAuth0Connections: newOrder })
    }
  }

  resetOrder = (): void => {
    const connections = this.props.auth0Connections
    this.setState({ orderedAuth0Connections: connections })
  }

  saveOrder = (): void => {
    this.setState({ loading: true })
    const newConnections: IAuthConnection[] = this.state.orderedAuth0Connections.map(conn => ({
      name: conn.name,
      displayName: conn.displayName
    }))
    const edittedTeam: IEditedTeam = { auth0Connections: newConnections }
    editTeamDetails(this.props.teamId, edittedTeam).then(() => {
      this.props.updateTeam()
      this.setState({ loading: false })
    })
  }

  render() {
    const auth0Connections = this.state.orderedAuth0Connections
    const { loading } = this.state

    const handleDeleteConnection = (connectionName: string) => () => deleteConnection(this.props.teamId, connectionName)

    return (
      <div>
        <Segment color="blue">
          <Table celled basic="very" stackable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Login Button Text</Table.HeaderCell>
                <Table.HeaderCell>Connection Name</Table.HeaderCell>
                <Table.HeaderCell>Connection Endpoint</Table.HeaderCell>
                <Table.HeaderCell>Re-Order</Table.HeaderCell>
                <Table.HeaderCell>Delete</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap
              loading={this.props.loading || this.state.loading}
              array={this.props.auth0Connections}
              emptyMessage="No Login Connections"
            >
              <Table.Body>
                {auth0Connections.map(connection => (
                  <Table.Row key={connection.name}>
                    <Table.Cell>{connection.displayName}</Table.Cell>
                    <Table.Cell>{connection.name}</Table.Cell>
                    <Table.Cell className="table-cell-overflow">{connection.tokenURL}</Table.Cell>
                    <Table.Cell collapsing textAlign="center">
                      <Button
                        icon="angle up"
                        className="form-button-sked-blue"
                        onClick={this.moveConnection(connection, 1)}
                      />
                      <Button
                        icon="angle down"
                        className="form-button-sked-blue"
                        onClick={this.moveConnection(connection, -1)}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <DeleteModal
                        type="Connection"
                        content={`Are you sure you want to delete ${connection.name} auth0 connection.`}
                        deleteMethod={handleDeleteConnection(connection.name)}
                        updateData={this.props.updateTeam}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </TableLoaderWrap>
          </Table>
          {!_.isEqual(auth0Connections, this.props.auth0Connections) && (
            <Menu stackable secondary size="small">
              <Menu.Item position="right">
                <Button
                  name="save order"
                  className={'form-button-sked-blue'}
                  onClick={this.saveOrder}
                  compact
                  loading={loading}
                >
                  Save Order
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button name="reset order" className={''} onClick={this.resetOrder} compact>
                  Reset Order
                </Button>
              </Menu.Item>
            </Menu>
          )}
        </Segment>
      </div>
    )
  }
}
