import { useInfiniteQuery } from '@tanstack/react-query'
import { getPackageVersions, IPackageVersion } from '../../actions/Package'
import { createErrorToast } from '../../views/alertComponents/Alert'

export interface PackageVersionQueryResponse {
  next?: string
  result: IPackageVersion[]
}

export const useGetPackageVersionQuery = (packageName: string, query: string) =>
  useInfiniteQuery(
    ['packageVersion', packageName, query],
    ({ pageParam = {} }) =>
      getPackageVersions(packageName, {
        next: pageParam.next ?? '',
        query
      }),
    {
      getNextPageParam: lastPage => (lastPage.next ? { next: lastPage.next } : undefined),
      retry: false,
      onError: (error: Error) => createErrorToast(error)
    }
  )
