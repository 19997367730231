import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast } from '../views/alertComponents/Alert'
import { deleteTeamLogin, TeamLoginConnection } from '../actions/NewTeams'

export const useDeleteTeamLoginMutation = (teamId: string) => (onSuccessDeleteFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<TeamLoginConnection, AxiosError, string>(
    (connectionName: string) => deleteTeamLogin(teamId)(connectionName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['team', teamId])
        queryClient.invalidateQueries(['teams'])
        onSuccessDeleteFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
