import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { IUserList, getUserList } from '../actions/Tenants'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useGetUserListQuery = (
  tenantId?: string,
  teamApi?: string,
  active?: boolean,
  hasAnyRoles?: boolean
): UseQueryResult<IUserList[]> =>
  useQuery(
    ['tenant', 'users', tenantId, active, hasAnyRoles],
    () => {
      if (tenantId && teamApi) {
        return getUserList(tenantId, teamApi, active ? true : undefined, hasAnyRoles ? true : undefined)
      }
      return Promise.resolve([] as IUserList[])
    },
    {
      onError: error => createErrorToast(error),
      enabled: !!teamApi
    }
  )
