import * as React from 'react'
import { Button, Form, InputOnChangeData, Modal } from 'semantic-ui-react'
import { createMobileVersion } from '../../../actions/Mobile'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'
import { InputWithError } from '../../formComponents/Inputs'

interface IState {
  open: boolean
  loading: boolean
  version: string
  hotfix: boolean
  hotfixVersion: string
}

const inputs = ['version', 'hotfixVersion']

export class CutMobileReleaseModal extends React.PureComponent<object, IState> {
  constructor(props: object) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      version: '',
      hotfix: false,
      hotfixVersion: ''
    }
  }

  toggleOpen = () => this.setState(prevState => ({ open: !prevState.open }))

  onChange = (event: React.SyntheticEvent, data: InputOnChangeData) => {
    if (inputs.includes(data.name)) {
      this.setState({
        [data.name as keyof IState]: data.value as string | boolean
      } as Pick<IState, keyof IState>)
    }
  }

  toggleHotfix = () => {
    this.setState(prevState => ({ hotfix: !prevState.hotfix }))
  }

  validateVersion(version: string) {
    return version.match(/^[0-9]+\.[0-9]+\.[0-9]+/)
  }

  createVersion = async () => {
    if (this.validateVersion(this.state.version)) {
      if (this.state.hotfix && this.validateVersion(this.state.hotfixVersion)) {
        await createMobileVersion(this.state.version, this.state.hotfixVersion)
        createSuccessToast(`Mobile release ${this.state.version} created.`)
      } else if (!this.state.hotfix) {
        await createMobileVersion(this.state.version)
        createSuccessToast(`Mobile release ${this.state.version} created.`)
      }
    }
  }

  submit = async () => {
    this.setState({ loading: true })
    try {
      await this.createVersion()
      this.resetForm()
    } catch (error) {
      createErrorToast(error, { title: 'Error Creating Release:' })
    } finally {
      this.setState({ loading: false })
    }
  }

  resetForm = () => {
    this.setState({
      version: '',
      hotfix: false,
      hotfixVersion: '',
      open: false,
      loading: false
    })
  }

  render() {
    const { open, loading, version, hotfix, hotfixVersion } = this.state

    return (
      <Modal
        trigger={<Button onClick={this.toggleOpen} content="Create Mobile Build" className="form-button-sked-blue" />}
        closeIcon
        open={open}
        onClose={this.toggleOpen}
      >
        <Modal.Header>Create Mobile Build</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.submit}>
            <InputWithError
              placeholder="Version"
              name="version"
              error={!(this.validateVersion(version) || !version)}
              onChange={this.onChange}
              value={version}
            />
            <Form.Group inline>
              <Form.Checkbox
                label="Hotfix"
                toggle
                name="hotfix"
                width={3}
                checked={hotfix}
                onClick={this.toggleHotfix}
              />
              <InputWithError
                label="Hotfix Base Version"
                name="hotfixVersion"
                placeholder="Hotfix Base Version"
                width={10}
                value={hotfixVersion}
                disabled={!hotfix}
                required={hotfix}
                onChange={this.onChange}
                error={!(this.validateVersion(hotfixVersion) || !hotfixVersion || !hotfix)}
              />
            </Form.Group>
            <Form.Button
              id="modal-button-add-team"
              type="submit"
              fluid
              className="form-button-sked-blue"
              content="Submit"
              disabled={!(this.validateVersion(version) && (this.validateVersion(hotfixVersion) || !hotfix))}
              loading={loading}
            />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
