import * as React from 'react'
import { DropdownProps, Form, Icon, Input, InputProps, Label, Table } from 'semantic-ui-react'
import { INewLoginOption } from '../../actions/Teams'
import { LoginOptionSelector } from './Selectors'

interface ILoginOptionRowProps {
  loginOptionData: INewLoginOption
  onChange(event: React.SyntheticEvent<HTMLElement>, data: InputProps | DropdownProps): void
  default?: boolean
  disabled?: boolean
  id: string
  iconAction(): void
  endpointError: boolean
}

export const LoginOptionRow = (props: ILoginOptionRowProps) => (
  <Table.Row id={props.id}>
    <Table.Cell collapsing>
      <LoginOptionSelector
        value={props.loginOptionData.loginOption}
        onChange={props.onChange}
        name="loginOption"
        default={props.default}
        disabled={props.disabled}
      />
    </Table.Cell>
    <Table.Cell>
      <Form.Input
        value={props.loginOptionData.loginButtonText}
        onChange={props.onChange}
        placeholder="Login button text"
        name="loginButtonText"
        disabled={props.loginOptionData.loginOption === 'salesforce'}
      />
    </Table.Cell>
    <Table.Cell verticalAlign="middle">
      <Form.Input>
        <Input
          name="endpoint"
          placeholder="Endpoint"
          label={<Label>https://</Label>}
          labelPosition="left"
          value={props.loginOptionData.endpoint}
          onChange={props.onChange}
          required={props.loginOptionData.loginOption === 'salesforce-custom-endpoint'}
          error={!props.endpointError}
          disabled={props.loginOptionData.loginOption !== 'salesforce-custom-endpoint'}
        />
      </Form.Input>
    </Table.Cell>
    <Table.Cell collapsing data-testid="add-remove-login-button">
      {props.default ? (
        <Icon name="add" onClick={props.iconAction} />
      ) : (
        <Icon name="close" onClick={props.iconAction} />
      )}
    </Table.Cell>
  </Table.Row>
)
