import { useEffect, useState } from 'react'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { createErrorToast } from '../views/alertComponents/Alert'
import { getTeamByName } from '../actions/NewTeams'

export interface ValidateTeamService {
  debouncedTeamName: string
  teamNameIsValid: boolean
  checkingTeamName: boolean
  editedTeamName: string
  setEditedTeamName: (name: string) => void
}

const validateTeamName = async (debouncedTeamName: string, setTeamNameIsValid: (valid: boolean) => void) => {
  if (debouncedTeamName) {
    try {
      await getTeamByName(debouncedTeamName)
      setTeamNameIsValid(false)
    } catch (error: any) {
      if (error.isAxiosError && error.response.status === 404) {
        setTeamNameIsValid(true)
      } else {
        createErrorToast(error)
      }
    }
  } else {
    setTeamNameIsValid(false)
  }
}

export const useValidateTeamService = (): ValidateTeamService => {
  const { value: checkingTeamName, setValue: setCheckingTeamName } = useBoolean(false)
  const { value: teamNameIsValid, setValue: setTeamNameIsValid } = useBoolean(true)
  const [editedTeamName, setEditedTeamName] = useState('')
  const debouncedTeamName = useDebounce(editedTeamName, 500)

  useEffect(() => {
    setCheckingTeamName(true)
    validateTeamName(debouncedTeamName, setTeamNameIsValid).then(_ => setCheckingTeamName(false))
  }, [debouncedTeamName])

  return {
    teamNameIsValid,
    checkingTeamName,
    debouncedTeamName,
    editedTeamName,
    setEditedTeamName
  }
}
