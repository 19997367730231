import { useEffect, useMemo, useState } from 'react'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { IRegionInfo } from 'src/actions/Regions'
import { ExternalCustomer, InternalCustomer, NewCustomer, NewCustomerConfig } from '../../../actions/NewCustomers'
import { createErrorToast, createInfoToast } from '../../alertComponents/Alert'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { useCreateTeamMutation } from '../../../mutations/CreateTeamMutation'
import { NewTeam, NewTeamPayload, Team } from '../../../actions/NewTeams'
import { useGetEnvironmentsQuery } from '../../../queries/GetEnvironmentsQuery'
import { useValidateTeamService } from '../../../services/ValidateTeamService'
import { FormErrors, initialFormErrors, validateTeamName } from './CreateTeamService'

export interface CreateNelxTeamService {
  newTeam: NewTeam
  regions: IRegionInfo[]
  environmentOptions: string[]
  isLoading: boolean
  addCustomer: boolean
  isSalesforceTeam: boolean
  formErrors: FormErrors
  teamIsValid: boolean
  createNewNelxTeam: (api: string, customerId?: string) => Promise<Team>
  runCreateNelxTeamProcess: (
    newCustomer: NewCustomer,
    createCustomerFn: (newCustomerConfig: NewCustomerConfig) => Promise<InternalCustomer | ExternalCustomer>,
    processEndFn: (teamId: string) => void
  ) => Promise<void>
  handleNelxTeamInput: (inputField: string) => (value: string) => void
  reset: () => void
  toggleAddCustomer: () => void
}

export const defaultNewNelxTeam: NewTeam = {
  primaryName: '',
  description: '',
  customerId: '',
  region: '',
  authProvider: 'auth0-organization',
  metadata: {},
  owner: '',
  environment: '',
  tenantId: '',
  isSalesforce: false
}

/**
 * Show "Create Nelx Team" button only for Dev/Test environment AND when metadata "nelx" is true.
 *
 * @param customer
 * @param hostname
 */
export const showCreateNelxTeamButton = (customer: InternalCustomer | ExternalCustomer | undefined, hostname: string) =>
  customer?.metadata?.nelx === true &&
  hostname !== 'admin-console.skedulo.com' &&
  hostname !== 'staging-admin-console.test.skl.io'

export const useCreateNelxTeamService = (suppliedCustomerRegion?: string): CreateNelxTeamService => {
  const [newTeam, setNewTeam] = useState<NewTeam>(
    suppliedCustomerRegion ? { ...defaultNewNelxTeam, region: suppliedCustomerRegion } : defaultNewNelxTeam
  )
  const debouncedNewTeam = useDebounce(newTeam, 500)
  const [errors, setNelxErrors] = useState<FormErrors>(initialFormErrors)

  const { mutateAsync: createTeamMutation, isLoading } = useCreateTeamMutation(() => reset())
  const { data: regionsInfo } = useGetRegionQuery()
  const { data: teamEnvironments } = useGetEnvironmentsQuery()
  // Validating team name uniqueness with BE
  const { teamNameIsValid: teamIsUnique, checkingTeamName, setEditedTeamName } = useValidateTeamService()

  // At the moment, we don't use this toggleAddCustomer yet, but we will need this in the near future.
  const { value: addCustomer, toggle: toggleAddCustomer, setFalse: setAddCustomerFalse } = useBoolean(false)

  const { value: isSalesforceTeam, setFalse: setIsSalesforceFalse, setValue: setIsSalesforceTeam } = useBoolean()

  const reset = () => {
    setNewTeam(defaultNewNelxTeam)
    setEditedTeamName('')
    setAddCustomerFalse()
    setIsSalesforceFalse()
  }

  const validateNelxTeam = () => {
    if (!validateTeamName(debouncedNewTeam.primaryName ?? '')) {
      setNelxErrors({ ...errors, primaryName: { ...errors.primaryName, error: true } })
    } else if (errors.primaryName.error) {
      setNelxErrors({ ...errors, primaryName: { ...errors.primaryName, error: false } })
    }

    if (debouncedNewTeam.primaryName && !checkingTeamName && !teamIsUnique) {
      setNelxErrors({ ...errors, uniqueTeam: { ...errors.uniqueTeam, error: true } })
    } else if (errors.uniqueTeam.error) {
      setNelxErrors({ ...errors, uniqueTeam: { ...errors.uniqueTeam, error: false } })
    }
  }

  useEffect(() => validateNelxTeam(), [debouncedNewTeam, teamIsUnique, checkingTeamName])

  /**
   * Simple check if team form is complete and without errors.
   *
   * @returns true if form is ready for submission.
   */
  const validateForm = () => {
    // No Errors?
    const formHasErrors = Object.values(errors).some(e => e.error)

    const teamInput =
      newTeam.primaryName !== '' &&
      newTeam.description !== '' &&
      newTeam.environment !== '' &&
      newTeam.region !== '' &&
      newTeam.owner !== ''

    const customerInput = !addCustomer ? newTeam.customerId !== '' : true

    return !formHasErrors && teamInput && customerInput && !checkingTeamName
  }

  /**
   * Indicates that team data is ready for submission.
   */
  const teamIsValid = useMemo(() => validateForm(), [debouncedNewTeam, errors, isSalesforceTeam])

  /**
   * Create a new  Nelx team with react-query mutateAsync promise.
   *
   * Should be called after a customer is created if we need to create one.
   *
   * If a customer was selected then will use customerId set in new team data.
   *
   * @param api - region specific api url
   * @param customerId - Created customer id if applicable.
   * @returns Create team mutation promise.
   */
  const createNewNelxTeam = (api: string, customerId?: string): Promise<Team> => {
    const team = setupNelxTeamPayload(customerId)

    createInfoToast(`Creating team: ${team.team.primaryName}`)
    return createTeamMutation({ api, team })
  }

  /**
   * Sets up Nelx team payload for creation.
   *
   * @param customerId - Optionally provided customerId if a customer needed to be created.
   * @returns Payload for team creation.
   */
  const setupNelxTeamPayload = (customerId?: string): NewTeamPayload => ({
    environment: newTeam.environment,
    team: {
      primaryName: newTeam.primaryName,
      description: newTeam.description,
      customerId: customerId || newTeam.customerId,
      region: newTeam.region,
      authProvider: newTeam.authProvider,
      metadata: newTeam.metadata,
      owner: newTeam.owner,
      isSalesforce: newTeam.isSalesforce
    }
  })

  /**
   * Create Nelx team process.
   *
   * 1 - Creates customer if required.
   * 2 - Creates team.
   * 3 - Creates login connection if required.
   *
   * @param newCustomer - Customer to be created.
   * @param createCustomerFn - Create customer promise.
   * @param processEndFn - Function to handle cleanup after process ends.
   * @returns Promise handling team creation.
   */
  const runCreateNelxTeamProcess = (
    newCustomer: NewCustomer,
    createCustomerFn: (newCustomerConfig: NewCustomerConfig) => Promise<InternalCustomer | ExternalCustomer>,
    processEndFn: (teamId: string) => void
  ): Promise<void> => {
    // If able to run process, then we will find region info and api as form has dependency on regions for completion pre-submission
    const regionApi =
      regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === newTeam.region?.toLowerCase())?.server.api ||
      ''

    // At the moment, we don't use this addCustomer IF logic yet, but we will need this in the near future.
    if (addCustomer) {
      createInfoToast(`Creating customer: ${newCustomer.name}`)
      return createCustomerFn({ api: regionApi, customer: newCustomer } as NewCustomerConfig)
        .then(customer => createNewNelxTeam(regionApi, customer.id))
        .then(team => processEndFn(team.id || ''))
        .catch(_error => {
          createErrorToast('Error occured during team creation.')
        })
    }
    return createNewNelxTeam(regionApi)
      .then(team => processEndFn(team.id || ''))
      .catch(_error => {
        createErrorToast('Error occured during team creation.')
      })
  }

  const formatInput = (field: string, value: string) => {
    if (field === 'primaryName') {
      return value.trim().toLowerCase()
    }
    return value
  }

  const handleNelxTeamInput = (inputField: string) => (value: string) => {
    if (inputField === 'primaryName') {
      setEditedTeamName(formatInput(inputField, value))
    }
    if (inputField === 'isSalesforce') {
      setIsSalesforceTeam(value === 'salesforce' ? true : false)
      setNewTeam({ ...newTeam, isSalesforce: value === 'salesforce' ? true : false })
    } else {
      setNewTeam({ ...newTeam, [inputField]: formatInput(inputField, value) })
    }
  }

  return {
    newTeam,
    regions: regionsInfo ?? [],
    environmentOptions: teamEnvironments ?? [],
    isLoading,
    addCustomer,
    isSalesforceTeam,
    formErrors: errors,
    teamIsValid,
    createNewNelxTeam,
    runCreateNelxTeamProcess,
    handleNelxTeamInput,
    reset,
    toggleAddCustomer
  }
}
