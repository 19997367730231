import { Button, Table } from 'semantic-ui-react'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { IFeatureFlagAggregate, TenantWithFeaturesNameAndRegion } from '../FeatureFlagReport'

export interface IProps {
  tenants: TenantWithFeaturesNameAndRegion[]
  featureFlagAggregate: IFeatureFlagAggregate
  loading: boolean
  setFeatureFlag: (featureFlag: string) => void
}

export const FeatureFlagList = (props: IProps) => (
  <div className="scrollable">
    <Table celled={true} stackable size="small" color="blue" striped={true} className={'sticky-table'}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Feature flag</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Number of tenants enabled</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">View enabled tenants</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <TableLoaderWrap loading={props.loading} array={props.tenants} emptyMessage="No feature flags found">
        <Table.Body>
          {Object.keys(props.featureFlagAggregate).map(key => (
            <Table.Row key={key}>
              <Table.Cell>{key}</Table.Cell>
              <Table.Cell>{props.featureFlagAggregate[key]?.description}</Table.Cell>
              <Table.Cell textAlign="center">{props.featureFlagAggregate[key]?.count}</Table.Cell>
              <Table.Cell textAlign="center">
                <Button icon="file code" onClick={() => props.setFeatureFlag(key)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </TableLoaderWrap>
    </Table>
  </div>
)
