import * as React from 'react'
import { Button, DropdownProps, Form, InputProps, Modal } from 'semantic-ui-react'
import { cutRelease, ICutReleaseResponse, IReleaseApplication } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'
import { InputWithError } from '../../formComponents/Inputs'
import { ApplicationSelector } from '../../formComponents/Selectors'

interface IProps {
  applications: IReleaseApplication[]
}

interface IState {
  applicationName: string
  version: string
  hotfix: boolean
  hotfixVersion: string
  open: boolean
  submitting: boolean
  force: boolean
}

const formFields = ['applicationName', 'version', 'hotfixVersion']

export class CutReleaseModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      applicationName: '',
      version: '',
      hotfix: false,
      hotfixVersion: '',
      open: false,
      submitting: false,
      force: false
    }
  }

  onChange = (event: React.SyntheticEvent, data: InputProps | DropdownProps) => {
    if (formFields.includes(data.name)) {
      this.setState({
        [data.name as keyof IState]: data.value
      } as Pick<IState, keyof IState>)
    }
  }

  toggleHotfix = () => {
    this.setState(prevState => ({ hotfix: !prevState.hotfix }))
  }

  toggleForce = () => {
    this.setState(prevState => ({ force: !prevState.force }))
  }

  handleCutRelease = async (request: Promise<ICutReleaseResponse>) => {
    this.setState({ submitting: true })
    try {
      const releaseResponse = await request
      this.clearForm()
      createSuccessToast(
        `Release ${releaseResponse.release.version} cut for ${releaseResponse.release.applicationName}.`
      )
      this.toggleModal()
    } catch (error) {
      createErrorToast(error, { title: 'Error Cutting Release:' })
    }
    this.setState({ submitting: false })
  }

  submit = async () => {
    const { version, hotfixVersion, hotfix, applicationName, force } = this.state
    if (this.validateVersion(version) && applicationName) {
      if (hotfix) {
        if (this.validateVersion(hotfixVersion)) {
          await this.handleCutRelease(cutRelease(applicationName, version, force, hotfixVersion))
        }
      } else {
        await this.handleCutRelease(cutRelease(applicationName, version, force))
      }
    }
  }

  clearForm = () => {
    this.setState({
      applicationName: '',
      version: '',
      hotfix: false,
      hotfixVersion: '',
      force: false
    })
  }

  toggleModal = () => {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  validateVersion = (version: string) => version.match(/^v[0-9]+\.[0-9]+\.[0-9]+/)

  render() {
    const { version, applicationName, hotfix, hotfixVersion, open, force } = this.state
    return (
      <Modal
        closeIcon
        open={open}
        onClose={this.toggleModal}
        trigger={
          <Button className="form-button-sked-blue" primary content="Cut New Release" onClick={this.toggleModal} />
        }
      >
        <Modal.Header>Cut New Release</Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.submit}>
            <ApplicationSelector
              applications={this.props.applications}
              value={applicationName}
              onChange={this.onChange}
              label="Application"
            />
            <InputWithError
              placeholder="Version"
              label="Version"
              required
              name="version"
              error={!(this.validateVersion(version) || !version)}
              onChange={this.onChange}
              value={version}
            />
            <Form.Group inline>
              <Form.Checkbox label="Force" toggle name="force" width={3} checked={force} onClick={this.toggleForce} />
              <Form.Checkbox
                label="Hotfix"
                toggle
                name="hotfix"
                width={3}
                checked={hotfix}
                onClick={this.toggleHotfix}
              />
              <InputWithError
                label="Hotfix Base Version"
                name="hotfixVersion"
                placeholder="Hotfix Base Version"
                width={10}
                value={hotfixVersion}
                disabled={!hotfix}
                required={hotfix}
                onChange={this.onChange}
                error={!(this.validateVersion(hotfixVersion) || !hotfixVersion || !hotfix)}
              />
            </Form.Group>
            <Form.Button
              id="modal-button-add-team"
              type="submit"
              fluid
              className="form-button-sked-blue"
              content="Submit"
              disabled={
                !(this.validateVersion(version) && (this.validateVersion(hotfixVersion) || !hotfix) && applicationName)
              }
              loading={this.state.submitting}
            />
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
