import { InputOnChangeData } from 'semantic-ui-react'
import { InfiniteQueryObserverResult } from '@tanstack/react-query'
import { getMaxPages, processFetchNextPage, processSearch } from './InfiniteTableUtils'

export interface InfiniteTableService<D extends object> {
  pageData: D | undefined
  isLoading: boolean
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  maxPages: number
}

export interface InfiniteTableServiceProps<D extends object> {
  infiniteQueryResult: InfiniteQueryObserverResult<D, Error>
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  activePage: number
  setActivePage: React.Dispatch<React.SetStateAction<number>>
}

export const useInfiniteTableService = <D extends object>(
  props: InfiniteTableServiceProps<D>
): InfiniteTableService<D> => {
  const { infiniteQueryResult, setSearchValue, activePage, setActivePage } = props
  const { data, isLoading: isLoadingData, hasNextPage, fetchNextPage, isFetchingNextPage } = infiniteQueryResult

  processFetchNextPage(activePage, setActivePage, {
    dataPagesLength: data?.pages?.length ?? 1,
    hasNextPage,
    fetchNextPage
  })

  return {
    pageData: data?.pages[activePage - 1],
    isLoading: isLoadingData || isFetchingNextPage,
    maxPages: getMaxPages(hasNextPage, data?.pages.length),
    handleSearchChange: processSearch(setSearchValue)
  }
}
