import { useGetTeamWebConfigQuery } from '../../../queries/GetTeamWebConfigQuery'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { VendorClaim } from '../../../actions/Utils'

export interface TenantApiServiceConfig {
  teamId: string
}

export interface TenantApiService {
  tenantId: string
  parentLoading: boolean
  teamAPI: string
  vendor?: VendorClaim
}

export const useTenantApiService = ({ teamId }: TenantApiServiceConfig): TenantApiService => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { data: teamWebConfig, isLoading: loadingTeamWebConfig } = useGetTeamWebConfigQuery(teamData?.primaryName || '')

  return {
    tenantId: teamData?.tenantId || '',
    parentLoading: loadingTeamData || loadingTeamWebConfig,
    teamAPI: teamWebConfig?.server.api || '',
    vendor: teamData?.isSalesforce ? VendorClaim.Salesforce : VendorClaim.Skedulo
  }
}
