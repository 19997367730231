import { Header, Label, SemanticWIDTHS } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useMobileV2FormService } from '../services/MobileV2FormService'
import { FormDeployContextEnum, IMobileV2Form } from '../../../actions/MobileV2Forms'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'

enum MobileFormV2Status {
  Draft = 'Draft',
  Published = 'Published'
}

enum MobileV2FormStatusColor {
  Green = 'green',
  Grey = 'grey'
}

enum MobileV2FormType {
  Job = 'Job',
  Resource = 'Resource'
}

const EMPTY_STRING = '-'
const FORM_IN_PACKAGE_COLUMN_WIDTH: SemanticWIDTHS = 3
const REVISION_ID_COLUMN_WIDTH: SemanticWIDTHS = 2
const FORM_TYPE_COLUMN_WIDTH: SemanticWIDTHS = 2
const EMPTY_MESSAGE = 'No mobile v2 forms found.'

const getFormJobTypeList = (form: IMobileV2Form) => form.jobTypes ?? []

const getFormStatus = (form: IMobileV2Form) => {
  const hasJobTypes = getFormJobTypeList(form)?.length > 0
  return hasJobTypes ? MobileFormV2Status.Published : MobileFormV2Status.Draft
}

const getFormType = (form: IMobileV2Form) => {
  const formDefinition = form.formRev?.definition
  const deployContext = formDefinition?.deploy?.context
  return deployContext === FormDeployContextEnum.Resource ? MobileV2FormType.Resource : MobileV2FormType.Job
}

const getJobTypeList = (form: IMobileV2Form) => getFormJobTypeList(form)?.join(', ')

export const getFormRevId = (form: IMobileV2Form) => form.formRev?.id ?? EMPTY_STRING

export const getChildFormNameList = (form: IMobileV2Form) =>
  form.formRev?.definition?.forms.map(item => item.name) ?? []

interface MobileFormListProps {
  teamId: string
}

const MobileFormList = ({ teamId }: MobileFormListProps) => {
  const { data: teamData, isLoading: loadingTeamData } = useGetTeamQuery(teamId)
  const { mobileV2Forms, isLoading: loadingMobileFormList } = useMobileV2FormService({
    tenantId: teamData?.tenantId ?? '',
    region: teamData?.region ?? ''
  })

  const tableColumns = createMemoizedColumns<IMobileV2Form>(
    [
      {
        Header: 'Forms in package',
        width: FORM_IN_PACKAGE_COLUMN_WIDTH,
        Cell: ({ row }: { row: Row<IMobileV2Form> }) => {
          const childFormNameList = getChildFormNameList(row.original)
          return (
            <div>
              {childFormNameList.map((formName, index) => (
                <p key={`${formName}-${index}`} style={{ marginBottom: '0.5rem' }}>
                  {formName}
                </p>
              ))}
            </div>
          )
        }
      },
      {
        Header: 'Revision ID',
        width: REVISION_ID_COLUMN_WIDTH,
        accessor: getFormRevId
      },
      {
        Header: 'Form type',
        width: FORM_TYPE_COLUMN_WIDTH,
        accessor: getFormType
      },
      {
        Header: 'Job type',
        width: undefined,
        accessor: getJobTypeList
      },
      {
        Header: 'Status',
        width: undefined,
        Cell: ({ row }: { row: Row<IMobileV2Form> }) => {
          const status = getFormStatus(row.original)
          const statusColor =
            status === MobileFormV2Status.Published ? MobileV2FormStatusColor.Green : MobileV2FormStatusColor.Grey
          return (
            <Label basic color={statusColor}>
              {status}
            </Label>
          )
        }
      }
    ],
    []
  )

  return (
    <div data-testid="mobile-v2-forms">
      <div
        style={{
          paddingTop: '1rem'
        }}
      >
        <Header as="h3">Mobile v2 forms</Header>
        <p>
          This page shows the list of Custom Forms v2 in a tenant. A package can include one or multiple forms with the
          same type and settings
        </p>
      </div>
      <Table<IMobileV2Form>
        canAdjustColumnWidth
        textAlign="left"
        columns={tableColumns}
        data={mobileV2Forms}
        loading={loadingTeamData || loadingMobileFormList}
        emptyMessage={EMPTY_MESSAGE}
        color="blue"
        celled
      />
    </div>
  )
}

export default MobileFormList
