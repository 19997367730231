import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { runChecksForDelivery } from '../../actions/Deliveries'

export const useRunDeliveryChecksMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (deliveryCheck: { application: string; version: string }) =>
      runChecksForDelivery(deliveryCheck.application, deliveryCheck.version),
    onSuccess: (data, deliveryCheck) => {
      queryClient.setQueryData(['deliveryChecks', deliveryCheck.application, deliveryCheck.version], data)
    },
    onError: (error: Error) => {
      createErrorToast(`Rerun delivery checks failed. ${error}`)
      return []
    }
  })
}
