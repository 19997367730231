import { useDeleteCustomerConnectionService } from '../../services/DeleteCustomerConnectionService'
import { NewDeleteModal } from '../../../modalComponents/NewDeleteModal'
import { CustomerConnection } from '../../../../actions/CustomerConnections'

export const NewDeleteLoginOptionModal = ({
  loginOption,
  onSuccessDelete,
  menuItem,
  disabled
}: {
  loginOption: CustomerConnection
  onSuccessDelete: () => void
  menuItem?: boolean
  disabled: boolean
}) => {
  const deleteCustomerConnectionService = useDeleteCustomerConnectionService(loginOption, onSuccessDelete)
  return (
    <NewDeleteModal
      deleteMethod={deleteCustomerConnectionService.runDeleteCustomerLoginOption}
      type="Login Option"
      buttonText="Delete"
      content="Are you sure you want to delete this login option? You can't undo this action"
      isDeleting={deleteCustomerConnectionService.isDeleting}
      menuItem={menuItem}
      disabled={disabled}
    />
  )
}
