import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IApplication, restartApplication } from '../../actions/Applications'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'

export const useApplicationRollbackMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: { application: IApplication }) =>
      restartApplication(props.application.meta.name, props.application.environment, props.application.instance),
    onSuccess: async (data, props) => {
      createSuccessToast(`Restarting ${data.name} on ${data.environment} ${data.instance}.`)
      await queryClient.invalidateQueries([
        'application',
        props.application.meta.name,
        props.application?.environment,
        props.application?.instance
      ])
    },
    onError: (error: Error) => {
      createErrorToast(error)
    }
  })
}
