import { User, WrappedCacheEntry } from '@auth0/auth0-spa-js'
import { Clipboard } from 'ts-clipboard'
import { useEffect, useState } from 'react'
import { Icon, Button, Table, TableRow, TableCell, TableBody, TableHeader, TableHeaderCell } from 'semantic-ui-react'
import { cacheKey } from '../../auth/AuthCacheKey'
import AuthClient from '../../auth/AuthClient'
import { createErrorToast, createSuccessToast } from '../alertComponents/Alert'
import { formatDate } from '../../utils/dateUtils'
import { AuthExtPermission } from '../../actions/MyDetails'
import { TableLoaderWrap } from '../tableComponents/TableLoader'
import { decodeAccessToken } from '../../actions/Utils'
import { useGetPermissionsQuery } from '../../queries/GetPermissionsQuery'

const checkPermission = (permission: AuthExtPermission, myPermissions: string[]) =>
  myPermissions?.find(p => p === permission.name)

const MyDetailsPage = () => {
  const [user, setUser] = useState(undefined as User | undefined)
  const [entry, setEntry] = useState(undefined as WrappedCacheEntry | undefined)
  const [myPermissions, setMyPermissions] = useState([])
  const { data: allPermissions, isLoading } = useGetPermissionsQuery()

  useEffect(() => {
    const getUser = async () => {
      const fetchedUser = await AuthClient.getUser()
      setUser(fetchedUser)
    }

    setAuthInfo()
    getUser().catch(error => createErrorToast(error))
  }, [])

  useEffect(() => {
    const decodeToken = decodeAccessToken(entry?.body.access_token)
    setMyPermissions(decodeToken)
  }, [entry])

  const setAuthInfo = () => {
    const tokenString = localStorage.getItem(cacheKey())
    if (tokenString) {
      setEntry(JSON.parse(tokenString))
    }
  }

  const handleCopy = (value: string, message: string) => () => {
    if (value) {
      Clipboard.copy(value)
      createSuccessToast(message)
    }
  }

  return (
    <div className="route-component paginated-table">
      <h2>{user?.name}</h2>
      <h3>User details</h3>
      <Table celled definition>
        <TableBody>
          <TableRow>
            <TableCell>Given name</TableCell>
            <TableCell data-testid="given_name">{user?.given_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Family name</TableCell>
            <TableCell data-testid="family_name">{user?.family_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell data-testid="user_id">{user?.sub}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Updated</TableCell>
            <TableCell data-testid="updated_at">
              {user?.updated_at && formatDate(user?.updated_at, 'h:mma ddd D MMM YYYY')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <h3>Authentication info</h3>
      <Table celled definition>
        <TableBody>
          <TableRow>
            <TableCell>Access token</TableCell>
            <TableCell>
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <span
                  className="overflow-hide"
                  data-testid="access_token"
                  style={{ width: '200px', overflow: 'hidden', display: 'block', whiteSpace: 'nowrap' }}
                >
                  {entry?.body.access_token?.substring(0, 30)}
                </span>
                <Button
                  data-testid="copy_button"
                  style={{ marginLeft: '1rem' }}
                  onClick={handleCopy(entry?.body.access_token ?? '', 'Token copied to clipboard.')}
                >
                  <Icon name="copy" /> Copy
                </Button>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Access token expiry</TableCell>
            <TableCell data-testid="expiresAt">
              {entry?.expiresAt && formatDate(new Date(entry?.expiresAt * 1000).toString(), 'h:mma ddd D MMM YYYY')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <h3>Permissions</h3>
      <div style={{ paddingBottom: '100px' }}>
        <Table celled className="sticky-table">
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Permission</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableLoaderWrap loading={isLoading} array={allPermissions || []} emptyMessage="Permissions cannot be found">
            <TableBody>
              {allPermissions
                ?.filter(p => !p.name.includes('vax'))
                .map(permission => (
                  <TableRow key={permission.name}>
                    <TableCell textAlign="center">
                      {checkPermission(permission, myPermissions) ? (
                        <Icon name="check" color="green"></Icon>
                      ) : (
                        <Icon name="close" color="red"></Icon>
                      )}
                    </TableCell>
                    <TableCell>{permission.name}</TableCell>
                    <TableCell>{permission.description}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </TableLoaderWrap>
        </Table>
      </div>
    </div>
  )
}

export default MyDetailsPage
