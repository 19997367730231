import { useQuery } from '@tanstack/react-query'
import { getTenantFeatures } from '../../actions/Tenants'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useFeaturesQuery = (tenantId: string, teamAPI: string, parentLoading: boolean) =>
  useQuery({
    queryKey: ['features', tenantId],
    queryFn: () => getTenantFeatures(tenantId, teamAPI),
    onError: error => {
      createErrorToast(error)
    },
    enabled: !parentLoading
  })
