import { useQuery } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getDeliveryCheckTemplates } from '../../actions/DeliveryCheck'

export const useDeliveryChecksTemplatesQuery = () =>
  useQuery({
    queryKey: ['deliveryCheckTemplates'],
    queryFn: () => getDeliveryCheckTemplates(),
    useErrorBoundary: (error: unknown) => {
      createErrorToast(`Get delivery check templates failed. ${error}`)
      return false
    }
  })
