import { TenantFeatures } from '../../tenants/components/TenantFeatures'
import { useTenantApiService } from '../services/TenantApiService'

const TeamFeatureFlags = ({ teamId }: { teamId: string; customerId: string }) => {
  const tenantApiService = useTenantApiService({
    teamId
  })
  return <TenantFeatures tenantApi={tenantApiService} />
}

export default TeamFeatureFlags
