import { useGetCustomerAccountDataQuery } from '../../../queries/GetCustomerAccountDataQuery'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import CustomerAccountDataForm from './CustomerAccountDataForm'
import { CustomerContractTable } from './CustomerContractTable'

export const CustomerAccountData = ({ customerId, contract }: { customerId: string; contract?: boolean }) => {
  const { data: customer } = useGetCustomerQuery(customerId)
  const { data: customerAccountData, isLoading } = useGetCustomerAccountDataQuery(customer?.salesforceId ?? '')
  const salesforceId = customer?.salesforceId
  if (!salesforceId) {
    return <></>
  }

  return contract ? (
    <CustomerContractTable data={customerAccountData} loading={isLoading} salesforceId={salesforceId} />
  ) : (
    <CustomerAccountDataForm data={customerAccountData} loading={isLoading} salesforceId={salesforceId} />
  )
}
