import { Button, Modal } from 'semantic-ui-react'
import { useState } from 'react'
import { IApplication } from '../../../actions/Applications'
import { useApplicationRollbackMutation } from '../../../mutations/applications/RestartApplicationMutation'

interface IProps {
  application: IApplication
}

export const RestartApplicationModal = (props: IProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [restarting, setRestarting] = useState(false)
  const { mutate: restartApplication } = useApplicationRollbackMutation()
  const toggleModalOpen = () => {
    setModalOpen(prevState => !prevState)
  }

  const handleRestart = () => {
    setRestarting(true)
    restartApplication(props)
    setRestarting(false)
    toggleModalOpen()
  }

  return (
    <Modal
      size={'mini'}
      open={modalOpen}
      onClose={toggleModalOpen}
      trigger={
        <Button circular icon="undo" loading={restarting} onClick={toggleModalOpen} className="circle-icon-button" />
      }
    >
      <Modal.Header>Restart Application</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to restart {props.application.name || props.application.meta.name}{' '}
          {props.application.environment} {props.application.instance}?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button name="no" negative onClick={toggleModalOpen}>
          No
        </Button>
        <Button name="yes" positive onClick={handleRestart}>
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
