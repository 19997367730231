import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cancelDeployment } from '../../actions/Deployments'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { decodeErrors } from '../../actions/Utils'

export const useDeploymentCancelMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: { environment: string; instance: string; id: number }) =>
      cancelDeployment(props.environment, props.instance, props.id),
    onSuccess: async (data, props) => {
      await queryClient.invalidateQueries(['deployments'])
      await queryClient.invalidateQueries(['deployment', props.environment, props.instance, props.id])
    },
    onError: (error: Error) => {
      decodeErrors(error, data => null, createErrorToast)
    }
  })
}
