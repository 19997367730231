import { elasticserver, elasticServerConfig } from './Endpoints'
import { ITeamTenant } from './Tenants'
import { ICreated, IResult } from './Utils'

const teamEndpoint = '/admin/provision/team'

interface IFormBase {
  customerName?: string
  customerId?: string
  name: string
  description: string
  instance?: string
}

export interface IFormTeam extends IFormBase {
  region: string
  vendor: string
  displayName?: string
}

export interface ITeam extends IFormBase {
  id: string
  auth0Connections: IAuthConnection[]
  region?: string
  tenants?: ITeamTenant[]
  createdBy?: string
  updatedBy?: string
  createdDate: string
  updatedDate: string
}

export interface IAuthConnection {
  name: string
  displayName: string
}

export interface IAuthConnectionDetails {
  id?: string
  name: string
  strategy?: string
  tokenURL?: string
}

export type IDetailedAuthConection = IAuthConnection & IAuthConnectionDetails

export type LoginOption = 'salesforce-custom-endpoint' | 'salesforce' | 'skedulo'

export interface INewLoginOption {
  loginOption: LoginOption
  loginButtonText: string
  endpoint: string
}

export interface INewConnection {
  vendor: string
  teamId: string
  name: string
  instance?: string
  displayName?: string
}

export interface IEditedTeam {
  description?: string
  name?: string
  customerId?: string | null
  auth0Connections?: IAuthConnection[]
}

export interface ITeamWebConfig {
  region: string
  team: {
    name: string
    tenantId: string
  }
  auth0: {
    clientId: string
    audience: string
    domain: string
    lock: any
  }
  server: {
    api: string
  }
}

export const getTeams = (): Promise<ITeam[]> =>
  elasticserver.get<IResult<ITeam[]>>(teamEndpoint).then(teams => teams.data.result)

export const deleteTeam = (teamId: string, deleteType: boolean): Promise<string[]> =>
  elasticserver.delete(`/admin/provision/team/${teamId}`, { params: { teamId, delete: deleteType } }).then(
    connectionsNotDeleted => connectionsNotDeleted.data.result // a list of all the connections that weren't succesfully deleted
  )

export const getTeamById = (teamId: string): Promise<ITeam | undefined> =>
  elasticserver.get<IResult<ITeam[]>>(teamEndpoint).then(teams => teams.data.result.find(team => team.id === teamId))

export const provisionTeam = (team: IFormTeam): Promise<ITeam> =>
  elasticserver.post<ICreated<ITeam>>(teamEndpoint, team).then(response => response.data.created)

export const editTeamDetails = (teamId: string, team: IEditedTeam): Promise<object> =>
  elasticserver.put(`${teamEndpoint}/${teamId}`, team).then(response => ({}))

export const addNewConnection = (teamId: string, newConnection: INewConnection): Promise<ITeam> =>
  elasticserver
    .post<IResult<ITeam>>(`${teamEndpoint}/${teamId}/connection`, newConnection)
    .then(team => team.data.result)

export const addMultipleConections = (teamId: string, newConnections: INewConnection[]): Promise<ITeam> =>
  recursiveConnectionRequest(teamId, newConnections)

const recursiveConnectionRequest = (teamId: string, connections: INewConnection[]): Promise<ITeam> =>
  elasticserver.post<IResult<ITeam>>(`${teamEndpoint}/${teamId}/connection`, connections[0]).then(
    result => {
      if (connections.length > 1) {
        return recursiveConnectionRequest(teamId, connections.slice(1))
      }
      return result.data.result
    },
    error => error
  )

export const deleteConnection = (teamId: string, connectionName: string): Promise<string> =>
  elasticserver.delete(`${teamEndpoint}/${teamId}/connection/${connectionName}`).then(response => response.data)

export const getConnectionDetails = (connectionName: string, api: string): Promise<IAuthConnectionDetails> =>
  elasticserver
    .get<IResult<IAuthConnectionDetails>>(`/admin/provision/auth0connections/${connectionName}`, {
      ...elasticServerConfig,
      baseURL: api
    })
    .then(response => response.data.result)

export const getTeamWebConfig = (teamName: string): Promise<ITeamWebConfig> =>
  elasticserver
    .get<IResult<ITeamWebConfig>>('/auth/config/team/web', { params: { name: teamName } })
    .then(response => response.data.result)
