import { isBoolean, isEqual, isNull, isObject, isString, isUndefined } from 'lodash'
import { highlight, languages } from 'prismjs'
import 'prismjs/components/prism-json'
import * as React from 'react'
import Editor from 'react-simple-code-editor'
import {
  Button,
  Checkbox,
  DropdownProps,
  Input,
  InputOnChangeData,
  Segment,
  Select,
  Table,
  TextAreaProps
} from 'semantic-ui-react'
import { IMetadata, IMetadataValueType } from '../../actions/Tenants'
import { createErrorToast } from '../alertComponents/Alert'
import { mapStringsToOptions } from '../Utils'
import { RenderEditableTableHeader } from './editableTableComponents/EditableTableHeader'
import { TableLoaderWrap } from './TableLoader'

const metadataTypes = ['string', 'number', 'undefined', 'boolean', 'json'] as const
type MetadataTypes = (typeof metadataTypes)[number]
type EditedMetadataType = string | undefined | boolean

interface IProps {
  metadata?: IMetadata
  loading: boolean
  updateMetadata(metadata: IMetadata): Promise<any>
  updateData(): Promise<void>
}

interface IState {
  editable: boolean
  submitting: boolean
  editedKeys: string[]
  editedValues: EditedMetadataType[]
  editedTypes: MetadataTypes[]
}

export class DynamicMetadataTable extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    const editedKeys = Object.keys(props.metadata || {})
    const editedValues = editedKeys.map(key => this.metadataValueToEditable((props.metadata || {})[key]))
    const editedTypes = editedValues.map(this.getType)
    this.state = {
      editable: false,
      submitting: false,
      editedKeys,
      editedValues,
      editedTypes
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (!isEqual(prevProps.metadata || {}, this.props.metadata || {})) {
      this.resetForm()
    }
  }

  resetForm = () => {
    const metadata = this.props.metadata || {}
    const editedKeys = Object.keys(metadata)
    const editedValues = editedKeys.map(key => this.metadataValueToEditable(metadata[key]))
    const editedTypes = editedKeys.map(key => this.getType(metadata[key]))
    this.setState({ editedKeys, editedValues, editedTypes, editable: false })
  }

  validateMetadata = (keys: string[], values: EditedMetadataType[], types: MetadataTypes[]) => {
    const valuesValid = values.reduce((valid: boolean, key, index) => {
      const value = values[index]
      switch (types[index]) {
        case 'string':
          return isString(value) && valid
        case 'boolean':
          return isBoolean(value) && valid
        case 'number':
          return !isNaN(parseFloat(value as string)) && valid
        case 'undefined':
          return isUndefined(value) && valid
        case 'json':
          return this.validateJson(value as string) && valid
      }
      return false
    }, true)
    const keysValid = new Set(keys).size === keys.length
    return keysValid && valuesValid
  }

  compileMetadata = (keys: string[], values: EditedMetadataType[], types: MetadataTypes[]) => {
    const newMetadata: Record<string, any> = {}
    keys.forEach((key, index) => {
      switch (types[index]) {
        case 'boolean':
        case 'string':
        case 'undefined':
          newMetadata[key] = values[index]
          break
        case 'json':
          newMetadata[key] = JSON.parse(values[index] as string)
          break
        case 'number':
          newMetadata[key] = parseFloat(values[index] as string)
          break
      }
    })
    return newMetadata
  }

  updateMetadata = async () => {
    const { editedKeys, editedValues, editedTypes } = this.state
    if (this.validateMetadata(editedKeys, editedValues, editedTypes)) {
      this.setState({ submitting: true })
      const metadata = this.compileMetadata(editedKeys, editedValues, editedTypes)
      if (!isEqual(metadata, this.props.metadata || {})) {
        try {
          await this.props.updateMetadata(metadata)
          await this.props.updateData()
          this.setState({ editable: false })
        } catch (error) {
          createErrorToast(error)
        }
      }
      this.setState({ submitting: false })
    }
  }

  metadataValueToEditable = (value: IMetadataValueType): EditedMetadataType => {
    if (isUndefined(value) || isNull(value)) {
      return undefined
    }
    if (isBoolean(value)) {
      return value
    }
    if (isObject(value)) {
      return JSON.stringify(value, undefined, 2)
    }
    return value.toString()
  }

  getType = (value: IMetadataValueType): MetadataTypes => {
    const rawType = typeof value
    if (rawType === 'object') {
      return 'json'
    }
    if (metadataTypes.includes(rawType as MetadataTypes)) {
      return rawType as MetadataTypes
    }
    throw Error('Invalid type in metadata.')
  }

  toggleEditable = () => this.setState(prevProps => ({ editable: !prevProps.editable }))

  addField = () => {
    const editedKeys = [...this.state.editedKeys, ' ']
    const editedValues = [...this.state.editedValues, ' ']
    const editedTypes = [...this.state.editedTypes, 'string'] as MetadataTypes[]
    this.setState({ editedKeys, editedValues, editedTypes })
  }

  onChangeKey = (index: number) => (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
    const editedKeys = [...this.state.editedKeys]
    editedKeys[index] = data.value
    this.setState({ editedKeys })
  }

  onChangeStringValue =
    (index: number) => (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | TextAreaProps) => {
      const editedValues = [...this.state.editedValues]
      editedValues[index] = data.value as string
      this.setState({ editedValues })
    }

  onChangeType =
    (index: number) => (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData | DropdownProps) => {
      const newType = data.value as MetadataTypes
      const editedValues = [...this.state.editedValues]
      const editedTypes = [...this.state.editedTypes]
      if (['string', 'json', 'number'].includes(newType)) {
        editedTypes[index] = newType
        editedValues[index] = (editedValues[index] || '').toString()
      }
      if (newType === 'undefined') {
        editedTypes[index] = newType
        editedValues[index] = undefined
      }
      if (newType === 'boolean') {
        editedTypes[index] = newType
        editedValues[index] = false
      }
      this.setState({ editedTypes, editedValues })
    }

  onJsonChange = (index: number) => (value: string) => {
    const editedValues = [...this.state.editedValues]
    editedValues[index] = value
    this.setState({ editedValues })
  }

  deleteRow = (index: number) => () => {
    const editedKeys = [...this.state.editedKeys]
    const editedValues = [...this.state.editedValues]
    const editedTypes = [...this.state.editedTypes] as MetadataTypes[]
    editedKeys.splice(index, 1)
    editedValues.splice(index, 1)
    editedTypes.splice(index, 1)
    this.setState({ editedKeys, editedValues, editedTypes })
  }

  toggleBoolean = (index: number) => () => {
    const editedValues = [...this.state.editedValues]
    editedValues[index] = !this.state.editedValues[index]
    this.setState({ editedValues })
  }

  codeHighlight = (code: string) => highlight(code, languages.json, 'json')

  validateJson = (value: string) => {
    try {
      JSON.parse(value)
      return true
    } catch (error) {
      return false
    }
  }

  generateValueChangeMethod = (index?: number) => (isUndefined(index) ? () => null : this.onJsonChange(index))

  renderJson = (editable: boolean, value: EditedMetadataType, index?: number) => (
    <Editor
      value={value as string}
      onValueChange={this.generateValueChangeMethod(index)}
      highlight={this.codeHighlight}
      padding={editable ? 10 : 0}
      disabled={!editable}
      style={
        editable
          ? {
              borderRadius: '.28571429rem',
              borderColor: this.validateJson(value as string) ? 'rgba(34,36,38,.15)' : '#e0b4b4',
              borderStyle: 'solid',
              borderWidth: '1px'
            }
          : undefined
      }
    />
  )

  renderValueInput = (value: EditedMetadataType, type: MetadataTypes, index: number) => {
    if (type === 'string' || type === 'number') {
      return (
        <Input
          value={value}
          onChange={this.onChangeStringValue(index)}
          error={type === 'number' ? isNaN(parseFloat(value as string)) : false}
          fluid
        />
      )
    }
    if (type === 'boolean') {
      return <Checkbox checked={value as boolean} onClick={this.toggleBoolean(index)} />
    }
    if (type === 'json') {
      return this.renderJson(true, value, index)
    }
    return 'undefined'
  }

  renderValue = (value: IMetadataValueType, type: string) => {
    if (type === 'string' || type === 'number') {
      return value
    }
    if (type === 'boolean') {
      return <Checkbox checked={value as boolean} />
    }
    if (type === 'json') {
      return this.renderJson(false, JSON.stringify(value, undefined, 2))
    }
    return 'undefined'
  }

  renderDisplayRow = (key: string) => {
    const metadata = this.props.metadata || {}
    const type = this.getType(metadata[key])
    return (
      <Table.Row key={key}>
        <Table.Cell>{key}</Table.Cell>
        <Table.Cell width={10} textAlign={type === 'boolean' || type === 'undefined' ? 'center' : undefined}>
          {this.renderValue(metadata[key], type)}
        </Table.Cell>
        <Table.Cell>{type}</Table.Cell>
      </Table.Row>
    )
  }

  renderEditRow = (key: string, index: number) => {
    const values = this.state.editedValues
    const types = this.state.editedTypes
    return (
      <Table.Row key={index}>
        <Table.Cell width={5}>
          <Input value={key} onChange={this.onChangeKey(index)} fluid />
        </Table.Cell>
        <Table.Cell
          width={8}
          textAlign={types[index] === 'boolean' || types[index] === 'undefined' ? 'center' : undefined}
        >
          {this.renderValueInput(values[index], types[index], index)}
        </Table.Cell>
        <Table.Cell width={2}>
          <Select
            value={types[index]}
            options={mapStringsToOptions(metadataTypes as any as string[])}
            onChange={this.onChangeType(index)}
            fluid
          />
        </Table.Cell>
        <Table.Cell width={1}>
          <Button color="red" icon="close" onClick={this.deleteRow(index)} />
        </Table.Cell>
      </Table.Row>
    )
  }

  validateSubmit = () => this.validateMetadata(this.state.editedKeys, this.state.editedValues, this.state.editedTypes)

  render() {
    const { loading, metadata } = this.props
    const { editedKeys, editable, submitting } = this.state
    const keys = Object.keys(metadata || {})
    return (
      <div>
        <RenderEditableTableHeader
          tableTitle="Metadata"
          tableSubtitle=""
          loading={loading}
          isEditable={true}
          editing={editable}
          toggleEditing={this.toggleEditable}
          validateSubmit={this.validateSubmit()}
          submit={this.updateMetadata}
          reset={this.resetForm}
          submitting={submitting}
        />
        <Segment color="blue" style={{ flexGrow: 0 }}>
          <Table celled basic="very" stackable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Key</Table.HeaderCell>
                <Table.HeaderCell>Value</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                {editable && <Table.HeaderCell>Remove</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <TableLoaderWrap loading={loading} array={editable ? [1] : keys} emptyMessage="No Metadata">
              <React.Fragment>
                <Table.Body>
                  {editable ? editedKeys.map(this.renderEditRow) : keys.map(this.renderDisplayRow)}
                  {editable && (
                    <Table.Row key="new-field">
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell>
                        <Button icon="plus" onClick={this.addField} />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </React.Fragment>
            </TableLoaderWrap>
          </Table>
        </Segment>
      </div>
    )
  }
}
