import { datadogRum } from '@datadog/browser-rum'
import Env from '../env/Env'
import { isDataDogDisabled } from './instrumentation'

export const logError = (error: unknown) => {
  console.error(error)

  // only track error for production environment in Datadog
  if (!isDataDogDisabled && Env.RUM_ENV !== 'test') {
    datadogRum.addError(error)
  }
}
