import { flatten } from 'lodash'
import { useDynamoInfiniteQuery } from '../../../queries/DynamoInfiniteQuery'
import { getTeamsForCustomer, Team } from '../../../actions/NewTeams'

export const useCustomerTeamsService = (customerId: string) =>
  useDynamoInfiniteQuery<Team>(`teams-customer-${customerId}`, getTeamsForCustomer(customerId))

export const getTeamsList = (customerId: string) => {
  const { data: customerData } = useCustomerTeamsService(customerId)({})

  // customerData will only paginate if number of teams exceeds 1000 so we don't need to paginate here
  return flatten(customerData?.pages.map(page => page.results.map(team => team)))
}
