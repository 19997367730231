import { AxiosError, AxiosResponse } from 'axios'
import * as _ from 'lodash'

import { toast, ToastOptions } from 'react-semantic-toasts'

const handleAxiosErrorResponse = (response: AxiosResponse): string => {
  if (response.data) {
    if (response.data.message) {
      return response.data.message
    } else if (response.data.error) {
      return response.data.error
    } else if (_.isArray(response.data) && response.data.length) {
      if (response.data[0].errorType) {
        return `A ${response.data[0].errorType} occurred: `
      } else if (response.data[0].message) {
        return response.data[0].message
      }
    } else if (_.isString(response.data)) {
      return response.data
    }
  } // the response matche none of the expected shapes
  return response.statusText || 'An error response was received but could not be handled.'
}

export const getErrorContent = (error?: unknown) => {
  if (error) {
    const axiosError = error as AxiosError
    if (axiosError.isAxiosError) {
      if (axiosError.response) {
        // a response was received
        return handleAxiosErrorResponse(axiosError.response)
      } else if (axiosError.config) {
        return `No Response from server at ${axiosError.config.baseURL}`
      } else {
        return (error as Error).message
      }
    } else {
      return (error as Error).message
    }
  }
  return ''
}

export const createErrorToast = (error: unknown, options?: Partial<ToastOptions>) => {
  toast({
    title: 'Error:',
    description: typeof error === 'string' ? error : getErrorContent(error),
    type: 'error',
    time: 60000,
    ...options
  })
}

export const createSuccessToast = (description: string, options?: Partial<ToastOptions>) => {
  toast({
    title: 'Success:',
    description,
    type: 'success',
    time: 10000,
    ...options
  })
}

export const createInfoToast = (description: string, options?: Partial<ToastOptions>) => {
  toast({
    title: 'Info:',
    description,
    type: 'info',
    time: 10000,
    ...options
  })
}
