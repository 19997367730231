import { useQuery } from '@tanstack/react-query'
import {
  getReleaseApplication,
  getReleaseApplications,
  getLastApplicationRelease,
  getReleasesForVersion
} from '../../actions/Releases'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getReleaseTickets } from '../../actions/Jira'

export const useReleaseApplicationQuery = (applicationName?: string) =>
  useQuery({
    queryKey: ['releaseApplication', applicationName],
    queryFn: () => getReleaseApplication(applicationName!),
    onError: error => {
      createErrorToast(error)
    },
    enabled: !!applicationName
  })

export const usePreviousReleaseQuery = (applicationName?: string, version?: string) =>
  useQuery({
    queryKey: ['previousRelease', applicationName, version],
    queryFn: () => getLastApplicationRelease(applicationName!, version!),
    onError: error => {
      createErrorToast(error)
    },
    enabled: !!applicationName && !!version
  })

export const useReleaseApplicationsQuery = () =>
  useQuery({
    queryKey: ['releaseApplications'],
    queryFn: () => getReleaseApplications(),
    onError: error => {
      createErrorToast(error)
    }
  })

export const useReleasesForVersionQuery = (version?: string) =>
  useQuery({
    queryKey: ['releasesForVersion', version],
    queryFn: () => getReleasesForVersion(version!),
    onError: error => {
      createErrorToast(error)
    },
    enabled: !!version
  })

export const useReleaseTicketsQuery = (version?: string, component?: string, applicationName?: string) =>
  useQuery({
    queryKey: ['releaseTickets', version, component, applicationName],
    queryFn: () => getReleaseTickets(version!, component!, applicationName!),
    onError: error => {
      createErrorToast(error)
    },
    enabled: !!applicationName && !!version && !!component
  })
