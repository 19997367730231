import { DropdownItemProps, DropdownProps, Grid, Icon, InputOnChangeData } from 'semantic-ui-react'
import { SearchInput } from '../../views/searchComponents/SearchInput'
import { ISearch } from '../../views/Utils'

interface DynamoQueryControlsProps {
  isFetching: boolean
  refetch: () => void
  searchValue: ISearch
  searchOptions: DropdownItemProps[]
  handleSearchChange: (_: any, data: InputOnChangeData) => void
  handleSearchCategoryChange: (_: any, data: DropdownProps) => void
  excludeSpinner?: boolean
}

const DynamoQuerySearchControls = ({
  isFetching,
  refetch,
  searchValue,
  searchOptions,
  handleSearchChange,
  handleSearchCategoryChange,
  excludeSpinner
}: DynamoQueryControlsProps) => (
  <Grid.Column textAlign="left">
    <Grid verticalAlign="middle">
      {excludeSpinner ? (
        ''
      ) : (
        <Grid.Column>
          <Icon
            size="large"
            loading={isFetching}
            name="refresh"
            aria-label="refresh"
            className="clickable"
            onClick={() => refetch()}
          />
        </Grid.Column>
      )}
      <Grid.Column>
        <SearchInput
          searchValue={searchValue}
          searchOptions={searchOptions}
          handleSearchChange={handleSearchChange}
          handleSearchCategoryChange={handleSearchCategoryChange}
        />
      </Grid.Column>
    </Grid>
  </Grid.Column>
)

export default DynamoQuerySearchControls
