import { Button, Header, Segment } from 'semantic-ui-react'
import { LoaderWrap } from '../../views/loadingComponents/LoaderWrap'
import {
  EditableInformationFieldData,
  EditableRecordInformationService
} from '../../services/EditableRecordInformationService'
import { EditableDisplayStringField } from './EditableDisplayStringField'
import { EditableDisplayBaseField } from './EditableDisplayBaseField'

export const renderEditableDisplay = <T extends object>(
  fields: EditableInformationFieldData<T>[],
  editing: boolean,
  isEditableTable?: boolean
) =>
  fields.map(field =>
    field.isString ? (
      <EditableDisplayStringField
        isEditableTable={isEditableTable ?? false}
        key={field.fieldName as string}
        fieldName={(field.headerTitle ?? field.fieldName) as string}
        editFieldFn={field.editFieldFn}
        editedValue={field.editedValue as string}
        value={field.value as unknown as string}
        error={field.error}
        editable={editing && field.editable}
        showField={field.showField}
        DisplayComponent={
          field.DisplayComponent ? <field.DisplayComponent value={field.value as unknown as string} /> : undefined
        }
      />
    ) : (
      <EditableDisplayBaseField
        isEditableTable={isEditableTable ?? false}
        key={field.fieldName as string}
        fieldName={(field.headerTitle ?? field.fieldName) as string}
        editable={editing && field.editable}
        DisplayComponent={<field.DisplayComponent value={field.value} />}
        EditComponent={
          field.editable ? <field.EditComponent editedValue={field.editedValue} editFn={field.editFieldFn} /> : <div />
        }
        showField={field.showField}
      />
    )
  )

export const renderEditButton = <T extends object>(
  editing: boolean,
  submitting: boolean,
  saveEdits: (editedData: Partial<T>) => void,
  editedData: Partial<T>,
  editIsValid: boolean,
  toggleEditing: () => void
) => (
  <>
    {editing && (
      <Button
        primary
        onClick={() => saveEdits(editedData)}
        floated="right"
        disabled={!editIsValid}
        loading={submitting}
        content="Save"
      />
    )}
    <Button onClick={toggleEditing} content={editing ? 'Cancel' : 'Edit'} />
  </>
)

export const EditableInformation = <T extends object>({
  title,
  loading,
  editing,
  submitting,
  fields,
  saveEdits,
  editedData,
  editIsValid,
  toggleEditing
}: EditableRecordInformationService<T>) => (
  <div>
    <Header>{title}</Header>
    <Segment color="blue">
      <LoaderWrap loading={loading}>
        <>
          {renderEditableDisplay(fields, editing)}
          {renderEditButton(editing, submitting, saveEdits, editedData, editIsValid, toggleEditing)}
        </>
      </LoaderWrap>
    </Segment>
  </div>
)
