import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { UpdateCustomerConnectionConfig, CustomerConnection, updateConnection } from '../actions/CustomerConnections'

export const useUpdateCustomerLoginOptionMutation = (successFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<CustomerConnection, AxiosError, UpdateCustomerConnectionConfig>(
    (updateCustomerConnectionConfig: UpdateCustomerConnectionConfig) =>
      updateConnection(updateCustomerConnectionConfig),
    {
      onSuccess: async ({ customerId, id, displayName }) => {
        await queryClient.invalidateQueries(['customerConnection', customerId])
        createSuccessToast(`Updated login option for ID: ${id} with description: ${displayName}`)
        successFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
