import { useDeleteCustomerService } from '../services/DeleteCustomerService'
import { NewDeleteModal } from '../../modalComponents/NewDeleteModal'

export const NewDeleteCustomerModal = ({
  customerId,
  onSuccessDelete,
  menuItem
}: {
  customerId: string
  onSuccessDelete: () => void
  menuItem?: boolean
}) => {
  const deleteCustomerService = useDeleteCustomerService(customerId, onSuccessDelete)
  return <NewDeleteModal {...deleteCustomerService} menuItem={menuItem} />
}
