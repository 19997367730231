import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { ConnectionOnTeamConfigRequest, enableConnectionOnTeam } from '../actions/NewTeamConnections'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export const useEnableTeamLoginOptionMutation = (teamId: string, customerId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<[], AxiosError, ConnectionOnTeamConfigRequest>(
    (connectionOnTeamConfig: ConnectionOnTeamConfigRequest) => enableConnectionOnTeam(connectionOnTeamConfig),
    {
      onSuccess: async (_, variables) => {
        try {
          await queryClient.invalidateQueries(['teamConnections', teamId])
          await queryClient.invalidateQueries(['customerConnection', customerId])
          await queryClient.invalidateQueries([`teams-customer-${customerId}`])
          createSuccessToast(`${variables.description} login option is enabled`)
        } catch (error) {
          createErrorToast(error)
        }
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
