import * as React from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { useState } from 'react'
import { createSuccessToast } from '../../alertComponents/Alert'
import { useCreateReleaseApplicationMutation } from '../../../mutations/settings/CreateReleaseApplication'
import { SearchableSelector } from '../../formComponents/Selectors'
import { DeliveryCheckTemplate } from '../../../actions/DeliveryCheck'
import { ReleaseMethod } from '../../../actions/Releases'

export interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  deliveryCheckTemplates: DeliveryCheckTemplate[]
}

export const CreateReleaseApplicationModal = (props: IProps) => {
  const { mutate: createReleaseApplication, isLoading } = useCreateReleaseApplicationMutation()
  const [applicationName, setApplicationName] = useState('')
  const [component, setComponent] = useState('')
  const [repositoryName, setRepositoryName] = useState('')
  const [repositoryOwner, setRepositoryOwner] = useState('')
  const [releaseMethod, setReleaseMethod] = useState<ReleaseMethod>('normal')
  const [deliveryCheckTemplateId, setDeliveryCheckTemplateId] = useState('')

  const clearForm = () => {
    setApplicationName('')
    setComponent('')
    setRepositoryName('')
    setRepositoryOwner('')
    setReleaseMethod('normal')
    setDeliveryCheckTemplateId('')
  }

  const submit = async () => {
    createReleaseApplication({
      application: {
        applicationName,
        component,
        repositoryName,
        repositoryOwner,
        releaseMethod,
        isHidden: false,
        deliveryCheckTemplateId: deliveryCheckTemplateId || undefined
      }
    })
    createSuccessToast(`Application ${applicationName} created.`)
    clearForm()
    props.setOpen(false)
  }

  const deliveryCheckTemplateOptions = [
    { text: 'None', value: '' },
    ...props.deliveryCheckTemplates.map(template => ({
      text: template.name,
      value: template.id
    }))
  ]

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      closeIcon
      size="tiny"
      trigger={<Button color="blue" content="Create Release Application" onClick={() => props.setOpen(true)} />}
      aria-label="Create Release Application"
    >
      <Modal.Header>Create Release Application</Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={submit}>
          <Form.Input
            placeholder="Application Name"
            label={<label htmlFor="applicationName">Application Name</label>}
            required
            name="applicationName"
            id="applicationName"
            onChange={(_ev, data) => setApplicationName(data.value)}
            value={applicationName}
          />
          <Form.Input
            placeholder="Component"
            label={<label htmlFor="component">Component</label>}
            required
            name="component"
            id="component"
            onChange={(_ev, data) => setComponent(data.value)}
            value={component}
          />
          <Form.Input
            placeholder="Repository Name"
            label={<label htmlFor="repositoryName">Repository Name</label>}
            required
            name="repositoryName"
            id="repositoryName"
            onChange={(_ev, data) => setRepositoryName(data.value)}
            value={repositoryName}
          />
          <Form.Input
            placeholder="Repository Owner"
            label={<label htmlFor="repositoryOwner">Repository Owner</label>}
            required
            name="repositoryOwner"
            id="repositoryOwner"
            onChange={(_ev, data) => setRepositoryOwner(data.value)}
            value={repositoryOwner}
          />
          <SearchableSelector
            label="Release Method"
            value={releaseMethod}
            name="releaseMethod"
            id="releaseMethod"
            options={[
              { text: 'Normal', value: 'normal' },
              { text: 'Mobile', value: 'mobile' },
              { text: 'Fast Track', value: 'fast-track' }
            ]}
            onChange={(_ev, data) => setReleaseMethod(data.value as ReleaseMethod)}
          />
          <SearchableSelector
            name="deliveryCheckTemplateId"
            options={deliveryCheckTemplateOptions}
            value={deliveryCheckTemplateId ?? ''}
            onChange={(_ev, data) => setDeliveryCheckTemplateId(data.value as string)}
            label="Delivery Check Template"
          />
          <Form.Button
            id="modal-button-add-team"
            type="submit"
            fluid
            className="form-button-sked-blue"
            content="Submit"
            loading={isLoading}
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
