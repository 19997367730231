import { adminApi } from './Endpoints'

export interface ICommitInformation {
  sha: string
  message: string
  timestamp: string
  author: {
    name: string
    email: string
  }
}

export interface ICommitsCompareResult {
  base: string
  head: string
  html: string
  status: string
  ahead_by: number
  behind_by: number
  total_commits: number
  commits_ahead: ICommitInformation[]
  commits_behind: ICommitInformation[]
}

export const compareCommits = async (applicationName: string, baseVersion: string, headVersion: string) =>
  adminApi
    .get<ICommitsCompareResult>(`/commits/compare/${applicationName}/${baseVersion}/${headVersion}`)
    .then(response => response.data)
