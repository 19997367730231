import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { getTeamConnectionsByTeamId, TeamConnection, TeamConnectionsConfigRequest } from '../actions/NewTeamConnections'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useGetTeamLoginConnectionsQuery = (
  teamConnectionsConfig: TeamConnectionsConfigRequest
): UseQueryResult<TeamConnection[]> =>
  useQuery(['teamConnections', teamConnectionsConfig.teamId], () => getTeamConnectionsByTeamId(teamConnectionsConfig), {
    onError: error => createErrorToast(error),
    enabled: !!teamConnectionsConfig.teamId
  })
