import _ from 'lodash'
import * as React from 'react'
import { useEffect } from 'react'
import { Button, Checkbox, Divider, InputProps, Form, Grid, Modal, Segment } from 'semantic-ui-react'
import { Delivery, ICompleteDeliveryRequest } from '../../../actions/Deliveries'
import { useDeployDeliveryMutation } from '../../../mutations/deliveries/DeployDeliveryMutation'

interface IProps {
  delivery?: Delivery
  disabled: boolean
  check: boolean
  checkMessage: string[]
}

export const DeployDeliveryModal = (props: IProps) => {
  const { delivery } = props

  const { mutate: deployDelivery } = useDeployDeliveryMutation()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [deliveryChecks, setDeliveryChecks] = React.useState<boolean[]>(Array(props.checkMessage.length).fill(false))
  const [explanation, setExplanation] = React.useState<string>('')

  useEffect(() => {
    setDeliveryChecks(Array(props.checkMessage.length).fill(false))
  }, [props.checkMessage, props.check])

  const toggleOpen = () => {
    setOpen(!open)
  }

  const toggleDeliveryCheck = (indx: number) => () => {
    const checks = [...deliveryChecks]
    checks[indx] = !checks[indx]
    setDeliveryChecks(checks)
  }

  const disableDeploy = () =>
    props.check && (!deliveryChecks.reduce((prev, curr) => prev && curr, true) || !explanation) // AND all deliveryChecks values

  const handleDeployDelivery = async () => {
    setLoading(true)
    if (!_.isUndefined(delivery)) {
      const completeDeliveryRequest: ICompleteDeliveryRequest = {
        completedExplanation: explanation
      }
      deployDelivery({
        delivery: { applicationName: delivery.applicationName, version: delivery.version },
        explanation: completeDeliveryRequest
      })
    }
    setLoading(false)
    toggleOpen()
  }

  const changeExplanation = (event: React.SyntheticEvent, data: InputProps) => {
    setExplanation(data.value)
  }

  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      size="mini"
      trigger={
        <Button positive fluid onClick={toggleOpen} disabled={!delivery || props.disabled}>
          Deploy
        </Button>
      }
    >
      <Modal.Header>Deploy Delivery</Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to deploy delivery for <b>{delivery?.applicationName}</b> version{' '}
          <b>{delivery?.version}</b>?
        </p>
        {props.checkMessage.map((message, indx) => (
          <Segment key={indx}>
            {message}
            <Divider />
            <Checkbox
              key={indx}
              checked={deliveryChecks[indx]}
              toggle
              label={'Deploy anyway.'}
              onClick={toggleDeliveryCheck(indx)}
            />
          </Segment>
        ))}
        <Form>
          <Form.Input
            label="Explanation"
            placeholder="Explanation to deploy this delivery"
            required={props.check}
            name="explanation"
            onChange={changeExplanation}
            value={explanation}
            id="deploy-delivery-explanation"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={2}>
          <Grid.Column>
            <Button negative fluid onClick={toggleOpen}>
              No
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button positive fluid onClick={handleDeployDelivery} loading={loading} disabled={disableDeploy()}>
              Yes
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
