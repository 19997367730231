import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { createCustomer, Customer, NewCustomerConfig } from '../actions/NewCustomers'

export const useCreateCustomerMutation = (successFn?: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Customer, AxiosError, NewCustomerConfig>(
    (newCustomerConfig: NewCustomerConfig) => createCustomer(newCustomerConfig),
    {
      onSuccess: (createdCustomer: Customer) => {
        queryClient.invalidateQueries(['customers'])
        createSuccessToast(`Customer with name ${createdCustomer.name} created.`)
        if (successFn) {
          successFn()
        }
      },
      onError: (error: AxiosError) => {
        createErrorToast(error, { title: 'Error Creating Customer:' })
      }
    }
  )
}
