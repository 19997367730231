/**
 * Convert salesforce id from a 15 char id to 18 char id.
 *
 * If an 18 char id is passed, then will just return the id.
 *
 * Assumes id passed to function is a validated salesforce id (see validateSalesforceId function).
 *
 * @param id - salesforce id string
 * @returns salesforce id in 18 character format.
 * @throws error if id is neither 15 or 18 characters in length.
 */
export const convertSalesforceId = (id: string) => {
  if (id.length === 18) {
    return id
  }

  if (id?.length !== 15) {
    throw new Error('Salesforce id should be 15 or 18 characters in length.')
  }

  let additionalChars = ''

  for (let block = 0; block < 3; block++) {
    let loop = 0

    for (let index = 0; index < 5; index++) {
      const current = id.charAt(block * 5 + index)
      if (current >= 'A' && current <= 'Z') {
        /* eslint-disable no-bitwise */
        loop += 1 << index
      }
    }
    additionalChars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345'.charAt(loop)
  }
  return id + additionalChars
}

/**
 * Validates 15 or 18 char salesforce Ids have valid characters.
 *
 * @param salesforceId - salesforce id string to test.
 * @returns true if valid id
 */
export const validateSalesforceId = (salesforceId: string) =>
  salesforceId.length === 15 ? /^[a-zA-Z0-9]{15}$/.test(salesforceId) : /^[a-zA-Z0-9]{18}$/.test(salesforceId)
