import { NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router'
import * as React from 'react'
import { Location } from '@remix-run/router'
export interface RouterProps {
  location: Location
  navigate: NavigateFunction
  params: Readonly<Params<string>>
}

export const LegacyRouter = ({
  Component,
  children
}: {
  Component: React.ComponentClass<RouterProps>
  children?: JSX.Element
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  return (
    <Component location={location} navigate={navigate} params={params}>
      {children}
    </Component>
  )
}

export const useRouterProps = (): RouterProps => ({
  location: useLocation(),
  navigate: useNavigate(),
  params: useParams()
})
