import * as React from 'react'
import { DropdownItemProps } from 'semantic-ui-react'
import { useState } from 'react'
import { SearchInput } from './SearchInput'

export interface ISimpleSearchValue {
  searchText: string
  searchCategory: string
}

export interface ISimpleSearch extends ISimpleSearchValue {
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  setSearchCategory: React.Dispatch<React.SetStateAction<string>>
  searchOptions: DropdownItemProps[]
}

export const useSimpleSearch = (searchOptions: DropdownItemProps[]): ISimpleSearch => {
  const [searchText, setSearchText] = useState('')
  const [searchCategory, setSearchCategory] = useState((searchOptions[0].value as string) ?? '')
  return {
    searchText,
    setSearchText,
    searchCategory,
    setSearchCategory,
    searchOptions
  }
}

export const SimpleSearch = (props: { simpleSearch: ISimpleSearch }) => (
  <SearchInput
    searchValue={{ searchValue: props.simpleSearch.searchText, searchCategory: props.simpleSearch.searchCategory }}
    searchOptions={props.simpleSearch.searchOptions}
    handleSearchChange={(_, data) => props.simpleSearch.setSearchText(data.value)}
    handleSearchCategoryChange={(_, data) => props.simpleSearch.setSearchCategory((data.value as string) || 'all')}
  />
)
