import { Checkbox, Input } from 'semantic-ui-react'
import { EditedMetadataValue, MetadataTypes } from '../../../services/DynamicMetadataService'
import { JsonEditableViewer } from './JsonEditableViewer'

export type DynamicValueInputProps = StringNumberJsonInputProps | BooleanInputProps | UndefinedInputProps

interface StringNumberJsonInputProps extends DynamicValueInputPropsBase {
  type: 'string' | 'number' | 'json'
  value: string
  setValue: (value: string) => void
}

interface BooleanInputProps extends DynamicValueInputPropsBase {
  type: 'boolean'
  value: boolean
  toggleValue: () => void
}

interface UndefinedInputProps extends DynamicValueInputPropsBase {
  type: 'undefined'
}

interface DynamicValueInputPropsBase {
  value: EditedMetadataValue
  type: MetadataTypes
}

export const DynamicValueInput = (props: DynamicValueInputProps) => {
  if (props.type === 'string' || props.type === 'number') {
    const { value, type, setValue } = props
    return (
      <Input
        value={value}
        onChange={(_, data) => setValue(data.value)}
        error={type === 'number' ? isNaN(parseFloat(value as string)) : false}
        fluid
      />
    )
  }
  if (props.type === 'boolean') {
    return <Checkbox checked={props.value} onClick={() => props.toggleValue()} />
  }
  if (props.type === 'json') {
    return <JsonEditableViewer {...{ ...props, editable: true, changeValue: props.setValue }} />
  }
  return <div>undefined</div>
}
