import { adminApi } from './Endpoints'

export type DeliveryCheckStatus = 'created' | 'passed' | 'failed' | 'warning' | 'expired'

export type DeliveryCheckType =
  | 'ansible_config_check'
  | 'delivery_dependency_check'
  | 'delivery_tag_exists_in_ecr_check'
  | 'db_migration_check'

interface IIdProperty {
  id: string
}

export interface IDeliveryCheck {
  id: string
  name: string
  details: string
  metadata: any
  checkType: DeliveryCheckType
  status: DeliveryCheckStatus
  expiresAt: string
  deliveryId: string
}

export interface INewDeliveryCheckTemplate {
  name: string
  description: string
  checkTypes: DeliveryCheckType[]
}

export type PatchDeliveryCheckTemplate = Partial<DeliveryCheckTemplate>

export type DeliveryCheckTemplate = INewDeliveryCheckTemplate & IIdProperty

export interface IDeliveryCheckDefinition {
  type: DeliveryCheckType
  name: string
  description: string
  expiryMilliseconds?: number
}

export const getDeliveryCheckDefinitions = async (): Promise<IDeliveryCheckDefinition[]> =>
  adminApi.get<IDeliveryCheckDefinition[]>('/delivery-check/definition').then(response => response.data)

export const getDeliveryCheckTemplates = async (): Promise<DeliveryCheckTemplate[]> =>
  adminApi.get<DeliveryCheckTemplate[]>('/delivery-check/template').then(response => response.data)

export const getDeliveryCheckTemplate = async (id: string): Promise<DeliveryCheckTemplate> =>
  adminApi.get<DeliveryCheckTemplate>(`/delivery-check/template/${id}`).then(response => response.data)

export const createDeliveryCheckTemplate = async (
  template: INewDeliveryCheckTemplate
): Promise<DeliveryCheckTemplate> =>
  adminApi.post<DeliveryCheckTemplate>('/delivery-check/template', template).then(response => response.data)

export const patchDeliveryCheckTemplate = async (
  id: string,
  updates: PatchDeliveryCheckTemplate
): Promise<DeliveryCheckTemplate> =>
  adminApi.patch<DeliveryCheckTemplate>(`/delivery-check/template/${id}`, updates).then(response => response.data)

export const getDeliveryCheck = async (id: string): Promise<IDeliveryCheck> =>
  adminApi.get<IDeliveryCheck>(`/delivery-check/${id}`).then(response => response.data)

export const runDeliveryCheck = async (id: string): Promise<IDeliveryCheck> =>
  adminApi.post<IDeliveryCheck>(`/delivery-check/${id}/run`).then(response => response.data)
