import * as _ from 'lodash'
import * as React from 'react'
import { Button, DropdownProps, Form, Grid, Header, Modal } from 'semantic-ui-react'
import { useEffect, useState } from 'react'

import { IEnvironmentDetails } from '../../../actions/Environments'
import { ApplicationSelector } from '../../formComponents/Selectors'
import { LoaderWrap } from '../../loadingComponents/LoaderWrap'
import { useReleaseApplicationsQuery } from '../../../queries/releases/GetReleaseApplicationsQuery'
import { useEnvironmentsQuery } from '../../../queries/environments/GetEnvironmentsQuery'
import { useDeploymentTemplateQuery } from '../../../queries/deployments/GetDeploymentTemplateQuery'
import { NewDeleteModal } from '../../modalComponents/NewDeleteModal'
import { useDeleteDeploymentTemplateMutation } from '../../../mutations/settings/DeleteDeploymentTemplateMutation'
import { useUpdateDeploymentTemplateMutation } from '../../../mutations/settings/UpdateDeploymentTemplateMutation'
import { AvailableEnvironmentsTable } from './AvailableEnvironmentsTable'

export interface IProps {
  modalOpen: boolean
  closeModal: () => void
  templateName?: string
}

export const DeploymentTemplatesDetailsModal = (props: IProps) => {
  const [applicationName, setApplicationName] = useState<string | null>()
  const [editedEnvironments, setEditedEnvironments] = useState<IEnvironmentDetails[]>([])
  const [pinConfiguration, setPinConfiguration] = useState(false)
  const [editing, setEditing] = useState(false)

  const { data: releaseApplications, status: releaseApplicationsStatus } = useReleaseApplicationsQuery()
  const { data: environments, status: environmentsStatus } = useEnvironmentsQuery()
  const { data: deploymentTemplate, status: deploymentTemplateStatus } = useDeploymentTemplateQuery(props.templateName)
  const { mutate: deleteDeploymentTemplate } = useDeleteDeploymentTemplateMutation()
  const { mutate: updateDeploymentTemplate } = useUpdateDeploymentTemplateMutation()
  const loading =
    releaseApplicationsStatus === 'loading' ||
    environmentsStatus === 'loading' ||
    deploymentTemplateStatus === 'loading'

  const { modalOpen, closeModal, templateName } = props

  useEffect(() => {
    if (deploymentTemplateStatus === 'success') {
      setEditedEnvironments(deploymentTemplate.environments)
      setPinConfiguration(deploymentTemplate.pinConfiguration)
      setApplicationName(deploymentTemplate.applicationName)
    }
  }, [deploymentTemplate, deploymentTemplateStatus])

  const createCheckboxHandler = (environment: IEnvironmentDetails) => () => {
    const index = editedEnvironments.findIndex(templateEnvironment => _.matches(templateEnvironment)(environment))
    setEditedEnvironments(prevEditedEnvironments => {
      const newEditedEnvironments = [...prevEditedEnvironments]
      if (index === -1) {
        newEditedEnvironments.push(environment)
      } else {
        newEditedEnvironments.splice(index, 1)
      }
      return newEditedEnvironments
    })
  }

  const onChange = (event: React.SyntheticEvent, data: DropdownProps) => {
    setApplicationName(data.value as string)
  }

  const isDifferent = () => {
    const environmentsSame =
      editedEnvironments
        .map(env => env.id)
        .every(element => (deploymentTemplate?.environments || []).map(env => env.id).includes(element)) &&
      editedEnvironments.length === deploymentTemplate?.environments.length
    return (
      deploymentTemplate?.applicationName !== applicationName ||
      !environmentsSame ||
      deploymentTemplate?.pinConfiguration !== pinConfiguration
    )
  }

  const submitEdit = async () => {
    if (isDifferent() && deploymentTemplate && applicationName) {
      setEditing(true)
      updateDeploymentTemplate({
        templateName: deploymentTemplate.name,
        environments: editedEnvironments,
        applicationName,
        pinConfiguration
      })
    }
    setEditing(false)
    closeModal()
  }

  const deleteTemplate = () => {
    if (deploymentTemplate) {
      deleteDeploymentTemplate(deploymentTemplate.name)
      closeModal()
    }
  }

  return (
    <Modal open={modalOpen} closeIcon onClose={closeModal} size="tiny" aria-label="View Deployment Template">
      <Modal.Header>Deployment Template</Modal.Header>
      <Modal.Content scrolling>
        <LoaderWrap loading={loading}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column textAlign="center">
                <Header size="tiny">Deployment Name</Header>
                <p>{templateName}</p>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header size="tiny">Application Name</Header>
                {editing && applicationName ? (
                  <ApplicationSelector
                    applications={releaseApplications ?? []}
                    value={applicationName}
                    onChange={onChange}
                    acceptNull
                  />
                ) : (
                  <p>{deploymentTemplate?.applicationName ?? 'None'}</p>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Form.Checkbox
                  label={<label htmlFor="pin-configuration">Pin Configuration</label>}
                  id="pin-configuration"
                  checked={pinConfiguration}
                  onChange={(_event, data) => setPinConfiguration(!!data.checked)}
                  name="pinConfiguration"
                  toggle
                  disabled={!editing}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center">
              <AvailableEnvironmentsTable
                environments={environments ?? []}
                editable={editing}
                templateEnvironments={deploymentTemplate?.environments || []}
                editedEnvironments={editedEnvironments}
                createCheckboxHandler={createCheckboxHandler}
              />
            </Grid.Row>
          </Grid>
        </LoaderWrap>
      </Modal.Content>
      <Modal.Actions>
        {editing ? (
          <Button color="blue" content="Save" disabled={!isDifferent()} loading={loading} onClick={submitEdit} />
        ) : (
          <NewDeleteModal
            deleteMethod={deleteTemplate}
            content={`Are you sure you want to delete the deployment template for ${templateName}?`}
            type="Deployment Template"
            buttonText="Delete"
            disabled={editing || loading}
            isDeleting={false}
          />
        )}
        <Button
          color={editing ? 'grey' : 'blue'}
          content={editing ? 'Cancel' : 'Edit'}
          disabled={!deploymentTemplate || loading}
          loading={loading}
          onClick={() => setEditing(current => !current)}
        />
      </Modal.Actions>
    </Modal>
  )
}
