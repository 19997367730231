import { Icon, Label } from 'semantic-ui-react'
import { Delivery } from '../../../actions/Deliveries'

interface IProps {
  delivery?: Delivery
  indxDiff: number
}

export const DeliveryCompareSelectorItem = (props: IProps) => {
  const deliveryStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return <Icon name="clock" style={{ color: '#008cff' }} />
      case 'approved':
        return <Icon name="check" color="green" />
      case 'rejected':
        return <Icon name="x" color="red" />
      default:
        return <Icon name="question" />
    }
  }

  const deliveryIndxDiffIcon = (indxDiff: number) => {
    // negative means the selected delivery is BEHIND the main delivery
    if (indxDiff < 0) {
      return <Icon name="backward" />
    } else if (indxDiff > 0) {
      return <Icon name="forward" />
    } else {
      return <Icon name="minus" />
    }
  }

  return (
    <Label size="large" image basic>
      {props.delivery?.version}
      <Label.Detail>
        <span>{props.delivery?.tag}</span>
        <span style={{ marginLeft: '.5rem' }}>
          {deliveryIndxDiffIcon(props.indxDiff)}
          {Math.abs(props.indxDiff)}
        </span>
        <span style={{ marginLeft: '.5rem' }}>{deliveryStatusIcon(props.delivery?.status || '')}</span>
        <a
          style={{ marginLeft: '.5rem' }}
          href={`/applications/deliveries/${props.delivery?.applicationName}/${props.delivery?.version}`}
          target="_blank"
          rel="noreferrer noopener"
          onClick={evt => {
            evt.stopPropagation()
          }}
        >
          View
        </a>
      </Label.Detail>
    </Label>
  )
}
