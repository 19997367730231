import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Team, PatchTeam, updateTeam } from '../actions/NewTeams'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export const useUpdateTeamMutation = (teamId: string) => (onSaveFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Team, AxiosError, PatchTeam>((patchTeam: PatchTeam) => updateTeam(teamId)(patchTeam), {
    onSuccess: (updatedTeam: Team) => {
      queryClient.invalidateQueries(['team', teamId])
      queryClient.invalidateQueries(['teams'])
      queryClient.invalidateQueries(['customerTeams', updatedTeam?.customerId])
      onSaveFn()
      createSuccessToast('Team updated successfully.')
    },
    onError: (error: AxiosError) => {
      createErrorToast(error)
    }
  })
}
