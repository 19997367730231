import { Grid, Header } from 'semantic-ui-react'

interface HeaderWithControlProps {
  title: string
  size?: 'h1' | 'h2' | 'h3'
  control?: React.ReactNode
}
const HeaderWithControl = ({ title, size = 'h3', control = undefined }: HeaderWithControlProps) => (
  <div>
    <Grid>
      <Grid.Column width={8} textAlign="left" verticalAlign="middle">
        <Header as={size}>{title}</Header>
      </Grid.Column>
      <Grid.Column width={8} textAlign="right">
        {control}
      </Grid.Column>
    </Grid>
  </div>
)

export default HeaderWithControl
