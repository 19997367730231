import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { IUserSummary, getUserSummary } from '../actions/Tenants'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useGetUsersReportForTenantIdsQuery = (
  tenantIds?: string[],
  teamApi?: string
): UseQueryResult<IUserSummary[]> =>
  useQuery(
    ['tenant', 'users', tenantIds],
    () => {
      if (tenantIds && teamApi) {
        return getUserSummary(tenantIds, teamApi)
      }
      return Promise.resolve(null)
    },
    {
      onError: error => createErrorToast(error),
      enabled: !!teamApi
    }
  )
