import { IResult } from './Utils'
import { regionalApiServer } from './Endpoints'

export enum FormDeployContextEnum {
  Resource = 'resource',
  Job = 'job'
}

export interface IMobileV2Form {
  id: string
  name: string
  formRev: IFormRev
  jobTypes: string[]
  orderIndex: number
}

interface IFormRev {
  id: string
  formId: string
  version: number
  definition: FormDefinition
  files: string[]
}

interface FormDefinition {
  forms: FormDefinitionFormItem[]
  jobTypes: any[]
  deploy: FormDefinitionDeploy
  meta: FormDefinitionMeta
}

interface FormDefinitionMeta {
  sdkVersion: number
}

interface FormDefinitionDeploy {
  context: string
}

interface FormDefinitionFormItem {
  name: string
  root: string
}

export const getMobileV2FormsByTenantId = (tenantId: string, region: string) =>
  regionalApiServer(region)
    .get<IResult<IMobileV2Form[]>>(`/internal/customform/form/${tenantId}`)
    .then(response => response.data.result)
