import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Accordion, Button, ButtonGroup, ButtonProps, Checkbox, Grid, Header, Icon } from 'semantic-ui-react'
import { IReleaseApplication } from 'src/actions/Releases'
import { Delivery, TDeliveryDetails } from '../../../actions/Deliveries'
import { useDeliveriesForApplicationQuery } from '../../../queries/deliveries/GetDeliveriesQuery'
import { useDeliveryCompareQuery } from '../../../queries/deliveries/GetDeliveryCommitDiffQuery'
import { DeliveryCommitsCompareList } from './DeliveryCommitsCompareList'
import { DeliveryCompareMessage } from './DeliveryCompareMessage'
import { DeliveryCompareSelectorItem } from './DeliveryCompareSelectorItem'
import { DeliveryCompareSelectorModal } from './DeliveryCompareSelectorModal'

export interface IDeliveryCompareDetails {
  delivery?: Delivery
  dayDiff: number
  idxDiff: number
}
interface IProps {
  thisDelivery?: TDeliveryDetails
}

const findPrevApprovedDeliveryIndex = (deliveries: Delivery[], currIndex: number) =>
  deliveries.findIndex((delivery, index) => index > currIndex && delivery.status === 'approved')
const findThisDeliveryIndex = (deliveries: Delivery[], version: string) =>
  deliveries.findIndex(delivery => delivery.version === version)

const findNextApprovedDeliveryIndex = (deliveries: Delivery[], currIndex: number) => {
  const tempIndex = deliveries
    .slice(0, currIndex)
    .reverse()
    .findIndex(delivery => delivery.status === 'approved')
  if (tempIndex !== -1) {
    return currIndex - 1 - tempIndex // undo the effect of slice -> reverse
  } else {
    return tempIndex // return if -1
  }
}

const getInitDeliveryCompare = (deliveries: Delivery[], version: string) => {
  const thisIndex = findThisDeliveryIndex(deliveries, version)
  const prevApprovedIndex = findPrevApprovedDeliveryIndex(deliveries, thisIndex)
  if (prevApprovedIndex !== -1) {
    return prevApprovedIndex
  } else if (thisIndex + 1 < deliveries.length) {
    return thisIndex + 1
  } else {
    return thisIndex
  }
}

export const DeliveryCompare = (props: IProps) => {
  const [selectedDeliveryIndex, setSelectedDeliveryIndex] = useState(0)
  const [deliveriesLoaded, setDeliveriesLoaded] = useState(false)

  const [approvedOnly, setApprovedOnly] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(true)

  const applicationName = props.thisDelivery?.applicationName
  const version = props.thisDelivery?.version

  const { data: deliveries } = useDeliveriesForApplicationQuery(applicationName)

  const deliveryComparison: IDeliveryCompareDetails = {
    delivery: undefined,
    dayDiff: 0,
    idxDiff: 0
  }

  let selectedDelivery: Delivery | undefined

  if (deliveries && !deliveriesLoaded && version) {
    setDeliveriesLoaded(true)
    setSelectedDeliveryIndex(getInitDeliveryCompare(deliveries, version))
  }

  if (deliveries && version) {
    const deliveryIndex = findThisDeliveryIndex(deliveries, version)
    selectedDelivery = deliveries[selectedDeliveryIndex]

    if (deliveryIndex !== selectedDeliveryIndex && selectedDeliveryIndex < deliveries.length) {
      deliveryComparison.dayDiff = dayjs(props.thisDelivery?.createdAt).diff(selectedDelivery.createdAt, 'days')
      deliveryComparison.idxDiff = findThisDeliveryIndex(deliveries, version) - selectedDeliveryIndex
      deliveryComparison.delivery = selectedDelivery!
    }
  }

  const { fetchStatus: deliveryCommitsFetchStatus, data: commits } = useDeliveryCompareQuery(
    applicationName!,
    version!,
    selectedDelivery?.version
  )

  const handleSelectDelivery = async (index: number) => {
    setSelectedDeliveryIndex(index)
  }

  const nextDelivery = (event: React.MouseEvent<HTMLButtonElement>, _: ButtonProps) => {
    event.stopPropagation()
    if (!deliveries || !selectedDeliveryIndex || selectedDeliveryIndex < 1) {
      return
    } else if (!approvedOnly) {
      setSelectedDeliveryIndex(prevValue => prevValue - 1)
    } else if (approvedOnly) {
      const nextIndex = findNextApprovedDeliveryIndex(deliveries, selectedDeliveryIndex)
      if (nextIndex !== -1) {
        setSelectedDeliveryIndex(nextIndex)
      } else {
        return
      }
    }
  }

  const prevDelivery = (event: React.MouseEvent<HTMLButtonElement>, _: ButtonProps) => {
    event.stopPropagation()
    if (!deliveries || selectedDeliveryIndex >= deliveries.length - 1) {
      return
    } else if (!approvedOnly) {
      setSelectedDeliveryIndex(prevValue => prevValue + 1)
    } else if (approvedOnly) {
      const nextIndex = findPrevApprovedDeliveryIndex(deliveries, selectedDeliveryIndex)
      if (nextIndex !== -1) {
        setSelectedDeliveryIndex(nextIndex)
      } else {
        return
      }
    }
  }

  const disableNextDelivery = () => {
    if (!deliveries) {
      return true
    }
    if (approvedOnly) {
      return !deliveries
        .slice(0, selectedDeliveryIndex)
        .reverse()
        .some(delivery => delivery.status === 'approved')
    } else {
      return selectedDeliveryIndex === 0
    }
  }

  const disablePrevDelivery = () => {
    if (!deliveries) {
      return true
    }
    if (approvedOnly) {
      return !deliveries.some((delivery, index) => index > selectedDeliveryIndex && delivery.status === 'approved')
    } else {
      return selectedDeliveryIndex === deliveries.length - 1
    }
  }

  return (
    <div>
      <Accordion>
        <Accordion.Title active={open} onClick={() => setOpen(!open)}>
          <Grid columns={2} stretched>
            <Grid.Column width={12} verticalAlign="middle">
              <Header as="h3">
                <Header.Content>
                  <Icon name="dropdown" />
                  Compare to{' '}
                  <DeliveryCompareSelectorItem
                    delivery={deliveryComparison.delivery}
                    indxDiff={deliveryComparison.idxDiff}
                  />
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={4}>
              <Grid.Row>
                <ButtonGroup>
                  <Button icon onClick={prevDelivery} disabled={disablePrevDelivery()}>
                    <Icon name="backward" aria-label="previous delivery" />
                  </Button>
                  <Button icon onClick={nextDelivery} disabled={disableNextDelivery()}>
                    <Icon name="forward" aria-label="next delivery" />
                  </Button>
                  <DeliveryCompareSelectorModal
                    deliveries={deliveries}
                    handleSelectDelivery={handleSelectDelivery}
                    primeDeliveryIndex={deliveries && version ? findThisDeliveryIndex(deliveries, version) : -1}
                  />
                </ButtonGroup>
              </Grid.Row>
              <Grid.Row>
                <Checkbox
                  toggle
                  label={<label htmlFor="approved-only">Approved Only</label>}
                  id="approved-only"
                  style={{ marginTop: '.5rem' }}
                  checked={approvedOnly}
                  onChange={(_, data) => {
                    setApprovedOnly(!!data.checked)
                  }}
                  onClick={evt => {
                    evt.stopPropagation()
                  }}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={open}>
          {selectedDelivery && (
            <DeliveryCompareMessage
              base={props.thisDelivery}
              head={deliveryComparison}
              loadingCommits={deliveryCommitsFetchStatus === 'fetching'}
              commits={commits}
            />
          )}
          <DeliveryCommitsCompareList
            application={props.thisDelivery?.application || ({} as IReleaseApplication)}
            loading={deliveryCommitsFetchStatus === 'fetching'}
            emptyMessage={'No Commits'}
            commitsAhead={commits?.commits_ahead || []}
            commitsBehind={commits?.commits_behind || []}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  )
}
