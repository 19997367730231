import { CacheKey } from '@auth0/auth0-spa-js'
import Env from '../env/Env'

export const cacheKey = () => {
  const key = new CacheKey({
    clientId: Env.AUTH0_CLIENT_ID,
    audience: Env.AUTH0_AUDIENCE,
    scope: 'openid groups permissions roles profile offline_access'
  })

  return key?.toKey()
}
