interface IConfig {
  AUTH0_CLIENT_ID: string
  AUTH0_CLIENT_DOMAIN: string
  AUTH0_AUDIENCE: string
  AUTH0_CALLBACK: string
  ADMIN_API: string
  API: string
  ENV: string
  AUTH_SERVICE: string
  CUSTOMER_SERVICE: string
  JIRA_URL: string

  /**
   * Datadog's RUM
   */
  RUM_ENABLED: string
  RUM_APPLICATION_ID: string
  RUM_CLIENT_TOKEN: string
  RUM_ENV: string
  RUM_SITE: string
  RUM_SERVICE: string
  RUM_SAMPLE_RATE: string
  RUM_REPLAY_SAMPLE_RATE: string
  RUM_TRACK_INTERACTIONS: string
  RUM_TRACK_LONG_TASKS: string
}

const Env: IConfig = (window as any).dynamic_env as IConfig

export default Env
