import { datadogRum } from '@datadog/browser-rum'
import { AuthClient } from '../auth/AuthClient'
import { cacheKey } from '../auth/AuthCacheKey'
import Env from '../env/Env'

interface RUMUserSessionData {
  skedulo: {
    email: string
    given_name: string
    family_name: string
  }
  [key: string]: unknown
}

export const isDataDogDisabled = Env.RUM_ENABLED !== 'true' || !Env.RUM_APPLICATION_ID || !Env.RUM_CLIENT_TOKEN

/**
 * Only init Datadog if RUM_ENABLED=true
 */
export const initDataDogRum = () => {
  if (isDataDogDisabled) {
    return
  }

  datadogRum.init({
    applicationId: Env.RUM_APPLICATION_ID,
    clientToken: Env.RUM_CLIENT_TOKEN,
    site: Env.RUM_SITE,
    service: Env.RUM_SERVICE,
    env: Env.RUM_ENV,
    sessionSampleRate: Env.RUM_SAMPLE_RATE ? Number(Env.RUM_SAMPLE_RATE) : undefined,
    sessionReplaySampleRate: Env.RUM_REPLAY_SAMPLE_RATE ? Number(Env.RUM_REPLAY_SAMPLE_RATE) : undefined,
    trackUserInteractions: Env.RUM_TRACK_INTERACTIONS === 'true',
    trackLongTasks: Env.RUM_TRACK_LONG_TASKS === 'true'
  })
}

export const logDataDogUserInfo = async () => {
  if (isDataDogDisabled) {
    return
  }

  const fetchedUser = await AuthClient.getUser()
  const tokenString = localStorage.getItem(cacheKey())
  const entry = tokenString ? JSON.parse(tokenString) : null

  if (entry?.body.client_id && fetchedUser?.sub) {
    const userSession: RUMUserSessionData = {
      skedulo: {
        email: fetchedUser?.sub,
        given_name: fetchedUser?.given_name ?? '',
        family_name: fetchedUser?.family_name ?? ''
      }
    }

    datadogRum.setUser(userSession)
  }
}
