import { StatusIcon, StatusIconProps } from '../../../components/StatusCount'

interface IProps {
  status: string
  checkMode: boolean
  explanation?: string
}

const deploymentStatusIconLookup: { [key: string]: StatusIconProps } = {
  successful: {
    statusName: 'Successful',
    color: 'green',
    iconName: 'check circle'
  },
  failed: {
    statusName: 'Failed',
    color: 'red',
    iconName: 'times circle'
  },
  error: {
    statusName: 'Error',
    color: 'red',
    iconName: 'minus circle'
  },
  pending: {
    statusName: 'Pending',
    iconName: 'pause circle'
  },
  waiting: {
    statusName: 'Waiting',
    iconName: 'pause circle'
  },
  running: {
    statusName: 'Running',
    iconName: 'refresh',
    loading: true
  },
  canceled: {
    statusName: 'Cancelled',
    iconName: 'warning sign',
    color: 'orange'
  }
}

const deploymentStatusVariableLookup = (
  checkMode: boolean,
  explanation?: string
): { [key: string]: StatusIconProps } => {
  const successful: StatusIconProps = {
    color: 'blue',
    iconName: 'info circle',
    statusName: 'Successful'
  }
  const failed: StatusIconProps = {
    color: 'red',
    iconName: 'info circle',
    statusName: 'Failed'
  }
  if (explanation) {
    failed.iconName = 'minus circle'
  }
  if (checkMode) {
    return {
      ...deploymentStatusIconLookup,
      successful,
      failed
    }
  }
  if (explanation) {
    return {
      ...deploymentStatusIconLookup,
      failed
    }
  }
  return deploymentStatusIconLookup
}

export const DeploymentStatusIcon = (props: IProps) => (
  <StatusIcon
    status={props.status}
    statusIconPropsByStatus={deploymentStatusVariableLookup(props.checkMode, props.explanation)}
    popupContent={props.status === 'failed' || props.status === 'error' ? props.explanation : undefined}
  />
)
