import React from 'react'
import { Segment, Grid, Icon } from 'semantic-ui-react'
import { useGetDeliveryCheckDefinitionsQuery } from '../../queries/applications/delivery-checks/GetDeliveryCheckDefinitionsQuery'
import {
  createDeliveryCheckTemplate,
  DeliveryCheckTemplate,
  INewDeliveryCheckTemplate,
  patchDeliveryCheckTemplate
} from '../../actions/DeliveryCheck'
import { useGetDeliveryCheckTemplatesQuery } from '../../queries/applications/delivery-checks/GetDeliveryCheckTemplatesQuery'
import { createErrorToast, createSuccessToast } from '../alertComponents/Alert'
import { DeliveryCheckTemplatesList } from './components/DeliveryCheckTemplatesList'
import { CreateDeliveryCheckTemplateModal } from './components/CreateDeliveryCheckTemplateModal'
import { EditDeliveryCheckTemplateModal } from './components/EditDeliveryCheckTemplateModal'

export const DeliveryCheckTemplates = () => {
  const getDeliveryCheckTemplatesQuery = useGetDeliveryCheckTemplatesQuery()
  const getDeliveryCheckDefinitionsQuery = useGetDeliveryCheckDefinitionsQuery()
  const [selectedModal, setSelectedModal] = React.useState<DeliveryCheckTemplate | undefined>(undefined)
  const [openEditModal, setOpenEditModal] = React.useState<boolean>(false)

  const createDeliveryCheckTemplateHook = async (newTemplate: INewDeliveryCheckTemplate) => {
    try {
      await createDeliveryCheckTemplate(newTemplate)
      createSuccessToast(`Succesfully created check template '${newTemplate.name}'.`)
    } catch (error) {
      createErrorToast(error)
      return false
    }
    await getDeliveryCheckTemplatesQuery.refetch()
    return true
  }

  const editDeliveryCheckTemplateHook = async (id: string, template: INewDeliveryCheckTemplate) => {
    try {
      const updatedTemplate = await patchDeliveryCheckTemplate(id, template)
      setSelectedModal(updatedTemplate)
      createSuccessToast(`Succesfully edited check template '${template.name}'.`)
    } catch (error) {
      createErrorToast(error)
      return false
    }
    await getDeliveryCheckTemplatesQuery.refetch()
    return true
  }

  const closeEditModalHook = () => {
    setOpenEditModal(false)
    setSelectedModal(undefined)
  }

  const selectDeliveryCheckTemplate = (template: DeliveryCheckTemplate) => () => {
    setSelectedModal(template)
    setOpenEditModal(true)
  }

  return (
    <Segment color="blue" className="scrollable">
      <EditDeliveryCheckTemplateModal
        open={openEditModal}
        selectedCheckTemplate={selectedModal}
        deliveryCheckDefinitions={getDeliveryCheckDefinitionsQuery.data ?? []}
        closeEditModalHook={closeEditModalHook}
        editDeliveryCheckTemplateHook={editDeliveryCheckTemplateHook}
      />
      <Grid stackable verticalAlign="middle">
        <Grid.Column width={6}>
          <h3>Delivery Check Templates</h3>
        </Grid.Column>
        <Grid.Column textAlign="right" width={10}>
          <Icon
            size="large"
            name="refresh"
            onClick={() => getDeliveryCheckTemplatesQuery.refetch()}
            loading={getDeliveryCheckTemplatesQuery.isLoading}
            className="clickable"
          />
          <CreateDeliveryCheckTemplateModal
            deliveryCheckDefinitions={getDeliveryCheckDefinitionsQuery.data ?? []}
            createDeliveryCheckTemplateHook={createDeliveryCheckTemplateHook}
          />
        </Grid.Column>
      </Grid>
      <DeliveryCheckTemplatesList
        templates={getDeliveryCheckTemplatesQuery.data ?? []}
        isLoading={getDeliveryCheckTemplatesQuery.isLoading}
        openDeliveryCheckTemplateModalHook={selectDeliveryCheckTemplate}
      />
    </Segment>
  )
}
