import { useMemo } from 'react'
import { useGetCustomerConnectionsQuery } from '../../queries/GetCustomerConnectionsQuery'
import { useGetCustomerQuery } from '../../queries/GetCustomerQuery'
import { getDisplayedLoginOptionType } from '../../actions/Utils'
import Breadcrumbs from './Breadcrumbs'
import { BreadcrumbItem } from './DynamicBreadcrumbs'

interface LoginOptionBreadcrumbWrapperProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  initialBreadcrumbs: BreadcrumbItem[]
  customerId: string
  loginOptionId: string
}

/**
 * Team Details breadcrumb for new customer model.
 *
 * Retrieves customer and team information and builds final step of breadcrumb.
 */
const LoginOptionBreadcrumbWrapper = ({
  initialBreadcrumbs,
  customerId,
  loginOptionId
}: LoginOptionBreadcrumbWrapperProps) => {
  const { data: customer } = useGetCustomerQuery(customerId ?? '')
  const { data: customerConnections } = useGetCustomerConnectionsQuery(customerId)
  const loginOption = customerConnections?.find(connection => connection.id === loginOptionId)

  const breadcrumbItems = useMemo(() => {
    if (!loginOption?.id || !customer?.name) {
      return initialBreadcrumbs
    }
    return [
      ...initialBreadcrumbs,
      {
        path: `/customers/${customer.id}`,
        name: customer.name
      } as BreadcrumbItem,
      {
        path: `/customers/${customer.id}/login-options/${loginOptionId}`,
        name: getDisplayedLoginOptionType(loginOption.type)
      } as BreadcrumbItem
    ]
  }, [customerConnections, loginOption, customer, initialBreadcrumbs, loginOptionId])

  return <Breadcrumbs items={breadcrumbItems} />
}

export default LoginOptionBreadcrumbWrapper
