import { Button, Form, Icon, Input, Label, Modal, Popup } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { LoginConnectionsConfig, VendorPrefix } from '../../../actions/NewTeams'
import { VendorOptions } from '../services/LoginConnectionsService'
import { RadioGroup } from '../../../components/RadioGroup/RadioGroup'

interface AddLoginModalProps {
  handleChange: (inputField: string) => (value: string) => void
  newLoginConnection: LoginConnectionsConfig
  vendorOptions: VendorOptions[]
  reset: () => void
  handleCreate: () => void
  loading?: boolean
  disabled?: boolean
  validEndpoint: boolean
  validConnectionName: boolean
  validLoginConnection: boolean
}

const endpointErrorMessage = {
  salesforce: 'Must start with a letter, contain no white spaces and be a valid URL without a protocol or path.',
  community: 'Must start with a letter, contain no white spaces and be a valid URL.'
}

const AddLoginModal = ({
  handleChange,
  newLoginConnection,
  vendorOptions,
  reset,
  handleCreate,
  loading = false,
  disabled = false,
  validEndpoint,
  validConnectionName,
  validLoginConnection
}: AddLoginModalProps) => {
  const { value: open, setTrue: openModal, setFalse: closeModal } = useBoolean(false)

  const vendorPrefix: VendorPrefix =
    vendorOptions.find(vendor => vendor.value === newLoginConnection.vendor)?.prefix || 'sf-'
  const endpointPrefix = 'https://'

  const handleClose = () => {
    reset()
    closeModal()
  }

  const handleSubmit = () => {
    handleCreate()
    closeModal()
  }

  return (
    <Modal
      data-testid="add-login-modal"
      closeIcon
      open={open}
      trigger={
        <Button
          className={'form-button-sked-blue'}
          content={'Add New Login Option'}
          onClick={openModal}
          disabled={disabled}
          loading={loading}
        />
      }
      onClose={handleClose}
      onOpen={openModal}
    >
      <Modal.Header>Add New Login Option</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field required>
            <label htmlFor="displayName">Login Button Text</label>
            <Input
              id="displayName"
              name="displayName"
              placeholder="Enter login button text"
              value={newLoginConnection.displayName}
              onChange={(event, { value }) => handleChange('displayName')(value)}
              fluid
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="vendor">Vendor</label>
            <RadioGroup
              options={vendorOptions}
              name="vendor"
              value={newLoginConnection.vendor}
              handleInput={(event, { value }) => handleChange('vendor')(value as string)}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="endpoint">
              {newLoginConnection.vendor === 'salesforce' ? 'Hostname ' : 'Endpoint'}
              {newLoginConnection.vendor === 'salesforce' && (
                <Popup
                  trigger={<Icon name="question circle" style={{ margin: 0 }} />}
                  content={
                    'Hostname is the Salesforce URL without the protocol or path. For example, the hostname of "https://dev.my.salesforce.com/page" is "dev.my.salesforce.com".'
                  }
                  basic
                />
              )}
            </label>
            {newLoginConnection.vendor === 'salesforce' && (
              <div className="create-team-note">
                {'Production orgs: '}
                <kbd>
                  <i>&lt;orgDomainName&gt;</i>.my.salesforce.com
                </kbd>
                <br />
                {'Sandbox orgs: '}
                <kbd>
                  <i>&lt;orgDomainName--SandboxName&gt;</i>.sandbox.my.salesforce.com
                </kbd>
              </div>
            )}
            <Input
              id="endpoint"
              name="endpoint"
              placeholder={
                newLoginConnection.vendor === 'salesforce'
                  ? 'Enter connection hostname'
                  : 'Enter connection endpoint (without https://)'
              }
              label={newLoginConnection.vendor === 'salesforce' ? undefined : endpointPrefix}
              value={newLoginConnection.instance}
              onChange={(event, { value }) => handleChange('instance')(value)}
              fluid
            />
            {newLoginConnection.instance && !validEndpoint && (
              <Label pointing color="red" name="endpoint error label">
                {newLoginConnection.vendor === 'salesforce'
                  ? endpointErrorMessage.salesforce
                  : endpointErrorMessage.community}
              </Label>
            )}
          </Form.Field>
          <Form.Field required>
            <label htmlFor="name">Connection Name</label>
            <Input
              id="name"
              name="name"
              placeholder="Enter connection name (without salesforce prefix)"
              label={vendorPrefix}
              value={newLoginConnection.name}
              onChange={(event, { value }) => handleChange('name')(value)}
              fluid
            />
            {newLoginConnection.name && !validConnectionName && (
              <Label pointing color="red" name="connection name error label">
                Name already used in this team.
              </Label>
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="add-login-submit"
          className="form-button-sked-blue"
          content="Add Login Connection"
          onClick={handleSubmit}
          disabled={!validLoginConnection}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default AddLoginModal
