import dayjs from 'dayjs'

export const SKEDULO_DATE_FORMAT = 'MMM D, YYYY'

export const formatDate = (date: string, dateFormat: string = SKEDULO_DATE_FORMAT) => {
  if (!dayjs(date).isValid()) {
    throw new Error(`[Parse Error] Cannot format invalid date: ${date}.`)
  }
  return dayjs(date).format(dateFormat)
}

export const formatDateTimeToHumanFriendly = (unixtime: number | undefined) =>
  unixtime ? new Date(unixtime * 1000).toLocaleString() : ''
