/* eslint-disable @typescript-eslint/quotes */
import {
  Button,
  ButtonProps,
  DropdownItemProps,
  DropdownProps,
  Form,
  Grid,
  Header,
  InputOnChangeData,
  Label
} from 'semantic-ui-react'
import { IRegionInfo } from 'src/actions/Regions'
import { upperCase } from 'lodash'
import { SearchableSelector } from '../../../../formComponents/Selectors'
import { tooltip } from '../CreateTeamForm'
import { RegionFlag } from '../../../../../utils/regionFlags'
import { RadioGroup, RadioGroupOption } from '../../../../../components/RadioGroup/RadioGroup'

interface CustomerFormSectionProps {
  title: string
  customerOptions: DropdownItemProps[]
  addCustomer: boolean
  internalCustomer: boolean
  customerName: string
  customerId: string
  customerSalesforceId: string
  handleCustomerInput: (
    event: React.SyntheticEvent<HTMLElement>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => void
  toggleInternalCustomer: () => void
  toggleAddCustomer: () => void
  customerNameIsValid: boolean
  customerIdIsValid: boolean
  checkingCustomer: boolean
  handleCustomerSearch: (_: any, data: InputOnChangeData) => void
  regions: IRegionInfo[]
  selectedRegion: string
}

const CustomerFormSection = ({
  title,
  customerOptions,
  addCustomer,
  internalCustomer,
  customerName,
  customerId,
  customerSalesforceId,
  handleCustomerInput,
  toggleInternalCustomer,
  toggleAddCustomer,
  customerIdIsValid,
  customerNameIsValid,
  checkingCustomer,
  handleCustomerSearch,
  regions,
  selectedRegion
}: CustomerFormSectionProps) => {
  const customerNameFieldWidth = internalCustomer ? 9 : 5
  const addCustomerFieldWidth = addCustomer ? 3 : 4
  const regionOptions: RadioGroupOption[] = regions.map(option => ({
    value: upperCase(option.region),
    text: option.name,
    display: <RegionFlag region={option.region} />
  }))

  return (
    <>
      <Header as="h3">{title}</Header>

      {addCustomer && customerName !== '' && !checkingCustomer && !customerNameIsValid && (
        <Label color="red">Customer name must be unique.</Label>
      )}
      {addCustomer && !internalCustomer && customerSalesforceId !== '' && !customerIdIsValid && (
        <Label color="red">Customer ID must be a valid salesforce ID.</Label>
      )}
      <Grid columns={16} padded="vertically" verticalAlign="bottom" stackable>
        {addCustomer ? (
          <>
            <Form.Field width={customerNameFieldWidth} required>
              <label htmlFor="customerName">Customer name</label>
              <Form.Input
                id="customerName"
                name="customerName"
                value={customerName}
                onChange={handleCustomerInput}
                placeholder="Enter new customer name"
                error={customerName !== '' && !customerNameIsValid}
                fluid
              />
            </Form.Field>
            {!internalCustomer && (
              <Form.Field width={4} required>
                <label htmlFor="customerSalesforceId">
                  Account ID {tooltip(`The Account ID of the customers record in Skedulo's Salesforce CRM system`)}
                </label>
                <Form.Input
                  id="customerSalesforceId"
                  name="customerSalesforceId"
                  value={customerSalesforceId}
                  onChange={handleCustomerInput}
                  placeholder="Enter account ID"
                  error={customerSalesforceId !== '' && !customerIdIsValid}
                  fluid
                />
              </Form.Field>
            )}
            <Form.Field width={4}>
              <label htmlFor="isInternal">
                Internal customer?{' '}
                {tooltip(
                  'If this customer is for internal purposes only, and is not a Skedulo customer, change this to "Yes"'
                )}
              </label>
              <Form.Checkbox
                toggle
                id="isInternal"
                name="isInternal"
                checked={internalCustomer}
                onClick={toggleInternalCustomer}
                label={internalCustomer ? 'Yes' : 'No'}
                style={{ paddingTop: '8px' }}
              />
            </Form.Field>
          </>
        ) : (
          <Form.Field width={12} required>
            <label htmlFor="customer">Customer</label>
            <SearchableSelector
              id="customer"
              key={customerId}
              name="customer"
              value={customerId}
              options={customerOptions}
              onChange={handleCustomerInput}
              placeholder="Select customer"
              onSearchChange={(_e, data) => handleCustomerSearch({}, { value: data.searchQuery })}
            />
          </Form.Field>
        )}
        <Grid.Column
          width={addCustomerFieldWidth}
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            paddingLeft: '0'
          }}
        >
          {!addCustomer && <div style={{ paddingRight: '14px', fontSize: '13px' }}>or</div>}
          <Button
            data-testid="add-customer-button"
            onClick={toggleAddCustomer}
            icon={addCustomer && 'close'}
            content={addCustomer ? undefined : 'Add new customer'}
          ></Button>
        </Grid.Column>
        {addCustomer && (
          <Form.Field required width={11}>
            <label htmlFor="region">Customer region</label>
            <RadioGroup
              options={regionOptions}
              name="region"
              value={selectedRegion || ''}
              handleInput={handleCustomerInput}
            />
          </Form.Field>
        )}
      </Grid>
    </>
  )
}

export default CustomerFormSection
