import { adminApi } from './Endpoints'

export interface IComponent {
  id: string
  componentName: string
}

export const getComponent = async (componentName: string): Promise<IComponent> => {
  const response = await adminApi.get<IComponent>(`/components/${componentName}`)
  return response.data
}

export const getComponents = async (): Promise<IComponent[]> => {
  const response = await adminApi.get<IComponent[]>('/components')
  return response.data
}

export const createComponent = async (newComponentName: string): Promise<IComponent> => {
  const response = await adminApi.post<IComponent>('/components', { componentName: newComponentName })
  return response.data
}

export const deleteComponent = async (componentName: string): Promise<void> => {
  const response = await adminApi.delete<void>(`/components/${componentName}`)
  return response.data
}
