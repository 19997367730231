import { useQuery } from '@tanstack/react-query'
import { TenantWithNameAndRegion } from 'src/actions/Tenants'
import { expensiveQueryConfig } from '../views/Utils'
import { getFeaturesForTenants, TenantWithFeaturesNameAndRegion } from '../views/tenants/FeatureFlagReport'

export const useGetFeatureFlagReportQuery = (tenantsData?: TenantWithNameAndRegion[]) =>
  useQuery<TenantWithFeaturesNameAndRegion[]>(
    ['tenants', 'features', tenantsData],
    () => (tenantsData ? getFeaturesForTenants(tenantsData) : []),
    {
      enabled: !!tenantsData,
      ...expensiveQueryConfig,
      staleTime: Infinity
    }
  )
