import { Form } from 'semantic-ui-react'
import {
  EMAIL_PASSWORD,
  LoginOptionType,
  SsoType,
  SSO,
  OPEN_ID_CONNECT
} from '../../../../../views/newCustomers/services/Constants'
import { CreateCustomerConnectionService } from '../../../services/CreateCustomerConnectionService'
import EmailPasswordLoginOption from './EmailPasswordLoginOption'
import OpenIDConnectLoginOption from './OpenIDConnectLoginOption'

interface LoginOptionDetailsProps {
  loginOptionType: LoginOptionType
  selectedSSO: SsoType
  createCustomerConnectionService: CreateCustomerConnectionService
}

const displayExtraSSOForm = (
  selectedSSO: SsoType,
  createCustomerConnectionService: CreateCustomerConnectionService
) => {
  if (selectedSSO === OPEN_ID_CONNECT) {
    return <OpenIDConnectLoginOption createCustomerConnectionService={createCustomerConnectionService} />
  }

  return <p>You can not add this SSO login option yet.</p>
}

const displayExtraForm = (
  loginOptionType: LoginOptionType,
  selectedSSO: SsoType,
  createCustomerConnectionService: CreateCustomerConnectionService
) => {
  switch (loginOptionType) {
    case EMAIL_PASSWORD:
      return <EmailPasswordLoginOption createCustomerConnectionService={createCustomerConnectionService} />
    case SSO:
      return displayExtraSSOForm(selectedSSO, createCustomerConnectionService)
    default:
      return <p>You can not add this login option yet.</p>
  }
}

const LoginOptionDetailsForm = ({
  loginOptionType,
  selectedSSO,
  createCustomerConnectionService
}: LoginOptionDetailsProps) => (
  <Form>
    <Form.Field width={16} error={false}>
      <label htmlFor="primaryName">Login option type</label>
      <div className="create-team-note">
        {loginOptionType} {selectedSSO && `— ${selectedSSO}`}
      </div>
    </Form.Field>
    {displayExtraForm(loginOptionType, selectedSSO, createCustomerConnectionService)}
  </Form>
)

export default LoginOptionDetailsForm
