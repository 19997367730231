import React from 'react'
import { Segment, Grid, Icon } from 'semantic-ui-react'
import { createComponent, deleteComponent, IComponent } from '../../actions/Components'
import { useGetComponentsQuery } from '../../queries/applications/component/GetComponentsQuery'
import { createErrorToast, createSuccessToast } from '../alertComponents/Alert'
import { ComponentsList } from './components/ComponentsList'
import { CreateComponentsModal } from './components/CreateComponentModal'
import { DeleteComponentModal } from './components/DeleteComponentModal'

export const Components = () => {
  const getComponentsQuery = useGetComponentsQuery()
  const [selectedModal, setSelectedModal] = React.useState<IComponent | undefined>(undefined)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

  const openDeleteModalHook = (component: IComponent) => () => {
    setSelectedModal(component)
    setOpenDeleteModal(true)
  }

  const closeDeleteModalHook = () => {
    setOpenDeleteModal(false)
    setSelectedModal(undefined)
  }

  const deleteComponentHook = async (componentName: string) => {
    try {
      await deleteComponent(componentName)
      createSuccessToast(`Succesfully deleted component '${componentName}'.`)
    } catch (error) {
      createErrorToast(error)
      return false
    }
    await getComponentsQuery.refetch()
    return true
  }

  const createComponentHook = async (componentName: string) => {
    try {
      await createComponent(componentName)
      createSuccessToast(`Succesfully created component '${componentName}'.`)
    } catch (error) {
      createErrorToast(error)
      return false
    }
    await getComponentsQuery.refetch()
    return true
  }

  return (
    <Segment color="blue" className="scrollable">
      <DeleteComponentModal
        selectedComponent={selectedModal}
        closeDeleteModalHook={closeDeleteModalHook}
        deleteComponentHook={deleteComponentHook}
        open={openDeleteModal}
      />
      <Grid stackable verticalAlign="middle">
        <Grid.Column width={6}>
          <h3>Components</h3>
        </Grid.Column>
        <Grid.Column textAlign="right" width={10}>
          <Icon
            size="large"
            name="refresh"
            onClick={() => getComponentsQuery.refetch()}
            loading={getComponentsQuery.isLoading}
            className="clickable"
          />
          <CreateComponentsModal createComponentHook={createComponentHook} />
        </Grid.Column>
      </Grid>
      <ComponentsList
        components={getComponentsQuery.data || []}
        isLoading={getComponentsQuery.isLoading}
        openDeleteModalHook={openDeleteModalHook}
      />
    </Segment>
  )
}
