import _ from 'lodash'
import React from 'react'
import { Button, Grid, Modal } from 'semantic-ui-react'
import { IComponent } from '../../../actions/Components'

interface IProps {
  open: boolean
  closeDeleteModalHook: () => void
  deleteComponentHook: (componentName: string) => Promise<boolean>
  selectedComponent: IComponent | undefined
}

export const DeleteComponentModal = (props: IProps) => {
  const [submitting, setSubmitting] = React.useState(false)

  const confirmDeleteComponent = async () => {
    if (!_.isUndefined(props.selectedComponent)) {
      setSubmitting(true)
      if (await props.deleteComponentHook(props.selectedComponent?.componentName)) {
        props.closeDeleteModalHook() // close if successful
      }
      setSubmitting(false)
    }
  }

  return (
    <Modal closeIcon open={props.open} size="mini" onClose={props.closeDeleteModalHook}>
      <Modal.Header>Delete Component</Modal.Header>
      <Modal.Content>
        Are you sure you want to delete component <b>{props.selectedComponent?.componentName}</b>?
      </Modal.Content>
      <Modal.Actions>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Button
                content="Delete"
                negative
                loading={submitting}
                disabled={submitting}
                fluid
                onClick={confirmDeleteComponent}
              />
            </Grid.Column>
            <Grid.Column>
              <Button content="Cancel" onClick={props.closeDeleteModalHook} fluid />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}
