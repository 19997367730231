import { useState } from 'react'
import { BaseSSOExtraMethods } from '../CreateCustomerConnectionService'

export interface OpenIDConnectMethods extends BaseSSOExtraMethods {
  setDiscoveryUrl: (discoveryUrl: string) => void
  setClientId: (clientId: string) => void
  setClientSecret: (clientSecret: string) => void
  setScopes: (scopes: string[]) => void
}

export interface OpenIDConnectFields {
  discoveryUrl: string
  clientId: string
  clientSecret?: string
  scopes: string[]
}

export interface CreateOpenIDConnectionService {
  methods: OpenIDConnectMethods
  fields: OpenIDConnectFields
}

export const useOpenIDConnectFieldService = (): CreateOpenIDConnectionService => {
  const [discoveryUrl, setDiscoveryUrl] = useState('')
  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [scopes, setScopes] = useState(['openid', 'profile', 'email'])

  const isValidExtraFields = discoveryUrl !== '' && clientId !== ''

  const resetExtraForm = () => {
    setDiscoveryUrl('')
    setClientId('')
    setClientSecret('')
    setScopes(['openid', 'profile', 'email'])
  }

  const fields: OpenIDConnectFields = { discoveryUrl, clientId, clientSecret, scopes }
  const methods: OpenIDConnectMethods = {
    setDiscoveryUrl,
    setClientId,
    setClientSecret,
    setScopes,
    resetExtraForm,
    isValidExtraFields
  }

  return { methods, fields }
}
