import { useState, useEffect } from 'react'
import { useUpdateCustomerLoginOptionMutation } from '../../../mutations/UpdateCustomerLoginOptionMutation'
import {
  CustomerConnection,
  IUpdateCustomerConnection,
  OpenIDCustomerConnectionMetadata,
  UpdateCustomerConnectionConfig
} from '../../../actions/CustomerConnections'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { createErrorToast } from '../../alertComponents/Alert'
import { CUSTOMER_LOGIN_TYPE_REQUEST, EMAIL_PASSWORD, OPEN_ID_CONNECT } from './Constants'

export interface UpdateCustomerConnectionService {
  runUpdateCustomerLoginOption: () => void
  isValidCustomerLoginOption: boolean
  isUpdatingCustomerLoginOption: boolean
  setIsResetForm: (isResetForm: boolean) => void
  setDescription: (description: string) => void
  description: string
  setIsEditable: (isEditable: boolean) => void
  isEditable: boolean
  isSaveButtonEnabled: boolean
  metadataFormValues: Partial<OpenIDCustomerConnectionMetadata>
  setMetadataFormValues: (metadata: Partial<OpenIDCustomerConnectionMetadata>) => void
}

export const validateCustomerLoginOptionRequest = (
  type: string,
  loginOption: CustomerConnection,
  description: string,
  metadataFormValues: Partial<OpenIDCustomerConnectionMetadata>
): boolean => {
  const { displayName, metadata } = loginOption

  if (type === CUSTOMER_LOGIN_TYPE_REQUEST[EMAIL_PASSWORD]) {
    return description !== '' && displayName !== description
  } else if (type === CUSTOMER_LOGIN_TYPE_REQUEST[OPEN_ID_CONNECT]) {
    return (
      (description !== '' && displayName !== description) ||
      ('discoveryUrl' in metadataFormValues &&
        metadataFormValues.discoveryUrl !== '' &&
        metadataFormValues.discoveryUrl !== metadata.discoveryUrl) ||
      ('clientId' in metadataFormValues &&
        metadataFormValues.clientId !== '' &&
        metadataFormValues.clientId !== metadata.clientId) ||
      ('scopes' in metadataFormValues && metadataFormValues.scopes?.toString() !== metadata.scopes?.toString()) ||
      metadataFormValues.clientSecret !== metadata.clientSecret
    )
  }
  return false
}

export const generateMetadataRequest = (
  type: string,
  loginOption: CustomerConnection,
  metadataFormValues: Partial<OpenIDCustomerConnectionMetadata>
): Partial<OpenIDCustomerConnectionMetadata> => {
  const metadataRequest: Partial<OpenIDCustomerConnectionMetadata> = {}
  const { metadata } = loginOption

  if (type === CUSTOMER_LOGIN_TYPE_REQUEST[OPEN_ID_CONNECT]) {
    if (metadataFormValues.discoveryUrl !== metadata.discoveryUrl) {
      metadataRequest.discoveryUrl = metadataFormValues.discoveryUrl
    }
    if (metadataFormValues.clientId !== metadata.clientId) {
      metadataRequest.clientId = metadataFormValues.clientId
    }
    if (metadataFormValues.clientSecret !== metadata.clientSecret) {
      metadataRequest.clientSecret = metadataFormValues.clientSecret
    }
    if (metadataFormValues.scopes?.toString() !== metadata.scopes?.toString()) {
      metadataRequest.scopes = metadataFormValues.scopes
    }
  }
  return metadataRequest
}

export const setupUpdateCustomerLoginPayload = (
  type: string,
  customerId: string,
  connectionId: string,
  description: string,
  metadataRequest: Partial<OpenIDCustomerConnectionMetadata>
): IUpdateCustomerConnection => {
  if (type === CUSTOMER_LOGIN_TYPE_REQUEST[OPEN_ID_CONNECT] && metadataRequest) {
    return {
      type,
      customerId,
      connectionId,
      region: 'US', // at the moment, we only support US region
      displayName: description,
      metadata: metadataRequest
    }
  }

  return {
    type,
    customerId,
    connectionId,
    region: 'US', // at the moment, we only support US region
    displayName: description
  }
}

export const useUpdateCustomerConnectionService = (
  loginOption: CustomerConnection
): UpdateCustomerConnectionService => {
  const { customerId, id: connectionId, displayName, type, metadata } = loginOption
  const { mutate: updateCustomerLoginOptionMutation, isLoading: updateCustomerLoginOptionLoading } =
    useUpdateCustomerLoginOptionMutation(() => {
      setIsEditable(false)
    })
  const { data: regionsInfo } = useGetRegionQuery()

  const [description, setDescription] = useState(displayName)
  const [metadataFormValues, setMetadataFormValues] = useState(metadata)
  const [isResetForm, setIsResetForm] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  useEffect(() => {
    if (isResetForm) {
      resetForm()
      setIsResetForm(false)
    }
  }, [isResetForm])

  const resetForm = () => {
    setDescription(displayName)
    setMetadataFormValues(metadata)
    setIsEditable(false)
  }

  const isValidCustomerLoginOption = validateCustomerLoginOptionRequest(
    type,
    loginOption,
    description,
    metadataFormValues
  )

  const runUpdateCustomerLoginOption = () => {
    if (isValidCustomerLoginOption) {
      const metadataRequest = generateMetadataRequest(type, loginOption, metadataFormValues)
      const request: IUpdateCustomerConnection = setupUpdateCustomerLoginPayload(
        type,
        customerId,
        connectionId,
        description,
        metadataRequest
      )
      const api =
        regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === request.region?.toLowerCase())?.server
          .api ?? ''

      const updateCustomerConnectionConfig: UpdateCustomerConnectionConfig = { api, request }
      updateCustomerLoginOptionMutation(updateCustomerConnectionConfig)
    } else {
      createErrorToast('Problem updating login connection, please ensure all fields are valid or try again.')
    }
  }

  return {
    runUpdateCustomerLoginOption,
    isValidCustomerLoginOption,
    isUpdatingCustomerLoginOption: updateCustomerLoginOptionLoading,
    setIsResetForm,
    setDescription,
    description,
    isEditable,
    setIsEditable,
    isSaveButtonEnabled: isValidCustomerLoginOption,
    metadataFormValues,
    setMetadataFormValues
  }
}
