import { Metadata } from '../services/DynamicMetadataService'
import { customerService, customerServiceConfig } from './Endpoints'
import { DynamoQuery, DynamoQueryResponse, IResult } from './Utils'

export type Customer = InternalCustomer | ExternalCustomer

export interface InternalCustomer extends BaseCustomer {
  isInternal: true
  salesforceId: undefined
}

export interface ExternalCustomer extends BaseCustomer {
  isInternal: false
  salesforceId: string
}

export interface BaseCustomer {
  id: string
  name: string
  metadata: Metadata
  region?: string
  updatedBy: string
  createdBy: string
  createdAt: string
  updatedAt: string
}

export type PatchCustomer = Partial<NewCustomer>

export type NewCustomer = NewInternalCustomer | NewExternalCustomer

export interface NewCustomerConfig {
  api?: string
  customer: NewCustomer
}
interface NewExternalCustomer extends NewBaseCustomer {
  isInternal: false
  salesforceId: string
}

interface NewInternalCustomer extends NewBaseCustomer {
  isInternal: true
}

interface NewBaseCustomer {
  name: string
  metadata: Record<string, any>
  region: string
}

export const getCustomers = (query: DynamoQuery) =>
  customerService.post<DynamoQueryResponse<Customer>>('/admin/customer/search', query).then(response => response.data)

export const getCustomerById = (id: string) =>
  customerService.get<IResult<Customer>>(`/admin/customer/${id}`).then(response => response.data.result)

export const getCustomerByName = (name: string) =>
  customerService
    .get<IResult<Customer>>('/admin/customer/name', { params: { name } })
    .then(response => response.data.result)

export const createCustomer = (customerConfig: NewCustomerConfig) => {
  if (customerConfig.api) {
    return customerService
      .post<IResult<Customer>>('/admin/customer', customerConfig.customer, {
        ...customerServiceConfig,
        baseURL: `${customerConfig.api}/authorization`
      })
      .then(response => response.data.result)
  }
  return customerService
    .post<IResult<Customer>>('/admin/customer', customerConfig.customer)
    .then(response => response.data.result)
}

export const updateCustomer = (id: string) => (patchCustomer: PatchCustomer) =>
  customerService
    .patch<IResult<Customer>>(`/admin/customer/${id}`, patchCustomer)
    .then(response => response.data.result)

export const deleteCustomer = (id: string) =>
  customerService.delete(`/admin/customer/${id}`).then(response => response.data)
