import {
  IPackage,
  IPackageTenantCount,
  IPackageVersion,
  IPackageVersionWithTenantCount,
  IPackageWithTenantCount
} from '../../../actions/Package'

export interface PackageTenantQuery {
  packageName: string
  packageVersion?: string
}

export const combinePackageWithTenantCount = (
  pkg: IPackage | undefined,
  tenantCounts: IPackageTenantCount[]
): IPackageWithTenantCount | undefined => {
  if (pkg) {
    const matchingTenantCount = tenantCounts.find(tenantCount => tenantCount.packageName === pkg.packageName)

    // If there's a matching tenant count, combine the objects, otherwise, use default values
    const combinedObject: IPackageWithTenantCount = {
      ...pkg,
      ...(matchingTenantCount || { packageVersion: undefined, count: 0 })
    }

    return combinedObject
  }
  return undefined
}

export const generatePackageTenantQuery = (pkg: IPackage | undefined): PackageTenantQuery[] => {
  if (pkg) {
    return [
      {
        packageName: pkg.packageName
      }
    ]
  }
  return []
}

export const combinePackageVersionsArrays = (
  packageVersions: IPackageVersion[],
  tenantCounts: IPackageTenantCount[]
): IPackageVersionWithTenantCount[] =>
  packageVersions.map(packageVersionInfo => {
    const matchingTenantCount = tenantCounts.find(
      tenantCount =>
        tenantCount.packageName === packageVersionInfo.packageName &&
        tenantCount.packageVersion === packageVersionInfo.version
    )

    // If there's a matching tenant count, combine the objects, otherwise, use default values
    const combinedObject: IPackageVersionWithTenantCount = {
      ...packageVersionInfo,
      ...(matchingTenantCount || { packageVersion: undefined, count: 0 })
    }

    return combinedObject
  })

export const generatePackageVersionTenantQueries = (packageVersions: IPackageVersion[]): PackageTenantQuery[] =>
  packageVersions.map(pkg => ({
    packageName: pkg.packageName,
    packageVersion: pkg.version
  }))

export const combinePackageArrays = (
  packages: IPackage[],
  tenantCounts: IPackageTenantCount[]
): IPackageWithTenantCount[] =>
  packages.map(packageInfo => {
    const matchingTenantCount = tenantCounts.find(tenantCount => tenantCount.packageName === packageInfo.packageName)

    // If there's a matching tenant count, combine the objects, otherwise, use default values
    const combinedObject: IPackageWithTenantCount = {
      ...packageInfo,
      ...(matchingTenantCount || { packageVersion: undefined, count: 0 })
    }

    return combinedObject
  })

export const generatePackageTenantQueries = (packages: IPackage[]): PackageTenantQuery[] =>
  packages.map(pkg => ({
    packageName: pkg.packageName
  }))

export const combinePackageVersionWithTenantCount = (
  packageVersion: IPackageVersion | undefined,
  tenantCounts: IPackageTenantCount[]
): IPackageVersionWithTenantCount | undefined => {
  if (packageVersion) {
    const matchingTenantCount = tenantCounts.find(
      tenantCount =>
        tenantCount.packageName === packageVersion.packageName && tenantCount.packageVersion === packageVersion.version
    )

    // If there's a matching tenant count, combine the objects, otherwise, use default values
    const combinedObject: IPackageVersionWithTenantCount = {
      ...packageVersion,
      ...(matchingTenantCount || { packageVersion: undefined, count: 0 })
    }

    return combinedObject
  }
  return undefined
}

export const generatePackageVersionTenantQuery = (
  packageVersion: IPackageVersion | undefined
): PackageTenantQuery[] => {
  if (packageVersion) {
    return [
      {
        packageName: packageVersion.packageName,
        packageVersion: packageVersion.version
      }
    ]
  }
  return []
}
