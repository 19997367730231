import { AxiosError } from 'axios'
import { useQueries, UseQueryResult } from '@tanstack/react-query'
import { getConnectionDetails, IAuthConnectionDetails } from '../actions/Teams'
import { createErrorToast } from '../views/alertComponents/Alert'
import { AuthConnections } from '../actions/NewTeams'

export const useGetConnectionsDetailsQuery =
  (teamId: string) =>
  (connections: AuthConnections, api: string): UseQueryResult<IAuthConnectionDetails>[] =>
    useQueries({
      queries: connections.map(connection => ({
        queryKey: [teamId, 'connection', connection.name],
        queryFn: () => getConnectionDetails(connection.name, api),
        onError: (error: AxiosError) => createErrorToast(error)
      }))
    })
