import { Checkbox, Table } from 'semantic-ui-react'
import { IEnvironmentDetails } from '../../../actions/Environments'

interface IProps {
  environments: IEnvironmentDetails[]
  editable: boolean
  editedEnvironments: IEnvironmentDetails[]
  templateEnvironments: IEnvironmentDetails[]
  createCheckboxHandler: (environment: IEnvironmentDetails) => () => void
}

export const AvailableEnvironmentsTable = (props: IProps) => {
  const renderAvailable = (environment: IEnvironmentDetails) => {
    const testEnvironments = props.editable ? props.editedEnvironments : props.templateEnvironments || []
    const checked = testEnvironments.some(templateEnvironment => templateEnvironment.id === environment.id)
    return (
      <Checkbox
        toggle
        checked={checked}
        onClick={props.createCheckboxHandler(environment)}
        disabled={!props.editable}
      />
    )
  }
  return (
    <Table basic="very" style={{ width: '80%', margin: 'auto' }} textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Environment</Table.HeaderCell>
          <Table.HeaderCell>Instance</Table.HeaderCell>
          <Table.HeaderCell>Namespace</Table.HeaderCell>
          <Table.HeaderCell>Available</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.environments.map(environment => (
          <Table.Row key={environment.id}>
            <Table.Cell>{environment.environment}</Table.Cell>
            <Table.Cell>{environment.instance}</Table.Cell>
            <Table.Cell>{environment.name}</Table.Cell>
            <Table.Cell>{renderAvailable(environment)}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
