import { Row } from 'react-table'
import { Checkbox, CheckboxProps } from 'semantic-ui-react'
import { createMemoizedColumns, Table } from '../../../components/TableComponents/ReactTable'
import { IDeliveryCheckDefinition } from '../../../actions/DeliveryCheck'

interface IProps {
  checkDefinitions: IDeliveryCheckDefinition[]
  editable: boolean
  templateCheckTypes: Set<string>
  createCheckboxHandler: (
    checkDefinition: IDeliveryCheckDefinition
  ) => (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void
}

export const DeliveryCheckDefinitionsTable = (props: IProps) => {
  const renderEnabled = (checkDefinition: IDeliveryCheckDefinition) => {
    const checked = props.templateCheckTypes.has(checkDefinition.type)
    return (
      <Checkbox
        toggle
        checked={checked}
        onChange={props.createCheckboxHandler(checkDefinition)}
        disabled={!props.editable}
      />
    )
  }

  const columns = createMemoizedColumns<IDeliveryCheckDefinition>(
    [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Expiry (ms)',
        accessor: 'expiryMilliseconds'
      },
      {
        Header: 'Enabled',
        Cell: ({ row }: { row: Row<IDeliveryCheckDefinition> }) => renderEnabled(row.original)
      }
    ],
    [props.templateCheckTypes, props.editable]
  )

  return (
    <Table<IDeliveryCheckDefinition>
      columns={columns}
      data={props.checkDefinitions}
      loading={false}
      emptyMessage={'No check definitions found.'}
      color="blue"
    />
  )
}
