import * as React from 'react'
import { useRouterProps } from '../router/RouterProps'
import { ISearch, IViewSearchProps } from './Utils'

interface SearchValues {
  teamSearchValue: ISearch
  customerSearchValue: ISearch
  mobileReleasesSearchValue: ISearch
  tenantSearchValue: ISearch
  releasenotesSearchValue: ISearch
}

export const emptySearchValue: ISearch = { searchValue: '', searchCategory: 'all' }

const initSearchValues: SearchValues = {
  teamSearchValue: emptySearchValue,
  customerSearchValue: emptySearchValue,
  mobileReleasesSearchValue: emptySearchValue,
  tenantSearchValue: emptySearchValue,
  releasenotesSearchValue: emptySearchValue
}

export const Searchable = ({
  Component,
  searchValueName
}: {
  Component: React.ComponentClass<IViewSearchProps> | ((props: IViewSearchProps) => JSX.Element)
  searchValueName: string
}) => {
  const [searchValues, setSearchValues] = React.useState<SearchValues>(initSearchValues)

  const handleSearchChange = (value?: ISearch) => {
    setSearchValues(prevValues => ({ ...prevValues, [searchValueName + 'SearchValue']: value || emptySearchValue }))
  }

  const routerProps = useRouterProps()
  return (
    <Component
      handleSearchChange={handleSearchChange}
      searchValue={searchValues[(searchValueName + 'SearchValue') as keyof SearchValues]}
      location={routerProps.location}
      navigate={routerProps.navigate}
      params={routerProps.params}
    />
  )
}
