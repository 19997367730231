import * as _ from 'lodash'
import * as React from 'react'
import { Breadcrumb, Divider, Grid, Header, Icon, Menu } from 'semantic-ui-react'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getReleaseNotesPage, IReleaseNotesPage } from '../../actions/Confluence'
import { getReleaseTickets, IJiraTicketDetails } from '../../actions/Jira'
import { completeMobileRelease, getMobileBuilds, IBuild, reviewMobileRelease } from '../../actions/Mobile'
import { getReleaseApplication, getRelease, IRelease, IReleaseApplication } from '../../actions/Releases'
import { createErrorToast } from '../alertComponents/Alert'
import { SimpleModal } from '../modalComponents/SimpleModal'
import { ReleaseInformation } from '../releases/Components/ReleaseInformation'
import { TicketsList } from '../releases/Components/TicketsList'
import { logError } from '../../analytics/error-log'
import { RouterProps } from '../../router/RouterProps'
import { BuildsList } from './components/BuildsList'

interface IState {
  release?: IRelease
  application?: IReleaseApplication
  tickets: IJiraTicketDetails[]
  builds: IBuild[]
  loadingTableData: boolean
  releaseNotesPage?: IReleaseNotesPage
}

export class MobileReleaseDetails extends React.PureComponent<RouterProps, IState> {
  constructor(props: RouterProps) {
    super(props)
    this.state = {
      tickets: [],
      builds: [],
      loadingTableData: false
    }
  }

  async componentDidMount() {
    this.setState({ loadingTableData: true })
    await this.loadPageData()
    this.setState({ loadingTableData: false })
  }

  async componentDidUpdate(prevProps: RouterProps) {
    if (!_.isEqual(prevProps.params, this.props.params)) {
      this.setState({
        release: undefined,
        application: undefined,
        loadingTableData: true
      })
      await this.loadPageData()
      this.setState({ loadingTableData: false })
    }

    const mobileReleaseDetailTitle = this.formatTitleFromApplicationName()
    document.title = formatDocumentTitle(mobileReleaseDetailTitle)
  }

  formatTitleFromApplicationName() {
    if (this.state.application) {
      return `${this.state.application?.applicationName} Release Details`
    }

    return 'Release Details'
  }

  async loadPageData() {
    try {
      await this.loadRelease()
      await this.loadApplication()
    } catch (error) {
      createErrorToast(error)
      logError(error)
    }
    await Promise.all(
      [this.loadJiraTickets(), this.loadReleaseNotesPage(), this.loadBuilds()].map(request =>
        request.catch(e => {
          createErrorToast(e)
          logError(e)
        })
      )
    )
  }

  loadRelease = async () => {
    const applicationName = this.props.params.applicationName
    const version = this.props.params.version
    if (applicationName !== undefined && version !== undefined) {
      const release = await getRelease(applicationName, version)
      this.setState({ release })
    }
  }

  loadApplication = async () => {
    const { release } = this.state
    if (!_.isUndefined(release)) {
      const application = await getReleaseApplication(release.applicationName)
      this.setState({ application })
    }
  }

  loadJiraTickets = async () => {
    const { application, release } = this.state
    if (!_.isUndefined(release) && !_.isUndefined(application)) {
      const tickets = await getReleaseTickets('v' + release.version, application.component, application.applicationName)
      this.setState({ tickets })
    }
  }

  loadBuilds = async () => {
    const { application, release } = this.state
    if (!_.isUndefined(release) && !_.isUndefined(application)) {
      const builds = await getMobileBuilds(release.applicationName, release.version)
      this.setState({ builds })
    }
  }

  loadReleaseNotesPage = async () => {
    const { application, release } = this.state
    if (!_.isUndefined(release) && !_.isUndefined(application)) {
      try {
        const releaseNotesPage = await getReleaseNotesPage(application.component, `v0${release.version}`)
        this.setState({ releaseNotesPage })
      } catch (error: any) {
        if (error.isAxiosError && error.response && error.response.status !== 404) {
          throw error
        }
      }
    }
  }

  makeReleaseNotesFn = (version: string) => () => reviewMobileRelease(version)

  completeReleaseFn = (version: string) => () => completeMobileRelease(version)

  render() {
    const applicationName = (this.state.application || { applicationName: '' }).applicationName
    const version = (this.state.release || { version: 'invalid' }).version
    return (
      <div className="route-component">
        <Menu secondary fluid stackable>
          <Menu.Menu position="left">
            <Menu.Item>
              <Breadcrumb>
                <Breadcrumb.Section className="back-button" onClick={() => this.props.navigate(-1)}>
                  <Icon name="chevron left" size="big" />
                  Back
                </Breadcrumb.Section>
              </Breadcrumb>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <SimpleModal
                submitFn={this.makeReleaseNotesFn(version)}
                header={'Make Release Notes'}
                content={`Are you sure you want to generate release notes for ${applicationName} version ${version}.`}
                successMessage={`Successfully generated release notes for ${applicationName} version ${version}.`}
                buttonClassName="form-button-sked-blue"
              />
            </Menu.Item>
            <Menu.Item>
              <SimpleModal
                submitFn={this.completeReleaseFn(version)}
                header={'Complete Release'}
                content={`Are you sure you want to complete the release for ${applicationName} version ${version}.`}
                successMessage={`Successfully completed the release for ${applicationName} version ${version}.`}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Grid stackable style={{ height: 'calc(100% - 50px)' }}>
          <Grid.Column width={5} style={{ height: '100%' }}>
            <ReleaseInformation
              runningApplications={[]}
              deployable={false}
              release={this.state.release}
              application={this.state.application}
              loading={this.state.loadingTableData}
            />
          </Grid.Column>
          <Grid.Column width={11} style={{ height: '100%' }} stretched className="scrollable">
            <Header as="h3" style={{ flexGrow: 0 }}>
              Tickets
            </Header>
            <TicketsList tickets={this.state.tickets} loading={this.state.loadingTableData} />
            <Divider />
            <Header as="h3" style={{ flexGrow: 0 }}>
              Builds
            </Header>
            <BuildsList builds={this.state.builds} loading={this.state.loadingTableData} />
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
