import { Popup, Table } from 'semantic-ui-react'
import humanizeDuration from 'humanize-duration'
import { useEffect, useMemo } from 'react'
import { PodInfo } from '../../actions/Applications'
import { useDeletePodMutation } from '../../mutations/applications/DeletePod'
import { TableLoaderWrap } from '../tableComponents/TableLoader'
import { NewDeleteModal } from '../modalComponents/NewDeleteModal'
import { createSuccessToast } from '../alertComponents/Alert'
import { PodStatusIcon } from './PodStatusCount'

interface IProps {
  pods: PodInfo[]
  loading: boolean
  multi?: boolean
}

const podAge = (podStartDate: Date) => {
  const duration = podStartDate.valueOf() - Date.now().valueOf()
  return humanizeDuration(duration, { units: ['d', 'h', 'm', 's'], largest: 2, round: true })
}

const podStatusLookup = (podStatus: string) => {
  switch (podStatus) {
    case 'ErrImagePull':
      return 'imagepull'
    case 'CrashLoopBackoff':
      return 'error'
    default:
      return podStatus.toLowerCase()
  }
}

const PodRow = (props: { key: string; pod: PodInfo; multi?: boolean }) => {
  const podStartDate = useMemo(() => new Date(props.pod.creationTimestamp), [props.pod.creationTimestamp])
  const { mutate: deletePod, isLoading: isDeleting, isSuccess } = useDeletePodMutation()
  useEffect(() => {
    if (isSuccess) {
      createSuccessToast(`Successfully deleted pod ${props.pod.name}`)
    }
  }, [isSuccess, props.pod.name])
  return (
    <Table.Row>
      {props.multi && (
        <>
          <Table.Cell>{props.pod.namespace}</Table.Cell>
          <Table.Cell>{props.pod.instance}</Table.Cell>
        </>
      )}
      <Table.Cell>{props.pod.name}</Table.Cell>
      <Table.Cell>{props.pod.primaryVersion}</Table.Cell>
      <Table.Cell>
        <Popup trigger={<span>{podAge(podStartDate)}</span>}>{props.pod.creationTimestamp}</Popup>
      </Table.Cell>
      <Table.Cell>
        <PodStatusIcon status={podStatusLookup(props.pod.status)} />
      </Table.Cell>
      <Table.Cell>{props.pod.containers.length}</Table.Cell>
      <Table.Cell>
        <NewDeleteModal
          deleteMethod={async () =>
            deletePod({
              application: props.pod.application,
              environment: props.pod.namespace,
              instance: props.pod.instance,
              name: props.pod.name
            })
          }
          content={`Are you sure you want to delete pod ${props.pod.name}`}
          isDeleting={isDeleting}
          type="Pod"
        />
      </Table.Cell>
    </Table.Row>
  )
}
export const PodsTable = (props: IProps) => (
  <Table aria-label="Pod List">
    <Table.Header>
      <Table.Row>
        {props.multi && (
          <>
            <Table.HeaderCell>Environment</Table.HeaderCell>
            <Table.HeaderCell>Instance</Table.HeaderCell>
          </>
        )}
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Tag</Table.HeaderCell>
        <Table.HeaderCell>Age</Table.HeaderCell>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.HeaderCell>Containers</Table.HeaderCell>
        <Table.HeaderCell>Delete</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <TableLoaderWrap loading={props.loading} array={props.pods} emptyMessage="No Pods Found">
      <Table.Body>
        {props.pods.map(pod => (
          <PodRow key={pod.name} pod={pod} multi={props.multi} />
        ))}
      </Table.Body>
    </TableLoaderWrap>
  </Table>
)
