import { useQuery } from '@tanstack/react-query'
import { TeamWithFeatures, getFeaturesForTeams } from '../views/newTeams/NewFeatureFlagReport'
import { expensiveQueryConfig } from '../views/Utils'
import { TeamWithApi } from '../views/newCustomers/component/CustomerUsers'

export const useGetFeatureFlagReportQuery = (teamsData?: TeamWithApi[]) =>
  useQuery<TeamWithFeatures[]>(['teams', 'features'], () => getFeaturesForTeams(teamsData!), {
    enabled: !!teamsData,
    ...expensiveQueryConfig
  })
