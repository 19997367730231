import { elasticserver } from './Endpoints'
import { IResult } from './Utils'

export interface IPermissions {
  [objectName: string]: IObjectPermissions
}

export interface IObjectPermissions {
  read: boolean
  create: boolean
  update: boolean
  delete: boolean
  fields: {
    [fieldName: string]: IFieldPermissions
  }
}

export interface IFieldPermissions {
  read: boolean
  create: boolean
  update: boolean
}

export type PermissionSet =
  | 'Skedulo_API_User'
  | 'Baseline_Permission_Set'
  | 'Community_Resource_Permission_Set'
  | 'Resource_Permission_Set'
  | 'Resource_Permission_Set_Without_Assigned_Apps'
  | 'Scheduler_Permission_Set'
  | 'View_All_Permission_Set'

export const getBasePermissions = (tenantId: string, permissionSet: PermissionSet): Promise<IPermissions> =>
  elasticserver
    .get<IResult<IPermissions>>(`/onboarding/internal/permissionsets/${tenantId}/${permissionSet}`)
    .then(permissions => permissions.data.result)

/**
 * Get user permissions for a given tenant
 *
 * @param userId
 * @param tenantId
 */
export const getUserPermissions = (userId: string, tenantId: string): Promise<IPermissions> =>
  elasticserver
    .get<IResult<IPermissions>>(`/custom/internal/permissions/user/${encodeURI(userId)}/tenant/${encodeURI(tenantId)}`)
    .then(permissions => permissions.data.result)
