import { useGetTeamNamesQuery } from '../../../queries/GetTeamNamesQuery'
import { useDeleteTeamNameMutation } from '../../../mutations/DeleteTeamNameMutation'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { createSuccessToast } from '../../../views/alertComponents/Alert'

/**
 * Interface for table data.
 */
export interface TeamNamesData {
  teamName: string
}

export interface TeamNamesServiceConfig {
  teamId: string
}

export interface TeamNamesService {
  /**
   * GET /authorization/admin/team/[id]/name returns array of team names.
   */
  data: string[]
  /**
   * Team's primary name retrieved from team GET /authorization/admin/team/[id] team query.
   */
  primaryName: string
  isLoading: boolean
  isDeleting: boolean
  deleteTeamName: (teamName: string) => void
}

export const useTeamNamesService = ({ teamId }: TeamNamesServiceConfig): TeamNamesService => {
  const { data: teamInformation, isLoading: isLoadingTeamInfo } = useGetTeamQuery(teamId)
  const { data, isLoading } = useGetTeamNamesQuery(teamId)
  const { mutate, isLoading: isDeleting } = useDeleteTeamNameMutation(teamId)(() =>
    createSuccessToast('Deleted team name.')
  )

  const deleteTeamName = (teamName: string) => mutate(teamName)

  return {
    data: data ?? [],
    primaryName: teamInformation?.primaryName ?? '',
    isLoading: isLoading || isLoadingTeamInfo,
    isDeleting,
    deleteTeamName
  }
}
