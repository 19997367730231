import { useEffect, useState } from 'react'
import { useBoolean, useDebounce } from 'usehooks-ts'

import { convertSalesforceId, validateSalesforceId } from '../utils/salesforce'
import {
  ExternalCustomer,
  getCustomerByName,
  InternalCustomer,
  NewCustomer,
  NewCustomerConfig
} from '../actions/NewCustomers'
import { IRegionInfo } from '../actions/Regions'
import { createErrorToast } from '../views/alertComponents/Alert'
import { useCreateCustomerMutation } from '../mutations/CreateCustomerMutation'
import { useGetRegionQuery } from '../queries/GetRegionsQuery'

export interface CreateCustomerService {
  createCustomer: (newCustomerConfig: NewCustomerConfig) => void
  createCustomerAsync: (newCustomerConfig: NewCustomerConfig) => Promise<InternalCustomer | ExternalCustomer>
  setCustomerName: (name: string) => void
  setCustomerSalesforceId: (id: string) => void
  setSelectedRegion: (region: string) => void
  toggleCustomerIsInternal: () => void
  toggleModalIsOpen: () => void
  checkingCustomerName: boolean
  customer: NewCustomer
  customerName: string
  customerSalesforceId: string
  customerIsInternal: boolean
  customerNameIsValid: boolean
  customerSalesforceIdIsValid: boolean
  customerIsValid: boolean
  creatingCustomer: boolean
  debouncedCustomerName: string
  modalIsOpen: boolean
  regionsInfo: IRegionInfo[]
  selectedRegion: string
}

const validateCustomerName = async (debouncedCustomerName: string, setCustomerIsValid: (valid: boolean) => void) => {
  if (debouncedCustomerName) {
    try {
      await getCustomerByName(debouncedCustomerName)
      setCustomerIsValid(false)
    } catch (error: any) {
      if (error.isAxiosError && error.response?.status === 404) {
        setCustomerIsValid(true)
      } else {
        createErrorToast(error)
      }
    }
  } else {
    setCustomerIsValid(false)
  }
}

interface ValidateCustomerParams {
  customerName: string
  debouncedCustomerName: string
  customerSalesforceId: string
  customerIsInternal: boolean
  checkingCustomerName: boolean
  customerNameIsValid: boolean
  customerSalesforceIdIsValid: boolean
}

const validateCustomer = ({
  customerName,
  debouncedCustomerName,
  customerIsInternal,
  checkingCustomerName,
  customerNameIsValid,
  customerSalesforceIdIsValid
}: ValidateCustomerParams) =>
  customerName === debouncedCustomerName &&
  (customerSalesforceIdIsValid || customerIsInternal) &&
  !checkingCustomerName &&
  customerNameIsValid

export const useCreateCustomerService = (): CreateCustomerService => {
  const {
    mutate: createCustomerMutation,
    mutateAsync: createCustomerMutationAsync,
    isLoading: createCustomerLoading
  } = useCreateCustomerMutation(() => {
    resetForm()
    toggleModalIsOpen()
  })
  const { data: regionsInfo } = useGetRegionQuery()
  const [selectedRegion, setSelectedRegion] = useState('')

  const [customerName, setCustomerName] = useState('')
  const [customerSalesforceId, setCustomerSalesforceId] = useState('')

  const {
    value: customerIsInternal,
    toggle: toggleCustomerIsInternal,
    setFalse: setCustomerIsInternalFalse
  } = useBoolean(false)
  const { value: modalIsOpen, toggle: toggleModalIsOpen } = useBoolean(false)
  const { value: customerNameIsValid, setValue: setCustomerIsValid } = useBoolean(true)
  const { value: checkingCustomerName, setValue: setCheckingCustomerName } = useBoolean(false)

  const debouncedCustomerName = useDebounce(customerName, 500)

  useEffect(() => {
    setCheckingCustomerName(true)
    validateCustomerName(debouncedCustomerName, setCustomerIsValid).then(_ => setCheckingCustomerName(false))
  }, [debouncedCustomerName])

  const resetForm = () => {
    setSelectedRegion('')
    setCustomerName('')
    setCustomerSalesforceId('')
    setCustomerIsInternalFalse()
    setCustomerIsValid(true)
  }

  const customerSalesforceIdIsValid = validateSalesforceId(customerSalesforceId)
  const customerIsValid = validateCustomer({
    customerName,
    debouncedCustomerName,
    customerSalesforceId,
    customerIsInternal,
    checkingCustomerName,
    customerNameIsValid,
    customerSalesforceIdIsValid
  })

  const customer: NewCustomer = customerIsInternal
    ? {
        name: customerName,
        isInternal: true,
        metadata: {},
        region: selectedRegion ? selectedRegion.toUpperCase() : ''
      }
    : {
        name: customerName,
        isInternal: false,
        salesforceId:
          customerSalesforceId.length === 15 ? convertSalesforceId(customerSalesforceId) : customerSalesforceId,
        metadata: {},
        region: selectedRegion ? selectedRegion.toUpperCase() : ''
      }

  return {
    createCustomer: createCustomerMutation,
    createCustomerAsync: createCustomerMutationAsync,
    setCustomerName,
    setCustomerSalesforceId,
    setSelectedRegion,
    toggleCustomerIsInternal,
    toggleModalIsOpen,
    checkingCustomerName,
    customer,
    customerName,
    customerSalesforceId,
    customerIsInternal,
    customerNameIsValid,
    customerSalesforceIdIsValid,
    customerIsValid,
    creatingCustomer: createCustomerLoading,
    debouncedCustomerName,
    modalIsOpen,
    regionsInfo: regionsInfo || [],
    selectedRegion
  }
}
