import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'
import { patchDeploymentTemplate } from '../../actions/Admin'
import { IEnvironmentDetails } from '../../actions/Environments'

export const useUpdateDeploymentTemplateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (props: {
      templateName: string
      environments: IEnvironmentDetails[]
      applicationName: string
      pinConfiguration: boolean
    }) =>
      patchDeploymentTemplate(props.templateName, props.environments, props.applicationName, props.pinConfiguration),
    {
      onSuccess: (data, template) => {
        createSuccessToast(`Deployment template ${template.templateName} updated successfully.`)
        queryClient.setQueryData(['deploymentTemplate', template.templateName], data)
        queryClient.invalidateQueries(['deploymentTemplates'])
      },
      onError: error => {
        createErrorToast(error)
      }
    }
  )
}
