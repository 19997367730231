import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Customer, deleteCustomer } from '../actions/NewCustomers'
import { createErrorToast } from '../views/alertComponents/Alert'

export const useDeleteCustomerMutation = (customerId: string) => (onSuccessDeleteFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Customer, AxiosError>(() => deleteCustomer(customerId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['customer', customerId])
      queryClient.invalidateQueries(['customers'])
      onSuccessDeleteFn()
    },
    onError: (error: AxiosError) => {
      createErrorToast(error)
    }
  })
}
