import React, { useState } from 'react'
import { Button, Checkbox, Input, InputOnChangeData, Modal } from 'semantic-ui-react'
import { Delivery } from '../../../actions/Deliveries'
import { DeliveryCompareSelectorTable } from './DeliveryCompareSelectorTable'

interface IProps {
  deliveries?: Delivery[]
  primeDeliveryIndex: number
  handleSelectDelivery: (index: number) => void
}

export const DeliveryCompareSelectorModal = (props: IProps) => {
  const [open, setOpen] = useState(false)
  const [approvedOnly, setApprovedOnly] = useState(false)
  const [versionFilter, setVersionFilter] = useState('')
  const [tagFilter, setTagFilter] = useState('')

  const toggleOpen = (evt: React.MouseEvent) => {
    evt.stopPropagation()
    setOpen(!open)
  }

  const rowSelect = (index: number) => () => {
    props.handleSelectDelivery(index)
    setOpen(!open)
  }

  const handleVersionSearchChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void => {
    setVersionFilter(data.value)
  }

  const handleTagSearchChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData): void => {
    setTagFilter(data.value)
  }

  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      trigger={
        <Button onClick={toggleOpen} disabled={!props.deliveries?.length} size="medium" compact>
          {'Select Delivery'}
        </Button>
      }
    >
      <Modal.Header>Select Delivery</Modal.Header>
      <Modal.Content>
        <Input label="Version" icon="search" value={versionFilter} onChange={handleVersionSearchChange} />
        <Input
          style={{ marginLeft: '1rem' }}
          label="Tag"
          icon="search"
          value={tagFilter}
          onChange={handleTagSearchChange}
        />
        <Checkbox
          toggle
          label="Approved Only"
          style={{ marginLeft: '1rem' }}
          checked={approvedOnly}
          onChange={(evt, data) => {
            setApprovedOnly(!!data.checked)
          }}
        />
        <DeliveryCompareSelectorTable
          deliveries={props.deliveries}
          approvedOnly={approvedOnly}
          primeDeliveryIndex={props.primeDeliveryIndex}
          handleRowSelection={rowSelect}
          version={versionFilter}
          tag={tagFilter}
        />
      </Modal.Content>
    </Modal>
  )
}
