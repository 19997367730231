import { useQuery } from '@tanstack/react-query'
import { getDeploymentTemplateDetails, getDeploymentTemplates } from '../../actions/Admin'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useDeploymentTemplateQuery = (templateName?: string, isEnabled?: boolean) =>
  useQuery({
    queryKey: ['deploymentTemplate', templateName],
    queryFn: () => getDeploymentTemplateDetails(templateName!),
    enabled: (isEnabled ?? true) && !!templateName,
    onError: error => createErrorToast(error)
  })

export const useDeploymentTemplatesQuery = () =>
  useQuery({
    queryKey: ['deploymentTemplates'],
    queryFn: () => getDeploymentTemplates(),
    onError: error => createErrorToast(error)
  })
