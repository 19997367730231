import { Header, Table } from 'semantic-ui-react'
import { startCase } from 'lodash'
import { ReactElement } from 'react'

export interface EditableDisplayFieldBaseProps {
  fieldName: string
  headerTitle?: string
  editable: boolean
  DisplayComponent: ReactElement
  EditComponent: ReactElement
  showField: (editable: boolean) => boolean
  isEditableTable?: boolean
}

export const EditableDisplayBaseField = ({
  headerTitle,
  fieldName,
  editable,
  DisplayComponent,
  EditComponent,
  showField,
  isEditableTable = false
}: EditableDisplayFieldBaseProps) =>
  showField(editable) ? (
    isEditableTable ? (
      <Table.Row key="EditableTeamDisplayBaseField" className="feature">
        <Table.Cell style={{ backgroundColor: '#f9fafb', paddingLeft: '10px' }}>
          <Header size="tiny"> {headerTitle || startCase(fieldName)}</Header>
        </Table.Cell>
        <Table.Cell style={{ padding: '10px' }}>{editable ? EditComponent : DisplayComponent}</Table.Cell>
      </Table.Row>
    ) : (
      <>
        <Header size="tiny">{headerTitle || startCase(fieldName)}:</Header>
        {editable ? EditComponent : DisplayComponent}
      </>
    )
  ) : (
    <></>
  )
