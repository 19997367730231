import { Form, Button, Radio, ButtonProps, DropdownProps, InputOnChangeData } from 'semantic-ui-react'

export interface RadioGroupOption {
  value: string
  logo?: any
  text: string
  display?: any
}
export interface RadioGroupProps {
  options: RadioGroupOption[]
  name: string
  value: string
  handleInput: (
    _event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: InputOnChangeData | DropdownProps | ButtonProps
  ) => void
}

export const RadioGroup = (props: RadioGroupProps) => (
  <Form.Group style={{ flexWrap: 'wrap' }}>
    {props.options.map(option => (
      <Form.Field key={option.value} style={{ marginBottom: '5px' }}>
        <Button
          className={props.value === option.value ? 'radio selected' : 'radio'}
          data-testid={`radio-${props.name}`}
          onClick={props.handleInput}
          name={props.name}
          value={option.value}
          basic
          fluid
        >
          <Radio
            label={
              <label>
                {option.logo && <>{option.logo}</>}
                {option.text}
                {option.display && <>&nbsp;&nbsp;{option.display}</>}
              </label>
            }
            name={`${props.name}CheckboxGroup`}
            value={option.value}
            checked={props.value === option.value}
          />
        </Button>
      </Form.Field>
    ))}
  </Form.Group>
)
