import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { patchReleaseApplication } from '../../actions/Admin'
import { IReleaseApplication } from '../../actions/Releases'

export const usePatchReleaseApplicationMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (props: { applicationName: string; patchApplication: Partial<IReleaseApplication> }) =>
      patchReleaseApplication(props.applicationName, props.patchApplication),
    onSuccess: (_data, props) => {
      queryClient.invalidateQueries(['releaseApplication', props.applicationName])
      queryClient.invalidateQueries(['releaseApplicationDetails'])
      queryClient.invalidateQueries(['releaseApplications'])
    },
    onError: error => {
      createErrorToast(error)
    }
  })
}
