import { useDynamicMetadataService } from '../../../services/DynamicMetadataService'
import { useUpdateTeamMutation } from '../../../mutations/UpdateTeamMutation'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { DynamicMetadataTable } from '../../../components/TableComponents/DynamicMetadataTable'
import { Team } from '../../../actions/NewTeams'

const TeamMetadataTable = ({ teamId, customerId }: { teamId: string; customerId: string }) => {
  const dynamicMetadataService = useDynamicMetadataService<Team>({
    updateMutation: useUpdateTeamMutation(teamId),
    getQuery: useGetTeamQuery(teamId),
    getMetadataFromObject: team => team?.metadata,
    makeObjectFromMetadata: metadata => ({ metadata }),
    tableTitle: 'Metadata',
    isEditable: true
  })
  return <DynamicMetadataTable {...dynamicMetadataService} />
}

export default TeamMetadataTable
