import * as React from 'react'
import { Button, DropdownProps, Grid, Icon, InputOnChangeData, Modal, Popup, SearchProps } from 'semantic-ui-react'
import { formatDocumentTitle } from '../../utils/documentTitleUtils'
import { getRegions, IRegionInfo, IRegions, toRegions } from '../../actions/Regions'
import { getTeams, ITeam } from '../../actions/Teams'
import { createErrorToast } from '../alertComponents/Alert'
import { SearchInput } from '../searchComponents/SearchInput'
import { IViewSearchProps } from '../Utils'
import { NewTeamForm } from './components/NewTeamForm'
import { TeamsList } from './components/TeamsList'

interface IState {
  teams: ITeam[]
  regions: IRegions
  regionInfos: IRegionInfo[]
  loading: boolean
  deleting: boolean
  addTeamModalOpen: boolean
}

const title = 'Teams'

export default class Teams extends React.PureComponent<IViewSearchProps, IState> {
  TEAMS_PER_PAGE = 20

  constructor(props: IViewSearchProps) {
    super(props)
    this.state = {
      teams: [],
      regions: {},
      regionInfos: [],
      loading: true,
      addTeamModalOpen: false,
      deleting: false
    }
  }

  handleAddTeamModal = () => {
    this.setState(prevState => ({ addTeamModalOpen: !prevState.addTeamModalOpen }))
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await Promise.all([this.fetchTeams(), this.fetchRegions()])
    document.title = formatDocumentTitle(title)
    this.setState({ loading: false })
  }

  fetchTeams = (): Promise<void> =>
    getTeams()
      .then(teams => this.setState({ teams }))
      .catch(createErrorToast)

  fetchRegions = async (): Promise<void> => {
    try {
      const regionInfos = await getRegions()
      this.setState({
        regionInfos,
        regions: toRegions(regionInfos)
      })
    } catch (error) {
      createErrorToast(error)
    }
  }

  handleSearchChange: SearchProps['onChange'] = (event: React.ChangeEvent, data: InputOnChangeData) => {
    const newSearch = { searchValue: data.value, searchCategory: this.props.searchValue.searchCategory }
    this.props.handleSearchChange(newSearch)
  }

  handleSearchCategoryChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    const newSearch = { searchValue: this.props.searchValue.searchValue, searchCategory: data.value as string }
    this.props.handleSearchChange(newSearch)
  }

  handleRowClick = (id: string) => () => this.props.navigate(`/old-model/teams/${id}`)

  render() {
    const { teams, regions, loading: fetching } = this.state
    const { searchValue } = this.props

    const searchOptions = [
      { text: 'All', value: 'all' },
      { text: 'Name', value: 'name' },
      { text: 'Description', value: 'description' },
      { text: 'Customer', value: 'customerName' },
      { text: 'Region', value: 'region' },
      { text: 'Team ID', value: 'id' },
      { text: 'Tenant ID', value: 'tenants' }
    ]

    return (
      <div className="route-component legacy-page">
        <Grid columns={2} stackable verticalAlign="middle">
          <Grid.Column>
            <Grid verticalAlign="middle">
              <Grid.Column>
                <Icon size="large" name="refresh" onClick={this.fetchTeams} loading={fetching} className="clickable" />
              </Grid.Column>
              <Grid.Column>
                <SearchInput
                  searchValue={searchValue}
                  searchOptions={searchOptions}
                  handleSearchChange={this.handleSearchChange}
                  handleSearchCategoryChange={this.handleSearchCategoryChange}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Modal
              open={this.state.addTeamModalOpen}
              onClose={this.handleAddTeamModal}
              closeOnDimmerClick={false}
              closeIcon
              size="large"
              trigger={
                <Popup
                  content="Please navigate to the new customer model page to add new team."
                  position="top center"
                  style={{
                    opacity: 0.9,
                    padding: '1rem',
                    textAlign: 'center'
                  }}
                  inverted
                  trigger={
                    <span>
                      <Button
                        disabled
                        onClick={this.handleAddTeamModal}
                        float="right"
                        primary
                        className="form-button-sked-blue"
                      >
                        Add Team
                      </Button>
                    </span>
                  }
                />
              }
            >
              <Modal.Header id="modal-header-add-team">Add Team</Modal.Header>
              <Modal.Content id="modal-content-add-team">
                <NewTeamForm
                  regions={this.state.regionInfos}
                  handleAddTeamModal={this.handleAddTeamModal}
                  fetchTeams={this.fetchTeams}
                />
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid>
        <TeamsList
          teams={teams}
          regions={regions}
          searchValue={searchValue}
          handleRowClick={this.handleRowClick}
          loading={fetching}
        />
      </div>
    )
  }
}
