import { StatusCount, StatusIconProps } from '../../../components/StatusCount'

const deploymentStatusIconLookup: { [key: string]: StatusIconProps } = {
  failed: {
    statusName: 'Failed',
    color: 'red',
    iconName: 'times circle'
  },
  successful: {
    statusName: 'Successful',
    color: 'green',
    iconName: 'check circle'
  },
  running: {
    statusName: 'In Progress',
    color: 'yellow',
    iconName: 'clock'
  },
  missing: {
    statusName: 'Missing',
    color: 'orange',
    iconName: 'question circle'
  }
}

export const DeliveryDeploymentStatusCount = (props: { status: string; count: number }) => (
  <StatusCount status={props.status} count={props.count} statusIconPropsByStatus={deploymentStatusIconLookup} />
)
