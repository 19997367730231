import Breadcrumbs from './Breadcrumbs'
import CustomerBreadcrumbWrapper from './CustomerBreadcrumbWrapper'
import FeatureFlagBreadcrumbWrapper from './FeatureFlagBreadcrumbWrapper'
import LoginOptionBreadcrumbWrapper from './LoginOptionBreadcrumbWrapper'
import TeamBreadcrumbWrapper from './TeamBreadcrumbWrapper'

export interface BreadcrumbItem {
  name: string
  path: string
}

interface DynamicBreadcrumbsProps {
  /**
   * Predefined breadcrumb items with a valid router path, and name
   */
  initialBreadcrumbs: BreadcrumbItem[]
  customerId?: string
  teamId?: string
  featureFlag?: string
  loginOptionId?: string
}

/**
 * Dynamic Breadcrumb wrapper to handle data driven breadcrumbs.
 *
 * Defaults to static breadcrumbs defined in `initialBreadcrumbs` prop.
 * Otherwise initialBreadcrumbs is any initial static breadcrumbs defined in the parent.
 */
const DynamicBreadcrumbs = ({
  initialBreadcrumbs,
  customerId,
  teamId,
  featureFlag,
  loginOptionId
}: DynamicBreadcrumbsProps) => {
  if (!customerId && !teamId && !featureFlag && !loginOptionId) {
    return <Breadcrumbs items={initialBreadcrumbs} />
  }

  if (loginOptionId && customerId) {
    return (
      <LoginOptionBreadcrumbWrapper
        initialBreadcrumbs={initialBreadcrumbs}
        customerId={customerId}
        loginOptionId={loginOptionId}
      />
    )
  }

  if (customerId) {
    return <CustomerBreadcrumbWrapper initialBreadcrumbs={initialBreadcrumbs} customerId={customerId} />
  }

  if (teamId) {
    return <TeamBreadcrumbWrapper initialBreadcrumbs={initialBreadcrumbs} teamId={teamId} />
  }

  if (featureFlag) {
    return <FeatureFlagBreadcrumbWrapper initialBreadcrumbs={initialBreadcrumbs} featureFlag={featureFlag} />
  }

  return null
}

export default DynamicBreadcrumbs
