import { useQuery } from '@tanstack/react-query'
import { getRelease, getReleases } from '../../actions/Releases'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useReleasesQuery = () =>
  useQuery({
    queryKey: ['releases'],
    queryFn: () => getReleases(),
    onError: error => {
      createErrorToast(error)
    }
  })

export const useReleaseQuery = (applicationName: string, version: string) =>
  useQuery({
    queryKey: ['release', applicationName, version],
    queryFn: () => getRelease(applicationName, version),
    onError: error => {
      createErrorToast(error)
    }
  })
