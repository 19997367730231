import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { logError } from '../../../analytics/error-log'
import { completeRelease, IRelease } from '../../../actions/Releases'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'

interface IProps {
  release?: IRelease
}

interface IState {
  open: boolean
  loading: boolean
}

export class CompleteReleaseModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      open: false,
      loading: false
    }
  }

  toggleOpen = () => {
    this.setState(prevProps => ({ open: !prevProps.open }))
  }

  handleCompleteRelease = async () => {
    if (this.props.release) {
      this.setState({ loading: true })
      try {
        await completeRelease(this.props.release.applicationName, this.props.release.version)
        this.toggleOpen()
        createSuccessToast('Completed Release Successfully')
      } catch (error) {
        logError(error)
        createErrorToast(error)
        this.toggleOpen()
      }
      this.setState({ loading: false })
    }
  }

  render() {
    const release = this.props.release || ({} as IRelease)
    return (
      <Modal
        open={this.state.open}
        onClose={this.toggleOpen}
        size="mini"
        trigger={<Button content="Complete Release" onClick={this.toggleOpen} disabled={!this.props.release} />}
      >
        <Modal.Header>Complete Release</Modal.Header>
        <Modal.Content>
          <p>
            Are you sure you want to complete release for {release.applicationName} version {release.version}.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button name="no" negative content="No" onClick={this.toggleOpen} />
          <Button name="yes" positive content="Yes" onClick={this.handleCompleteRelease} loading={this.state.loading} />
        </Modal.Actions>
      </Modal>
    )
  }
}
