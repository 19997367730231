import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'
import { validateSalesforceId } from '../../../utils/salesforce'
import { createSuccessToast } from '../../alertComponents/Alert'
import { useGetTeamQuery } from '../../../queries/GetTeamQuery'
import { useResetTeamMutation } from '../../../mutations/ResetTeamMutation'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { validateUserEmail } from './CreateTeamService'

export interface ResetTeamServiceConfig {
  teamId: string
  redirectFn: () => void
}

export interface ResetTeamService {
  resetSalesforceTeam: () => void
  resetStandaloneTeam: () => void
  isResetting: boolean
  standaloneUserInput: StandaloneUserInput
  salesforceId: string
  setSalesforceId: (salesforceId: string) => void
  showSalesforceIdError: boolean
  showEmailError: boolean
  setStandaloneUserInput: (standaloneUserInput: StandaloneUserInput) => void
  modalIsOpen: boolean
  toggleModalIsOpen: () => void
  isReadySalesforceReset: boolean
  isReadyStandaloneReset: boolean
}

export interface StandaloneUserInput {
  initialStandaloneUser: {
    email: string
    firstName: string
    lastName: string
  }
}

export interface SalesforceUserInput {
  salesforceId: string
}

export type IResetTeamInput = StandaloneUserInput | SalesforceUserInput

export const useResetTeamService = ({ teamId, redirectFn }: ResetTeamServiceConfig): ResetTeamService => {
  const { data: team } = useGetTeamQuery(teamId)
  const { data: regionsInfo } = useGetRegionQuery()
  const { value: modalIsOpen, toggle: toggleModalIsOpen } = useBoolean(false)
  const [salesforceId, setSalesforceId] = useState('')
  const [standaloneUserInput, setStandaloneUserInput] = useState<StandaloneUserInput>({
    initialStandaloneUser: {
      email: '',
      firstName: '',
      lastName: ''
    }
  })

  const api =
    regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === team?.region.toLowerCase())?.server.api || ''

  const { mutate: resetTeam, isLoading: isResetting } = useResetTeamMutation(teamId)(() => {
    createSuccessToast(`Team with name ${team?.primaryName} reset`)
    redirectFn()
  })

  const resetSalesforceTeam = () =>
    resetTeam({
      api,
      resetTeamInput: { salesforceId }
    })

  const resetStandaloneTeam = () => {
    const { initialStandaloneUser } = standaloneUserInput
    resetTeam({
      api,
      resetTeamInput: {
        initialStandaloneUser
      }
    })
  }

  const showSalesforceIdError = !salesforceId || !(!salesforceId.trim() || validateSalesforceId(salesforceId))
  const showEmailError = !validateUserEmail(standaloneUserInput.initialStandaloneUser.email)

  const isRequiredDataFilled = (type: 'salesforce' | 'standalone') => {
    if (type === 'salesforce') {
      return salesforceId !== ''
    } else if (type === 'standalone') {
      const { initialStandaloneUser } = standaloneUserInput
      return (
        initialStandaloneUser.email !== '' &&
        initialStandaloneUser.firstName !== '' &&
        initialStandaloneUser.lastName !== ''
      )
    }
    return false
  }

  return {
    resetSalesforceTeam,
    resetStandaloneTeam,
    modalIsOpen,
    toggleModalIsOpen,
    salesforceId,
    setSalesforceId,
    showSalesforceIdError,
    showEmailError,
    standaloneUserInput,
    setStandaloneUserInput,
    isResetting,
    isReadySalesforceReset: isRequiredDataFilled('salesforce') && !showSalesforceIdError,
    isReadyStandaloneReset: isRequiredDataFilled('standalone') && !showEmailError
  }
}
