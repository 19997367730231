import { useQuery } from '@tanstack/react-query'
import { createErrorToast } from '../../views/alertComponents/Alert'
import { getReleaseApplicationDetails } from '../../actions/Admin'

export const useReleaseApplicationDetailsQuery = () =>
  useQuery({
    queryKey: ['releaseApplicationDetails'],
    queryFn: () => getReleaseApplicationDetails(),
    onError: error => {
      createErrorToast(error)
    }
  })
