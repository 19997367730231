import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { createConnection, CreateCustomerConnectionConfig, CustomerConnection } from '../actions/CustomerConnections'

export const useCreateCustomerLoginConMutation = (customerId: string) => (successFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<CustomerConnection, AxiosError, CreateCustomerConnectionConfig>(
    (createCustomerConnectionConfig: CreateCustomerConnectionConfig) =>
      createConnection(createCustomerConnectionConfig),
    {
      onSuccess: (connectionResult: CustomerConnection) => {
        queryClient.invalidateQueries(['customerConnection', customerId])
        createSuccessToast(
          `Created new login option for type: ${connectionResult.type} and description: ${connectionResult.displayName}`
        )
        successFn()
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
