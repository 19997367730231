import { useQuery } from '@tanstack/react-query'
import { getChecksForDelivery } from '../../actions/Deliveries'
import { createErrorToast } from '../../views/alertComponents/Alert'

export const useDeliveryChecksQuery = (applicationName: string, version: string, enabled: boolean) =>
  useQuery({
    queryKey: ['deliveryChecks', applicationName, version],
    queryFn: () => getChecksForDelivery(applicationName, version),
    enabled,
    useErrorBoundary: (error: unknown) => {
      createErrorToast(`Get delivery checks failed. ${error}`)
      return false
    }
  })
