import { Button, Form, Modal } from 'semantic-ui-react'

import { RegionSelect } from '../../../utils/regionSelect'
import { NewCustomerConfig } from '../../../actions/NewCustomers'
import { InputWithError } from '../../formComponents/Inputs'
import { useCreateCustomerService } from '../../../services/CreateCustomerService'

export const CreateCustomerModal = () => {
  const {
    createCustomer,
    setCustomerName,
    setCustomerSalesforceId,
    toggleCustomerIsInternal,
    toggleModalIsOpen,
    setSelectedRegion,
    checkingCustomerName,
    customer,
    customerName,
    customerSalesforceId,
    customerIsInternal,
    customerNameIsValid,
    customerSalesforceIdIsValid,
    customerIsValid,
    creatingCustomer,
    debouncedCustomerName,
    modalIsOpen,
    regionsInfo,
    selectedRegion
  } = useCreateCustomerService()
  const showNameError = !(
    !customerName.trim() ||
    debouncedCustomerName !== customerName ||
    customerNameIsValid ||
    checkingCustomerName
  )
  const showIdError = !(!customerSalesforceId.trim() || customerSalesforceIdIsValid)

  return (
    <Modal
      trigger={
        <Button className="form-button-sked-blue" onClick={toggleModalIsOpen}>
          Create Customer
        </Button>
      }
      open={modalIsOpen}
      closeOnDimmerClick
      onClose={toggleModalIsOpen}
      closeIcon
      size="tiny"
    >
      <Modal.Header id="modal-header-add-customer">Add Customer</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={() => {
            createCustomer({ customer } as NewCustomerConfig)
          }}
        >
          <Form.Group inline>
            <InputWithError
              required
              placeholder="Customer Name"
              labelMessage="Customer Already Exists"
              error={showNameError}
              labelSelector={showNameError}
              labelPointing="left"
              value={customerName}
              onChange={(_, data) => setCustomerName(data.value)}
              name="customerName"
              loading={checkingCustomerName}
              width={9}
              style={{ marginLeft: '1em' }}
            />
            <Form.Checkbox
              name="internalCustomerCheckbox"
              toggle
              checked={customerIsInternal}
              onClick={toggleCustomerIsInternal}
              label="Internal Customer"
              width={7}
            />
          </Form.Group>
          {!customerIsInternal && (
            <InputWithError
              required
              placeholder="Customer Salesforce Account ID"
              labelMessage="Must be a valid salesforce account id."
              error={showIdError}
              labelSelector={showIdError}
              name="salesforceId"
              value={customerSalesforceId}
              onChange={(_, data) => setCustomerSalesforceId(data.value)}
              style={{ marginLeft: '1em', marginRight: '1em' }}
            />
          )}
          <RegionSelect
            setSelectedRegion={setSelectedRegion}
            regionsInfo={regionsInfo}
            selectedRegion={selectedRegion}
          />
          <Form.Button
            type="submit"
            id="modal-button-add-customer"
            fluid={true}
            loading={creatingCustomer}
            disabled={!customerIsValid}
          >
            Create Customer
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
