import * as React from 'react'
import classnames from 'classnames'
import { Table } from 'semantic-ui-react'

import { IFieldPermissions } from '../../../../actions/Permissions'
import { permissionMatches } from './permission-utils'
import { PermissionIcon } from './PermissionIcon'

/**
 * Renders a field row with permissions, if this field has any permission issues it will render with a light red background
 */
export const PermissionField: React.FC<{
  /**
   * Name to display
   */
  fieldName: string
  /**
   * Base permission field used to compare
   */
  baseFields: IFieldPermissions
  /**
   * Selected permission field used to compare
   */
  userFields?: IFieldPermissions
  /**
   * Show all fields with and without errors, only error are displayed when false
   */
  showAll: boolean
}> = ({ fieldName, baseFields, userFields, showAll }) => {
  const readMatches = userFields ? permissionMatches(baseFields.read, userFields.read) : false
  const updateMatches = userFields ? permissionMatches(baseFields.update, userFields.update) : false
  const hasIssue = !readMatches || !updateMatches
  const rowClasses = classnames({ 'tw-bg-red-100': hasIssue })

  if (!hasIssue && !showAll) {
    return null
  }

  return (
    <Table.Row className={rowClasses}>
      <Table.Cell>
        <span className="tw-pl-8">{fieldName}</span>
      </Table.Cell>
      <Table.Cell>
        <PermissionIcon hasPermission={baseFields.read} matches={readMatches} />
      </Table.Cell>
      <Table.Cell>
        <PermissionIcon hasPermission={baseFields.update} matches={updateMatches} />
      </Table.Cell>
    </Table.Row>
  )
}
