import { IMobileV2Form } from '../../../actions/MobileV2Forms'
import { useGetMobileV2FormListQuery } from '../../../queries/GetMobileV2FormListQuery'

export interface MobileV2FormServiceConfig {
  tenantId: string
  region: string
}

export interface MobileV2FormService {
  mobileV2Forms: IMobileV2Form[]
  isLoading: boolean
}

export const useMobileV2FormService = ({ tenantId, region }: MobileV2FormServiceConfig): MobileV2FormService => {
  const { data: mobileV2Forms, isLoading } = useGetMobileV2FormListQuery(tenantId, region)

  return {
    mobileV2Forms: mobileV2Forms ?? [],
    isLoading
  }
}
