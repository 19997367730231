import * as React from 'react'
import { Button, Modal } from 'semantic-ui-react'

interface IProps {
  deliveryCheckName: string
  deliveryCheckData: any
}

export const DeliveryCheckMetadataModal = (props: IProps) => {
  const { deliveryCheckData, deliveryCheckName } = props
  const [open, setOpen] = React.useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <Modal
      open={open}
      onClose={toggleOpen}
      size="small"
      trigger={<Button icon="file alternate outline" onClick={toggleOpen} className="square-icon-button" />}
    >
      <Modal.Header>{`Metadata of "${deliveryCheckName}"`}</Modal.Header>
      <Modal.Content>
        <div>
          <pre>{JSON.stringify(deliveryCheckData, null, 2)}</pre>
        </div>
      </Modal.Content>
    </Modal>
  )
}
