import { Image } from 'semantic-ui-react'

export const RegionFlag = ({ region, width }: { region?: string; width?: string }) => {
  switch (region?.toLowerCase()) {
    case 'au':
      return (
        <Image
          src={require('../images/flag-au.png')}
          verticalAlign="top"
          width={width || '32px'}
          id={`flag-${region}`}
        />
      )
    case 'ca':
      return (
        <Image
          src={require('../images/flag-ca.png')}
          verticalAlign="top"
          width={width || '32px'}
          id={`flag-${region}`}
        />
      )
    case 'uk':
      return (
        <Image
          src={require('../images/flag-uk.png')}
          verticalAlign="top"
          width={width || '32px'}
          id={`flag-${region}`}
        />
      )
    case 'us':
      return (
        <Image
          src={require('../images/flag-us.png')}
          verticalAlign="top"
          width={width || '32px'}
          id={`flag-${region}`}
        />
      )
    default:
      return <></>
  }
}
