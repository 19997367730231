import * as React from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'

interface IEditableTableProps {
  editing: boolean
  isEditable: boolean
  loading: boolean
  reset: () => any
  submit: () => any
  submitting: boolean
  tableTitle: string
  tableSubtitle: string
  toggleEditing: () => any
  validateSubmit: boolean
}

export const RenderEditableTableHeader = (props: IEditableTableProps) => (
  <div>
    <Grid>
      <Grid.Column width={12} textAlign="left" verticalAlign="middle">
        <Header as="h3">{props.tableTitle}</Header>
        <p>{props.tableSubtitle}</p>
      </Grid.Column>
      <Grid.Column width={4} textAlign="right">
        {props.isEditable ? (
          props.editing ? (
            <React.Fragment>
              <Button
                disabled={!props.validateSubmit || props.submitting}
                loading={props.submitting || false}
                onClick={props.submit}
                color="blue"
                content="Save"
              />
              <Button content="Cancel" onClick={props.reset} disabled={props.submitting} />
            </React.Fragment>
          ) : (
            <Button
              content="Edit"
              onClick={props.toggleEditing}
              className="sked-blue-button"
              disabled={props.loading}
            />
          )
        ) : (
          ''
        )}
      </Grid.Column>
    </Grid>
  </div>
)
