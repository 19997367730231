import { adminApi } from './Endpoints'

export interface IReleaseNotesPage {
  id: string
  _links: {
    base: string
    webui: string
  }
}

export const getReleaseNotesPage = async (component: string, version: string) =>
  adminApi.get<IReleaseNotesPage>(`/confluence/release/${component}/${version}`).then(result => result.data)
