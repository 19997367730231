import { elasticserver } from './Endpoints'
import { IResult } from './Utils'

export interface AuthExtPermission {
  _id: string
  name: string
  description: string
}

export const getPermissions = () =>
  elasticserver
    .get<IResult<AuthExtPermission[]>>('admin/auth0/internal/authextension/permission')
    .then(response => response.data.result)
