import { Button, Checkbox, Table } from 'semantic-ui-react'
import { useState } from 'react'
import { IDeploymentTemplate } from '../../../actions/Admin'
import { SortedHeaderCell } from '../../tableComponents/SortedHeaderCell'
import { filterData, sortData } from '../../tableComponents/TableFunctions'
import { TableLoaderWrap } from '../../tableComponents/TableLoader'
import { ISimpleSearch } from '../../searchComponents/SimpleSearch'

interface IProps {
  templates: IDeploymentTemplate[]
  makeDetailsLink: (id: string) => () => void
  searchValue: ISimpleSearch
  loading: boolean
}

export const DeploymentTemplatesList = (props: IProps) => {
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending')

  const handleSort = (clickedColumn: string) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn)
      setSortDirection('ascending')
    } else {
      setSortDirection(prevDirection => (prevDirection === 'ascending' ? 'descending' : 'ascending'))
    }
  }
  const { makeDetailsLink, searchValue, templates, loading } = props

  const SortedTemplateHeaderCell = SortedHeaderCell(sortColumn, sortDirection, handleSort)

  const filteredDeploymentTemplates: IDeploymentTemplate[] = filterData(
    { searchValue: searchValue.searchText, searchCategory: searchValue.searchCategory },
    templates
  )
  const sortedDeploymentTemplates = sortData(filteredDeploymentTemplates, sortColumn, sortDirection)

  return (
    <Table striped color="blue" sortable selectable stackable size="small" celled>
      <Table.Header>
        <Table.Row>
          <SortedTemplateHeaderCell title="Name" />
          <SortedTemplateHeaderCell title="Application Name" />
          <Table.HeaderCell textAlign="center">Pinned?</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <TableLoaderWrap loading={loading} array={sortedDeploymentTemplates} emptyMessage="No Deployment Templates">
        <Table.Body>
          {sortedDeploymentTemplates.map(deploymentTemplate => (
            <Table.Row key={deploymentTemplate.name}>
              <Table.Cell className="table-cell-overflow">{deploymentTemplate.name}</Table.Cell>
              <Table.Cell className="table-cell-overflow">{deploymentTemplate.applicationName}</Table.Cell>
              <Table.Cell className="center">
                <Checkbox checked={deploymentTemplate.pinConfiguration} disabled />
              </Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  icon="file alternate outline"
                  className="square-icon-button"
                  onClick={makeDetailsLink(deploymentTemplate.name)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </TableLoaderWrap>
    </Table>
  )
}
