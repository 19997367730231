import * as _ from 'lodash'
import * as React from 'react'
import { Button, DropdownProps, Form, InputProps, Modal } from 'semantic-ui-react'
import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'
import { IEnvironmentDetails } from '../../../actions/Environments'
import { IReleaseApplication } from '../../../actions/Releases'
import { ApplicationSelector } from '../../formComponents/Selectors'
import { useReleaseApplicationsQuery } from '../../../queries/releases/GetReleaseApplicationsQuery'
import { useEnvironmentsQuery } from '../../../queries/environments/GetEnvironmentsQuery'
import { useDeploymentTemplateCreationMutation } from '../../../mutations/settings/CreateDeploymentTemplateMutation'
import { AvailableEnvironmentsTable } from './AvailableEnvironmentsTable'

export interface IProps {
  updateTemplates: () => void
}

export interface IState {
  open: boolean
  loading: boolean
  environments: IEnvironmentDetails[]
  applications: IReleaseApplication[]
  templateName: string
  applicationName: string
  templateEnvironments: IEnvironmentDetails[]
  submitting: boolean
}

export const CreateDeploymentTemplateModal = (props: IProps) => {
  const [applicationName, setApplicationName] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [templateEnvironments, setTemplateEnvironments] = useState<IEnvironmentDetails[]>([])
  const [pinConfiguration, setPinConfiguration] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const { value: modalOpen, setTrue: openModal, setFalse: closeModal } = useBoolean(false)

  const { data: releaseApplications, status: releaseApplicationsStatus } = useReleaseApplicationsQuery()
  const { data: environments, status: environmentsStatus } = useEnvironmentsQuery()
  const { mutate: createDeploymentTemplate } = useDeploymentTemplateCreationMutation()

  const loading = releaseApplicationsStatus === 'loading' || environmentsStatus === 'loading'

  const submit = () => {
    if (templateName && !loading) {
      setSubmitting(true)
      createDeploymentTemplate({
        templateName,
        templateEnvironments,
        applicationName,
        pinConfiguration
      })
      props.updateTemplates()
      closeModal()
      setSubmitting(false)
    }
  }

  const createCheckboxHandler = (environment: IEnvironmentDetails) => () => {
    const index = templateEnvironments.findIndex(templateEnvironment => _.matches(templateEnvironment)(environment))
    setTemplateEnvironments(prevTemplateEnvironments => {
      const newTemplateEnvironments = [...prevTemplateEnvironments]
      if (index === -1) {
        newTemplateEnvironments.push(environment)
      } else {
        newTemplateEnvironments.splice(index, 1)
      }
      return newTemplateEnvironments
    })
  }

  const onChangeTemplateName = (event: React.SyntheticEvent, data: InputProps) => setTemplateName(data.value)

  const onChangeApplication = (_event: React.SyntheticEvent, data: DropdownProps) =>
    setApplicationName(data.value as string)

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      closeIcon
      size="tiny"
      aria-label="Create Deployment Template"
      trigger={<Button color="blue" content="Create Deployment Template" onClick={openModal} />}
    >
      <Modal.Header>Create Deployment Template</Modal.Header>
      <Modal.Content scrolling>
        <Form onSubmit={submit} loading={loading}>
          <Form.Input
            placeholder="Template Name"
            label="Template Name"
            required
            name="templateName"
            onChange={onChangeTemplateName}
            value={templateName}
          />
          <ApplicationSelector
            applications={releaseApplications ?? []}
            value={applicationName}
            onChange={onChangeApplication}
            acceptNull
            label="Application Name"
          />
          <Form.Checkbox
            label={<label htmlFor="pin-configuration">Pin Configuration</label>}
            id="pin-configuration"
            checked={pinConfiguration}
            onChange={(_event, data) => setPinConfiguration(!!data.checked)}
            name="pinConfiguration"
          />
          <AvailableEnvironmentsTable
            environments={environments ?? []}
            editable={true}
            templateEnvironments={[]}
            editedEnvironments={templateEnvironments}
            createCheckboxHandler={createCheckboxHandler}
          />
          <Form.Button
            id="modal-button-add-team"
            type="submit"
            fluid
            className="form-button-sked-blue"
            content="Submit"
            disabled={!templateName || loading}
            loading={submitting}
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}
