import { adminApi } from './Endpoints'
import { IEnvironmentDetails } from './Environments'
import { IReleaseApplication } from './Releases'

export interface IDeploymentTemplate {
  name: string
  applicationName: string | null
  pinConfiguration: boolean
}

export interface IDeploymentTemplateDetails extends IDeploymentTemplate {
  environments: IEnvironmentDetails[]
  createdAt: string
  updatedAt: string
}

export interface IReleaseApplicationDetails extends IReleaseApplication {
  createdAt: string
  updatedAt: string
}

export const getDeploymentTemplates = () =>
  adminApi.get<IDeploymentTemplate[]>('admin/deployment/template').then(response => response.data)

export const getDeploymentTemplateDetails = (name: string) =>
  adminApi.get<IDeploymentTemplateDetails>(`admin/deployment/template/${name}`).then(response => response.data)

export const patchDeploymentTemplate = (
  name: string,
  environments: IEnvironmentDetails[],
  applicationName?: string | null,
  pinConfiguration?: boolean
) =>
  adminApi
    .patch<IDeploymentTemplateDetails>(`admin/deployment/template/${name}`, {
      environments,
      applicationName,
      pinConfiguration
    })
    .then(response => response.data)

export const deleteDeploymentTemplate = (name: string) => adminApi.delete(`admin/deployment/template/${name}`)

export const createDeploymentTemplate = (
  name: string,
  environments: IEnvironmentDetails[],
  applicationName?: string,
  pinConfiguration?: boolean
) =>
  adminApi
    .post<IDeploymentTemplateDetails>('admin/deployment/template', {
      name,
      environments,
      applicationName,
      pinConfiguration
    })
    .then(response => response.data)

export const getReleaseApplicationDetails = () =>
  adminApi.get<IReleaseApplicationDetails[]>('admin/release/application').then(response => response.data)

export const createReleaseApplication = (body: IReleaseApplication) =>
  adminApi.post<IReleaseApplicationDetails>('admin/release/application', body).then(response => response.data)

export const patchReleaseApplication = (name: string, patch: Partial<IReleaseApplication>) =>
  adminApi.patch<IReleaseApplicationDetails>(`admin/release/application/${name}`, patch)

export const deleteReleaseApplication = (name: string) =>
  adminApi.delete(`admin/release/application/${name}`).then(response => response.data)
