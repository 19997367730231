import { Form, Message } from 'semantic-ui-react'
import { InputWithError } from '../../../../views/formComponents/Inputs'
import { ResetTeamService } from '../../services/ResetTeamService'

export interface StandaloneResetTeamFormProps {
  resetTeamService: ResetTeamService
}

const StandaloneResetTeamForm = ({ resetTeamService }: StandaloneResetTeamFormProps) => {
  const {
    resetStandaloneTeam,
    isResetting,
    isReadyStandaloneReset,
    standaloneUserInput,
    setStandaloneUserInput,
    showEmailError
  } = resetTeamService
  const { email, firstName, lastName } = standaloneUserInput.initialStandaloneUser
  return (
    <>
      <Message
        content={
          <div>
            <p>
              Resetting this Team will remove all data records, users and system configuration including packages,
              custom pages, settings, custom fields and objects.
            </p>
          </div>
        }
        icon="warning"
        error
      />
      <p>
        Enter the details of the user who will need to go through the onboarding for this Team after the reset process
        has finished.
      </p>
      <Form onSubmit={resetStandaloneTeam}>
        <InputWithError
          required
          placeholder="First name of Admin user"
          label="First name of Admin user"
          error={!firstName}
          name="firstName"
          value={firstName}
          onChange={(_, data) =>
            setStandaloneUserInput({
              initialStandaloneUser: {
                ...standaloneUserInput.initialStandaloneUser,
                firstName: data.value
              }
            })
          }
        />
        <InputWithError
          required
          placeholder="Last name of Admin user"
          label="Last name of Admin user"
          error={!lastName}
          name="lastName"
          value={lastName}
          onChange={(_, data) =>
            setStandaloneUserInput({
              initialStandaloneUser: {
                ...standaloneUserInput.initialStandaloneUser,
                lastName: data.value
              }
            })
          }
        />
        <InputWithError
          required
          placeholder="Email address of Admin User"
          label="Email address of Admin user"
          error={showEmailError}
          labelSelector={showEmailError}
          labelMessage="Must be a valid email."
          name="email"
          value={email}
          onChange={(_, data) =>
            setStandaloneUserInput({
              initialStandaloneUser: {
                ...standaloneUserInput.initialStandaloneUser,
                email: data.value
              }
            })
          }
        />
        <Form.Button
          type="submit"
          id="modal-button-add-customer"
          fluid={true}
          loading={isResetting}
          disabled={!isReadyStandaloneReset}
        >
          Reset Team
        </Form.Button>
      </Form>
    </>
  )
}

export default StandaloneResetTeamForm
