import { Grid, Input } from 'semantic-ui-react'
import { CustomerConnection } from '../../../../../actions/CustomerConnections'
import { useUpdateCustomerConnectionService } from '../../../services/UpdateCustomerConnectionService'
import { formatDate } from '../../../../../utils/dateUtils'
import { getDisplayedLoginOptionType } from '../../../../../actions/Utils'
import { renderEditSaveCancelButton, renderRow } from './Utils'

interface EmailPasswordLoginOptionDetailsProps {
  loginOption: CustomerConnection
}

const EmailPasswordLoginOptionDetails = ({ loginOption }: EmailPasswordLoginOptionDetailsProps) => {
  const updateCustomerConnectionService = useUpdateCustomerConnectionService(loginOption)
  const { description, isEditable, setDescription } = updateCustomerConnectionService
  const { createdAt, updatedAt, id } = loginOption

  return (
    <>
      {renderEditSaveCancelButton(updateCustomerConnectionService)}
      <Grid columns="6" style={{ margin: '5px' }}>
        {renderRow('Login option type', <>{getDisplayedLoginOptionType(loginOption?.type ?? '')}</>)}
        {renderRow(
          'Description',
          isEditable ? (
            <Input
              placeholder="Description"
              value={description}
              onChange={(_, data) => setDescription(data.value)}
              style={{ width: '600px' }}
            />
          ) : (
            <>{description}</>
          )
        )}
        {renderRow('Created', <>{formatDate(createdAt, 'h:mma ddd D MMM YYYY')}</>)}
        {renderRow('Updated', <>{formatDate(updatedAt, 'h:mma ddd D MMM YYYY')}</>)}
        {renderRow('Connection ID', <>{id}</>)}
      </Grid>
    </>
  )
}

export default EmailPasswordLoginOptionDetails
