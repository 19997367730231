import { useMemo } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { find } from 'lodash'
import { useGetCustomerConnectionsQuery } from '../../../queries/GetCustomerConnectionsQuery'
import { getTeamsList } from '../services/CustomerTeamsService'
import { CustomerLoginOptionsList } from './CustomerLoginOptionsList'
import AddLoginOptionsForm from './LoginOptions/AddLoginOptionsForm'

export const CustomerLoginOptions = ({ customerId }: { customerId: string }) => {
  const { data: customerConnections, isLoading } = useGetCustomerConnectionsQuery(customerId)
  const teams = getTeamsList(customerId)

  const connectionWithTeamData = useMemo(
    () =>
      customerConnections?.map(connection => ({
        ...connection,
        teams: teams?.filter(team => find(team.authConnections, { connectionId: connection.id }))
      })) ?? [],
    [customerConnections, teams]
  )

  return (
    <div>
      <Grid columns={2} stackable verticalAlign="middle">
        <Grid.Column>
          <Header as="h3">Login options</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <AddLoginOptionsForm buttonText="Add login option" title="Add login option" customerId={customerId} />
        </Grid.Column>
      </Grid>
      <CustomerLoginOptionsList data={connectionWithTeamData} loading={isLoading} />
    </div>
  )
}
