import { Form, Header, InputOnChangeData, Label } from 'semantic-ui-react'
import { InitialUser } from '../../../../../actions/NewTeams'
import { FormErrors } from '../../../../../views/newTeams/services/CreateTeamService'

interface InitialUserFormSectionProps {
  title: string
  initialUser: InitialUser
  errors: FormErrors
  handleInitialUserInput: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
}

const InitialUserFormSection = ({
  title,
  initialUser,
  handleInitialUserInput,
  errors
}: InitialUserFormSectionProps) => (
  <>
    <Header as="h3" className="create-team-header">
      {title}
    </Header>
    <div style={{ paddingBottom: '8px' }} className="create-team-note">
      {
        'Contact details of the initial user who will be invited to the new team and will run the onboarding wizard. You can create an email alias for each team using expertservices@skedulo.com and adding <+customer><environment> e.g., expertservices+newbusinessprod@skedulo.com. '
      }
      {/* To be added when we decide on a URL
      <a href="" target="_blank" rel="noreferrer">
        Learn more
      </a> */}
    </div>
    <Form.Field required>
      <label htmlFor="firstName">First name</label>
      <Form.Input
        id="firstName"
        name="firstName"
        value={initialUser.firstName}
        placeholder="Enter user's first name"
        onChange={handleInitialUserInput}
        width={12}
      />
    </Form.Field>
    <Form.Field required>
      <label htmlFor="lastName">Last name</label>
      <Form.Input
        id="lastName"
        name="lastName"
        value={initialUser.lastName}
        placeholder="Enter user's last name"
        onChange={handleInitialUserInput}
        width={12}
      />
    </Form.Field>
    <Form.Field required error={errors.userEmail.error}>
      <label htmlFor="email">Email</label>
      <Form.Input
        id="email"
        name="email"
        value={initialUser.email}
        placeholder="Enter a valid email address"
        onChange={handleInitialUserInput}
        width={12}
      />
    </Form.Field>
    {errors?.userEmail?.error && (
      <Label pointing color="red">
        {errors.userEmail.message}
      </Label>
    )}
  </>
)

export default InitialUserFormSection
