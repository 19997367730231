import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'
import { migrateTeam, MigrateTeamConfig, Team } from '../actions/NewTeams'

export const useMigrateTeamMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<Team, AxiosError, MigrateTeamConfig>(
    (migrateTeamConfig: MigrateTeamConfig) => migrateTeam(migrateTeamConfig),
    {
      onSuccess: (migratedTeam: Team) => {
        queryClient.invalidateQueries(['teams'])
        createSuccessToast(`Team with name ${migratedTeam.primaryName} migrated.`, {
          title: 'Success Migrating Team:'
        })
      },
      onError: (error: AxiosError) => {
        createErrorToast(error, { title: 'Error Migrating Team:' })
      }
    }
  )
}
