import { Button, Icon, List, Modal } from 'semantic-ui-react'
import { useState } from 'react'
import { IApplication, IApplicationRevision } from '../../../actions/Applications'
import { useApplicationRollbackMutation } from '../../../mutations/applications/RollbackApplication'

interface IProps {
  open: boolean
  application?: IApplication
  revision?: IApplicationRevision
  resetModal: () => void
}

export const RollbackApplicationModal = (props: IProps) => {
  const { mutate: rollbackApplication } = useApplicationRollbackMutation()
  const [loading, setLoading] = useState(false)

  const handleRollback = async () => {
    if (props.revision !== undefined && props.application !== undefined) {
      setLoading(true)
      rollbackApplication({ application: props.application, revision: props.revision })
      setLoading(false)
      props.resetModal()
    } else {
      console.error('Tried to rollback to undefined revision.')
    }
  }

  const revision = props.revision || ({} as IApplicationRevision)
  const application = props.application || ({} as IApplication)
  return (
    <Modal open={props.open} closeIcon closeOnDimmerClick onClose={props.resetModal}>
      <Modal.Header>
        Rollback {application.name} {application.environment} {application.instance} to revision {revision.revisionId}
      </Modal.Header>
      <Modal.Content>
        <p>
          Are you sure you want to rollback <b>{application.name}</b> in{' '}
          <b>
            {application.environment} {application.instance}
          </b>{' '}
          to revision <b>{revision.revisionId}</b>. This will reset application version and config version to the values
          listed below:
        </p>
        <List>
          <List.Item>
            <List.Header>Tag:</List.Header>
            {revision.tag}
          </List.Item>
          <List.Item>
            <List.Header>Config:</List.Header>
            {revision.configHash}
          </List.Item>
          <List.Item>
            <List.Header>Time Created:</List.Header>
            {revision.creationTimestamp}
          </List.Item>
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={props.resetModal} disabled={loading}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button color="green" disabled={loading} loading={loading} onClick={handleRollback}>
          <Icon name="checkmark" /> Roll Back
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
