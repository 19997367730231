import { Button, ListContent, ListItem } from 'semantic-ui-react'
import { ActiveFilter, FilterMatch } from './Filters'

interface FilterProps {
  filter: ActiveFilter
  onClick(): void
  onDelete(): void
}

const filterMatchOperator = (operator: FilterMatch) => {
  switch (operator) {
    case FilterMatch.CONTAINS:
      return '~'
    case FilterMatch.EXACT:
      return '='
  }
}

export const Filter = (props: FilterProps) => {
  const filterText = `${props.filter.category} ${filterMatchOperator(props.filter.match)} ${props.filter.value}`

  return (
    <ListItem>
      <ListContent>
        <Button className="filter-content" onClick={props.onClick}>
          {filterText}
        </Button>
        <Button basic color="red" icon="times" onClick={props.onDelete} aria-label={`Remove ${filterText} filter`} />
      </ListContent>
    </ListItem>
  )
}
