import { useState, useEffect } from 'react'

export interface FormService {
  isValid: boolean
  isUpdating: boolean
  isEditable: boolean
  isSaveButtonEnabled: boolean
  handleEditClicked: () => void
  handleSaveClicked: () => void
  handleCancelClicked: () => void
}

export const useFormService = (): FormService => {
  const [isResetForm, setIsResetForm] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (isResetForm) {
      resetForm()
      setIsResetForm(false)
    }
  }, [isResetForm])

  const resetForm = () => {
    setIsEditable(false)
    setIsUpdating(false)
  }

  const isValid = true

  const runUpdate = () => null
  const handleEditClicked = () => setIsEditable(true)
  const handleSaveClicked = () => {
    runUpdate()
    setIsEditable(false)
  }
  const handleCancelClicked = () => setIsResetForm(true)

  return {
    isValid,
    isUpdating,
    isEditable,
    isSaveButtonEnabled: isValid,
    handleEditClicked,
    handleSaveClicked,
    handleCancelClicked
  }
}
