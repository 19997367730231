import { Button, Input, Select, Table } from 'semantic-ui-react'
import { mapStringsToOptions } from '../../../views/Utils'
import {
  EditedMetadataValue,
  MakeSetValueFn,
  MetadataTypes,
  metadataTypeOptions
} from '../../../services/DynamicMetadataService'
import { DynamicValueInputProps, DynamicValueInput } from './DynamicValueInput'

interface DynamicEditRowProps {
  editedKey: string
  editedValue: EditedMetadataValue
  editedType: MetadataTypes
  makeSetValue: MakeSetValueFn
  setKey: (value: string) => void
  setType: (value: MetadataTypes) => void
  deleteRow: () => void
}

const makeInputProps =
  (makeSetValue: MakeSetValueFn) =>
  (type: MetadataTypes, editedValue: string | boolean | undefined): DynamicValueInputProps => {
    switch (type) {
      case 'string':
      case 'number':
      case 'json':
        return {
          type,
          value: editedValue as string,
          setValue: makeSetValue(type)
        }
      case 'boolean':
        return {
          type,
          value: editedValue as boolean,
          toggleValue: makeSetValue(type) as () => void
        }
      case 'undefined':
        return {
          type,
          value: editedValue as undefined
        }
    }
  }

export const DynamicEditRow = ({
  editedKey,
  editedValue,
  editedType,
  makeSetValue,
  setKey,
  setType,
  deleteRow
}: DynamicEditRowProps) => (
  <Table.Row>
    <Table.Cell width={5}>
      <Input value={editedKey} onChange={(_, data) => setKey(data.value)} fluid />
    </Table.Cell>
    <Table.Cell width={8} textAlign={editedType === 'boolean' || editedType === 'undefined' ? 'center' : undefined}>
      <DynamicValueInput {...makeInputProps(makeSetValue)(editedType, editedValue)} />
    </Table.Cell>
    <Table.Cell width={2}>
      <Select
        value={editedType}
        options={mapStringsToOptions(metadataTypeOptions as any as string[])}
        onChange={(_, data) => setType(data.value as MetadataTypes)}
        fluid
      />
    </Table.Cell>
    <Table.Cell width={1}>
      <Button color="red" icon="close" onClick={deleteRow} data-testid="delete-row-button" />
    </Table.Cell>
  </Table.Row>
)
