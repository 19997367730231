import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deployDelivery, ICompleteDeliveryRequest, NewDelivery } from '../../actions/Deliveries'

export const useDeployDeliveryMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (args: { delivery: NewDelivery; explanation: ICompleteDeliveryRequest }) =>
      deployDelivery(
        { applicationName: args.delivery.applicationName, version: args.delivery.version },
        args.explanation
      ),
    onSuccess: async (_, args) => {
      await Promise.all([
        queryClient.invalidateQueries(['deliveries']),
        queryClient.invalidateQueries(['delivery', args.delivery.applicationName, args.delivery.version])
      ])
    }
  })
}
