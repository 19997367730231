import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, InputProps, Message, Modal } from 'semantic-ui-react'
import { createCustomer, ICustomer } from '../../../actions/Customers'
import { createErrorToast, createSuccessToast } from '../../alertComponents/Alert'
import { InputWithError } from '../../formComponents/Inputs'

interface IProps {
  fetchCustomers(): void
  customers: ICustomer[]
}

interface IState {
  customerName: string
  salesforceId: string
  loading: boolean
  active: boolean
  exists: boolean
}

export class AddCustomerModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      customerName: '',
      salesforceId: '',
      loading: false,
      active: false,
      exists: false
    }
  }

  toggleAddCustomer = (): void => {
    this.setState(prevState => ({ active: !prevState.active }))
  }

  validateCustomerName(nameToValidate: string): boolean {
    const { customers } = this.props
    return !customers.some(customer => customer.name.toLowerCase() === nameToValidate.toLowerCase())
  }

  submitCustomer = async (): Promise<void> => {
    const { fetchCustomers } = this.props
    this.setState({ loading: true })
    if (this.validateCustomerName(this.state.customerName)) {
      try {
        const createdCustomer = await createCustomer(this.state.customerName, this.state.salesforceId || undefined)
        this.setState({ customerName: '', salesforceId: '', loading: false, active: false, exists: false })
        createSuccessToast(`New customer created with the ID: ${createdCustomer.id}`)
        fetchCustomers()
      } catch (error) {
        createErrorToast(error)
        this.setState({ loading: false })
      }
    } else {
      this.setState({ loading: false, exists: true })
    }
  }

  handleChange = (event: React.SyntheticEvent<HTMLElement>, data: InputProps): void => {
    this.setState(prevState => ({ ...prevState, [data.name]: data.value }))
  }

  render() {
    return (
      <Modal
        trigger={
          <Button className="form-button-sked-blue addCustomerButton" onClick={this.toggleAddCustomer}>
            Add Customer
          </Button>
        }
        open={this.state.active}
        closeOnDimmerClick
        onClose={this.toggleAddCustomer}
        closeIcon
        size="tiny"
      >
        <Modal.Header id="modal-header-add-customer">Add Customer</Modal.Header>
        <Modal.Content>
          <Message warning>
            <Message.Header>WAIT!</Message.Header>
            <p>
              You should only be adding a new Customer using this form if you need to{' '}
              <Link
                to={{
                  pathname:
                    'https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2395799569/New+Team+Model+-+Migrations+overview'
                }}
                target="_blank"
              >
                migrate Teams to the new model
              </Link>
            </p>
            <p>
              If you are creating a Customer record for a new customer, use the form in the{' '}
              <Link to={'/customers'}>New Customer Model</Link> instead.
            </p>
          </Message>
          <Form onSubmit={this.submitCustomer}>
            <InputWithError
              required
              placeholder="Customer Name"
              labelMessage="Customer Already Exists"
              error={this.state.exists}
              labelSelector={this.state.exists}
              value={this.state.customerName}
              onChange={this.handleChange}
              name="customerName"
            />
            <Form.Input
              placeholder="Customer Salesforce Account ID"
              name="salesforceId"
              value={this.state.salesforceId}
              onChange={this.handleChange}
            />
            <Form.Button
              type="submit"
              id="modal-button-add-customer"
              fluid={true}
              loading={this.state.loading}
              disabled={!this.state.customerName || !this.state.customerName.trim()}
            >
              Create Customer
            </Form.Button>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
