import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { LoginConnectionsConfig, TeamLoginConnection, createTeamLogin } from '../actions/NewTeams'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export const useCreateTeamLoginMutation = (teamId: string) => (onSaveFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<TeamLoginConnection, AxiosError, LoginConnectionsConfig>(
    (connection: LoginConnectionsConfig) => createTeamLogin(connection),
    {
      onSuccess: (connectionResult: TeamLoginConnection) => {
        queryClient.invalidateQueries(['team', teamId])
        queryClient.invalidateQueries(['teams'])
        onSaveFn()
        createSuccessToast(`Created login connection: ${connectionResult.name}.`)
      },
      onError: (error: AxiosError) => {
        createErrorToast(error)
      }
    }
  )
}
