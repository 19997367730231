import { Users } from '../../../views/tenants/components/Users/Users'
import { useTenantApiService } from '../services/TenantApiService'

const TeamUsers = ({ teamId, customerId }: { teamId: string; customerId: string }) => {
  const tenantApiService = useTenantApiService({
    teamId
  })
  return (
    <Users tenantId={tenantApiService.tenantId} teamApi={tenantApiService.teamAPI} vendor={tenantApiService.vendor} />
  )
}

export default TeamUsers
