import { chunk, flatten, groupBy, keyBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { expensiveQueryConfig } from '../views/Utils'
import { getUserSummary, IUserSummary } from '../actions/Tenants'
import { TeamWithSummary } from '../views/newCustomers/component/CustomerUsers'
import { TeamWithModel } from '../actions/NewTeams'
import { IRegionInfo } from '../actions/Regions'

export const getUsersForTeams = async (teams: TeamWithModel[], regions: IRegionInfo[]): Promise<TeamWithSummary[]> => {
  const teamsByRegion = groupBy<TeamWithModel>(teams, 'region')
  const summaryRequests = Object.entries(teamsByRegion).flatMap(async ([region, regionTeams]) => {
    const api = regions?.find(r => r.region.toLowerCase() === region.toLowerCase())?.server.api ?? ''
    const teamsChunked = chunk(regionTeams, 100).map(async regionTeamsChunk =>
      getUserSummary(
        regionTeamsChunk.map(team => team.tenantId),
        api
      )
    )
    const teamsWithSummary = await Promise.all(teamsChunked)
    return flatten(teamsWithSummary)
  })
  const summariesChunked = await Promise.all(summaryRequests)
  const summaries = flatten(summariesChunked)
  const summaryByTenantId = keyBy<IUserSummary>(summaries, 'tenantId')
  return teams.map(team => {
    const toReturn = {
      team,
      summary: summaryByTenantId[team.tenantId] || {
        tenantId: team.tenantId,
        totalActiveResources: null,
        totalActiveSchedulers: null
      }
    }
    return toReturn
  })
}

export const useGetUsersReportForTeamsQuery = (teamsData: TeamWithModel[], regionsData: IRegionInfo[]) =>
  useQuery<TeamWithSummary[]>(['teams', 'users', teamsData], () => getUsersForTeams(teamsData, regionsData), {
    enabled: !!(teamsData && regionsData),
    ...expensiveQueryConfig
  })
