import { Button, Modal, Step, Image, Icon } from 'semantic-ui-react'
import { STEP_LOGIN_OPTION_DETAILS, STEP_LOGIN_OPTION_TYPE } from '../../services/Constants'
import { useCreateCustomerConnectionService } from '../../services/CreateCustomerConnectionService'
import LoginOptionDetails from './AddLoginOptionsForm/LoginOptionDetailsForm'
import LoginOptionType from './AddLoginOptionsForm/LoginOptionTypeForm'
interface AddLoginOptionFormProps {
  customerId: string
  buttonText: string
  title: string
}

const AddLoginOptionsForm = ({ customerId, buttonText, title }: AddLoginOptionFormProps) => {
  const createCustomerConnectionService = useCreateCustomerConnectionService(customerId)
  const {
    isValidCustomerLoginCon,
    setIsResetForm,
    selectedLoginOptionType,
    selectedSSO,
    setSelectedLoginOptionType,
    setSelectedSSO,
    modalIsOpen,
    openModal,
    closeModal,
    isCreatingCustomerLoginCon,
    isValidLoginOptionType,
    runCreateCustomerConnection,
    currentStep,
    setCurrentStep,
    resetLoginOptionDetailsForm,
    isWarningMessageVisible,
    showWarningMessage,
    hideWarningMessage,
    showErrorFormMessage,
    hideErrorFormMessage
  } = createCustomerConnectionService

  const handleNextClicked = () => {
    if (isValidLoginOptionType) {
      setCurrentStep(STEP_LOGIN_OPTION_DETAILS)
    } else {
      showWarningMessage()
    }
  }
  const handlePreviousClicked = () => {
    hideErrorFormMessage()
    resetLoginOptionDetailsForm()
    setCurrentStep(STEP_LOGIN_OPTION_TYPE)
  }
  const handleCreateClicked = async () => {
    if (!isValidCustomerLoginCon) {
      showErrorFormMessage()
      return
    }
    runCreateCustomerConnection()
  }
  const handleClose = () => {
    setIsResetForm(true)
    closeModal()
  }

  return (
    <Modal
      data-testid="add-login-options-modal"
      closeIcon
      open={modalIsOpen}
      trigger={
        <Button
          className={'form-button-sked-blue'}
          content={buttonText}
          onClick={openModal}
          disabled={isCreatingCustomerLoginCon}
          loading={isCreatingCustomerLoginCon}
        />
      }
      onClose={handleClose}
      onOpen={openModal}
    >
      <Modal.Header id="#modal-header-add-login-option">{title}</Modal.Header>
      <Modal.Content>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <Step.Group size="mini">
            <Step
              completed={currentStep !== STEP_LOGIN_OPTION_TYPE}
              active={currentStep === STEP_LOGIN_OPTION_TYPE}
              onClick={handlePreviousClicked}
              style={{ padding: '2px 12px' }}
            >
              {currentStep === STEP_LOGIN_OPTION_TYPE ? (
                <Image
                  id="icon-step-one"
                  src={require('../../../../images/sso/step-one.svg')}
                  style={{ width: '22px', height: 'auto', margin: '5px' }}
                />
              ) : (
                <Icon name="check" style={{ fontSize: '22px', margin: '5px' }} />
              )}
              <Step.Content>
                <Step.Title>Login option type</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={currentStep === STEP_LOGIN_OPTION_DETAILS}
              disabled={currentStep !== STEP_LOGIN_OPTION_DETAILS}
              style={{ padding: '2px 12px' }}
            >
              <Image
                id="icon-step-two"
                src={require('../../../../images/sso/step-two.svg')}
                style={{ width: '22px', height: 'auto', margin: '5px' }}
              />
              <Step.Content>
                <Step.Title>Login option details</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>

        {currentStep === STEP_LOGIN_OPTION_TYPE && (
          <LoginOptionType
            selectedLoginOptionType={selectedLoginOptionType}
            setSelectedLoginOptionType={setSelectedLoginOptionType}
            selectedSSO={selectedSSO}
            setSelectedSSO={setSelectedSSO}
            isWarningMessageVisible={isWarningMessageVisible}
            hideWarningMessage={hideWarningMessage}
          />
        )}

        {currentStep === STEP_LOGIN_OPTION_DETAILS && (
          <LoginOptionDetails
            loginOptionType={selectedLoginOptionType}
            selectedSSO={selectedSSO}
            createCustomerConnectionService={createCustomerConnectionService}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          id="cancel-add-login-option-button"
          content="Cancel"
          onClick={handleClose}
          disabled={isCreatingCustomerLoginCon}
          style={{
            border: 'none',
            backgroundColor: 'transparent'
          }}
        />

        {currentStep === STEP_LOGIN_OPTION_TYPE && (
          <Button
            id="next-team-button"
            data-testid="next-team-form-button"
            content="Next"
            className="form-button-sked-blue"
            onClick={handleNextClicked}
            loading={isCreatingCustomerLoginCon}
          />
        )}

        {currentStep === STEP_LOGIN_OPTION_DETAILS && (
          <Button
            id="previous-team-button"
            data-testid="previous-team-form-button"
            content="Previous"
            onClick={handlePreviousClicked}
            loading={isCreatingCustomerLoginCon}
            basic
          />
        )}

        {currentStep === STEP_LOGIN_OPTION_DETAILS && (
          <Button
            id="create-team-button"
            data-testid="create-team-form-button"
            className="form-button-sked-blue"
            content="Create"
            onClick={handleCreateClicked}
            loading={isCreatingCustomerLoginCon}
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}

export default AddLoginOptionsForm
