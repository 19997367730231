import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Team, updateTeamName } from '../actions/NewTeams'
import { createErrorToast, createSuccessToast } from '../views/alertComponents/Alert'

export interface IUpdateMigratedTeam {
  teamId: string
  name: string
}

export const useUpdateTeamNameMutation = (onSaveFn: () => void) => {
  const queryClient = useQueryClient()
  return useMutation<Team, AxiosError, IUpdateMigratedTeam>(
    ({ teamId, name }: IUpdateMigratedTeam) => updateTeamName(teamId)(name),
    {
      onSuccess: (updatedTeam: Team) => {
        queryClient.invalidateQueries(['team', updatedTeam.id])
        queryClient.invalidateQueries(['teams'])
        queryClient.invalidateQueries(['team', updatedTeam.id, 'teamNames'])
        queryClient.invalidateQueries(['customerTeams', updatedTeam?.customerId])
        onSaveFn()
        createSuccessToast(`Team name updated to ${updatedTeam.primaryName}`)
      },
      onError: (error: AxiosError) => {
        createErrorToast(error, { title: 'Error Updating Team Name:' })
      }
    }
  )
}
