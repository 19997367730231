import { OpenIDConnectFields } from '../views/newCustomers/services/LoginOptions/OpenIDConnectFieldService'
import { customerService, customerServiceConfig } from './Endpoints'
import { Team } from './NewTeams'
import { IResult } from './Utils'

export interface OpenIDCustomerConnectionMetadata {
  clientId: string
  clientSecret: string
  discoveryUrl: string
  scopes: string[]
}

export interface CustomerConnection {
  id: string
  type: string
  customerId: string
  authProvider: string
  region: string
  displayName: string
  name: string
  createdBy: string
  updatedBy: string
  updatedAt: string
  createdAt: string
  metadata: Partial<OpenIDCustomerConnectionMetadata>
}

export interface CustomerConnectionWithTeamData extends CustomerConnection {
  teams: Team[]
}

export interface ICreateCustomerConnection {
  type: string
  customerId: string
  authProvider: string
  region: string
  displayName: string
  // add new type for newly created fields
  extraFields?: OpenIDConnectFields
}

export interface IUpdateCustomerConnection {
  type: string
  customerId: string
  connectionId: string
  region: string
  displayName: string
  metadata?: Partial<OpenIDCustomerConnectionMetadata>
}

export interface IDeleteCustomerConnection {
  customerId: string
  connectionId: string
}

export interface IReturnedCreatedCustomerConnection {
  connectionId: string
  connectionType: string
  customerId: string
  authProvider: string
  region: string
  name: string
  createdBy: string
  updatedBy: string
  updatedAt: string
  createdAt: string
}

export interface CreateCustomerConnectionConfig {
  api: string
  connection: ICreateCustomerConnection
}

export interface UpdateCustomerConnectionConfig {
  api: string
  request: IUpdateCustomerConnection
}

export interface DeleteCustomerConnectionConfig {
  api: string
  request: IDeleteCustomerConnection
}

export const getCustomerConnectionsById = (id: string) =>
  customerService
    .get<IResult<CustomerConnection[]>>(`/admin/customer/${id}/connections`)
    .then(response => response.data.result)

export const createConnection = (createCustomerConnectionConfig: CreateCustomerConnectionConfig) =>
  customerService
    .post<IResult<CustomerConnection>>('/admin/customer/connection', createCustomerConnectionConfig.connection, {
      ...customerServiceConfig,
      baseURL: `${createCustomerConnectionConfig.api}/authorization`
    })
    .then(response => response.data.result)

export const updateConnection = (updateCustomerConnectionConfig: UpdateCustomerConnectionConfig) => {
  const { customerId, connectionId, displayName, type, metadata } = updateCustomerConnectionConfig.request
  const request = { type, displayName, ...metadata }

  return customerService
    .patch<IResult<CustomerConnection>>(
      `/admin/customer/${customerId}/connection?connectionId=${connectionId}`,
      request,
      {
        ...customerServiceConfig,
        baseURL: `${updateCustomerConnectionConfig.api}/authorization`
      }
    )
    .then(response => response.data.result)
}

export const deleteConnection = (customerId: string, connectionId: string) =>
  customerService
    .delete<
      IResult<CustomerConnection>
    >(`/admin/customer/${customerId}/connection?connectionId=${connectionId}`, customerServiceConfig)
    .then(response => response.data.result)
