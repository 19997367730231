import { useState, useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'
import { useCreateCustomerLoginConMutation } from '../../../mutations/CreateCustomerLoginConMutation'
import { CreateCustomerConnectionConfig, ICreateCustomerConnection } from '../../../actions/CustomerConnections'
import { useGetRegionQuery } from '../../../queries/GetRegionsQuery'
import { createErrorToast, createInfoToast } from '../../../views/alertComponents/Alert'
import {
  CUSTOMER_LOGIN_TYPE_REQUEST,
  EMAIL_PASSWORD,
  LoginOptionType,
  SsoType,
  SSO,
  STEP_LOGIN_OPTION_TYPE,
  OPEN_ID_CONNECT
} from './Constants'
import {
  OpenIDConnectFields,
  OpenIDConnectMethods,
  useOpenIDConnectFieldService
} from './LoginOptions/OpenIDConnectFieldService'

// Please extend this interface for every new SSO login options
export interface BaseSSOExtraMethods {
  resetExtraForm: () => void
  isValidExtraFields: boolean
}

export interface SSOExtraFieldsMethods {
  openIDExtraFields: OpenIDConnectFields
  openIDSpecificMethods: OpenIDConnectMethods
}

export interface CreateCustomerConnectionService {
  runCreateCustomerConnection: () => void
  selectedLoginOptionType: LoginOptionType
  setSelectedLoginOptionType: (selectedLoginOptionType: LoginOptionType) => void
  selectedSSO: SsoType
  setSelectedSSO: (SSO: SsoType) => void
  modalIsOpen: boolean
  openModal: () => void
  closeModal: () => void
  toggleModalIsOpen: () => void
  isValidCustomerLoginCon: boolean
  isCreatingCustomerLoginCon: boolean
  setIsResetForm: (isResetForm: boolean) => void
  setDescription: (description: string) => void
  description: string
  isValidLoginOptionType: boolean
  currentStep: string
  setCurrentStep: (currentStep: string) => void
  ssoExtraFieldsMethods: SSOExtraFieldsMethods
  resetLoginOptionDetailsForm: () => void
  isWarningMessageVisible: boolean
  showWarningMessage: () => void
  hideWarningMessage: () => void
  isErrorFormMessageVisible: boolean
  showErrorFormMessage: () => void
  hideErrorFormMessage: () => void
}

export const getCustomerLoginType = (selectedLoginOptionType: LoginOptionType, selectedSSO: SsoType) => {
  if (selectedLoginOptionType === EMAIL_PASSWORD) {
    return CUSTOMER_LOGIN_TYPE_REQUEST[EMAIL_PASSWORD]
  } else if (selectedLoginOptionType === SSO && selectedSSO !== undefined) {
    return CUSTOMER_LOGIN_TYPE_REQUEST[selectedSSO]
  }
  return undefined
}

export const isValidLoginOption = (loginOptionType: LoginOptionType, sso: SsoType): boolean =>
  loginOptionType === EMAIL_PASSWORD || (loginOptionType === SSO && sso !== undefined)

export const validateCustomerLoginConRequest = (
  loginOptionType: LoginOptionType,
  sso: SsoType,
  description: string,
  ssoExtraFieldsMethods: SSOExtraFieldsMethods
): boolean => {
  const isValidLoginOptionAndDescription = isValidLoginOption(loginOptionType, sso) && description !== ''
  const { openIDSpecificMethods } = ssoExtraFieldsMethods

  if (loginOptionType === SSO && sso === OPEN_ID_CONNECT) {
    return isValidLoginOptionAndDescription && openIDSpecificMethods.isValidExtraFields
  }

  return isValidLoginOptionAndDescription
}

export const setupCreateCustomerLoginPayload = (
  customerId: string,
  selectedLoginOptionType: LoginOptionType,
  selectedSSO: SsoType,
  description: string,
  extraFields: SSOExtraFieldsMethods
): ICreateCustomerConnection => {
  const baseRequest = {
    type: getCustomerLoginType(selectedLoginOptionType, selectedSSO) ?? '',
    customerId,
    authProvider: 'auth0-organization',
    region: 'US', // at the moment, we only support US region
    displayName: description
  }

  if (selectedLoginOptionType === SSO && selectedSSO === OPEN_ID_CONNECT) {
    // remove client secret from request if it's empty
    if (extraFields.openIDExtraFields.clientSecret === '') {
      delete extraFields.openIDExtraFields.clientSecret
    }

    return { ...baseRequest, ...extraFields.openIDExtraFields }
  }

  return { ...baseRequest }
}

export const useCreateCustomerConnectionService = (customerId: string): CreateCustomerConnectionService => {
  const { mutate: createCustomerLoginConMutation, isLoading: createCustomerLoginConLoading } =
    useCreateCustomerLoginConMutation(customerId)(() => {
      resetForm()
      toggleModalIsOpen()
    })
  const { data: regionsInfo } = useGetRegionQuery()

  const [selectedLoginOptionType, setSelectedLoginOptionType] = useState<LoginOptionType>(undefined)
  const [selectedSSO, setSelectedSSO] = useState<SsoType>(undefined)
  const [description, setDescription] = useState('')
  const { value: modalIsOpen, setTrue: openModal, setFalse: closeModal, toggle: toggleModalIsOpen } = useBoolean(false)
  const [currentStep, setCurrentStep] = useState(STEP_LOGIN_OPTION_TYPE)
  const [isResetForm, setIsResetForm] = useState(false)
  const {
    value: isWarningMessageVisible,
    setTrue: showWarningMessage,
    setFalse: hideWarningMessage
  } = useBoolean(false)
  const {
    value: isErrorFormMessageVisible,
    setTrue: showErrorFormMessage,
    setFalse: hideErrorFormMessage
  } = useBoolean(false)

  // extra fields for sso
  const { methods: openIDSpecificMethods, fields: openIDExtraFields } = useOpenIDConnectFieldService()
  const ssoExtraFieldsMethods: SSOExtraFieldsMethods = { openIDExtraFields, openIDSpecificMethods }

  useEffect(() => {
    if (isResetForm) {
      hideWarningMessage()
      hideErrorFormMessage()
      resetForm()
      setIsResetForm(false)
    }
  }, [isResetForm])

  useEffect(() => {
    if (selectedLoginOptionType !== SSO) {
      setSelectedSSO(undefined)
    }
  }, [selectedLoginOptionType])

  // Add reset specific sso fields when there are new sso types implemented
  const resetLoginOptionDetailsForm = () => {
    setDescription('')
    openIDSpecificMethods.resetExtraForm()
  }

  const resetForm = () => {
    setCurrentStep(STEP_LOGIN_OPTION_TYPE)
    setSelectedLoginOptionType(undefined)
    setSelectedSSO(undefined)
    resetLoginOptionDetailsForm()
  }

  const isValidLoginOptionType = isValidLoginOption(selectedLoginOptionType, selectedSSO)
  const isValidCustomerLoginCon = validateCustomerLoginConRequest(
    selectedLoginOptionType,
    selectedSSO,
    description,
    ssoExtraFieldsMethods
  )

  const runCreateCustomerConnection = () => {
    if (isValidCustomerLoginCon) {
      const request: ICreateCustomerConnection = setupCreateCustomerLoginPayload(
        customerId,
        selectedLoginOptionType,
        selectedSSO,
        description,
        ssoExtraFieldsMethods
      )
      const api =
        regionsInfo?.find(regionInfo => regionInfo.region.toLowerCase() === request.region?.toLowerCase())?.server
          .api || ''

      const createCustomerConnectionConfig: CreateCustomerConnectionConfig = { api, connection: request }
      createInfoToast(`Creating login option for type: ${request.type}`)
      createCustomerLoginConMutation(createCustomerConnectionConfig)
    } else {
      createErrorToast('Problem creating login connection, please ensure all fields are valid or try again.')
    }
  }

  return {
    runCreateCustomerConnection,
    toggleModalIsOpen,
    selectedLoginOptionType,
    setSelectedLoginOptionType,
    selectedSSO,
    setSelectedSSO,
    isValidCustomerLoginCon,
    modalIsOpen,
    openModal,
    closeModal,
    isCreatingCustomerLoginCon: createCustomerLoginConLoading,
    setIsResetForm,
    setDescription,
    description,
    isValidLoginOptionType,
    currentStep,
    setCurrentStep,
    ssoExtraFieldsMethods,
    resetLoginOptionDetailsForm,
    isWarningMessageVisible,
    showWarningMessage,
    hideWarningMessage,
    isErrorFormMessageVisible,
    showErrorFormMessage,
    hideErrorFormMessage
  }
}
