import _ from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { CheckboxProps, Divider, Form, Grid, InputProps } from 'semantic-ui-react'
import {
  DeliveryCheckTemplate,
  DeliveryCheckType,
  IDeliveryCheckDefinition,
  INewDeliveryCheckTemplate
} from '../../../actions/DeliveryCheck'
import { DeliveryCheckDefinitionsTable } from './DeliveryCheckDefinitionsTable'

interface IProps {
  loading: boolean
  deliveryCheckDefinitions: IDeliveryCheckDefinition[]
  enableEdit: boolean
  initialState?: DeliveryCheckTemplate
  onSubmitHook: (template: INewDeliveryCheckTemplate) => Promise<boolean>
}

export const EditDeliveryCheckTemplateForm = (props: IProps) => {
  const getInitialTemplateName = useCallback(
    () => (props.initialState ? props.initialState.name : ''),
    [props.initialState]
  )

  const getInitialTemplateDescription = useCallback(
    () => (props.initialState ? props.initialState.description : ''),
    [props.initialState]
  )

  const getInitialTemplateCheckTypes = useCallback(
    () => (props.initialState ? new Set(props.initialState.checkTypes) : new Set<DeliveryCheckType>()),
    [props.initialState]
  )

  /* Delivery Check Template Properties */
  const [templateName, setTemplateName] = React.useState<string>(getInitialTemplateName())
  const [templateDescription, setTemplateDescription] = React.useState<string>(getInitialTemplateDescription())
  const [templateCheckTypes, setTemplateCheckTypes] =
    React.useState<Set<DeliveryCheckType>>(getInitialTemplateCheckTypes())
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const loading = submitting || props.loading

  const changeTemplateName = (_event: React.SyntheticEvent, data: InputProps) => {
    setTemplateName(data.value)
  }

  const resetTemplateName = useCallback(() => {
    setTemplateName(getInitialTemplateName())
  }, [getInitialTemplateName])

  const changeTemplateDescription = (_event: React.SyntheticEvent, data: InputProps) => {
    setTemplateDescription(data.value)
  }

  const resetTemplateDescription = useCallback(() => {
    setTemplateDescription(getInitialTemplateDescription())
  }, [getInitialTemplateDescription])

  const resetTemplateCheckTypes = useCallback(() => {
    setTemplateCheckTypes(getInitialTemplateCheckTypes())
  }, [getInitialTemplateCheckTypes])

  const resetTemplate = useCallback(() => {
    resetTemplateName()
    resetTemplateDescription()
    resetTemplateCheckTypes()
  }, [resetTemplateName, resetTemplateDescription, resetTemplateCheckTypes])

  useEffect(() => {
    if (!props.enableEdit) {
      resetTemplate()
    }
  }, [props.enableEdit, resetTemplate])

  const allowSubmit = () => {
    if (props.enableEdit) {
      if (templateName && templateDescription) {
        return (
          // compare to initial state
          templateName !== props.initialState?.name ||
          templateDescription !== props.initialState?.description ||
          !_.isEqual(props.initialState?.checkTypes, [...templateCheckTypes])
        )
      }
    }
    return false
  }

  const submit = async () => {
    setSubmitting(true)
    if (allowSubmit()) {
      const newTemplate: INewDeliveryCheckTemplate = {
        name: templateName,
        description: templateDescription,
        checkTypes: [...templateCheckTypes]
      }
      await props.onSubmitHook(newTemplate)
    }
    setSubmitting(false)
  }

  const createCheckboxHandler =
    (checkDefinition: IDeliveryCheckDefinition) => (_event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      const newCheckTypes = new Set([...templateCheckTypes])
      if (data.checked) {
        newCheckTypes.add(checkDefinition.type)
      } else {
        newCheckTypes.delete(checkDefinition.type)
      }
      setTemplateCheckTypes(newCheckTypes)
    }

  return (
    <Form onSubmit={() => submit()} loading={loading}>
      <Form.Input
        label="Template Name"
        placeholder="Template Name"
        required
        name="templateName"
        onChange={changeTemplateName}
        value={templateName}
        readOnly={!props.enableEdit}
      />
      <Form.Input
        label="Template Description"
        placeholder="Template Description"
        required
        name="templateDescription"
        onChange={changeTemplateDescription}
        value={templateDescription}
        readOnly={!props.enableEdit}
      />
      <Divider horizontal section>
        Select Checks
      </Divider>
      <DeliveryCheckDefinitionsTable
        checkDefinitions={props.deliveryCheckDefinitions}
        editable={props.enableEdit}
        templateCheckTypes={templateCheckTypes}
        createCheckboxHandler={createCheckboxHandler}
      />
      <Divider horizontal section />
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column floated="left">
            <Form.Button
              id="modal-submit-no-padding"
              type="submit"
              className="modal-submit-no-padding"
              content="Save"
              loading={submitting}
              fluid
              disabled={!allowSubmit()}
            />
          </Grid.Column>
          <Grid.Column floated="right" verticalAlign="bottom">
            <Form.Button type="reset" content="Reset" onClick={resetTemplate} fluid />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  )
}
