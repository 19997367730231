import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IFeatureFlags, setTenantFeatures } from '../../actions/Tenants'
import { createErrorToast, createSuccessToast } from '../../views/alertComponents/Alert'

export const useFeatureMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (props: { tenantId: string; teamAPI: string; patch: IFeatureFlags; successCallback: () => void }) =>
      setTenantFeatures(props.tenantId, props.patch, props.teamAPI),
    {
      onSuccess: (data, template) => {
        const patchStr = Array.from(template.patch.keys())
          .map(key => `${key}: ${template.patch.get(key)}`)
          .join(', ')
        createSuccessToast(`Successfully updated the following tenant features: ${patchStr}.`, {
          time: 5000 + 5000 * Object.entries(template.patch).length
        })
        queryClient.invalidateQueries(['features', template.tenantId])
        template.successCallback()
      },
      onError: error => {
        createErrorToast(error)
      }
    }
  )
}
