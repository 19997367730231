import { IResult } from './Utils'
import { regionalApiServer } from './Endpoints'

export interface IConnectedFunction {
  name: string
  packageName: string
  description: string
  runtime: string
  modifiedDate: string
  createdBy: string
  specVersion: string
  lambdaName?: string
}

export const getConnectedFunctionsByTenantId = (tenantId: string, region: string) =>
  regionalApiServer(region)
    .get<IResult<IConnectedFunction[]>>(`/pkgr/internal/functions/${tenantId}`)
    .then(response => response.data.result)
