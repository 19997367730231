import { Checkbox } from 'semantic-ui-react'
import * as React from 'react'
import { MetadataTypes, MetadataValue } from '../../../services/DynamicMetadataService'
import { JsonEditableViewer } from './JsonEditableViewer'

export type DynamicValueDisplayProps =
  | DynamicValueDisplayJsonProps
  | DynamicValueDisplayStringNumber
  | DynamicValueDisplayBoolean
  | DynamicValueDisplayUndefined

interface DynamicValueDisplayJsonProps extends DynamicValueDisplayBaseProps {
  type: 'json'
  value: Record<string, MetadataValue> | MetadataValue[]
}

interface DynamicValueDisplayStringNumber extends DynamicValueDisplayBaseProps {
  type: 'string' | 'number'
  value: string | number
}

interface DynamicValueDisplayBoolean extends DynamicValueDisplayBaseProps {
  type: 'boolean'
  value: boolean
}

interface DynamicValueDisplayUndefined extends DynamicValueDisplayBaseProps {
  type: 'undefined'
  value: undefined
}

interface DynamicValueDisplayBaseProps {
  type: MetadataTypes
  value: MetadataValue
}

export const DynamicValueDisplay: React.FC<DynamicValueDisplayBaseProps> = ({ type, value }) => {
  if (type === 'string' || type === 'number') {
    return <div>{value}</div>
  }
  if (type === 'boolean') {
    return <Checkbox checked={value as boolean} />
  }
  if (type === 'json') {
    return <JsonEditableViewer editable={false} value={JSON.stringify(value)} />
  }
  return <div>undefined</div>
}
