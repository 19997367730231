import { Button, Form, Modal, Segment, Table } from 'semantic-ui-react'
import { ICustomer } from '../../../actions/Customers'
import { InputWithError } from '../../formComponents/Inputs'
import { useMigrateCustomerService } from '../../../services/MigrateCustomerService'
import { TableLoaderWrap } from '../../../views/tableComponents/TableLoader'
import { ITeam } from '../../../actions/Teams'
import { RegionSelect } from '../../../utils/regionSelect'
import { MigrateTeamRow } from './MigrateTeamRow'

export interface IMigrateCustomerModalProps {
  customer: ICustomer
  teams: ITeam[]
  setRedirect: (redirectUrl: string) => () => void
}

export const errorCustomerNames = {
  errorRequiredCustomerName: 'Customer name is required',
  errorNotValidCustomerName:
    'Customer name can only contain alphanumeric, space, apostrophe or hyphen. Must not start or end with whitespace.'
}

export const MigrateCustomerModal = ({ teams, customer, setRedirect }: IMigrateCustomerModalProps) => {
  const {
    migrateCustomerAndTeam,
    isInvalidCustomerName,
    setCustomerName,
    setCustomerSalesforceId,
    setSelectedRegion,
    toggleCustomerIsInternal,
    customerName,
    customerSalesforceId,
    customerIsInternal,
    modalIsOpen,
    toggleModalIsOpen,
    isReadyMigrate,
    loading,
    showSalesforceIdError,
    addMigratedTenantId,
    removeMigratedTenantId,
    migratedTenantIds,
    newTeamsRequest,
    updateTeamRequest,
    setIsInvalidTeamsRequest,
    environmentOptions,
    regionsInfo,
    selectedRegion
  } = useMigrateCustomerService(customer, teams, setRedirect)

  const renderHeader = () => (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Migrate</Table.HeaderCell>
        <Table.HeaderCell>Team Name</Table.HeaderCell>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <Table.HeaderCell>Region</Table.HeaderCell>
        <Table.HeaderCell>Tenant ID</Table.HeaderCell>
        <Table.HeaderCell>Salesforce</Table.HeaderCell>
        <Table.HeaderCell>Owner</Table.HeaderCell>
        <Table.HeaderCell>Team Environment Type</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  )

  const renderTeamRow = (tenantId: string) => (
    <MigrateTeamRow
      key={`table-row-${tenantId}`}
      tenantId={tenantId}
      environmentOptions={environmentOptions}
      newTeamsRequest={newTeamsRequest}
      migratedTenantIds={migratedTenantIds}
      updateTeamRequest={updateTeamRequest}
      addMigratedTenantId={addMigratedTenantId}
      removeMigratedTenantId={removeMigratedTenantId}
      setIsInvalidTeamsRequest={setIsInvalidTeamsRequest}
    />
  )

  const renderTeamsTable = (team: ITeam) =>
    team.tenants && team.tenants?.length > 0 ? team.tenants.map(tenant => renderTeamRow(tenant.tenantId)) : null

  return (
    <Modal
      trigger={
        <Button className="form-button-sked-red" onClick={toggleModalIsOpen}>
          Migrate Customer
        </Button>
      }
      open={modalIsOpen}
      closeOnDimmerClick
      onClose={toggleModalIsOpen}
      closeIcon
      size="fullscreen"
      mountNode={document.getElementById('root')}
    >
      <Modal.Header id="modal-header-add-customer">Migrate Customer</Modal.Header>
      <Modal.Content>
        <Form onSubmit={migrateCustomerAndTeam} loading={loading}>
          <p>
            Use this form to migrate this Customer record and its associated Teams and Tenants to the new Team model.{' '}
            <a
              href="https://skedulo.atlassian.net/wiki/spaces/ProductDevelopment/pages/2395799569/New+Team+Model+-+Migrations+overview"
              target="_blank"
              rel="noreferrer"
            >
              <b style={{ fontWeight: 800, textDecoration: 'underline' }}>
                Click here to read more about what this migration means.
              </b>
            </a>
          </p>
          <b>Customer Name</b>
          <InputWithError
            required
            placeholder="Customer Name"
            labelMessage={
              isInvalidCustomerName
                ? errorCustomerNames.errorNotValidCustomerName
                : errorCustomerNames.errorRequiredCustomerName
            }
            error={!customerName || isInvalidCustomerName}
            labelSelector={!customerName || isInvalidCustomerName}
            name="customerName"
            value={customerName}
            onChange={(_, data) => setCustomerName(data.value)}
            style={{ marginLeft: '1em', marginRight: '1em' }}
          />

          <b>Is Internal</b>
          <Form.Checkbox
            toggle
            checked={customerIsInternal}
            onClick={toggleCustomerIsInternal}
            style={{ marginLeft: '1em', marginRight: '1em' }}
            label="This should only be enabled for Customer records that are only used for internal purposes e.g. Demo, Test, Development."
          />

          {!customerIsInternal && (
            <>
              <b>Salesforce Id</b>
              <InputWithError
                placeholder="Salesforce ID"
                labelMessage="Must be a valid salesforce account id."
                error={showSalesforceIdError}
                labelSelector={showSalesforceIdError}
                name="salesforceId"
                value={customerSalesforceId ?? ''}
                onChange={(_, data) => setCustomerSalesforceId(data.value)}
                style={{ marginLeft: '1em', marginRight: '1em' }}
              />
            </>
          )}

          <b>Customer Region</b>
          <RegionSelect
            setSelectedRegion={setSelectedRegion}
            regionsInfo={regionsInfo}
            selectedRegion={selectedRegion}
          />

          <hr />
          <b>Teams</b>
          <p>
            Select which teams to be migrated to the new model as part of this Customer. Teams with multiple tenants
            will need to get new Team records created. Production Teams are required to be migrated. You must set a
            Environment Type and Owner for each team if it does not have it set already.
          </p>
          <Segment color="blue" style={{ flexGrow: 0 }}>
            <Table celled basic="very" stackable size="small">
              {renderHeader()}
              <TableLoaderWrap
                loading={false}
                array={teams}
                emptyMessage="No teams loaded. This customer does not have team."
              >
                <Table.Body>{teams.map(team => renderTeamsTable(team))}</Table.Body>
              </TableLoaderWrap>
            </Table>
          </Segment>
          <Form.Button
            type="submit"
            id="modal-button-add-customer"
            fluid={true}
            loading={loading}
            disabled={!isReadyMigrate}
          >
            Migrate Customer
          </Form.Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
