import { Checkbox, Grid, Icon } from 'semantic-ui-react'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { useLocalStorage } from 'usehooks-ts'
import { useDeploymentsQuery } from '../../queries/deployments/GetDeploymentsQuery'
import { useDeploymentModal } from '../../hooks/useDeploymentModal'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { FilterCategoryOptions, Filters } from '../filterComponents/Filters'
import { FilterSearch } from '../filterComponents/FilterSearch'
import { DeployApplicationModal } from './components/DeployApplicationModal'
import { DeploymentsList } from './components/DeploymentsList'

export const invalidateDeployments = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({ queryKey: ['deployments'] })
}

export const Deployments = () => {
  const filterCategories = new Map<string, FilterCategoryOptions>([
    ['application', { text: 'Application' }],
    ['env', { text: 'Environment' }],
    ['instance', { text: 'Instance' }],
    ['tag', { text: 'Tag' }],
    ['config_hash', { text: 'Config' }]
  ])

  const [displayLocalTime, setDisplayLocalTime] = useLocalStorage('display-local-time', false)
  const [showConfigVersion, setShowConfigVersion] = useLocalStorage('show-config-version', false)

  useDocumentTitle('Deployments')

  const modal = useDeploymentModal()

  const queryClient = useQueryClient()

  const { status, data: deployments } = useDeploymentsQuery()

  if (deployments) {
    filterCategories.set('application', {
      text: 'Application',
      values: deployments.map(deployment => deployment.application)
    })
  }

  return (
    <div className="route-component">
      <Grid columns={16} stackable verticalAlign="middle">
        <Grid.Column>
          <Icon
            size="large"
            name="refresh"
            onClick={() => invalidateDeployments(queryClient)}
            loading={status === 'loading'}
            className="clickable"
          />
        </Grid.Column>
        <Grid.Column width={2} textAlign="left" verticalAlign="middle">
          <FilterSearch category="application" />
        </Grid.Column>
        <Grid.Column width={8} textAlign="left" verticalAlign="middle">
          <Filters filterCategories={filterCategories} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Checkbox
            label={<label htmlFor="displayLocalTime">Show local times</label>}
            id="displayLocalTime"
            toggle
            checked={displayLocalTime}
            onChange={(_, data) => {
              setDisplayLocalTime(!!data.checked)
            }}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Checkbox
            label={<label htmlFor="showConfigVersion">Show config versions</label>}
            id="showConfigVersion"
            toggle
            checked={showConfigVersion}
            onChange={(_, data) => {
              setShowConfigVersion(!!data.checked)
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <DeployApplicationModal modal={modal} withButton />
        </Grid.Column>
      </Grid>
      <DeploymentsList
        loading={status === 'loading'}
        deployments={deployments || []}
        filterCategories={filterCategories}
        modal={modal}
        displayLocalTime={displayLocalTime}
        showConfigVersion={showConfigVersion}
      />
    </div>
  )
}
